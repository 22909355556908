import {COURSEINFO, LETTERDIALOG, SAVE_HANDOUT_CATEGORY} from '../mutation-types';

// 下划线转换驼峰
export const toHump = (name) => {
    return name.replace(/_(\w)/g, function(all, letter){
        return letter.toUpperCase();
    });
};

// 驼峰转换下划线
export const toLine = (name) => {
    return name.replace(/([A-Z])/g,'_$1').toLowerCase();
};

export const toTriggerLineOrHump = (obj, isLine = true) => {
    const newObj = {};
    for (let key in obj) {
        newObj[isLine ? toLine(key) : toHump(key)] = obj[key];
    }
    return newObj;
};

const state = {
    course_info: {
        allow_download: '',
        allow_exam: '',
        allow_investigate: '',
        allow_plan: '',
        allow_question: '',
        audition: '',
        brief_introduction: '',
        course_id: '',
        course_name: '',
        course_type: '',
        cover: '',
        cover_default: '',
        investigate_url: '',
        project: {project_id: 0, project_name: '', subject_list: null},
        project_id: '',
        subject: {subject_id: 0, subject_name: ''},
        subject_id: '',
        course_setting: {}
    }, //课程所有信息
    nickname: '', //学生昵称
    brief_introduction: '', //课程简介
    course_name: '', //课程名称
    welcome_letter: '', //欢迎信
    teacher_name: '', //老师名字
    letterDialog: false, //信封弹层
    project_name: '', //项目名称
    bWelcomeLetter: '', //是否启用介绍信
    // handout_category_open: 0,//当前课程下讲义是否设置讲义分类
}

const actions = {
    saveCourseInfoAndSetting({commit, state}, data) {
        commit(COURSEINFO, data)
    },
    changeLetterDialog({commit, state}, data) {
        commit(LETTERDIALOG, data)
    },
    // saveHandoutCategory({commit, state}, data) {
    //     commit(SAVE_HANDOUT_CATEGORY, data);
    // }
}

const mutations = {
    [COURSEINFO](state, payload) {
        const data = toTriggerLineOrHump(payload);
        if (data.course_setting) {
            data.course_setting = toTriggerLineOrHump(data.course_setting)
        }
        if (data.project) {
            data.project = toTriggerLineOrHump(data.project)
        }
        if (data.subject) {
            data.subject = toTriggerLineOrHump(data.subject)
        }
        state.course_info = data;
        state.nickname = data.student && data.student.nickname;
        state.brief_introduction = data.brief_introduction;
        if (data.welcome_letter_type == 1) {
            state.welcome_letter = data.welcome_letter_template; //1是通用模板
        } else if (data.welcome_letter_type == 2) {
            state.welcome_letter = data.welcome_letter; //2是自定义欢迎新
        }
        state.teacher_name = data.teacher_name;
        state.project_name = data.project.project_name;
        state.course_name = data.course_name;
        state.bWelcomeLetter = data.welcome_letter_type == 0 ? false : true;
    },
    [LETTERDIALOG](state, data) {
        state.letterDialog = data;
    },
    // [SAVE_HANDOUT_CATEGORY](state, data) {
    //     state.handout_category_open = data
    // }
}

export default {
    state,
    actions,
    mutations,
}
