import axios from 'axios';
import {getBaseUrl, getEnv} from '../util/config'
import {Message} from 'element-ui';
import {ACCESS_TOKEN} from '../util/keys';
// import Vue from "../common/Vue";
const queryString = require('querystring');
import {getCookie, setCookie} from '../util/cookie.js';

let siteEnv = getEnv();
let prefix = getEnv();

var instance = axios.create({
    baseURL: '//',
    // headers: {
    //     'Content-Type': 'application/json'
    // },
    responseType: 'blob'
});

instance.interceptors.request.use(async function(config) {

    let token = getCookie(ACCESS_TOKEN);

    if (config.url.indexOf('refreshtoken') === -1 && token != undefined) {
        config.headers.common['Authentication'] = `Basic ${token}`;
        return Promise.resolve(config);
    }
    return Promise.resolve(config);
}, function(error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function(response) {

    let saas_url = location.href;
    saas_url = encodeURIComponent(saas_url);

    // 登录失效 553649410～553649444
    if (response.config.url.indexOf('refreshtoken') === -1 && response.data.status > 553649000 && response.data.status < 563649999) {
        location.href = `https://${siteEnv}v.gaodun.com/Member/logout?saas_url=${saas_url}`;
        return Promise.resolve(response);
    }

    return Promise.resolve(response);
}, function(error) {
    return Promise.reject(error);
});

const get = instance.get.bind(instance);

export const downloadSrc = path => get(`${getBaseUrl()}apigateway.gaodun.com/calais/front/v1/download?path=${path}`);

