<template>
    <li
        :class="['aside-node', 'aside-resource-node', className]"
        :title="data.name"
        @click="handleClick"
    >
        <i class="aside-resource-mark" v-if="resourceLock" />
        <i :class="['aside-resource-progress', progressClassName]" />
        <span
            :class="['aside-resource-icon', data.resource.discriminator]"
            v-if="data.resource"
        >
            <span>{{ data.resource.discriminator | Resource2chn }}</span>
        </span>
        <span style="vertical-align: middle">{{ data.name }}</span>
    </li>
</template>

<script>
import { bindEvents } from '../../../util/sensor-data';
import { W2_STUDYVIDEO_CLICK_SYLLABUSTREE } from "@/config";

export default {
    name: "AsideResourceNode",
    props: {
        data: Object,
        parentId: Array,
        currentResourceId: Number,
    },
    data() {
        return {};
    },
    watch: {
        isActive(newVal) {
            if (newVal) {
                const data = {
                    parentId: this.parentId,
                    resource: this.data,
                };
                this.$emit("on-current-resource-parents", data);
            }
        },
    },
    computed: {
        courseCheck() {
            return +this.$store.state.home.course_info.audition;
        },
        isActive() {
            return this.currentResourceId === this.data.resource_id;
        },
        className() {
            if (this.isActive) {
                return "active";
            }
            return "";
        },
        progressClassName() {
            switch (this.data.progress) {
                case 1:
                    return "aside-resource-progress2";
                case 2:
                    return "aside-resource-progress3";
                default:
                    return "";
            }
        },
        resourceLock() {
            const isAuditionResource =
                this.data.audition === 1 &&
                this.data.resource &&
                this.data.resource.discriminator === "video";
            return this.courseCheck === 1 && !isAuditionResource;
        },
    },
    mounted() {
        if (this.isActive) {
            const data = {
                parentId: this.parentId,
                resource: this.data,
            };
            this.$emit("on-current-resource-parents", data);
        }
    },
    methods: {
        handleClick() {
            this.$emit("on-click", this.data);
            let courseType;
            if (this.data.hasOwnProperty('resource') && this.data.resource.hasOwnProperty("discriminator")) { 
                switch (this.data.resource.discriminator) {                
                    case "lecture_note":
                        courseType = "讲义";
                        break;
                    case "video":
                        courseType = "视频";
                        break;
                    case "legacy_live":
                        courseType = "直播";
                        break;
                    case "live_new":
                        courseType = "直播";    
                        break;
                    case "paper":
                        courseType = "试卷";
                        break;
                    default:
                }  
                if (courseType) { 
                    bindEvents(W2_STUDYVIDEO_CLICK_SYLLABUSTREE, { course_task_type: courseType });
                }
            }
        },
    },
};
</script>
