import {
    SAVE_TEST_DATA,
    RECOMBINE_DATA,
    NEXT_EXERCISE,
    PREV_EXERCISE,
    SAVE_EXERCISE_ANSWER,
    NEXT_SM_EXERCISE,
    PREV_SM_EXERCISE,
    CHANGE_SM_EXERCISE,
    CHANGE_TEST_SHEET,
    FAVORITE_EXERCISE,
    SET_IS_COMMIT,
} from '../mutation-types';
import { Message } from 'element-ui';
import { question_type } from '../../common/paperConfig.js';

const state = {


    question_data: [], //题目列表

    recm_question_data: [], //按题目类型重组的试卷


    total_question: '', //试卷的总题数(不包含综合题下的小题)

    cur_question: '', //当前的题目
    cur_type: '', //当前的题目的类型

    cur_question_index: '', //当前的题目的索引
    cur_userAnswer: '', //当前的题目的用户的答案
    cur_question_id: '', //当前的题目的id

    cur_question_yanswer: '', //当前的题目的正确答案

    cur_question_analysis: '', //当前的题目的解析

    cur_question_sorts: '', //当前的题目的题序

    question_is_video: '', //是否可以视频解析

    video_analysis: '', //视频解析html

    cs_item_id: '', //所属大纲条目id

    //用户答案 正确答案 题目id 解析 题序 是否视频解析  视频解析地址 所属大纲条目id => 可以共用一个变量

    cur_Sm_question: '', //当前题目为综合题时,当前的小题

    cur_Sm_type: '', //当前题目为综合题时,当前的小题的类型

    cur_Sm_question_index: 0, //当前题目为综合题时,当前的小题的索引

    last_question: false, //判断是否是试卷的最后一题

    last_Sm_question: false, //判断是否是综合题的最后一题

    paper_cur_favorite: '', //当前的题目是否收藏

    question_cur_is_analysis: false//当前题目有没有交卷过
}

const actions = {
    //保存试卷信息
    saveTestData({ commit, state }, data) {
        commit(SAVE_TEST_DATA, data)
        commit(RECOMBINE_DATA, data) //重组试卷按题目类型recombine
    },
    nextExercise({ commit, state }, data) {
        commit(NEXT_EXERCISE, data)
    },
    prevExercise({ commit, state }, data) {
        commit(PREV_EXERCISE, data)
    },
    //保存用户的答案
    saveExerciseAnswer({ commit, state }, data) {
        // let answer = {...state.cur_userAnswer };
        let answer = data;
        commit(SAVE_EXERCISE_ANSWER, answer)
    },
    nextSmExercise({ commit, state }, data) {
        commit(NEXT_SM_EXERCISE, data)
    },
    prevSmExercise({ commit, state }, data) {
        commit(PREV_SM_EXERCISE, data)
    },
    //切换综合题下小题
    changeSmExercise({ commit, state }, index) {
        commit(CHANGE_SM_EXERCISE, index)
    },
    //切换答题卡
    changeTestSheet({ commit, state }, index) {
        commit(CHANGE_TEST_SHEET, index)
    },
    //收藏
    favoriteExercise({ commit, state }, status) {
        commit(FAVORITE_EXERCISE, status)
    },
    setIsSubmit({ commit, state }, status) {
        commit(SET_IS_COMMIT, status)
    },
}

const saveVideoUri=(state, child)=>{
    state.video_analysis = child.video_analysis||{}; //当前的题目是否有视频解析
    state.question_is_video = !!child.video_analysis; //当前的题目是否有视频解析
    state.video_analysis.uri = child.video_analysis && child.video_analysis.source_id;
}

//保存综合题目小题
const saveSmQuestion = (state, index = 0, cur_Sm_question_index) => {
    const children = state.cur_question && state.cur_question.sonitem ||[];
    if (children.length == 0)
    {
        state.cur_Sm_question = null;
        state.cur_Sm_question_index = 0;
        return;
    }

    const child = children[index];
    state.cur_Sm_question = child;
    state.cur_Sm_type = child.type;
    state.cur_Sm_question_index = index+1;
    state.cur_userAnswer = child.user_answer; //当前的题目的用户的答案
    state.cur_question_id = child.question_id; //当前的题目的id
    state.cur_question_yanswer = child.answer; //当前的题目的正确答案
    state.cur_question_analysis = child.analysis; //当前的题目的解析
    state.cur_question_sorts = child.sorts; //当前的题目的题序
    state.cs_item_id = child.item_id; //
    saveVideoUri(state, child);
}

const saveOtherQuestion = (state, index=0) => {
    const data = state.question_data;
    index = index < 0 ? 0 : index;
     //其他单体题型
    state.cur_question_id = data[index].id;
    state.cur_userAnswer = data[index].user_answer;
    state.cur_question_yanswer = data[index].answer;
    state.cur_question_analysis = data[index].analysis;
    // state.cur_question_sorts = data[index].sorts;
    state.question_is_video = data[index].is_video;
    state.cs_item_id = data[index].item_id;
    saveVideoUri(state, data[index]);
}

const mutations = {
    [SAVE_TEST_DATA](state, data) {
        state.question_data = data;
        state.total_question = data.length;
        if (data.length == 0) return;
        for (const key in data) {
            const element = data[key];
            element.is_analysis = false;
            element.user_answer = '';
            if(element.type == 5 && element.sonitem){
                for (const ckey in element.sonitem) {
                    const item = element.sonitem[ckey];
                    item.user_answer = '';
                }
            }
        }
        state.cur_question = data[0]; //默认拿到第一题
        state.cur_question_index = 1;
        state.cur_type = data[0].type;
        state.paper_cur_favorite = data[0].is_favorite;
        if (state.cur_type == 5) {
            //5=>综合题
            saveSmQuestion(state, 0 , 1);

        } else {
            //其他单体题型
            saveOtherQuestion(state, 0);
        }
    },
    [SET_IS_COMMIT](state, data){
        state.question_data[state.cur_question_index -1].is_analysis = true;
    },
    [RECOMBINE_DATA](state, data) {
        // var data = [...data];
        var recm_obj = {};
        var recm_data = [];
        for (var i = 0; i < data.length; i++) {
            if (recm_obj.hasOwnProperty(data[i].type)) {
                recm_obj[data[i].type].push(data[i]);
            } else {
                recm_obj[data[i].type] = [data[i]];
            }
        }
        for (var id in recm_obj) {
            for (var i = 0; i < question_type.length; i++) {
                if (question_type[i].id == id) {
                    recm_data.push({ title: question_type[i].name, questions: recm_obj[id] })
                }
            }
        }
        state.recm_question_data = recm_data;
    },
    [NEXT_EXERCISE](state) {
        //先拿到当前的索引
        //然后判断是不是题目列表的的最后一题了
        // 如果不是则继续拿下一个题目
        // 如果是的则提示用户已做完
        //每次拿下一个题目时都需要判断是不是综合题
        if (state.cur_question_index < state.question_data.length) {
            const index = state.cur_question_index;

            state.cur_question = state.question_data[index];
            state.question_cur_is_analysis = state.cur_question.is_analysis ;
            state.cur_type = state.question_data[index].type;
            state.paper_cur_favorite = state.question_data[index].is_favorite;
            if (state.cur_type == 5) {
                //5=>综合题
                saveSmQuestion(state, 0 , 1);
                // state.cur_Sm_question_index = 1;
            } else {
                //其他单体题型
                saveOtherQuestion(state, index);
            }
            state.cur_question_index++;
        } else {
            Message({
                message: '后面没有题了哦',
                showClose: true,
            });
        }
        //交卷按钮的显示隐藏
        //这个题目是最后一个题目
        if (state.cur_question_index == state.question_data.length) {
            state.last_question = true;
        } else {
            state.last_question = false;
        }

    },
    [PREV_EXERCISE](state) {
        //先拿到当前的索引
        //然后判断是不是题目列表的第一道题了
        // 如果不是则继续拿题目列表的前一次题目
        // 如果是的则则提示用户已做完
        if (state.cur_question_index != 1) {
            const index = state.cur_question_index - 2;
            state.cur_question = state.question_data[index];
            state.question_cur_is_analysis = state.cur_question.is_analysis ;
            state.cur_type = state.question_data[index].type;
            state.paper_cur_favorite = state.question_data[index].is_favorite;
            if (state.cur_type == 5) {
                //5=>综合题
                saveSmQuestion(state, 0 , 1);
            } else {
                //其他单体题型
                saveOtherQuestion(state, index);
            }
            state.cur_question_index = index+1;
        } else {
            Message({
                message: '前面没有题了哦',
                showClose: true,
            });
        }
        //交卷按钮的显示隐藏
        //这个题目是最后一个题目
        if (state.cur_question_index == state.question_data.length) {
            state.last_question = true;
        } else {
            state.last_question = false;
        }
    },
    [SAVE_EXERCISE_ANSWER](state, answer) {
        state.cur_userAnswer = answer;
        if (state.cur_type == 5) {
            state.question_data[state.cur_question_index - 1].sonitem[state.cur_Sm_question_index - 1].user_answer = answer;
        }else{
            state.question_data[state.cur_question_index - 1].user_answer = answer;
        }
    },
    [NEXT_SM_EXERCISE](state, data) {
        //先拿到当前的小题的索引
        //然后判断是不是这个题目的最后一个小题
        // 如果不是则继续拿这个题目的下一次小题
        // 如果是的则提示用户已做完，
        if (state.cur_Sm_question_index < state.cur_question.sonitem.length) {

            saveSmQuestion(state, state.cur_Sm_question_index, state.cur_Sm_question_index++);

        } else {
            //message: '综合题已经做完了...后续功能正在开发中...',
        }
        //切换综合题小题时是否为最后一个小题
        if (state.cur_Sm_question_index == state.cur_question.sonitem.length) {
            state.last_Sm_question = true;
        } else {
            state.last_Sm_question = false;
        }
    },
    [PREV_SM_EXERCISE](state, data) {
        //先拿到当前的小题的索引
        //然后判断是不是这个题目的第一个小题
        // 如果不是则继续拿这个题目的上一个小题
        // 如果是的则提示用户已经是第一题了，
        if (state.cur_Sm_question_index != 1) {
            saveSmQuestion(state, state.cur_Sm_question_index - 2, state.cur_Sm_question_index -1);
        } else {
            /*Message({
                message: '综合题第一题...后续功能正在开发中...',
                showClose: true,
            });*/
        }
        //切换综合题小题时是否为最后一个小题
        if (state.cur_Sm_question_index == state.cur_question.sonitem.length) {
            state.last_Sm_question = true;
        } else {
            state.last_Sm_question = false;
        }
    },
    [CHANGE_SM_EXERCISE](state, index) {
        index = Number(index);
        //切换小题
        saveSmQuestion(state, index, index+1);
        //切换综合题小题时是否为最后一个小题
        if (state.cur_Sm_question_index == state.cur_question.sonitem.length) {
            state.last_Sm_question = true;
        } else {
            state.last_Sm_question = false;
        }
    },
    [CHANGE_TEST_SHEET](state, index) {
        let question_index = Number(index.question_index);
        let sm_question_index = index.sm_question_index ? Number(index.sm_question_index) : 0;
        // const currIndex = question_index + 1;

        //切换 当前题目 当前小题...
        state.cur_question = state.question_data[question_index];
        state.question_cur_is_analysis = state.cur_question.is_analysis ;
        state.cur_type = state.question_data[question_index].type;
        state.paper_cur_favorite = state.question_data[question_index].is_favorite;
        if (state.cur_type == 5 ) {
            //5=>综合题
            saveSmQuestion(state, sm_question_index, sm_question_index + 1);
        } else {
            // cur_Sm_question
            //其他单体题型
            saveOtherQuestion(state, question_index);
        }
        state.cur_question_index = question_index + 1;
        //交卷按钮的显示隐藏
        //这个题目是最后一个题目
        if (state.cur_question_index == state.question_data.length) {
            state.last_question = true;
        } else {
            state.last_question = false;
        }
    },
    [FAVORITE_EXERCISE](state, status) {
        state.paper_cur_favorite = status;
        state.question_data[state.cur_question_index - 1].is_favorite = status;
    },
}

export default {
    state,
    actions,
    mutations,
}
