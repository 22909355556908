<style lang="less">
    .g2-video-study {
        .video-content {
            width: 100%;
            height: 100%;
            position: relative;
            &.g2-video-study {
                height: 100%;
            }
        }
    }

    .buy-course-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/images/shiting/video-bg.png");
        background-size: cover;
        color: #fff;
        text-align: center;
    }
    .padding-r {
        padding-right: 320px;
    }
    .margin-r {
        margin-right: 320px;
    }
    .vipt {
        font-size: 30px;
        margin: 150px 0 35px;
    }
    .text {
        font-size: 16px;
        line-height: 30px;
    }
    .buy-btn {
        width: 180px;
        color: #fff;
        background: #35c25e;
        margin-top: 40px;
    }
    .small-icon {
        width: 105px;
        height: 23px;
    }
    .full-screen  {
        background-image: url(https://simg01.gaodunwangxiao.com/uploadfiles/tmp/upload/202106/11/7ca7a_20210611153535.png);
        background-size: 100% 100%;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 80px;
        bottom: 8px;
        z-index: 10000;
        cursor: pointer;
    }
    .exit-full-screen {
        background-image: url(http://simg01.gaodunwangxiao.com/uploadfiles/tmp/upload/202106/11/4b5a8_20210611154330.png);
        background-size: 100% 100%;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 80px;
        bottom: 8px;
        z-index: 10000;
        cursor: pointer;
    }

</style>
<template>
    <div class="video-study g2-video-study" v-loading="isLoading"  element-loading-background="rgba(40, 40, 40, 0.8)">
        <div class="video-content clear" v-if="videoId">
            <SubPlayer 
                v-if="simplifyRecordUrl"
                 ref="qiankunPlayer"
                :video_id="simplifyRecordUrl" 
                :initPlayer="initPlayer"
                :businessType="100"
                :sceneType="110"
                :callbackSpeed="callbackSpeed"
                :businessNote="businessNote"
                />
            <div class="buy-course-video" v-if="showBuyCor">
                <p class="vipt" :class="{ 'padding-r': isCollapse }">
                    <img
                        class="small-icon"
                        src="../../assets/images/shiting/left-icn.png"
                    />
                    <span>{{ $t("videoPlay.vip") }}</span>
                    <img
                        class="small-icon"
                        src="../../assets/images/shiting/right-icn.png"
                    />
                </p>
                <p class="text" :class="{ 'padding-r': isCollapse }">
                    {{ $tc("videoPlay.becomeVip", 1) }}<br />
                    {{ $tc("videoPlay.becomeVip", 3) }}
                </p>
                <el-button
                    type="success"
                    class="buy-btn"
                    :class="{ 'margin-r': isCollapse }"
                    @click="goBuy"
                    >{{ $t("videoPlay.buyVip") }}
                </el-button>
            </div>
        </div>
         <NpsReport v-if="isEnded" :sceneId="simplifyRecordUrl? 14 : sceneId" :courseId="Number(courseId)" :resourceId="Number(resourceId)" :npsShowType="1"/>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Vue from "../../common/Vue.js";
import PlayTool from "../videoStudy/PlayTool.vue";
import { reqResourceDetail, getLiveReplayUrl } from "../../api/study.js";
import { getEnv } from "../../util/config.js";
import lang from "../../language/lang";
import { G2ResourceReport } from "../../../src/mixins/index"
import NpsReport from "../../components/nps-report/NpsReport.vue";
import {SCENEINFO_MAP} from "../../components/nps-report/const";
import SubPlayer from "../videoStudy/sub-player.vue";

let prefix = getEnv();
const QUALITY = 'g2-quality';
const CURRENT_POSITION = 'currentVideoPosition';

export default {
    components: {PlayTool,NpsReport,SubPlayer},
    mixins: [G2ResourceReport],
    data() {
        return {
            g2LivePlaybackUrl: {},
            // 实际播放时长计时
            realyPlayTime: 0,
            // 实际播放时长计算
            playTimeout: 0,

            playTime: 0,

            // 播放器实例
            player: null,

            // 是否是全屏
            isFull: false,
            // 正在全屏中
            isFulling: false,

            isLoading: false,
            //视频id
            videoId: "",
            // G2精简回放地址
            simplifyRecordUrl: '',
            //动画盒子
            videoPlay: "",
            toolsBox: "",
            //查询视频播放时间
            timer: "",
            //右侧工具弹框的叉叉显示隐藏
            toolsBoxclose: false,
            parentMsg: 1,
            // 不可试听显示提醒购课页
            showBuyCor: false,
            isCollapse: false,
            //视频是否第一次播放
            isFirstPlay: true,
            echoLoop: null,
            echoLoopTime: 10 * 60 * 1000,
            showUserTimer: null,
            //视频播放进度是否结束
            isProgressOver: false,
            //视频播放是否结束
            isEnded: false,
            student_id: 0,
            video_user_id: 0,
            sceneId:SCENEINFO_MAP.videoCourse,
            businessNote:{}
        };
    },
    computed: {
        courseId() {
            return this.$route.query.course_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
        gradationId() {
            return this.$route.query.gradation_id;
        },
        resourceId() {
            return this.$route.query.resource_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
        vid() {
            return this.$route.query.vid || "";
        },
        refSite() {
            return this.$route.query.refSite || "";
        },
        simplifyUrl() {
            return this.$route.query.simplifyUrl;
        },
        ...mapState({
            _isSendXhr: state => state.study.isSendXhr
        }),
    },
    mounted() {

        this.$nextTick(() => {
            this.videoPlay = this.$refs.videoPlay;
            this.toolsBox = this.$refs.toolsBox;
        });

        this.getResourceItem();
        Vue.$on("showBuyCourseVideo", (val) => {
            this.showBuyCor = val;
            if (this.player) {
                this.player.pause();
            }
        });
        window.onresize =  () => {
            const parent = document.getElementsByClassName('exit-full-screen');
            if (parent && parent.length) {
                if (!this.isFulling && parent[0]) {
                    this.resizeWindow(this.isFull, parent[0]);
                    this.isFull = false;
                }
            }

        };
    },
    methods: {
        callbackSpeed(speedNum){
            const localStorageSpeed = localStorage.getItem('GplayerRate') || -1;
            if(+localStorageSpeed === speedNum){
                //切换清晰度
                // 立即上报
                this._resourceRepeat('changeVideoQuality');
                // 结束计时
                this._eReportEvent();
            }else{
                if (this._getVTimer()) {
                    // 立即上报
                    this._resourceRepeat('setSpeed');
                    // 结束计时
                    this._eReportEvent();
                    // 重新计时
                    this._sReportEvent();
                }
            }
        },
        ...mapMutations(['isSendXhr']),
        initPlayer(subPlayer){
            //TODO 切换的时候上报清晰度
            this.player = subPlayer;
            window.gdplayer = subPlayer;
            // const this = this;
            // 初始化视频开始时间
            this.initBeginTime()

            // 结束事件
            this.player.on('ended', () => {
                this.isEnded = true;
            });

            // 播放事件
            this.player.on('play', () => {
                if (!this.playTimeout) {
                    this.playTimeout = setInterval(() => { this.realyPlayTime++ ;}, 1000);
                }
                this.player && this._sReportEvent();
            })

            this.player.on('quality_end', () => {
                const ele = document.getElementsByClassName('gplayer-quality-icon')[0];
                const text = ele && ele.innerText || '';
                if (text) {
                    const videoObj = this.getQualitys(text);
                    localStorage.setItem(QUALITY, JSON.stringify(videoObj));
                }
            })

            // 暂停回调
            this.player.on('pause', () => {
                this.player && this._resourceRepeat('onpause');
            });
        },
        /**
         * @description
         * 初始化开始播放时间
         */
        initBeginTime() {
            setTimeout(() => {
                const position = localStorage.getItem(CURRENT_POSITION) || 0;
                if (Number(position)) {
                    this.player && this.player.seek(position);
                }
            }, 1000);

        },
        // 获取资源信息
        async getResourceItem() {
            this.isFirstPlay = true;
            this.isProgressOver = false;
            this.isEnded = false;
            const ret = await reqResourceDetail(this.resourceId, {
                courseId: this.courseId,
                syllabusId: this.syllabusId,
                csItemId: this.syllabusItemId,
            });
            if (ret.status !== 0 || !ret.result) {
                this.$message({ message: lang[window.GD_LANG].videoPlay.videoError});
                this.goResource404();
                return;
            }
            const live = ret.result.resource.live;
            this.videoId = ret.result.resource.live_id
            this.simplifyRecordUrl = live.simplifyRecordUrl
            if (live.statusOfLive === 4) {
                return  location.href = live.urlLivePlayBackPc
            }
            if (live.statusOfLive === 2 || live.statusOfLive === 1 || live.statusOfLive === 0) {
                return  location.href = live.urlLiveIsPlayingPc
            }
            if (live.statusOfLive === 3 ) {
                this.simplifyUrl = live.simplifyRecordUrl
            }
            this.businessNote = {
                courseId: this.courseId,
                csItemId: this.syllabusItemId,
                resourceId: this.resourceId,
                // "progressStatus": 0,// 资源完成状态 0：未完成，1：已完成，2：进行中
                liveId: live.id,
                // videoPlayerRecovery: 0,//  0：正常模式，1：灾备模式
                resourceType: ret.result.resource.discriminator
            };
            if(!live.simplifyRecordUrl){
                this.$message.warning("播放地址没请求到哦～");
            }

            const qiankunPlayer = this.$refs.qiankunPlayer;
            if(qiankunPlayer){
                qiankunPlayer.init(this.simplifyRecordUrl, this.businessNote);
            }
            this.addElementDiv();
        },
        // 跳转到资源404
        goResource404() {
            this.$router.replace({
                name: "Resource404",
                query: this.$route.query,
            });
        },
        /**
         * @description 设置播放时长
         * @param { number } time 播放时间
         */
        setPlayTime(time) {
            if ( time < 1 ) return
        },
        // 播放
        play() {
            this.player && this.player.play();
        },
        // 暂停
        pause() {
            const currentTime = this.player.video && this.player.video.currentTime;
            this.player && this.player.pause(currentTime);
        },
            //跳转到资源404
        goResource404() {
            this.$router.replace({
                name: "Resource404",
                query: this.$route.query,
            });
        },
        addElementDiv() {
            const parent = document.getElementsByClassName('gplayer-icons gplayer-icons-right');
            if (parent && parent.length) {
                const fullDiv = document.createElement('div');
                fullDiv.setAttribute('class', 'full-screen');

                fullDiv.onclick = () => {
                    this.resizeWindow(this.isFull, fullDiv);
                    this.isFull = !this.isFull
                    this.isFulling = true;
                    setTimeout(() => {
                        this.isFulling = false;
                    }, 500);
                };
                parent[0].appendChild(fullDiv);
            }
        },
        resizeWindow(isFull, div) {
            div.setAttribute('class', !isFull ? 'exit-full-screen' : 'full-screen');
            return !isFull ? this.player.fullScreen.request() : this.player.fullScreen.cancel();
        },
        goBuy() {
                window.open(
                    `//${prefix}v.gaodun.com/shopping/check/cid/${this.vid}.html`
                );
        },
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: {
            handler() {
                this.getResourceItem()
            },
            deep: true,
        }
    },
    created() {
    console.log("🚀 ~ created ~ created:")
    },
    beforeDestroy() {
        window.onresize = null;
        this.player = null;
    },
};
</script>
