<template>
    <el-dialog
        title="评价"
        :visible.sync="dialogVisible"
        size="tiny"
        :before-close="handleClose">
        <div class="alert__info">
            <i></i>
            <span>提交评价后该提问将不可再追问</span>
        </div>
        <div class="tools-note-box">
            <el-form :model="dataFrom" ref="dataFrom" class="note-From">
                <el-form-item class="input-form-item" prop="content"
                              :rules="filter_rules({type:'isAllSpace',min:0,max:100})">
                    <el-input type="textarea"
                              :autosize="{ minRows: 4, maxRows: 6}"
                              placeholder="添加内容..."
                              v-model="dataFrom.content">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <span class="gray_16">对本解答的评价：</span>
                    <el-rate v-model="rateVal" @change="handleChange"
                             :colors="['#35c25e', '#35c25e', '#35c25e']"></el-rate>
                </el-form-item>
                <el-form-item class="btn-form-item">
                    <el-button type="primary" size="small" class="gr_bor_btn" :loading="btnLoading" @click="handleClick('dataFrom')"
                    >{{btnLoading?'提交中...':'提交评价'}}
                    </el-button>

                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>
<script>
    import Vue from '../../common/Vue.js'

    export default {
        components: {},
        props: [
            'dialogVisible',
            'rateVal',
            'btnLoading'
        ],
        data() {
            return {
                rateVal:0,
                // dialogVisible: true,
                dataFrom: {
                    content: '',
                },
            }
        },
        computed: {},
        mounted() {
        },
        methods: {
            handleChange(val) {
                Vue.$emit('handleChange', val);
            },
            handleClick(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$emit('appraisal',this.dataFrom.content);
                    } else {
                        return false;
                    }
                });
            },
            handleClose(done){
                Vue.$emit('handleChange', 0);
                setTimeout(()=>{
                    done();
                },0)
            }
        },
        watch: {},
        created() {
        },
        beforeDestroy() {
        }
    }
</script>
