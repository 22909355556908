
export const loadingText = function () {
    // var data = [
    //     '不忘初心，方得始终',
    //     '嘿咻嘿咻拼命加载中',
    //     '勤能补拙是良训，一分辛劳一分才',
    //     '不愤不启，不悱不发',
    //     '聪明在于勤奋，天才在于积累',
    //     '没有求知欲的学生，就像没有翅膀的鸟儿',
    //     '智慧源于勤奋，伟大出自平凡',
    //     '业精于勤荒于嬉，行成于思毁于随',
    // ];
    // var num = Math.round(Math.random()*7);
    // return data[num];
    return '加载中...'
}
