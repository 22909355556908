<template>
    <div style="width: 100%;height: 100%;background: #282828;">
        <div class="resource404">
            <img class="pic-404__parent" src="../assets/images/resource404_2.png" alt="404">
            <p>{{$t('syllabus.empty')}}...</p>
            <p>{{$t('syllabus.comingSoon')}}!</p>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return{

            }
        }
    }
</script>
