<template>
    <div class="recordBox">
        <div class="w_1090 psoition-r">
            <!-- <NpsReport title="评价一下【提问】" :sceneId="sceneId" :courseId="Number($route.params.cid)"/> -->
            <NoteList
                :dataList="dataList"
                :zLoading="zLoading"
                :loadingText="loadingText"
            >
            </NoteList>
            <div class="no__content" v-show="no__content">
                {{ $t("noContent") }}
            </div>
        </div>
    </div>
</template>
<style scoped>
.no__content {
    height: calc(100vh - 420px);
    min-height: 330px;
}
.psoition-r{
    position: relative;
}
</style>
<script>
import { exercise_type } from "../../common/recordConfig.js";
import NoteList from "./RecordModuleNoteList.vue";
import { getStudyRecordStructure, getEP3NoteList } from "../../api/record.js";
import { loadingText } from "../../common/loadingTextConfig.js";
import NpsReport from "../../components/nps-report/NpsReport.vue";
import {SCENEINFO_MAP} from "../../components/nps-report/const";
import { bindEvents } from "../../util/sensor-data.js";
import { W2_VIEWSCREEN_COURSENOTE } from "@/config";

export default {
    components: {
        NoteList,
        NpsReport
    },
    data() {
        return {
            activeExe: exercise_type[0].action,
            exercise_type: exercise_type,
            dataList: [],
            zLoading: true,
            loadingText: "loading...",
            no__content: false,
            posi_fix_bg: false,
            count: 0,
            sceneId:SCENEINFO_MAP.note
        };
    },
    computed: {
        course_id() {
            return this.$route.params.cid;
        },
    },
    mounted() {},
    methods: {
        async getNodeTask() {
            this.zLoading = true;
            let params = {
                course_id: this.course_id,
                type: 3, //默认0-错题；1-对题；2-收藏题；3笔记
            };
            let res = await getStudyRecordStructure(params);
            if (res.status == 0) {
                const { result } = res;
                this.dataList = result || [];
                let noteSize = 0;
                for (let i = 0; i < this.dataList.length; i++) {
                    const item = this.dataList[i];
                    if (item.item_total > 0) {
                        noteSize += item.item_total;
                    }
                }
                this.no__content = noteSize == 0;
            } else {
                this.no__content = false;
            }
            this.zLoading = false;
        },
        countList(data) {
            if (!data) {
                return;
            }
            for (let i = 0; i < data.length; i++) {
                if (data[i].note_total > 0) {
                    if (data[i].syllabus && data[i].syllabus.length != 0) {
                        this.countList(data[i].syllabus);
                    }
                    if (data[i].children) {
                        this.count++;
                    }
                }
            }
        },
    },
    watch: {},
    created() {
        this.getNodeTask();
    },
    mounted() { 
        bindEvents(W2_VIEWSCREEN_COURSENOTE)
    },
    beforeDestroy() {
        document.getElementById("app").className = "";
    },
};
</script>
