<template>
    <div class="buy-course">
        <img class="logo-img" src="../../assets/images/shiting/logo.png"/>
        <p class="vip">
            <img class="small-icon" src="../../assets/images/shiting/left-icn.png"/>
            <span>{{$t('videoPlay.vip')}}</span>
            <img class="small-icon" src="../../assets/images/shiting/right-icn.png"/>
        </p>
        <p class="desc">{{$tc('videoPlay.becomeVip', 2)}} <br/>
            {{$tc('videoPlay.becomeVip', 3)}}</p>
        <el-button class="btn" @click="goBuy">{{$t('videoPlay.buyVip')}}</el-button>
    </div>
</template>

<script>
    import {getEnv} from '../../util/config.js'
    let prefix = getEnv();
    export default {
        name: "BuyCourse",
        computed: {
            vid(){
                return this.$route.query.vid || '';
            }
        },
        methods: {
            goBuy () {
                window.open(`//${prefix}v.gaodun.com/shopping/check/cid/${this.vid}.html`)
            }
        }
    }
</script>

<style scoped>
    .buy-course {
        text-align: center;
    }
    .vip {
        font-size: 20px;
        margin: 10px 0 20px;
        color: #4A4A4A;
    }
    .desc {
        font-size: 12px;
        color: #898B92;
        line-height: 18px;
    }
    .logo-img {
        width: 80px;
        height: 80px;
        margin-top: 40px;
    }
    .btn {
        width: 180px;
        color: #fff;
        background: #35C25E;
        margin-top: 40px;
    }
    .small-icon {
        width: 70px;
        height: 15px;
    }
</style>
