<template>
    <div class="handout-study" style="position: relative">
        <div
            v-if="isPdf"
            id="pdf-container"
            class="pdf-container"
            ref="pdfContainer"
            v-loading="loading"
            :element-loading-text="$t('loading')"
        />
        <div class="handout-download-box" v-else>
            <span>该格式文件暂不支持预览，请下载后查看</span>
            <el-button type="primary" round :loading="isLoading" @click="downloadFile">{{isLoading ? '下载中...' : '下载'}}</el-button>
        </div>
    </div>
</template>
<style>
.handout-download-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    justify-content: center;
    >span {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
    }
    .el-button {
        margin-top: 44px;
        width: 150px;
        height: 44px;
        font-size: 18px;
    }
}


.handout-study {
    height: 100%;
}

.pdf-container {
    width: 100%;
    height: 100%;
}

.handout-study .el-loading-mask {
    height: 100vh;
    background: #0f0f0f;
}

.handout-header {
    height: 60px;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
}

.handout-header .handout-header-tools {
    width: 320px;
    float: right;
    height: 100%;
}

.handout-header .handout-header-tools .tools-btn {
    border-left: solid 2px rgba(255, 255, 255, 0.2);
    float: right;
    width: 85px;
    height: 60px;
    font-size: 12px;
    color: #b0b2b7;
    line-height: 60px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.handout-footer {
    height: 48px;
    width: 100%;
    position: absolute;
    z-index: 200;
    bottom: 0;
}

.handout-content {
    width: 100%;
    overflow: hidden;
    top: 60px;
    position: absolute;
    z-index: 10;
    bottom: 48px;
}

.handout-view {
    /*width: 100%;*/
    height: 100%;
    /*margin-top: 60px;*/
    transition: all 0.3s linear;
    position: relative;
}

.handout-view .tools-btn {
    z-index: 99999;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 140px;
    text-align: center;
    color: #fffdef;
    font-size: 64px;
    cursor: pointer;
    transition: all 0.6s linear;
}

.handout-view .btn-prev {
    width: 70px;
    height: 140px;
    border-radius: 0px 70px 70px 0;
    left: -70px;
}

.handout-view .btn-next {
    width: 70px;
    height: 140px;
    border-radius: 70px 0px 0px 70px;
    right: -70px;
}

.handout-content:hover .btn-prev {
    left: 0;
}

.handout-content:hover .btn-next {
    right: 0;
}

.handout-view .progress-tools {
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -44px;
    left: 0;
    transition: all 0.5s linear;
}

.handout-content:hover .progress-tools {
    bottom: 0;
}

.handout-view .progress-tools .progress-bar {
    position: absolute;
    width: 100%;
    height: 2px;
    top: -2px;
    left: 0;
}

.handout-view .progress-tools .progress-bar .el-progress-bar {
    width: 100% !important;
    height: 100%;
}

.handout-view
    .progress-tools
    .progress-bar
    .el-progress-bar
    .el-progress-bar__outer {
    height: 100% !important;
}

.handout-view .progress-tools .progerss-text {
    font-size: 12px;
    line-height: 40px;
    color: #ebebec;
    margin-left: 20px;
}

#pdfdiv1 {
    width: 100%;
    height: 100%;
    background: #282828 !important;
    border: none !important;
}

iframe {
    border: none;
    background: #282828;
}
</style>
<script>
import { reqHandoutAuthPath, reqResourceDetail } from "../../api/study.js";
import { bindEvents } from "../../util/sensor-data.js";
import { W2_VIEWSCREEN_STUDYHANDOUT } from "@/config";

export default {
    data() {
        return {
            pdfurls: "",
            isLoading: false,
            isPdf: true,
            loading: false,
            handoutName: '',
            handoutSrc: ''
        };
    },
    computed: {
        courseId() {
            return this.$route.query.course_id;
        },
        resourceId() {
            return this.$route.query.resource_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
    },
    methods: {
        async getResourceItem() {
            this.isPdf = true;
            this.loading = true;
            document.getElementsByTagName("body")[0].className = "lock__scroll";
            const ret = await reqResourceDetail(this.resourceId, {
                courseId: this.courseId,
                syllabusId: this.syllabusId,
                csItemId: this.syllabusItemId,
            });
            this.$refs.pdfContainer.innerHTML = "";
            document.getElementsByTagName("body")[0].className = "";
            this.loading = false;
            if (+ret.status !== 0 || !ret.result) {
                this.goResource404();
                return;
            }
            const pdfurls = await this.getHandoutAuthPath(ret.result);
            if (!pdfurls) {
                this.goResource404();
                return;
            }
            this.handoutName = ret.result.resource.title;
            this.handoutSrc = pdfurls;
            if (pdfurls.includes('.pdf')) {
                this.isPdf = true;
                const pdfdiv1 = document.createElement("iframe");
                pdfdiv1.id = "pdfdiv1";
                pdfdiv1.src = pdfurls;
                this.$refs.pdfContainer &&
                    this.$refs.pdfContainer.appendChild(pdfdiv1);
                setTimeout(() => {
                    $(pdfdiv1).media({
                        width: "100%",
                        height: "100%",
                        bgColor: "#282828",
                    });
                }, 0);
            } else {
                this.isPdf = false;
            }
        },
        async getHandoutAuthPath(data) {
            const res = await reqHandoutAuthPath({
                resource_id: data.resource.id,
                filename: `${encodeURIComponent(data.resource.title)}.${
                    data.resource.extension
                }`,
            });
            if (!res || res.status !== 0 || !res.data) {
                return "";
            }
            return res.data;
        },
        //跳转到资源404
        goResource404() {
            this.$router.replace({
                name: "Resource404",
                query: this.$route.query,
            });
        },
        downloadFile() {
            const fileUrl = this.handoutSrc;
            const fileName = this.handoutName;
            // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
            if (window.fetch) {
                this.isLoading = true;
                fetch(fileUrl)
                    .then(res => res.blob())
                    .then(blob => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        const aId = fileName + '_' + +new Date();
                        a.id = aId;
                        a.href = url;
                        a.download = fileName;
                        document.body.append(a); // 修复qq浏览器无法下载
                        a.click();
                        setTimeout(() => {
                            document.getElementById(aId) && a.remove(); // 修复qq浏览器无法连续下载
                        }, 1000);
                        this.isLoading = false;
                    });
            } else {
                const a = document.createElement('a');
                a.href = fileUrl;
                a.target = '_blank';
                a.download = fileName;
                a.click();
            }
        }
    },
    watch: {
        $route: "getResourceItem",
    },
    created() {
        // this.getResourceItem();
    },
    mounted() {
        this.getResourceItem();
        bindEvents(W2_VIEWSCREEN_STUDYHANDOUT);
    }
};
</script>
