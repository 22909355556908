<template>
    <div class="recordBox">
        <div class="w_1090">
            <div class="sub-nav-menu">
                <ul class="clear">
                    <li
                        class="sub-nav-item"
                        :class="[activeTab === item.action ? 'active' : '']"
                        v-for="(item, index) in exercise_type"
                        :key="index"
                        @click="handleChange(item.action)"
                    >
                        <span>{{ item.name }}</span>
                    </li>
                </ul>
            </div>
            <div v-loading="loading" element-loading-text="加载中...">
                <exercise-list
                    v-if="syllabusData && syllabusData.length"
                    :syllabusData="syllabusData"
                    :source="activeTab == '2' ? SOURCE_FAV : SOURCE_WRONG"
                    :type="activeTab"
                />
                <div v-else class="no__content">暂时没有内容哦~</div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.no__content {
    height: calc(100vh - 420px);
    min-height: 330px;
}
</style>
<script>
import { bindEvents } from "../../util/sensor-data";
import { W2_VIEWSCREEN_STUDYRECORDEXERCISE,W2_CLICK_COURSEEXERCISE_ERRORS,W2_CLICK_COURSEEXERCISE_RIGHT,W2_CLICK_COURSEEXERCISE_STOREUP} from "@/config";
import exerciseList from "./RecordModuleExerciseList.vue";

export default {
    components: {
        exerciseList,
    },
    data() {
        return {
            activeTab: "0",
            exercise_type: [
                { action: "0", name: "做错的题" },
                { action: "1", name: "做对的题" },
                { action: "2", name: "收藏的题" },
            ],
            syllabusData: [],
            loading: true,
            SOURCE_WRONG: "2",
            SOURCE_FAV: "9",
            isHaveFav: false, //是否有收藏
            questionIdAboutCsItemId: {}, //保存对印关系
        };
    },
    methods: {
        //获取问题对应的csitemid对应关系,保存在浏览器里，
        getQuestionsMap(child) {
            if (child.item_list && child.item_list.length > 0) {
                for (let index = 0; index < child.item_list.length; index++) {
                    const questionId = child.item_list[index];
                    this.questionIdAboutCsItemId[questionId] = child.id;
                }
            }
        },
        async getExerciseRecord() {
            let params = {
                course_id: this.$route.params.cid,
                type: this.activeTab, //默认0-错题；1-对题；2-收藏题
            };
            this.loading = true;
            let ret = await this.$http.getStudyRecordStructure(params);
            if (ret.status !== 0) {
                return;
            }
            this.loading = false;
            this.syllabusData = ret.result || [];
            const questionIdAboutCsItemId = {};
            for (let i = 0; i < this.syllabusData.length; i++) {
                const element = this.syllabusData[i];
                const syllabusItems = element.syllabus;

                if (element.item_total > 0) {
                    this.isHaveFav = true;
                }

                syllabusItems &&
                    syllabusItems.map((syllabusItem) => {
                        syllabusItem.firstCsItemId =
                            syllabusItem.cs_item_ids &&
                            syllabusItem.cs_item_ids[0];
                        const level2Children = syllabusItem.children;
                        level2Children &&
                            level2Children.map((child) =>
                                this.getQuestionsMap(child)
                            );
                    });
            }
            localStorage.setItem(
                "questionsMap",
                JSON.stringify(this.questionIdAboutCsItemId)
            );
        },
        handleChange(action) {
            switch (action) { 
                case '0':
                    bindEvents(W2_CLICK_COURSEEXERCISE_ERRORS);
                    break;
                case '1':
                    bindEvents(W2_CLICK_COURSEEXERCISE_RIGHT);
                    break;
                case '2':
                    bindEvents(W2_CLICK_COURSEEXERCISE_STOREUP);
                    break;
            }
            this.activeTab = action;
            this.getExerciseRecord();
        },
    },
    created() {
        this.getExerciseRecord();
    },
    mounted() { 
        bindEvents(W2_VIEWSCREEN_STUDYRECORDEXERCISE)
    }
};
</script>
