import {
    GET_KNOWLEDGE,
    CHANGE_KNOWLEDGE,
} from '../mutation-types';
import {Message} from 'element-ui';


const state = {
    knowledge_info:'',//知识图谱信息
    active:0,  //激活的章的索引
    activeKnowledge:'',  //激活的章的索引
}

const actions = {
    //保存知识图谱信息
    getKnowledge({commit, state}, data) {
        commit(GET_KNOWLEDGE, data)
    },
    //改变激活的知识点章节
    changeActKnowledge({commit, state}, index){
        commit(CHANGE_KNOWLEDGE, index)
    }
}

const mutations = {
    [GET_KNOWLEDGE](state, data) {
        state.knowledge_info = data;
        state.activeKnowledge = data.contents.length!=0&&data.contents[0];
    },
    [CHANGE_KNOWLEDGE](state, index) {
        state.active = index;
        state.activeKnowledge = state.knowledge_info.contents.length!=0&&state.knowledge_info.contents[index];
    },
}

export default {
    state,
    actions,
    mutations,
}