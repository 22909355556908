<template>
    <div class="audio-main">
        <div class="audio-switch audio-pause" @click="audioPlay"></div>
        <el-slider
            :max="maxNum"
            v-model="sliderNum"
            :show-tooltip="false"
            class="audio-progress-bar"
            @change="onChangeProgress"
        ></el-slider>
        <audio ref="audioRef" :src="audioSrc">
            你的浏览器不支持音频播放哦！
        </audio>
        <span class="audio-time">
            <span>{{ currentTime }}</span>
            <span>/{{ allTime }}</span>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        audioSrc: String,
    },
    data() {
        return {
            onOff: false,
            target: null,
            currentTime: "00:00", //总时长
            allTime: "00:00", //总时长
            sliderNum: 0,
            maxNum: 100,
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.canplay();
        this.timeUpdate();
        this.handleAudio();
    },
    methods: {
        canplay() {
            this.$refs.audioRef.oncanplay = () => {
                let allTime =
                    (
                        Math.floor(this.$refs.audioRef.duration / 60) + ""
                    ).padStart(2, "0") +
                    ":" +
                    (
                        Math.floor(this.$refs.audioRef.duration % 60) + ""
                    ).padStart(2, "0");
                this.allTime = allTime;
                this.maxNum = Math.floor(this.$refs.audioRef.duration * 100);
            };
        },
        //在音频播放时当前时间也跟着变化
        timeUpdate() {
            this.$refs.audioRef.ontimeupdate = () => {
                let currentTime =
                    (
                        Math.floor(this.$refs.audioRef.currentTime / 60) + ""
                    ).padStart(2, "0") +
                    ":" +
                    (
                        Math.floor(this.$refs.audioRef.currentTime % 60) + ""
                    ).padStart(2, "0");
                this.currentTime = currentTime;
                this.sliderNum =
                    (this.$refs.audioRef.currentTime /
                        this.$refs.audioRef.duration) *
                    this.maxNum;
                if (
                    this.$refs.audioRef.currentTime ===
                    this.$refs.audioRef.duration
                ) {
                    if (this.onOff) {
                        this.target.classList.remove("audio-play");
                        this.target.classList.add("audio-pause");
                    }
                    this.sliderNum = this.maxNum;
                }
            };
        },
        handleAudio() {
            const audio = this.$refs.audioRef;
            audio.addEventListener("error", () => {
                let audioSwitch =
                    document.getElementsByClassName("audio-switch");
                for (let i = 0; i < audioSwitch.length; i++) {
                    audioSwitch[i].classList.remove("audio-play");
                    audioSwitch[i].classList.add("audio-pause");
                }
                this.currentTime = "00:00"; //当前时间
                this.allTime = "00:00"; //总时长
                this.sliderNum = 0;
                this.onOff = false;
                this.maxNum = 100;
                this.target = null;
            });
            audio.addEventListener("canplaythrough", () => {
                if (this.onOff) {
                    this.target.classList.remove("audio-pause");
                    this.target.classList.add("audio-play");
                    audio.play();
                } else {
                    audio.pause();
                }
            });
            audio.addEventListener("loadedmetadata", () => {
                let audioSwitch =
                    document.getElementsByClassName("audio-switch");
                for (let i = 0; i < audioSwitch.length; i++) {
                    audioSwitch[i].classList.remove("audio-play");
                    audioSwitch[i].classList.add("audio-pause");
                }
                this.onOff = false;
                this.currentTime = "00:00";
                this.allTime = "00:00";
            });
            // progress，音频在加载或缓冲
            audio.addEventListener("progress", () => {
                if (this.onOff) {
                    this.target.classList.remove("audio-pause");
                    this.target.classList.add("audio-play");
                    this.$refs.audioRef.play();
                } else {
                    this.$refs.audioRef.pause();
                }
            });
        },
        //播放开始
        audioPlay(e) {
            console.log(e.target.classList.contains("audio-pause"));
            if (e.target.classList.contains("audio-pause")) {
                console.log("bo fang");
                this.onOff = true;
                this.target = e.target;
                let audioSwitch =
                    document.getElementsByClassName("audio-switch");
                let audios = document.getElementsByTagName("audio");
                for (let i = 0; i < audioSwitch.length; i++) {
                    audioSwitch[i].classList.remove("audio-play");
                    audioSwitch[i].classList.add("audio-pause");
                    audios[i].pause();
                }
                e.target.classList.remove("audio-pause");
                e.target.classList.add("audio-play");
                this.$refs.audioRef.play();
            } else {
                //暂停
                e.target.classList.remove("audio-play");
                e.target.classList.add("audio-pause");
                this.$refs.audioRef.pause();
                this.onOff = false;
                this.target = e.target;
            }
        },
        //值改变时触发（使用鼠标拖曳时，只在松开鼠标后触发）
        onChangeProgress(value) {
            this.$refs.audioRef.currentTime =
                (value / this.maxNum) * this.$refs.audioRef.duration;

            if (this.onOff) {
                this.target.classList.remove("audio-pause");
                this.target.classList.add("audio-play");
                this.$refs.audioRef.play();
            } else {
                this.$refs.audioRef.pause();
            }
        },
    },
    beforeDestroy() {
        this.$refs.audioRef.ontimeupdate = null;
    },
};
</script>
<style lang="less">
.audio-progress-bar {
    .el-slider__runway {
        background-color: #ffffff;
        height: 4px;
    }

    .el-slider__bar {
        height: 4px;
    }

    .el-slider__button-wrapper {
        top: -16px;
    }

    .el-slider__button {
        background-color: #409eff;
        border-color: #ffffff;
        width: 10px;
        height: 10px;
        box-shadow: 0px 1px 5px 0px rgba(64, 158, 255, 0.26);
    }
}
</style>
<style lang="less" scoped>
.audio-main {
    width: 75%;
    max-width: 343px;
    margin-bottom: 16px;
    margin-top: 22px;
    padding: 0 12px;
    display: flex;
    height: 44px;
    background-color: #f5f6f7;
    align-items: center;

    .audio-switch {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .audio-play {
        background: url(../../assets/images/audio/play.png) no-repeat;
        background-size: 100% 100%;
    }

    .audio-pause {
        background: url(../../assets/images/audio/pause.png) no-repeat;
        background-size: 100% 100%;
    }

    .el-slider {
        flex: 1 1;
        margin: 0 12px 0 16px;
    }

    .audio-time {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
    }
}
</style>
