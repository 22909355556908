import axios from 'axios';
import {getBaseUrl, getLogoutUrl} from '../util/config';
import {ACCESS_TOKEN} from './keys';
import {networkMsg} from './messageBox.js';
import {getCookie} from '../util/cookie.js';

const instance = axios.create({
    baseURL: `${getBaseUrl()}apigateway.gaodun.com`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 超时时间
instance.interceptors.request.use(function (config) {
    const token = getCookie(ACCESS_TOKEN);
    // 换取最新token接口直接返回验证
    if (config.url.indexOf('refreshtoken') !== -1) {
        return Promise.resolve(config);
    }
    // 非换取token接口headers携带token验证
    if (config.url.indexOf('refreshtoken') === -1 && token != undefined) {
        config.headers.common['Authentication'] = `Basic ${token}`;
        return Promise.resolve(config);
    }
    return Promise.resolve(config);
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(async function (response) {
    let saasUrl = location.href;
    saasUrl = encodeURIComponent(saasUrl);

    // 获取token接口直接返回
    if (response.config.url.indexOf('refreshtoken') !== -1) {
        return Promise.resolve(response.data);
    }
    // 登录失效 553649410～553649444
    if (response.config.url.indexOf('refreshtoken') === -1 && response.data.status > 553649000 && response.data.status < 563649999) {
        // location.href = getLogoutUrl(saasUrl);
        return Promise.resolve(response.data);
    }

    return Promise.resolve(response.data);
}, function (error) {
    if (error == 'Error: Network Error' && error.config.url.indexOf('/study-api/resource/') !== -1) {
        networkMsg();
    }
    return Promise.reject(error);
});
export default class ZeusAxios {
    constructor(options = {}) {
        this.options = options;
    }

    request(options) {
        return instance.request(options);
    }

    get(url, options = {}) {
        return this.request({
            url,
            params: {
                ...options,
            },
        });
    }

    post(url, data, options = {}) {
        if (data instanceof Object) {
            data = JSON.stringify(data);
        }
        return this.request({
            method: 'post',
            url,
            data,
            ...options,
        });
    }

    delete(url, options = {}) {
        return this.request({
            method: 'delete',
            url,
            params: {
                ...options,
            },
        });
    }

    put(url, data, options = {}) {
        if (data instanceof Object) {
            data = JSON.stringify(data);
        }
        return this.request({
            method: 'put',
            url,
            data,
            ...options,
        });
    }
}
const instanceAxios = new ZeusAxios;
export const request = instanceAxios.request.bind(instanceAxios);
export const get = instanceAxios.get.bind(instanceAxios);
export const post = instanceAxios.post.bind(instanceAxios);
export const zDelete = instanceAxios.delete.bind(instanceAxios);
export const put = instanceAxios.put.bind(instanceAxios);
