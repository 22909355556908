import sensors from 'sa-sdk-javascript';
import {getEnv} from "./config";


export function  sensorsInit () {
	if (getEnv() === '') {
		sensors.init({
			server_url: 'https://gaodun.datasink.sensorsdata.cn/sa?project=default&token=b9ea8de774df405c',
			name: 'sa',
			debug_mode: false,
			debug_mode_upload: false,
			heatmap: {
				clickmap: 'default',
				scroll_notice_map: 'default'
			}
		});
	}
		sensors.quick('autoTrack',{
			platform:'zeus-pc'
		});
}

export function bindEvents (key, data) {
	if (getEnv() === '') {
		setTimeout(() => {
			sensors.track(key, {...data});
		}, 500);
	}
}
