<template>
    <div class="paper-tools-box">
        <div class="paper-tools-box-header">
            <el-menu :default-active="activeName" mode="horizontal" @select="handleSelect" class="tools-change">
                <el-menu-item index="note">记笔记</el-menu-item>
                <el-menu-item index="question">我要提问</el-menu-item>
            </el-menu>
        </div>
        <div class="paper-tools-box-content">
            <AnalysisNote
                v-show="activeName=='note'"
                :noteList="noteList"
                :editBoxshow="editBoxshow"
                :btnLoading="btnLoading"
                @cancelUpdate="cancelUpdate"
                @showEditBox="showEditBox"
                @addNote="addNote"
                @deleteinfo="deleteinfo"
                @updatenote="updateNote"
                @getNotelist="getNotelist"
            >
            </AnalysisNote>
            <AnalysisAsk v-show="activeName=='question'" @goGbot="goGbot"></AnalysisAsk>
        </div>
    </div>
</template>
<script>
    import AnalysisNote from "../paperAnalysis/AnalysisModuleNote.vue";
    import AnalysisAsk from "../paperAnalysis/AnalysisModuleAsk.vue";
    import {addNoteListEp3Task, updateNoteEp3Task} from "../../api/studyForm.js";
    import {getNoteListEP3Task, deleteNoteinfoEP3Task, createGbotUrl} from "../../api/study";
    import {getResourceId} from "../../api/record.js";
    import {getEnv, gbotbaseURL} from "../../util/config";
    import {mapState} from "vuex";
    import Vue from "../../common/Vue.js";
    import {SAAS_COURSE, ACCESS_TOKEN} from "../../util/keys.js";
    import {getCookie, setCookie} from "../../util/cookie.js";

    let prefix = getEnv();
    export default {
        props:[
            'csItemId',
            'currIndex',
        ]
        ,
        components: {
            AnalysisNote,
            AnalysisAsk
        },
        data() {
            return {
                activeName: "note",
                noteList: [], //笔记列表
                editBoxshow: false,
                btnLoading: false, //按钮loading,
                resource_id:'',//771104
                questionCsitemIds: JSON.parse(localStorage.getItem('questionsMap')),
            };
        },
        created(){
               this.getResourceIdTask((resource_id) => {
                this.resource_id = resource_id;
            });
        }
        ,
        watch:{
            currIndex(value){
                this.getNotelist();
            }
        }
        ,
        computed: {
            ...mapState({
                cs_item_id: state => {
                    return state.retest.cs_item_id;
                },
                cur_question_id: state => {
                    //    当前的题目-小题的id
                    const question = state.retest.cur_question;
                    return question.id;
                }
            }),
            course_id() {
                return this.$route.params.cid; // 课程id
            }
        },
        methods: {
            handleSelect(key) {
                this.activeName = key;
            },
            //取消编辑
            cancelUpdate(index) {
                this.noteList[index].status = false;
            },
            //编辑笔记
            showEditBox(index) {
                for (let val of this.noteList) {
                    val.status = false;
                }
                this.noteList[index].status = true;
            },
            async getResourceIdTask( callback ){
                const res = await getResourceId(this.csItemId);
                 if (res.status == 0) {
                    callback(res.result.resourceId);
                    this.getNotelist();
                } else {
                    console.log('没有找到对应的资源，请联系您的学管师修复~');
                }
            },
            //删除笔记
            deleteinfo(noteid) {
                this.$confirm("确认删除吗？", "", {
                    customClass: "leaveTip",
                    showCancelButton: true,
                    showConfirmButton: true,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    cancelButtonText: "不删除",
                    confirmButtonText: "删除",
                    center: true,
                    beforeClose: async (action, instance, done) => {
                        if (action === "confirm") {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = "删除中...";
                            let ret = await deleteNoteinfoEP3Task(noteid);
                            done();
                            instance.confirmButtonLoading = false;
                            if (ret.status == 0) {
                                this.$message({
                                    message: ret.message ? ret.message : "删除笔记成功！",
                                    type: "success"
                                });
                                this.getNotelist();
                            } else {
                                this.$message.error(
                                    ret.message ? ret.message : "删除笔记失败!"
                                );
                            }
                        } else {
                            done();
                        }
                    }
                })
                    .then(() => {
                    })
                    .catch(() => {
                    });
            },
            //新增笔记
            async addNote(content) {

                let url = {
                    course_id: this.course_id, // 课程id
                    resource_id: this.resource_id, // 资源id
                    resource_item_id: this.cur_question_id, //题目id，试卷类型资源必填
                    resource_type: "question", // 资源类型.video：视频；lecture_note：讲义；paper：试卷；question：题目。
                    is_public: 1,            // 是否公开笔记,1:公开，0:不公开
                    content: content, // 笔记内容
                    origin: "web", // 来源
                    course_syllabus_item_id: this.getCsItemId(), // 大纲条目id
                };
                this.btnLoading = true;
                const ret = await addNoteListEp3Task(url);
                this.btnLoading = false;
                if (ret.status == 0) {
                    this.$message({
                        type: "success",
                        message: "新建笔记成功!"
                    });
                    this.getNotelist();
                    Vue.$emit("clearContent");
                } else {
                    this.$message.error('没有找到对应的资源，请联系您的学管师修复~');
                    // this.$message.error(
                    //     ret.message ? ret.message : "新建笔记失败!"
                    // );
                }
            },
            //更新笔记
            async updateNote(content, editNoteId) {
                let params = {
                    content: content, // 笔记内容
                    is_public: 1,            // 是否公开笔记,1:公开，0:不公开
                    resource_id: this.resource_id, // 资源id
                    resource_type: "question", // 资源类型.video：视频；lecture_note：讲义；paper：试卷；question：题目。
                    origin: "web", // 来源
                    course_id: this.course_id, // 课程id
                    course_syllabus_item_id: this.getCsItemId(), // 大纲条目id
                    resource_item_id:this.getQuestionId(),
                    id: editNoteId,
                };
                    //  "id":91,                //类型：Number  必有字段  备注：无
                    // "course_id":1,                //类型：Number  必有字段  备注：无
                    // "resource_id":1,                //类型：Number  必有字段  备注：无
                    // "resource_item_id":1,                //类型：Number  必有字段  备注：无
                    // "resource_type":"paper",                //类型：String  必有字段  备注：无
                    // "is_public":1,                //类型：Number  必有字段  备注：无
                    // "content":"345432525",                //类型：String  必有字段  备注：无
                    // "live_seconds":11,                //类型：Number  必有字段  备注：无
                    // "origin":"111",                //类型：String  必有字段  备注：无
                    // "course_syllabus_item_id":1,                //类型：Number  必有字段  备注：无
                    // "image_url":"mock"


                let ret = await updateNoteEp3Task(params);

                Vue.$emit("confirmSuccess");
                if (ret.status == 0) {
                    this.$message({
                        type: "success",
                        message: ret.message ? ret.message : "更新笔记成功!"
                    });
                    this.getNotelist();
                } else {
                    this.$message.error(
                        ret.message ? ret.message : "更新笔记失败!"
                    );
                }
            },
            //拉取笔记
            async getNotelist() {
                const qid = this.getValue('cur_question').id;
                 let params = {
                    course_id: this.course_id, // 课程id
                    cs_item_id: this.getCsItemId(),
                    resource_item_id: qid,
                    page:1,
                    offset:50,
                    source_type:'question',
                    origin: "web", // 来源
                };
                this.noteList =[];
                // let ret = await getNoteList(resource_id, params);
                const ret = await getNoteListEP3Task(params);
                if (ret.status == 0) {
                    let noteList = ret.result||[];
                    for (let item of noteList) {
                        item.status = false;
                    }
                    this.noteList = noteList;
                }
            },
            getQuestionId(){
                return this.getValue('cur_question').id;
            },
            getCsItemId(){
                const qid = this.getValue('cur_question').id;
                return this.questionCsitemIds[qid];
            },
            getValue(value){
                return this.$store.state.retest && this.$store.state.retest[value];
            },
            async goGbot() {
                let resource = 0;
                const item_id = this.cs_item_id;
                let isResource = false; //标记是否请求成功

                //ItemId获取ResourceId
                // function getResourceIdSuccessCallBack(res) {
                //     if (res.status == 0) {
                //         resource = res.result.resource_id;
                //         isResource = true;
                //     }
                // }

                // this.$ajax({
                //     type: 'GET',
                //     url: "https://" + prefix + `apigateway.gaodun.com/toc-service/syllabus/item/${item_id}/resource/id`,
                //     successCallBack: getResourceIdSuccessCallBack,
                // })

                if (!this.resource_id) return this.$message.error('没有找到对应的资源，请联系您的学管师修复~');

                const project_id = this.$route.query.projectId;
                const subject_id = this.$route.query.subjectId;

                const course_id = this.course_id; //课程id
                const payload = this.cs_item_id; //大纲条目id
                let data = {
                    course_id: course_id, // 课程id
                    resource: resource, //资源条目id
                    resource_id: this.cur_question_id, // 资源id（视频id 题目id）
                    payload: payload, // 大纲条目id
                    gbot_source: 1, //来源  1课程  2题库
                    project_id: project_id, // 项目id
                    subject_id: subject_id, // 科目id
                    type: 2 // 类型 1视频 2题目
                };
                window.open(`https://${prefix}gbot.gaodun.com/update-course/${course_id}/${project_id}/${subject_id}/${this.cur_question_id}/2?source=1&resource=${this.resource_id}&payload=${payload}&source_type=saas&client_source=0&client_version=0`);
                // this.$ajax({
                //     type: 'POST',
                //     url: createGbotUrl,
                //     data: data,
                //     successCallBack: this.getGbotUrlSuccessCallBack,
                // })
            },
            getGbotUrlSuccessCallBack(ret) {
                if (ret.status == 0) {
                    window.open("https://" + prefix + "gbot.gaodun.com/?cid=" + ret.data.conversation_id + "&crid=" + ret.data.conversation_record_id + "", "_blank");
                }
            }
        }
    };
</script>
<style scoped>

.paper-tools-box {
    border-bottom: solid 1px #ebebec;
    padding-left: 40px;
    background-color: white;
    padding-right: 40px;
    padding-bottom: 100px;
}
.paper-tools-box{
    background: white;
}
.note-list{
    padding-bottom: 10px;
}
</style>
