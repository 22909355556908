<template>
    <div class="study-record">
        <div class="nav-menu-wrap">
            <div class="w_1090 clear">
                <div class="h-menu">
                    <router-link
                        active-class="h-menu-link--active"
                        class="h-menu-link"
                        tag="div"
                        :to="{
                            name: item.index,
                            params: { cid: course_id },
                            query: { vid: vid, refSite: refSite },
                        }"
                        v-for="(item, index) in record_type"
                        v-if="
                            !(gdLang !== 'zh-CN' && item.index === 'exercise')
                        "
                        :key="index"
                        @click.native = buryPoint(item)>
                        {{ item.name }}
                    </router-link>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import { record_type } from "../common/recordConfig.js";
import Exercise from "../components/StudyRecord/RecordModuleExercise.vue";
import Note from "../components/StudyRecord/RecordModuleNote.vue";
import Quiz from "../components/StudyRecord/RecordModuleQuiz.vue";
import { bindEvents } from "../util/sensor-data.js";
import { W2_CLICK_COURSEEXERCISE, W2_CLICK_COURSENOTE, W2_CLICK_COURSEQUESTION } from "@/config";

export default {
    components: {
        Exercise,
        Note,
        Quiz,
    },
    data() {
        return {
            record_type: record_type,
        };
    },
    methods: {
        buryPoint(item) { 
            switch (item.index) { 
                case "exercise":
                    bindEvents(W2_CLICK_COURSEEXERCISE)
                    break;
                case "note":
                    bindEvents(W2_CLICK_COURSENOTE)
                    break;
                case "question":
                    bindEvents(W2_CLICK_COURSEQUESTION)
                    break;
                default:
            }
        }
    },
    computed: {
        course_id() {
            return this.$route.params.cid;
        },
        vid() {
            return this.$route.query.vid || "";
        },
        refSite() {
            return this.$route.query.refSite || "";
        },
        gdLang() {
            return window.GD_LANG;
        },
    },
};
</script>
