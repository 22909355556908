import {
    SAVE_PAPER,
    CHANGE_IS_SUBMIT,
    RECOMBINE_PAPER,
    NEXT_QUESTION,
    PREV_QUESTION,
    SAVE_ANSWER,
    NEXT_SM_QUESTION,
    PREV_SM_QUESTION,
    CHANGE_SM_QUESTION,
    CHANGE_SHEET,
    CHANGE_FAVORITE,
    GET_NEWTIME,
    DESTROY_PAPER,
    PAGER_DATA
} from '../mutation-types';
import { Message } from 'element-ui';
import { question_type } from '../../common/paperConfig.js';
import { saveAnswerEp3Task } from '../../api/studyForm.js'
import {getPaperRecord} from '../../api/study';

const state = {
    paper_result: '', //试卷所有信息
    paper_data: [], //试卷题木列表
    paper_is_submit: true, //是否交卷
    paper_recm_data: [], //按题目类型重组的试卷
    paper_runtime: '', //试卷的时间
    paper_id: "", //试卷id
    paper_status: "", //试卷的状态 status 0 是未交卷   1 是已交卷
    paper_data_id: "", //用户试卷id
    paper_data_log_id: "", //用户试卷log_id
    paper_total_questions: '', //试卷的总题数(不包含综合题下的小题)

    paper_cur_questions: '', //当前的题目
    paper_cur_type: '', //当前的题目的类型
    paper_cur_favorite: '', //当前的题目是否收藏
    paper_cur_question_index: '', //当前的题目的索引
    paper_cur_userAnswer: '', //当前的题目的用户的答案
    paper_cur_id: '', //当前的题目的id
    paper_cur_correct: '', //当前的题目的正确答案  做题版
    paper_yanswer: '', //当前的题目的正确答案  解析版
    paper_cur_analysis: '', //当前的题目的解析
    paper_cur_sorts: '', //当前的题目的题序
    paper_is_video: '', //是否可以视频解析
    paper_video_analysis: '', //视频解析html
    //用户答案 正确答案 题目id 解析 题序 是否视频解析  视频解析地址 => 可以共用一个变量
    paper_cur_Sm_question: '', //当前题目为综合题时,当前的小题
    paper_cur_Sm_type: '', //当前题目为综合题时,当前的小题的类型
    paper_cur_Sm_question_index: '', //当前题目为综合题时,当前的小题的索引

    lastOneFlag: false, //判断是否是试卷的最后一题
    lastSmOneFlag: false, //判断是否是综合题的最后一题
    NowTime: '', //当前开始做题的时间
    paperCorrectStatus: {}, // 试卷批阅状态
}

const actions = {
    savePaperCorrectStatus({ commit }, data) {
        commit('SAVE_PAPER_CORRECT_STATUS', data)
    },
    //保存试卷信息
    savePaper({ commit, state }, data) {
        commit(SAVE_PAPER, data)
        commit(RECOMBINE_PAPER, data) //重组试卷按题目类型recombine
    },
    changeIsSubmit({ commit, state }, bool) {
        commit(CHANGE_IS_SUBMIT, bool)
    },
    nextQuestion({ commit, state }, data) {
        commit(NEXT_QUESTION, data)
    },
    prevQuestion({ commit, state }, data) {
        commit(PREV_QUESTION, data)
    },
    //保存用户的答案
    saveAnswer({ commit, state }, data) {
        // let answer = {...state.paper_cur_userAnswer };
        // answer.content = data;
        commit(SAVE_ANSWER, data)
    },
    nextSmQuestion({ commit, state }, data) {
        commit(NEXT_SM_QUESTION, data)
    },
    prevSmQuestion({ commit, state }, data) {
        commit(PREV_SM_QUESTION, data)
    },
    //切换综合题下小题
    changeSmQuestion({ commit, state }, index) {
        commit(CHANGE_SM_QUESTION, index)
    },
    //切换答题卡
    changeSheet({ commit, state }, index) {
        commit(CHANGE_SHEET, index)
    },
    //收藏
    changeFavorite({ commit, state }, status) {
        commit(CHANGE_FAVORITE, status)
    },
    //保存当前做题的时间
    getNewTime({ commit, state }) {
        commit(GET_NEWTIME)
    },
    //销毁试卷
    destroyPaper({ commit, state }) {
        commit(DESTROY_PAPER)
    },
    //获取试卷状态
    getExamPaperStatus({commit, state}, payload) {
        return new Promise(async (resolve) => {
            const response = await getPaperRecord({ ...payload, sourceFromType: 100533962 });
            let path = '';
            if (response.status === 0) {
                path = '/study/paper/exercise';
            }
            resolve({...response.result, path: path, message: response.message});
        });
    },
}

const saveVideoUri=(state, child)=>{
    state.paper_video_analysis = child.video_analysis||{}; //当前的题目是否有视频解析
    state.paper_is_video = !!child.video_analysis; //当前的题目是否有视频解析
    state.paper_video_analysis.uri = child.video_analysis && child.video_analysis.source_id;
}


//保存综合题目小题
export const saveSmQuestion = (state, index = 0) => {
    const children = state.paper_cur_questions && state.paper_cur_questions.sonitem;
    if (children && children.length != 0) {
        const child = children[index];
        state.paper_cur_Sm_question = child;
        state.paper_cur_Sm_type = child.type;
        state.paper_cur_userAnswer = child.user_answer.user_answer; //当前的题目的用户的答案
        state.paper_cur_id = child.id; //当前的题目的id
        state.paper_cur_correct = child.user_answer.correct_content; //当前的题目的正确答案
        state.paper_yanswer = child.answer; //当前的题目的正确答案
        state.paper_cur_analysis = child.analysis; //当前的题目的解析
        state.paper_cur_sorts = child.item_index; //当前的题目的题序
        // state.paper_is_video = !!child.videoa; //当前的题目是否有视频解析
        // state.paper_video_analysis = child.video_analysis; //当前的题目是否有视频解析
        saveVideoUri(state, child);
    }
}

export const saveOtherQuestion = (state, index=0) => {
     //其他单体题型
     const question = state.paper_data && state.paper_data[index];
     if (question) {
        state.paper_cur_id = question.id;
        if(question.user_answer){
            state.paper_cur_istrue = question.user_answer.istrue;//是否正确
            state.paper_cur_userAnswer = question.user_answer.user_answer||"";
            state.paper_cur_correct = question.user_answer.correct_content;
        } else {
            console.error('user_answer is null :' + question.user_answer);
        }
        state.paper_yanswer = question.answer;
        state.paper_cur_analysis = question.analysis;
        state.paper_cur_sorts = question.item_index;
        // state.paper_is_video = !!question.videoa;
        // state.paper_video_analysis = question.video_analysis;
        // state.paper_video_analysis.uri = saveVideoUri(question);
        saveVideoUri(state, question);
     } else {
         console.error('question is null: ' + question);
     }
}

const mutations = {
    [SAVE_PAPER](state, data) {
        state.paper_result = data;
        state.paper_runtime = data.runtime && data.runtime;
        state.paper_id = data.paper_id && data.paper_id;
        state.paper_data_id = data.pdid && data.pdid || 0;
        state.paper_total_questions = data.itemcount || 0;
        state.paper_data = data.item_list;
        if (state.paper_total_questions != 0) {
            state.paper_cur_questions = data.item_list && data.item_list[0]; //默认拿到第一题
            state.paper_cur_question_index = 1;
            state.paper_cur_favorite = data.item_list && data.item_list[0].is_favorite;
            state.paper_cur_type = data.item_list && data.item_list[0].type;
            saveSmQuestion(state, 0);
            saveOtherQuestion(state, 0);
            state.paper_cur_Sm_question_index = 1;
        }
    },
    ['SAVE_PAPER_CORRECT_STATUS'](state, data) {
        state.paperCorrectStatus = data;
    },
    [CHANGE_IS_SUBMIT](state, bool) {
        state.paper_is_submit = bool;
    },
    [RECOMBINE_PAPER](state, data) {
        if ( data && data.item_list){
            var data = [...data.item_list];
            var recm_obj = {};
            var recm_data = [];
            for (var i = 0; i < data.length; i++) {
                if (recm_obj.hasOwnProperty(data[i].type)) {
                    recm_obj[data[i].type].push(data[i]);
                } else {
                    recm_obj[data[i].type] = [data[i]];
                }
            }
            for (var id in recm_obj) {
                for (var i = 0; i < question_type.length; i++) {
                    if (question_type[i].id == id) {
                        recm_data.push({ title: question_type[i].name, questions: recm_obj[id] })
                    }
                }
            }
            state.paper_recm_data = recm_data;
        }
    },
    [NEXT_QUESTION](state) {
        //先拿到当前的索引
        //然后判断是不是题目列表的的最后一题了
        // 如果不是则继续拿下一个题目
        // 如果是的则提示用户已做完
        //每次拿下一个题目时都需要判断是不是综合题

        //  4   当前第1题 length 4  索引是0
        //  4   当前第2题 length 4  索引是1
        //  4   当前第3题 length 4  索引是2
        //  4   当前第4题 length 4  索引是3
        if (state.paper_cur_question_index < state.paper_data.length) {
            const index = state.paper_cur_question_index;
            state.paper_cur_questions = state.paper_data[index];
            state.paper_cur_type = state.paper_data[index].type;
            state.paper_cur_favorite = state.paper_data[index].is_favorite;
            if (state.paper_cur_type == 5) {
                //5=>综合题
                if (state.paper_cur_questions.sonitem && state.paper_cur_questions.sonitem.length != 0) {
                }
                saveSmQuestion(state, 0);
                state.paper_cur_Sm_question_index = 1;
            } else {
                //其他单体题型
                saveOtherQuestion(state, index);
            }
            state.paper_cur_question_index++;

        } else {
            Message({
                message: '后面没有题了哦',
                showClose: true,
            });
        }
        //交卷按钮的显示隐藏
        //这个题目是最后一个题目
        if (state.paper_cur_question_index == state.paper_data.length) {
            state.lastOneFlag = true;
        } else {
            state.lastOneFlag = false;
        }

    },
    [PREV_QUESTION](state) {
        //先拿到当前的索引
        //然后判断是不是题目列表的第一道题了
        // 如果不是则继续拿题目列表的前一次题目
        // 如果是的则则提示用户已做完

        //  4   当前第1题 length 4  索引是0
        //  4   当前第2题 length 4  索引是1
        //  4   当前第3题 length 4  索引是2
        //  4   当前第4题 length 4  索引是3
        if (state.paper_cur_question_index != 1) {
            state.paper_cur_questions = state.paper_data[state.paper_cur_question_index - 2];
            state.paper_cur_favorite = state.paper_data[state.paper_cur_question_index - 2].is_favorite;
            state.paper_cur_type = state.paper_data[state.paper_cur_question_index - 2].type;
            if (state.paper_cur_type == 5) {
                //5=>综合题
                saveSmQuestion(state, 0);
                state.paper_cur_Sm_question_index = 1;
            } else {
                //其他单体题型
                saveOtherQuestion(state, state.paper_cur_question_index - 2);
            }
            state.paper_cur_question_index--;

        } else {
            Message({
                message: '前面没有题了哦',
                showClose: true,
            });
        }

        //交卷按钮的显示隐藏
        //这个题目是最后一个题目
        if (state.paper_cur_question_index == state.paper_data.length) {
            state.lastOneFlag = true;
        } else {
            state.lastOneFlag = false;
        }

    },
    [SAVE_ANSWER](state, answer) {
        clearTimeout(window.timeoutAnswer);
        state.paper_cur_userAnswer = answer;
        const question = state.paper_data[state.paper_cur_question_index - 1];
        if (state.paper_cur_type == 5) {
            question.sonitem[state.paper_cur_Sm_question_index - 1].user_answer.user_answer = answer;
        }else{
            question.user_answer.user_answer = answer;
        }

        let course_id = this.state.study.course_Id;
        let userAnswers = [];
        state.paper_data && state.paper_data.map((element)=>{
            userAnswers.push({
                question_id: element.id,
                answer:element.user_answer && element.user_answer.user_answer||'',
                images:[]
            });
        });
        state.userAnswerList = userAnswers;
        window.timeoutAnswer = setTimeout(() => {
            // SaveAnswer(course_id, syllabus_id, paper_id, params);
            saveAnswerEp3Task({
                answers:userAnswers,
                cost_times: state.paper_runtime,
                course_id,
                paper_data_log_id:state.paper_result.paper_data_log_id
            });
        },500)
    },
    [NEXT_SM_QUESTION](state, data) {
        //先拿到当前的小题的索引
        //然后判断是不是这个题目的最后一个小题
        // 如果不是则继续拿这个题目的下一次小题
        // 如果是的则提示用户已做完，

        if (state.paper_cur_Sm_question_index < state.paper_cur_questions.sonitem.length) {

            saveSmQuestion(state, state.paper_cur_Sm_question_index);
            state.paper_cur_Sm_question_index++;
        } else {
            //message: '综合题已经做完了...后续功能正在开发中...',
        }
        //切换综合题小题时是否为最后一个小题
        if (state.paper_cur_Sm_question_index == state.paper_cur_questions.sonitem.length) {
            state.lastSmOneFlag = true;
        } else {
            state.lastSmOneFlag = false;
        }
    },
    [PREV_SM_QUESTION](state, data) {
        //先拿到当前的小题的索引
        //然后判断是不是这个题目的第一个小题
        // 如果不是则继续拿这个题目的上一个小题
        // 如果是的则提示用户已经是第一题了，

        if (state.paper_cur_Sm_question_index != 1) {
            saveSmQuestion(state, state.paper_cur_Sm_question_index - 2);
            state.paper_cur_Sm_question_index--;
        } else {
            /*Message({
                message: '综合题第一题...后续功能正在开发中...',
                showClose: true,
            });*/
        }
        //切换综合题小题时是否为最后一个小题
        if (state.paper_cur_Sm_question_index == state.paper_cur_questions.sonitem.length) {
            state.lastSmOneFlag = true;
        } else {
            state.lastSmOneFlag = false;
        }
    },
    [CHANGE_SM_QUESTION](state, index) {
        index = Number(index);
        //切换小题
        saveSmQuestion(state, index);
        state.paper_cur_Sm_question_index = index + 1;
        //切换综合题小题时是否为最后一个小题
        if (state.paper_cur_Sm_question_index == state.paper_cur_questions.sonitem.length) {
            state.lastSmOneFlag = true;
        } else {
            state.lastSmOneFlag = false;
        }
    },
    [CHANGE_SHEET](state, index) {
        let question_index = Number(index.question_index);
        let sm_question_index = index.sm_question_index ? Number(index.sm_question_index) : 0;
        //切换 当前题目 当前小题...
        state.paper_cur_questions = state.paper_data[question_index];
        state.paper_cur_favorite = state.paper_data[question_index].is_favorite;
        state.paper_cur_type = state.paper_data[question_index].type;
        if (state.paper_cur_type == 5) {
            //5=>综合题
            if (state.paper_cur_questions.sonitem && state.paper_cur_questions.sonitem.length != 0) {
                saveSmQuestion(state, sm_question_index);
                state.paper_cur_Sm_question_index = sm_question_index + 1;
            }
        } else {
            //其他单体题型
            saveOtherQuestion(state, question_index);
        }

        state.paper_cur_question_index = question_index + 1;

        //交卷按钮的显示隐藏
        //这个题目是最后一个题目
        if (state.paper_cur_question_index == state.paper_data.length) {
            state.lastOneFlag = true;
        } else {
            state.lastOneFlag = false;
        }
    },
    [CHANGE_FAVORITE](state, status) {
        state.paper_cur_favorite = status;
        state.paper_data[state.paper_cur_question_index - 1].is_favorite = status;
    },
    [GET_NEWTIME](state) {
        state.NowTime = new Date();
    },
    [DESTROY_PAPER](state) {
        state.paper_result = ''; //试卷所有信息
        state.paper_data = ''; //试卷题木列表
        state.paper_is_submit = false; //是否交卷
        state.paper_recm_data = ''; //按题目类型重组的试卷
        state.paper_runtime = ''; //试卷的时间
        state.paper_id = ""; //试卷id
        state.paper_status = ""; //试卷的状态 status 0 是未交卷   1 是已交卷
        state.paper_data_id = ""; //用户试卷id
        state.paper_data_log_id = ""; //用户试卷log_id
        state.paper_total_questions = ''; //试卷的总题数(不包含综合题下的小题)
        state.paper_cur_questions = ''; //当前的题目
        state.paper_cur_type = ''; //当前的题目的类型
        state.paper_cur_favorite = ''; //当前的题目是否收藏
        state.paper_cur_question_index = ''; //当前的题目的索引
        state.paper_cur_userAnswer = ''; //当前的题目的用户的答案
        state.paper_cur_id = ''; //当前的题目的id
        state.paper_cur_correct = ''; //当前的题目的正确答案  做题版
        state.paper_yanswer = ''; //当前的题目的正确答案  解析版
        state.paper_cur_analysis = ''; //当前的题目的解析
        state.paper_cur_sorts = ''; //当前的题目的题序
        state.paper_is_video = ''; //
        state.paper_video_analysis = ''; //
        //用户答案 正确答案 题目id 解析 题序 => 可以共用一个变量
        state.paper_cur_Sm_question = ''; //当前题目为综合题时,当前的小题
        state.paper_cur_Sm_type = ''; //当前题目为综合题时,当前的小题的类型
        state.paper_cur_Sm_question_index = ''; //当前题目为综合题时,当前的小题的索引
        state.lastOneFlag = false; //判断是否是试卷的最后一题
        state.lastSmOneFlag = false; //判断是否是综合题的最后一题
        state.NowTime = ''; //当前开始做题的时间
    },
    [PAGER_DATA](state, data) {
      state.paper_result = data;
    }
}

export default {
    state,
    actions,
    mutations,
    saveOtherQuestion,
    saveSmQuestion
}
