<template>
    <div class="test-again-container">
        <div id="sub-tiku-container" class="sub-tiku-container">
        </div>
        <div id="micro-container"></div>
    </div>
</template>
<style>
.test-again-container {
    width: 100%;
    height: 100%;
    min-width: 860px;
    padding: 30px;
    background-color: #F7F7F7;
    overflow: hidden;
}

.sub-tiku-container,
.sub-tiku-container>div,
#__qiankun_microapp_wrapper_for_sub_tiku__ {
    width: 100%;
    height: 100%;
}
</style>
<script>
import { loadMicroApp } from 'qiankun'
import { environment, qs } from '@gaodun.com/gtools'

export default {
    data() {
        return {
            allow_question: false,
            luca_id: 0,
        }
    },
    computed: {
        questionIds() {
            const questionIds = this.$route.query.questionIds
            return questionIds ? questionIds.split(',') : []
        },
        title() {
            switch (this.$route.query.type) {
                case '0':
                    return '做错的题'
                case '1':
                    return '做对的题'
                default:
                    return '收藏的题'
            }
        },
        businessParam() {
            return {
                courseId: Number(this.$route.query.courseId || 0),
                chapterId: Number(this.$route.query.chapterId || 0),
                paperId: 0,
            }
        },
        questionBusinessParamMap() {
            const questionsMap = JSON.parse(localStorage.getItem('questionsMap'));
            const map = {}
            for (const key in questionsMap) {
                if (Object.hasOwnProperty.call(questionsMap, key)) {
                    map[key] = {
                        csItemId: questionsMap[key]
                    }
                }
            }
            return map
        }
    },
    async mounted() {
        this.initCourseSetting()
        this.loadedDoQuestion() 
    },
    methods: {
        loadedDoQuestion () {
            if (this.microApp) {
                this.microApp.unmount()
            }
            this.microApp = loadMicroApp(
                {
                    name: 'sub-tiku',
                    entry: `https://${environment.getApiEnv()}sub-tiku.gaodun.com/do-question`,
                    // entry: `http://dev-sub-tiku.gaodun.com:8066/do-question`,
                    container: document.querySelector('#sub-tiku-container'),
                    props: {
                        baseUrl: '/test/again',
                        questionIds: this.questionIds,
                        theme: 'green',
                        title: this.title,
                        businessParam: this.businessParam,
                        questionBusinessParamMap: this.questionBusinessParamMap,
                        onClickAnswerQuestion: this.handleClickAnswer.bind(this),
                    },
                },
                {
                    sandbox: { strictStyleIsolation: false }, //沙箱样式隔离
                    // 部分特殊的动态加载的微应用资源（css/js) 不被 qiankun 劫持处理
                    excludeAssetFilter: (assetUrl) => {
                        if (assetUrl.indexOf('/luckysheet') > -1) {
                            return true;
                        }
                        return false;
                    }
                },
            )
        },
        async initCourseSetting () {
            const res = await this.$http.getCourseInfoAndSetting(this.$route.query.courseId)
            if (res.status === 0) {
                this.allow_question = Boolean(res.result.allowQuestion);
                this.luca_id = res.result.lucaQaId;
                if (this.luca_id) {
                    this.initLuca()
                }
            }
        },
        handleClickAnswer(data){
            const courseId = this.$route.query.courseId
            if (this.luca_id) {
                this.answerCallback({
                    type: 'open',
                    params: {
                        lucaId: this.luca_id,
                        courseId,
                        courseType: 10,
                        entrySource: 100538098, // 提问入口 全局
                        resourceType: 2, 
                        clientSource: 'gaodun-pc',
                        sceneSource: 1,
                        resourceId: data.questionId,
                        projectId: data.projectId,
                        subjectId: data.subjectId,
                        twoLevelSubjectId: data.twoLevelSubjectId,
                    },
                })
                return;
            }

            if (!this.allow_question) {
                this.$message('暂无权限提问');
                return;
            }
            if (courseId) {
                window.open(
                    qs.stringifyUrl({
                        url: `https://${environment.getApiEnv()}gbot.gaodun.com/update-course/${courseId}/${data.projectId}/${data.subjectId}/${data.questionId}/2`,
                        query: {
                            source: 1,
                            source_type: 'saas',
                            resource: data.questionId,
                            payload: 0,
                            client_source: 0,
                            client_version: 0,
                        },
                    })
                );
            }
        },
        initLuca() {
            this.lucaChatRef = loadMicroApp(
                {
                    name: 'luca-qa',
                    entry: `https://${environment.getApiEnv()}luca.gaodun.com`,
                    container: '#micro-container',
                    props: {
                        onReceiveMessageFromMicroApp: () => {},
                        // 注册通信微应用的方法
                        registryNoticeMicroAppFn: (callback) => {
                            this.answerCallback = callback;
                        },
                    },
                },
                // 启用严格样式隔离
                { sandbox: { strictStyleIsolation: true } }
            );
        }
    },
    beforeDestroy() {
        this.microApp && this.microApp.unmount()
    },
}
</script>
