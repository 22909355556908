<template>
  <el-row :gutter="20" class="wrapper">
    <el-col :span="12">
      <div class="grid-content bg-purple">
        <el-row :class="`audio-wrapper ${classname}-${index} margin-top-15`">
          <audio :src="answer && answer.file_link"></audio>
          <el-col :span="2" class="pause"
            ><img
              @click="start(index)"
                :ref="`img_${index}`"
              :src="playIcon"
              alt=""
          /></el-col>
          <el-col :span="19" class="my-slider">
            <el-slider
              @change="change"
              v-model="audioCurrentTime"
              :max="answer.link_time/1000"
              :step="0.01"
              :show-tooltip="false"
            ></el-slider>
            <el-row  class="start-time">
               <el-col :span="12" class="pause"
            >
             {{timeFormat(Math.floor(audioCurrentTime))}}
            </el-col>
               <el-col :span="12" class="end-time">
             {{timeFormat(Math.floor(answer.link_time/1000))}}
            </el-col>
            </el-row>
          </el-col>
          <el-col :span="2" class="text">
            <el-button type="text" @click="translate">{{$t('turnText')}}</el-button>
          </el-col>
        </el-row>
        <el-row  :class="`translate-wrapper ${classname}-${index}-translate-wapper`" v-if="showTranlate">
          <div v-if="answer&&answer.link_content&&isLoading">
             <el-col class="translate">
              {{answer.link_content}}
          </el-col>
          <el-col class="translate-success"><i class="el-icon-circle-check"></i>{{$t('turnSuccess')}}</el-col>
          </div>
          <div v-if="isLoading&&!answer.link_content" class="translate-fail">
              <div v-if="isShowTry">
                  <span class="fail-text">{{$t('turnFail')}}</span>
                  <el-button type="text" @click="retry" class="retry">{{$t('retry')}}</el-button>
              </div>

          </div>
        </el-row>
      </div>
      </el-col
    >
  </el-row>
</template>

<script>
import {getTaskId,getTranslateText} from '../../api/audio';
import lang from '../../language/lang';
export default {
  props: ["answer",'classname',"index"],
  data() {
    return {
        audioCurrentTime: 0,
        showTranlate:false,
        startImg:'https://simg01.gaodunwangxiao.com/uploadfiles/tmp/upload/202011/23/cfcb8_20201123135141.png',
        pauseImg:'https://simg01.gaodunwangxiao.com/uploadfiles/tmp/upload/202011/23/d0476_20201123170412.png',
        playIcon:'https://simg01.gaodunwangxiao.com/uploadfiles/tmp/upload/202011/23/d0476_20201123170412.png',
        status:'paused',
        isLoading:false,
        isShowTry:false
    };
  },
  mounted() {
      document.querySelector(`.audio-wrapper.${this.classname}-${this.index} .el-slider__button.el-tooltip`).addEventListener('mousedown',()=>{
          this.pauseAll();
      });
  },
  methods: {
    start() {
        this.pauseAll();
        this.$refs[`img_${this.index}`].src = this.startImg;
        let audioDom = document.querySelector(`.${this.classname}-${this.index}>audio` );
        this.audioLister(audioDom,this.$refs[`img_${this.index}`]);
    },
      pauseAll(){
      let audioArr = document.querySelectorAll('audio');
      let inm= document.querySelectorAll('.pause img')
      for(let i=0;i<audioArr.length;i++){
        audioArr[i].pause();
        inm[i].src=this.pauseImg
      }
    },
    change(v) {
      this.pauseAll();
      let audioDom = document.querySelector(`.${this.classname}-${this.index}>audio`);
      audioDom.currentTime = v;
      this.audioLister(audioDom,this.$refs[`img_${this.index}`]);
      audioDom.play();
      this.$refs[`img_${this.index}`].src=this.startImg;
    },
    audioLister(dom,img) {
        dom.addEventListener('timeupdate', () => {
            this.audioCurrentTime = dom.currentTime;
            if (dom.currentTime === dom.duration) {
                this.audioCurrentTime = 0;
                img.src= this.pauseImg;
            }
        });
        dom.addEventListener('playing',() => {
            this.status='playing';
        });
        dom.addEventListener('pause',() => {
            this.status='paused';
        });
        if (this.status === 'paused') {
            dom.play();
            img.src = this.startImg;
        } else {
            dom.pause();
            img.src= this.pauseImg;
        }
    },
    timeFormat(v){
      let m = Math.floor(v/60);
      let s = v%60;
       m = m<=9? `0${m}`:m;
       s = s <=9?`0${s}`:s;
      return `${m}:${s}`;
    },
    translate(){
      if(!this.showTranlate){
      let time = +this.answer.link_time;
      let loading;
      setTimeout(() => {
        this.showTranlate = true;
      }, 0);
      setTimeout(() => {
         loading = this.loadingFun();
      }, 0);
      setTimeout(() => {
        loading.close();
        this.isLoading = true;
        this.isShowTry= true;
      }, time/10>=2000?time/10:2000);
      }
    },
     async retry(){
        let ret = await getTaskId({fileLink:this.answer.file_link});
        if(ret.status === 0){
        let res =await getTranslateText({taskId:ret.result.taskId});
        if(res.status === 0){
            if(+res.result.statusCode === 21050000){
                this.answer.link_content = res.result.answerContent;
                loading.close();
                this.isShowTry = !res.result.answerContent?true:false;
            }else{
                this.$message.warning(lang[window.GD_LANG].turnTextFail);
                loading.close();
                this.isShowTry = true;
            }
        }else{
            this.$message.error(res.message);
            loading.close();
            this.isShowTry = true;
        }
        }else{
            this.$message.error(ret.message);
            loading.close();
            this.isShowTry = true;
        }
    },
  },

};
</script>

<style lang="less">
.audio-wrapper {
  background-color: #FAFAFB;
  padding: 10px 0 0 8px;
  .my-slider{
      height: 50px;
  }
  .pause {
    img {
      display: inline-block;
      width: 28px;
      cursor: pointer;
    }
  }
  .el-slider__runway {
    margin: 10px auto;
  }
  .text {
    display: inline-block;
    text-align: right;

    margin-left: 10px;
    .el-button.el-button--text {
      padding: 5px 0 0 0;
    }
  }
  .end-time{
    text-align: right;
    position: relative;
    right: -26%;
    top: -33px;
  }
  .start-time{
      height: 0;
  }
  .start-time,.end-time{
    font-size: 12px;
    color: #999999;
    .pause{
      position: relative;
      left: -28%;
      top: -9px;
    }
  }
  .start-time{
    transform: scale(.66);
  }
}
.translate-wrapper{
    background-color: #FAFAFB;
     padding: 0 0 10px 0;
     position: relative;
     top: -15px;
    .translate{
      font-size: 12px;
      color: #333;
      padding:8px;
  }
  .translate-success{
    color: #999;
    font-size: 12px;
    margin: 5px 0 0 8px;
    .el-icon-circle-check{
        margin-right: 5px;
    }
  }
  .translate-fail{
    text-align: center;
    font-size: 12px;
    .fail-text{
      color: #999;
      margin-right: 10px;
    }
    .retry{
      font-size: 12px;
    }
  }
  .el-icon-loading{
    color: #999;
    font-size: 25px;
  }
  .el-loading-text{
    display: inline-block;
    font-size: 12px;
    margin-left: 10px;
    color: #999;
    vertical-align: super;
  }
  .el-loading-mask{
      height: 30px!important;
      .el-loading-spinner{
          top: 15px;
      }
  }
  }
</style>
