<template>
    <div class="video-study">
        <div class="video-header clear">
            <!-- 头部 -->
            <div class="video-header-tools">
                <span class="videoiconitem" @click="changeCollapse(1)">
                    <span class="notetab">{{ $t("videoPlay.note") }}</span>
                </span>
                <span class="videoiconitem" @click="changeCollapse(2)">
                    <span class="questiontab">{{ $t("videoPlay.ask") }}</span>
                </span>
            </div>
        </div>
        <div class="video-content clear">
            <!-- <ali-player
                v-if="videoType === 2 && video_id"
                ref="videoPlay"
                :vid="video_id"
                :course-id="courseId"
                :width="'auto'"
                :height="'100%'"
                :play-style="'transition: all .3s linear;'"
                :bulletScreen="false"
                :onRef="onRef"
            /> -->
            <!-- <div
                v-else-if="video_id"
                id="videoPlay"
                class="video-play"
                ref="videoPlay"
            /> -->
            <div  ref="videoPlayConten" class="video-play">
            <SubPlayer 
                v-if="video_id"
                 ref="qiankunPlayer" 
                :video_id="video_id" 
                :initPlayer="initPlayer"
                :businessType="100"
                :sceneType="110"
                :businessNote="businessNote"
            />
            </div>
        </div>
        <!-- 工具栏 -->
        <div class="tools-box" ref="toolsBox">
            <i
                class="toolsBoxclose"
                v-show="toolsBoxclose"
                @click="closeCollapse"
            ></i>
            <PlayTool
                :activeName="parentMsg"
                :video_id="video_id"
                :player="player"
            ></PlayTool>
        </div>
    </div>
</template>
<style>
.video-content {
    width: 100%;
    height: -moz-calc(100% - 60px);
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px);
    position: relative;
}
</style>
<script>
import PlayTool from "../videoStudy/PlayTool.vue";
import Vue from "../../common/Vue.js";
import { RESOURCE_PROGRESS_STATUS_FINISH } from "../../util/keys";
import { reqResourceDetail } from "../../api/study";
import lang from "../../language/lang";
import { ResourceReport } from '../../mixins'
import { mapState } from 'vuex'
import SubPlayer from "../videoStudy/sub-player.vue";

export default {
    data() {
        return {
            live_Info: "", //直播信息
            video_id: "", //视频id
            full_video_id: '', // 完整视频id
            true: false,
            videoPlay: "", //动画盒子
            toolsBox: "", //动画盒子
            timer: "", //查询视频播放时间
            toolsBoxclose: false, //右侧工具弹框的叉叉显示隐藏
            parentMsg: 1,
            player: "", //播放器
            echoLoop: null,
            echoLoopTime: 10 * 60 * 1000,
            showUserTimer: null,
            student_id: 0,
            video_user_id: 0,
            mutationRef: null,
            findRateTagTimer: null,
            isG1:false,
            businessNote:{}
        };
    },
    components: {
        PlayTool,
        SubPlayer
    },
    computed: {
        courseId() {
            return this.$route.query.course_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
        resourceId() {
            return this.$route.query.resource_id;
        },
        liveId() {
            return this.$route.params.liveid;
        },
        videoType() {
            return +this.$route.query.video_type;
        },
        ...mapState({
            _isSendXhr: state => state.study.isSendXhr
        }),
    },
    watch: {
        video_id: {
            handler() {
                if (!this.video_id) {
                    return;
                }
                this.$nextTick(() => {
                    this.videoPlay =
                        this.videoType === 2
                            ? this.$refs.videoPlay.$el
                            : this.$refs.videoPlay;
                    this.toolsBox = this.$refs.toolsBox;
                });
            },
        },
        // 如果路由有变化，会再次执行该方法
        $route: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
    mixins: [ResourceReport],
    mounted() {},
    methods: {
        initPlayer(subPlayer){
            this.player = subPlayer;
            window.gdplayer = subPlayer;
            this.player.on('play', () => {
                this._sReportEvent()
            })
            this.player.on('pause', () => {
                this._resourceRepeat('onpause')
            })
            this.player.on('canplay', () => {
                this.resourceStartTime =  t * 1000;
            })
            this.player.on('durationchange', () => {
                this.createBulletScreen(this.player);
                this.initGPlayerRateChange();
            })
        },
        async init() {
            this.video_id = "";
            if (this.videoType === 2) {
                await this.gethermesLive();
            } else {
                await this.getResourceItem();
            }
            this.updateResourceProgress(RESOURCE_PROGRESS_STATUS_FINISH);
        },
        async getResourceItem() {
            this.loading = true;
            const params = {
                courseId: this.courseId,
                csItemId: this.syllabusItemId,
                syllabusId: this.syllabusId,
            };
            let response = {
                status: 0,
                result: null,
            };

            try {
                response = await reqResourceDetail(this.resourceId, params);
            } catch (e) {
                response.status = 1;
                response.result = e;
            }

            this.loading = false;
            let isHasLive = false;
            try {
                isHasLive = response.result.live.video_list[0];
            } catch (e) {
                console.log(e);
                isHasLive = false;
            }
            if (response.status !== 0 || !isHasLive) {
                this.goResource404();
                return;
            }

            const videoList = response.result.live.video_list;
            const isNewLive = parseInt(response.result.resource.live_id) === -1; //新老版本直播区分字段
            //遍历拿到当前回放
            for (let i = 0; i < videoList.length; i++) {
                if (parseInt(videoList[i].live_id) === parseInt(this.liveId)) {
                    this.live_Info = videoList[i];
                    this.video_id = isNewLive
                        ? videoList[i].new_url
                        : videoList[i].url && videoList[i].url.sewise_source_id; //新版本直播取
                    this.$nextTick(() => {
                        this.setVideoPlay();
                    });
                    return;
                }
            }
        },
        setVideoPlay() {
            if (!this.video_id) {
                //视频id为空时跳转
                this.$message({
                    message: lang[window.GD_LANG].videoPlay.videoError,
                });
                this.goResource404();
                return;
            }

            this.full_video_id = this.video_id;

            const qiankunPlayer = this.$refs.videoPlay;
            if(qiankunPlayer){
                qiankunPlayer.init(this.video_id, this.businessNote);
            }
        },
        changeCollapse(num) {
            Vue.$emit("videostatus", num);
            this.parentMsg = num;
            if (!this.isCollapse) {
        
               
                this.toolsBox.style.width = "320px";
                this.toolsBox.style.opacity = 1;
                this.toolsBoxclose = true;
                this.isCollapse = true;
                this.videoPlayConten.style.marginRight = "320px";

            } else {
                this.toolsBox.style.width = "0px";
                this.toolsBox.style.opacity = 0;
                 this.toolsBoxclose = false;
                this.isCollapse = false;
                this.videoPlayConten.style.marginRight = "0px";
               
            }
        },
        closeCollapse() {
            if (this.isCollapse) {
                this.toolsBox.style.width = "0px";
                this.toolsBox.style.opacity = 0;
                this.toolsBoxclose = false;
                this.isCollapse = false;
                 this.videoPlayConten.style.marginRight = "0px";
            }
        },
        //跳转到资源404
        goResource404() {
            this.$router.replace({
                name: "Resource404",
                query: this.$route.query,
            });
        },
        async updateResourceProgress(status) {
            const params = {
                course_id: +this.courseId, //类型：Number  必有字段  备注：课程id
                cs_item_id: +this.syllabusItemId, //类型：Number  必有字段  备注：条目id
                resource_id: +this.resourceId, //类型：Number  可有字段  备注：资源id
                status: status, //类型：Number  必有字段  备注：进度状态：1-完成；2-进行中
            };
            await this.$http.reqProgressSync(params);
            this.$emit("on-update-syllabus", `progress-${status}`);
        },
        _findGPlayerRateTagElement() {
            return new Promise((resolve) => {
                let observerDOM;
                let findSecond = 0;
                if (this.findRateTagTimer) {
                    clearInterval(this.findRateTagTimer);
                    this.findRateTagTimer = null;
                }
                this.findRateTagTimer = setInterval(() => {
                    if (findSecond > 5) {
                        clearInterval(this.findRateTagTimer);
                        this.findRateTagTimer = null;
                        resolve(null)
                    }
                    if (this.player
                        && this.player.render
                        && this.player.render.rightPanel
                        && this.player.render.rightPanel.rateTag.element
                    ) {
                        observerDOM = this.player.render.rightPanel.rateTag.element;
                        clearInterval(this.findRateTagTimer);
                        this.findRateTagTimer = null;
                        resolve(observerDOM)
                    }
                    findSecond++;
                }, 1000);
            })
        },
        async initGPlayerRateChange() {
            const observerDOM = await this._findGPlayerRateTagElement();
            if (this.mutationRef) {
                this.mutationRef.disconnect();
                this.mutationRef = null;
            }
            if (observerDOM) {
                const callback = (mutations) => {
                    const GplayerRate = mutations[0].removedNodes[0].nodeValue
                        ? mutations[0].removedNodes[0].nodeValue.slice(0,3)
                        : '';
                    if (this._getVTimer()) {
                        // 立即上报
                        this._resourceRepeat('setSpeed', {playSpeed: GplayerRate});
                        // 结束计时
                        this._eReportEvent();
                        // 重新计时
                        this._sReportEvent()
                    }
                };

                const config = {
                    attributes: false,
                    attributeOldValue: false,
                    characterData: true,
                    characterDataOldValue: true,
                    childList: true,
                    subtree: true,
                };
                this.mutationRef = new MutationObserver(callback);
                // cofig设置项必须的有
                this.mutationRef.observe(observerDOM, config);
            }
        },
        createBulletScreen(player) {
            this.checkUserLogin(player.id);
            this.showUserId(player.id);
        },
        checkUserLogin(id) {
            clearInterval(this.echoLoop);

            this.echoLoop = setInterval(() => {
                const container =
                    document.querySelector("#videoPlay").children[0] ||
                    document.querySelector("#videoPlay");
                let domUserID = document.createElement("div");
                domUserID.id = "pos_" + Math.ceil(Math.random() * 1000);
                domUserID.className = "check_user_login";
                domUserID.innerHTML =
                    lang[window.GD_LANG].videoPlay.bulletScreen.before +
                    id +
                    lang[window.GD_LANG].videoPlay.bulletScreen.after;
                container.appendChild(domUserID);
                setTimeout(function () {
                    container.removeChild(domUserID);
                }, 6000);
            }, this.echoLoopTime);
        },
        bindPlayerEvent() {
            this.player.getCurrentTime = () => {
                const currentTime =
                    this.player && this.player.core
                        ? this.player.core.currentTime
                        : 0;
                return parseInt(currentTime || 0);
            };
            this.player.getDuration = () => {
                const duration =
                    this.player && this.player.core
                        ? this.player.core.duration
                        : 0;
                return parseInt(duration || 0);
            };
        },
        showUserId(id) {
            const container =
                document.querySelector("#videoPlay").children[0] ||
                document.querySelector("#videoPlay");
            let domUserID = document.createElement("div");
            const PROGRESS_BAR = 50;
            let maxPosLeft = 100,
                maxPosTop = 100,
                domUserIDWid = 0,
                domUserIDHei = 0;
            let containerWid = container.offsetWidth,
                containerHei = container.offsetHeight;
            let posLeft = Math.floor(Math.random() * 100);
            let posTop =
                posLeft < 0 || posLeft > 80
                    ? Math.floor(Math.random() * 100)
                    : (posTop = Math.floor(Math.random() * 10));
            domUserID.id = "pos_id_" + Math.ceil(Math.random() * 1000);
            let styleText =
                "position: absolute;font-size: 13px; color: #ffffff;" +
                "text-shadow:0 1px 1px #000;" +
                "opacity: 0.1;" +
                "filter: alpha(opacity=10); z-index: 9147483647;";
            domUserID.setAttribute("style", styleText);
            domUserID.style.left = posLeft + "%";
            domUserID.style.top = posTop + "%";
            domUserID.innerHTML = id;
            container.appendChild(domUserID);

            clearInterval(this.showUserTimer);
            this.showUserTimer = setInterval(() => {
                !domUserIDWid ? (domUserIDWid = domUserID.offsetWidth) : "";
                !domUserIDHei ? (domUserIDHei = domUserID.offsetHeight) : "";
                !containerWid ? (containerWid = container.offsetWidth) : "";
                !containerHei ? (containerHei = container.offsetHeight) : "";
                maxPosLeft = Math.floor(
                    ((containerWid - domUserIDWid) / containerWid) * 100
                );
                maxPosTop = Math.floor(
                    ((containerHei - domUserIDHei - PROGRESS_BAR) /
                        containerHei) *
                        100
                );
                posLeft = Math.floor(Math.random() * maxPosLeft);
                if (posLeft < 2 || posLeft > 80) {
                    posTop = Math.floor(Math.random() * maxPosTop);
                } else {
                    posTop = Math.floor(Math.random() * 10);
                }
                domUserID.setAttribute("style", styleText);
                domUserID.style.left = posLeft + "%";
                domUserID.style.top = posTop + "%";
            }, 5000);
        },
        async gethermesLive() {
            const res = await this.$http.reqHermesLiveDetail(this.resourceId);
            if (!res || res.status !== 0 || !res.data) return;
            this.video_id = res.data.simplify_record_url;
        },
        onRef(player) {
            this.player = player;
        },
    },
    async created() {
        this.businessNote = {
            csItemId: this.syllabusItemId,
            resourceId: this.resourceId,
            // progressStatus: 1,// 资源完成状态 0：未完成，1：已完成，2：进行中
            liveId: this.liveId,
            // videoPlayerRecovery: 0,//  0：正常模式，1：灾备模式
            resourceType: 'live_new'
        }
        if (
            !isNaN(Number(localStorage.getItem("echoLoopTime"))) &&
            Number(localStorage.getItem("echoLoopTime")) !== 0
        ) {
            this.echoLoopTime = Number(localStorage.getItem("echoLoopTime"));
        }
        this.init();
    },
    beforeDestroy() {
        clearInterval(this.echoLoop);
        if (this.mutationRef) {
            this.mutationRef.disconnect();
            this.mutationRef = null;
        }
        if (this.findRateTagTimer) {
            clearInterval(this.findRateTagTimer);
            this.findRateTagTimer = null;
        }
    },
};
</script>
