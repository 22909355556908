<template>
    <div class="paper-view Multiple-paper">
        <div class="paper-view-head clear">
            <div class="paper-type">
                <span v-if="type == 2 || type == 52">多选题</span>
                <span v-if="type == 7">不定项</span>
            </div>
            <div class="progerss-text">
                <span> (</span
                ><span class="currentnum">{{ cur_data_index }}</span
                >/<span>{{ total_data }}</span
                ><span>)</span>
            </div>
        </div>
        <Audio v-if="titleAudio" :audio-src="titleAudio"></Audio>
        <div
            v-html="title"
            class="paper-title"
            @click="previewImages($event)"
        ></div>

        <div class="paper-options">
            <div>
                <div
                    :v-html="option"
                    class="paper-options-content"
                    @click="previewImages($event)"
                >
                </div>
                <div class="paper-options-group">
                    <span
                        class="paper-options-items"
                        v-for="(val, index) in Number(optionNum)"
                        :key="index"
                        :class="[
                            answer &&
                            answer.indexOf(String.fromCharCode(index + 65)) > -1
                                ? 'cur'
                                : '',
                        ]"
                        @click="
                            checkAns &&
                                checkAns(String.fromCharCode(index + 65))
                        "
                    >
                        {{ String.fromCharCode(index + 65) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Audio from "./Audio.vue";

export default {
    components: {Audio},
    props: {
        //题型
        type: {
            type: [String, Number],
            required: true,
        },
        //题干
        title: {
            type: String,
            required: true,
        },
        //答案
        answer: {
            type: [String, Array],
        },
        //选项
        option: {
            type: String,
            required: true,
        },
        optionNum: {
            type: [String, Number],
            required: true,
        },
        //当前第几题
        cur_data_index: [String, Number],
        //总题数
        total_data: [String, Number],
        //选择答案的回调
        checkAns: [Function],
        titleAudio: String,
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        previewImages(e) {
            this.$emit("previewImages", e);
        },
    },
    created() {},
};
</script>
