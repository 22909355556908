export function number2DateTime(value, fmt = 'yyyy-MM-dd HH:mm:ss') { // yyyy-MM-dd HH:mm:ss
    if (isNaN(value)) return '';
    const date = new Date(Number(value));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return fmt.replace('yyyy', year.toString())
    .replace('yy', (year % 100).toString())
    .replace('MM', (month).toString())
    .replace('dd', (day).toString())
    .replace('HH', (hour).toString())
    .replace('mm', (minute).toString())
    .replace('ss', (second).toString());
}

