import { post, get, zDelete } from '../util/zeusAxios';
import { formPost } from '../util/zeusFormAxios.js'
import { getBaseUrl } from '../util/config'


// 根据大纲拉取资源接口
// export const getResourceItem = (source_id, params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/resource/${source_id}`, params);
export const getResourceItem = (source_id, params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-study/api/v1/front/resource/${source_id}`, params);

export const getResourceItemEP3Task = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/create`, params);

export const reqResourceDetail = (resourceId, params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-study/api/v1/front/resource/${resourceId}`, params);

// export const reqResourceDetail = (resourceId, params) => get(`/ep-study/api/v1/front/resource/${resourceId}`, params);

//课程首页大纲树
export const getSyllabusTree = (course_id, syllabus_id, params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/syllabus/${syllabus_id}/tree`, params);

//查询笔记
export const getNoteList = (resource_id, params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/resource/${resource_id}/note`, params);
//EP3查询笔记 ?course_id=12431&resource_id=827778
export const getNoteListEP3Task = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/note/list`, params);

//报告页面
export const getPaperReport = (course_id, syllabus_id, paper_data_id, params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/${syllabus_id}/paper/${paper_data_id}/report`, params);
export const getPaperReportEp3Task = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/report`, params);

//删除笔记
export const deleteNoteinfo = (note_id, params) => zDelete(`${getBaseUrl()}apigateway.gaodun.com/study-api/note/${note_id}`, params)

export const deleteNoteinfoEP3Task = (note_id) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/note/del`,  {note_id,})

//判断试卷状态
export const getPaperStatus = (resource_id, params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/resource/${resource_id}/status`, params)
//course_id=12295&cs_item_id=256627&resource_id=812715&is_skip=0
export const getPaperStatusEp3Task = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/status`, params)

//重做
export const RepeatPaper = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/paper/repeat/times`, params)
export const repeatPaperEP3Task = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/repeat`, params)

//提问相关常见问题
export const commonQuestion = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/carefree/api/v1/front/ask/common`, params)

//保存学习位置
export const saveStudyPosition = (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/study/position`, params)

//获取用户头像
export const getStudentInfo = () => get(`${getBaseUrl()}apigateway.gaodun.com/passport/api/v3/user/get-user-info-by-token`);

//检查学习位置是否正确
export const checkoutStudyPosition = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-study/front/study/position/checkout`, params)

//创建gbot会话
export const createGbotUrl = `${getBaseUrl()}apigateway.gaodun.com/gbotapi/api/conversation/create`

//删除收藏
export const cancelFavorite = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/question/favorite`, params)

//获取讲义查看地址
export const reqHandoutAuthPath = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/hermes/front/v1/download/resource`, params);

//学习完成同步进度&记录最后一次学习位置
export const reqProgressSync = (params) => post(`/ep-study/front/progress/sync`, params);

// 资源上报
export const reqResourceRepeatRecord = (params) => post(`/g-study/api/v1/front/resource/repeatRecord`, params);

// [G2精简回放] - 上报学习时长
export const reportPlayTime = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/hermes/glive/reportViewTime`, [params]);

/** 增加视频资源判断 是G1 还是G2 */ 
export const isG2OrG1VideoTask = (sourceId) => get('/glive2-vod/api/v1/check/resource', { sourceId });

// 查询试卷状态新
export const getPaperRecord = (params) => get('/minerva/api/v1/front/student/paper/record', {...params, needAuth: 1});

// 重新做题
export const redoItemPaper = (params) => post('/minerva/api/v1/front/redo-paper', params);

// 创建试卷 
export const createItemPaper = (params) => post('/minerva/api/v1/front/create-paper', params);

// 获取大纲条目信息
export const getCsItemInfo = (id) => get(`/ep-course/api/v1/item/${id}`);

// 获取大纲试卷条目批阅状态
export const getSyllabusItemCorrectStatus = (params) => get('/ep-paper/front/paper/update/status', params);
