<template>
    <div class="paper-view TFNG-paper">
        <div class="paper-view-head clear">
            <div class="paper-type">
                <span>判断题</span>
            </div>
            <div class="progerss-text">
                <span> (</span>
                <span class="currentnum">{{ cur_data_index }}</span
                >/
                <span>{{ total_data }}</span>
                <span>)</span>
            </div>
        </div>
        <Audio v-if="titleAudio" :audio-src="titleAudio"></Audio>
        <div
            v-html="title"
            class="paper-title"
            @click="previewImages($event)"
        ></div>

        <div class="paper-options">
            <div class="paper-select-content">
                <el-radio-group v-model="computedAnswer">
                    <!--0是错 1是对-->
                    <el-radio :disabled="isDisabled" label="1">对</el-radio>
                    <el-radio :disabled="isDisabled" label="0">错</el-radio>
                    <!--0是错 1是对-->
                    <!-- <el-radio label="1">对</el-radio> -->
                    <!-- <el-radio label="0">错</el-radio> -->
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import Audio from "./Audio.vue";

export default {
    components: {Audio},
    props: {
        //题型
        type: [String, Number],
        //题干
        title: {
            type: String,
            required: true,
        },
        //答案
        answer: {
            type: String,
        },
        //当前第几题
        cur_data_index: [String, Number],
        //总题数
        total_data: [String, Number],
        titleAudio: String,
        //选择答案的回调
        // checkAns: {
        //     type:Function,
        // },
    },
    data() {
        return {
            isDisabled: false,
        };
    },
    computed: {
        computedAnswer: {
            get: function () {
                return this.answer;
            },
            set: function (a) {
                this.$emit("checkAns", a);
            },
        },
    },
    mounted() {},
    methods: {
        previewImages(e) {
            this.$emit("previewImages", e);
        },
    },
    created() {
        let path = this.$route.path;
        if (path.indexOf("analysis") > -1) {
            this.isDisabled = true;
        } else {
            this.isDisabled = false;
        }
    },
};
</script>
