<template>
    <div class="syllabus-menu-inner">
        <div
            v-if="active"
            :class="['syllabus-menu-item', 'is-active', activeClass]"
            @click="handleClick(active)"
        >
            <div class="syllabus-menu-item-title multiple-line-ellipsis">
                <span>{{active.name}}</span>
            </div>
            <div class="syllabus-menu-item-info" v-if="active.total_resource">
                <i class="intro-icon intro-icon3" />
                <span v-if="active.video_total">{{active.video_total}}{{$t('syllabus.video')}}</span>
                <span v-if="active.lecture_note_total">{{active.lecture_note_total}}{{$t('syllabus.lectureNote')}}</span>
                <span v-if="active.paper_total">{{active.paper_total}}{{$t('syllabus.paper')}}</span>
                <span v-if="active.legacy_live_total">{{active.legacy_live_total}}{{$t('syllabus.legacyLive')}}</span>
                <span v-if="active.soft_link_total">{{active.soft_link_total}}{{$t('syllabus.soft_link')}}</span>
            </div>
        </div>
        <transition name="el-fade-in-linear">
            <div class="swiper-action-icon" v-show="isShowArrow">
                <i class="el-icon-arrow-down" />
                <i class="el-icon-arrow-down" />
            </div>
        </transition>
        <swiper
            :options="swiperOption"
            class="syllabus-menu-scroll-box"
            id="syllabus-menu-scroll-box"
            ref="mySwiper"
        >
            <swiper-slide
                v-for="(item, index) in list"
                :key="`swiper-slide-${index}`"
            >
                <div
                    :class="['syllabus-menu-item', +active.id === +item.id ? 'is-active' : '' ]"
                    @click="handleClick(item, index)"
                >
                    <div class="syllabus-menu-item-title multiple-line-ellipsis">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="syllabus-menu-item-info" v-if="item.total_resource">
                        <i class="intro-icon intro-icon3" />
                        <span v-if="item.video_total">{{item.video_total}}{{$t('syllabus.video')}}</span>
                        <span v-if="item.lecture_note_total">{{item.lecture_note_total}}{{$t('syllabus.lectureNote')}}</span>
                        <span v-if="item.paper_total">{{item.paper_total}}{{$t('syllabus.paper')}}</span>
                        <span v-if="item.legacy_live_total">{{item.legacy_live_total}}{{$t('syllabus.legacyLive')}}</span>
                        <span v-if="item.soft_link_total">{{item.soft_link_total}}{{$t('syllabus.soft_link')}}</span>

                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>

</template>
<script>
import '../../../assets/css/syllabus/syllabus-chapter.less';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import { bindEvents } from '../../../util/sensor-data';
import { W2_CLICK_EPSYLLABUS} from '@/config'
export default {
    components: {
        swiper,
        swiperSlide,
    },
    props: {
        list: Array,
        active: Object,
    },
    data() {
        return {
            swiperOption: {
                direction: 'vertical',
                slidesPerView: 'auto',
                freeMode: true,
                observer: true,
                noSwiping: false,
                preventClicks: false,
                scrollbar: false,
                mousewheel: {
                    invert: true,
                    forceToAxis: true,
                    releaseOnEdges: false,
                },
                on: {
                    slideChange: () => {
                        this.handleChangeSlide();
                    },
                },
            },
            snapIndex: 0,
            isShowArrow: false,
        };
    },
    computed: {
        swiperJS() {
            return this.$refs.mySwiper.swiper;
        },
        activeClass() {
            if (this.active) {
                const index = this.list.findIndex((item) => +this.active.id === +item.id);
                return this.snapIndex > index ? 'is-visible' : 'is-hidden';
            }
            return '';
        },
    },
    mounted() {
        this.isShowArrow = this.swiperJS.virtualSize > this.swiperJS.size && !this.snapIndex;
    },
    updated() {
        this.isShowArrow = this.swiperJS.virtualSize > this.swiperJS.size && !this.snapIndex;
    },
    methods: {
        handleChangeSlide() {
            this.snapIndex = this.swiperJS.snapIndex;
        },
        handleClick(data) {
            bindEvents(W2_CLICK_EPSYLLABUS, { syllabus_id: data.course_syllabus_id, chapter_id: data.item_id });            
            this.$emit('on-click', data);
        },
    },
};
</script>
