import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {cookie} from '@gaodun.com/gtools'

// element-ui多语言
import locale from 'element-ui/lib/locale';
import zhEl from 'element-ui/lib/locale/lang/zh-CN';
import zhTWEl from 'element-ui/lib/locale/lang/zh-TW';

// 本地多语言
import zhCN from "./lang/zh-CN";
import zhTW from "./lang/zh-TW";

Vue.use(VueI18n);
window.GD_LANG = cookie.getCookie('lang') || 'zh-CN'

const i18n =  new VueI18n({
    locale: cookie.getCookie('lang') || 'zh-CN', // 设置地区
    messages: {
        'zh-CN': {...zhCN, ...zhEl},
        'zh-TW': {...zhTW, ...zhTWEl},
    }
})
locale.i18n((key, value) => i18n.t(key, value))

export default i18n
