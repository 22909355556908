import {formPost,formPut} from '../util/zeusFormAxios.js'
import {getBaseUrl, getEnv} from '../util/config'
import {post, get} from '../util/zeusAxios.js';

//创建学习进度
export const StudyProgress= (course_id,params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/progress`, params)

//保存做题
export const SaveAnswer= (course_id,syllabus_id,paper_id,params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/${syllabus_id}/paper/${paper_id}/answer`, params)
export const saveAnswerEp3Task= (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/save`, params)

//提交试卷
export const SubmitPaper= (course_id,syllabus_id,paper_id,params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/save`, params)
export const SubmitPaperEP3Task= (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/paper/submit`, params);

//添加笔记
export const addNoteList= (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/note`, params)
//ep3添加笔记
export const addNoteListEp3Task= (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/note/add`, params)

//修改笔记
export const upNotetol= (note_id,params) => formPut(`${getBaseUrl()}apigateway.gaodun.com/study-api/note/${note_id}`, params)
//修改笔记ep3: https://apigateway.gaodun.com/ep-paper/front/note/edit
export const updateNoteEp3Task= (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/note/edit `, params)

//添加题目收藏
export const favoriteQuestion = (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/question/favorite`, params)
//添加题目收藏
export const favoriteOrDelQuestionEP3Task = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/question/favorite`, params)
//一题一做交卷
export const oneQuestionOneSubmitEP3Task = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/item/repeat`, params)
//一题一做
export const getOneByOneQuestionEP3Task = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/item/info`, params)









