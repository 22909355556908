<template>
    <div
        :style="[{
            position: 'relative',
            width: '100%',
            minHeight: '300px',
            height: 'calc(100vh - 210px)',
        }]"
    >
        <div
            :style="[{
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
            }]"
        >
            <img src="../../assets/images/empty.png" alt="" />
            <div
                :style="[{
                    textAlign: 'center',
                    color: '#687583',
                    marginTop: '16px',
                    fontSize: '16px',
                }]"
                v-if="text"
            >
                {{text}}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EmptyView',
    props: {
        src: String,
        text: String,
    },
    data() {
        return {};
    },
};
</script>
