<template>
    <div class="paper-report" v-loading="zLoading"
         :element-loading-text="loadingText">
        <div class="report-head clear">
            <div class="user_icon">
                <div class="user_img">
                    <img :src="picture_url?picture_url:defaultSrc" @error="getImageUrl" alt="头像">
                </div>
            </div>
            <div class="report-head-l">
                <div class="head-title">测试报告</div>
                <div class="report-time" >{{reportTime}}
                </div>
            </div>
            <div class="report-head-c">
                <div class="paper-score">
                    <span class="paper-score-num">{{$store.state.report.score}}</span>
                    <span>分</span>
                </div>
                <div class="paper-info">
                    <span>总题数</span><span>{{$store.state.report.question_total}}</span><span>道</span>
                    <span>&nbsp;&nbsp;&nbsp;答对</span><span>{{$store.state.report.question_right}}</span><span>道</span>
                    <span>&nbsp;&nbsp;&nbsp;答错</span><span>{{$store.state.report.question_wrong}}</span><span>道</span>
                </div>
            </div>
        </div>
        <div class="report-content">
            <div class="report-content-box">
                <div class="report-content-head">
                    <div class="report-title">答题状态</div>
                    <div class="report-tips">
                        <span class="dot green-dot"></span>
                        <span class="tips-txt">正确</span>
                        <span class="dot red-dot"></span>
                        <span class="tips-txt">错误</span>
                    </div>
                </div>
                <div class="answer-status-content">
                    <div class="clear">
                        <span
                            :class="[item.type == 5 && item.sonitem && item.sonitem.length > 0 ? 'question-items-root' : 'question-items',
                             item.type == 5 && item.sonitem && item.sonitem.length > 0 ? '' : (item.user_answer && item.user_answer.istrue == 1 ? 'right' : 'wrong')]"
                            v-for="(item, question_index) in paper_data"
                            :key="question_index"
                        >
                            <span v-if="!item.sonitem || item.sonitem.length == 0">
                                {{question_index+1}}
                            </span>
                            <span
                                class="question-items"
                                :class="[child.user_answer && child.user_answer.istrue == 1 ? 'right' : 'wrong']"
                                v-for="(child, i) in item.sonitem"
                                :key="i">
                                {{`${question_index+1}-${i+1}`}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="report-foot">
            <span class="footer-btn btn-analysis" @click="checkAnalysis">查看解析</span>
            <span class="footer-btn hand-in-paper" @click="RepeatPaper">再做一次</span>
        </div>
    </div>
</template>
<style>
    .paper-report .el-loading-mask {
        height: 100%;
        background: #f4f7f6;
    }
    .question-items-root{
        display: flex;
        float: left;
    }
</style>
<script>
    import echarts from 'echarts/lib/echarts'
    import 'echarts/lib/chart/gauge';
    import 'echarts/lib/component/tooltip';
    import {mapState} from 'vuex'
    import {repeatPaperEP3Task} from '../api/study.js'
    import { getPaperReportEp3Task, getStudentInfo, getPaperStatusEp3Task} from '../api/study.js'
    import {gaugeOption, WrongPaper} from '../common/paperConfig.js'
    import {defaultSrc} from '../common/paperConfig.js'
    import {formatDateTime} from '../../src/util/formatDate'
    import {loadingText} from '../common/loadingTextConfig.js'
    import Vue from '../common/Vue.js'
import { bindEvents } from '../util/sensor-data.js';
import { W2_VIEWSCREEN_PAPERREPORT } from "@/config";

    export default {
        components: {},
        data() {
            return {
                gaugeOption: gaugeOption,
                average_echart: '',
                percent_echart: '',
                paper_data_id: '',
                paper_id: '',
                course_id: "",
                syllabus_id: "",
                resource_id: "",
                cs_item_id: "",
                picture_url: '',//用户头像
                defaultSrc: defaultSrc,
                zLoading: false,
                loadingText: '',
                checkResourceNameTimer: '',//检车资源名的定时器
                reportTime:'',
                paper_data_log_id:''
            }
        },
        computed: {
            ...mapState({
                paper_data: state => {
                    return state.report.report.item_list;
                }
            }),
        },
        mounted() {
            bindEvents(W2_VIEWSCREEN_PAPERREPORT);
        },
        filters: {
            TimeFormat: function (value) {
                if (!value) return ''
                return value.split(':')[0] + ':' + value.split(':')[1];
            }
        },
        methods: {
            getImageUrl(data) {
                data.currentTarget.src = defaultSrc;
            },
            //查看解析
            checkAnalysis() {
                this.$router.push({
                    path: '/study/paper/analysis',
                    query: {...this.$route.query,paper_data_log_id: this.$store.state.report.report.paper_data_log_id}
                })
            },
            //获取试卷状态
            async getPaperStatus() {
                this.course_id = this.$route.query.course_id;
                this.syllabus_id = this.$route.query.syllabus_id;
                this.resource_id = this.$route.query.resource_id;
                this.cs_item_id = this.$route.query.syllabusitem_id;
                let resource_id = this.resource_id;
                let params = {
                    course_id: this.course_id,
                    cs_item_id: this.cs_item_id,
                    resource_id: this.resource_id
                }
                this.loadingText = loadingText();
                this.zLoading = true;
                // let ret = await getPaperStatus(resource_id, params);
                let statusRes = await getPaperStatusEp3Task(params);
                if (statusRes.status == 0) {
                    if (statusRes.result.is_submit == 1) {
                        this.$store.dispatch('changeIsSubmit', true); //交卷了
                        this.paper_id = statusRes.result.paper_id;
                        this.paper_data_id = statusRes.result.paper_data_id;
                        this.paper_data_log_id = statusRes.result.paper_data_log_id;
                        this.getStudentInfo();//获取用户信息
                        await this.getPaperReport();
                        this.zLoading = false;
                    } else if (statusRes.result.is_submit == 0) {
                        this.$store.dispatch('changeIsSubmit', false); //mei交卷了
                        this.$router.push({
                            path: '/study/paper/exercise',
                            query: this.$route.query
                        })
                    }
                } else {
                    this.zLoading = false;
                }
            },
            //重做
            async RepeatPaper() {
                let params = {
                    course_id: this.$route.query.course_id,
                    cs_item_id: this.$route.query.syllabusitem_id,
                    paper_data_log_id: this.paper_data_log_id,

                };
                let ret = await repeatPaperEP3Task(params);
                if (ret.status == 0) {
                    this.$router.push({
                        path: `/study/paper/exercise`,
                        query: this.$route.query,
                    })
                }
            },
            //获取报告
            async getPaperReport() {
                let course_id = this.course_id;
                let syllabus_id = this.syllabus_id;
                let paper_id = this.paper_id;
                let params = {
                    course_id: this.course_id,
                    cs_item_id: this.$route.query.syllabusitem_id,
                    paper_data_log_id: this.paper_data_log_id||'',
                    paper_data_id: this.paper_data_id,
                }

                let ret = await getPaperReportEp3Task(params);
                if (ret.status == 0) {
                    this.$store.dispatch('getReport', ret.result);
                    this.$store.dispatch('savePaper', ret.result.paper);
                    this.reportTime = formatDateTime(ret.result.paper && ret.result.paper.modifydate || 0);

                }
            },
            //获取用户信息
            async getStudentInfo() {
                let ret = await getStudentInfo();
                if (ret.status == 0) {
                    this.picture_url = ret.data.headImgUrl;
                }
            },
            //初始化试卷
            destroyPaper() {
                this.$store.dispatch('destroyPaper');
            }
        },
        watch: {
            // 如果路由有变化，会再次执行该方法
            "$route": ['destroyPaper', 'getPaperStatus'],
        },
        created() {
            this.course_id = this.$route.query.course_id;
            this.syllabus_id = this.$route.query.syllabus_id;
            this.resource_id = this.$route.query.resource_id;
            this.cs_item_id = this.$route.query.syllabusitem_id,
            this.getPaperStatus();
        },
        beforeDestroy() {
            this.$store.dispatch('destroyPaper');
            this.$store.dispatch('destroyReport');
        }
    }
</script>
