export const COURSEINFO = 'COURSEINFO' //课程首页信息
export const LETTERDIALOG = 'LETTERDIALOG' //课程首页信封弹层


export const OUTLINE_TABS = 'OUTLINE_TABS' //课程大纲的阶段
export const SYLLABUS_ATTR = 'SYLLABUS_ATTR' //课程大纲的基本属性
export const ACTIVE_SYLLABUS = 'ACTIVE_SYLLABUS' //当前激活的资源
export const ACTIVE_GRADATION = 'ACTIVE_GRADATION' //当前激活的阶段
export const CURRENT_RESOURCE = 'CURRENT_RESOURCE' //当前激活的资源条目
export const RESOURCE_LIST = 'RESOURCE_LIST' //资源列表

export const SYLLABUS_ITEMS = 'SYLLABUS_ITEMS' //课程大纲的条目
export const NEXT_RESOURCE = 'NEXT_RESOURCE' //记下下一个资源的条目

export const STUDY_PATH = 'STUDY_PATH' //跳转到学习页的路径


export const COURSE_ID = 'COURSE_ID' //保存课程id 在学习页
export const RESOURCE_ID = 'RESOURCE_ID' //资源id 
export const SYLTEM_ID = 'SYLTEM_ID' //保存当前资源大纲条目id 在学习页
export const SYITEM_NAME = 'SYITEM_NAME' //保存当前资源大纲条目名称 在学习页
export const SAVE_PAPER = 'SAVE_PAPER' //保存试卷
export const DESTROY_PAPER = 'DESTROY_PAPER' //销毁试卷
export const CHANGE_IS_SUBMIT = 'CHANGE_IS_SUBMIT' //交卷状态改变
export const RECOMBINE_PAPER = 'RECOMBINE_PAPER' //重组试卷按题目类型
export const NEXT_QUESTION = 'NEXT_QUESTION' //下一道题目
export const PREV_QUESTION = 'PREV_QUESTION' //上一道题目
export const SAVE_ANSWER = 'SAVE_ANSWER' //保存用户的答案
export const NEXT_SM_QUESTION = 'NEXT_SM_QUESTION' //下一道小题
export const PREV_SM_QUESTION = 'PREV_SM_QUESTION' //上一道小题
export const CHANGE_SM_QUESTION = 'CHANGE_SM_QUESTION' //点击切换小题
export const CHANGE_SHEET = 'CHANGE_SHEET' //点击答题卡切换
export const CHANGE_FAVORITE = 'CHANGE_FAVORITE' //收藏
export const GET_NEWTIME = 'GET_NEWTIME' //收藏

export const GET_REPORT = 'GET_REPORT' //保存报告
export const DESTROY_REPORT = 'DESTROY_REPORT' //销毁报告


export const GET_KNOWLEDGE = 'GET_KNOWLEDGE' //保存知识图谱信息
export const CHANGE_KNOWLEDGE = 'CHANGE_KNOWLEDGE' //切换知识图谱的章节索引、内容


export const SAVE_TEST_DATA = 'SAVE_TEST_DATA' //保存练习信息
export const RECOMBINE_DATA = 'RECOMBINE_DATA' //重组试卷按题目类型
export const NEXT_EXERCISE = 'NEXT_EXERCISE' //下一道题目
export const PREV_EXERCISE = 'PREV_EXERCISE' //上一道题目
export const SAVE_EXERCISE_ANSWER = 'SAVE_EXERCISE_ANSWER' //保存用户的答案
export const NEXT_SM_EXERCISE = 'NEXT_SM_EXERCISE' //下一道小题
export const PREV_SM_EXERCISE = 'PREV_SM_EXERCISE' //上一道小题
export const CHANGE_SM_EXERCISE = 'CHANGE_SM_EXERCISE' //点击切换小题
export const CHANGE_TEST_SHEET = 'CHANGE_TEST_SHEET' //点击答题卡切换
export const FAVORITE_EXERCISE = 'FAVORITE_EXERCISE' //收藏
export const SET_IS_COMMIT = 'SET_IS_COMMIT' //设置提交


export const SAVE_LIVEDATA = 'SAVE_LIVEDATA' //保存直播信息

export const SAVE_HANDOUT_CATEGORY = 'SAVE_HANDOUT_CATEGORY'// 保存是否设置讲义分类
export const SAVE_DEVELOP = 'SAVE_DEVELOP'// 保存大纲是否展开
export const PAGER_DATA = 'PAGER_DATA'  // 试卷信息


