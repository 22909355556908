//网课类型
export const knowledge_type = [
    {
        label: '0',
        name: '全部',
    }, {
        label: '1',
        name: '需加强',
    }, {
        label: '2',
        name: '未掌握',
    }
];

export const knowledgeResult = {
    "id": 93,
    "title": "基金从业：科目一 基金法律法规",
    "contents": [
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11788,
                    "title": "Code of Ethics and Standards of Professional Conduct",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11789,
                    "title": "CFA Institute Research Objectivity Standards",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11790,
                    "title": "The Glenarm Company",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11791,
                    "title": "Preston Partners",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11792,
                    "title": "Super Selection",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11793,
                    "title": "Trade Allocation: Fair Dealing and Disclosure",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11794,
                    "title": "Changing Investment Objectives",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11787,
            "title": "Ethical and Professional Standards",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11796,
                    "title": "Correlation and Regression",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11797,
                    "title": "Multiple Regression and Issues in Regression Analysis",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11798,
                    "title": "Time-Series Analysis",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11795,
            "title": "Quantitative Methods",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": [
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11801,
                            "title": "Bid-Ask spread",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11802,
                            "title": "Cross rate calculation",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11803,
                            "title": "Triangular Arbitrage",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11804,
                            "title": "Forward Discount or Premium",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11805,
                            "title": "Mark-to-market value of a forward contract",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11806,
                            "title": "International Parity Relationships",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11807,
                            "title": "Covered and uncovered Interest Rate Parity",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11808,
                            "title": "International Fisher Relation",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11809,
                            "title": "PPP",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11810,
                            "title": "Long Term Exchange Rate Determination",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11811,
                            "title": "FX Carry Trade",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11812,
                            "title": "Balance-of-Payments",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11813,
                            "title": "Long Term  Equilibrium Exchange Rate Assessments",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11814,
                            "title": "Effects of  Monetary and Fiscal Policy",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11815,
                            "title": "Central Bank Intervention and Capital Controls",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11800,
                            "relation_type": 5,
                            "id": 11816,
                            "title": "Signs of Currency Crisis",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        }
                    ],
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11800,
                    "title": "Currency Exchange Rates: Understanding Equilibrium Value",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": [
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11819,
                            "title": "Potential GDP vs. Actual GDP",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11820,
                            "title": "Production Function",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11821,
                            "title": "Growth Accounting Relations",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11822,
                            "title": "Productivity Curves",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11823,
                            "title": "Natural Resources",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11824,
                            "title": "Labor Supply Factors",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11825,
                            "title": "Factors Affecting Economic Growth",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11817,
                            "relation_type": 5,
                            "id": 11826,
                            "title": "Economic Growth Theories",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        }
                    ],
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11817,
                    "title": "Economic Growth and the Investment Decision",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": [
                        {
                            "relation_id": 11827,
                            "relation_type": 5,
                            "id": 11828,
                            "title": "Overview of Regulation",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11827,
                            "relation_type": 5,
                            "id": 11829,
                            "title": "Regulatory Interdependencies",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11827,
                            "relation_type": 5,
                            "id": 11830,
                            "title": "Purposes in Regulating Commerce and Financial Markets",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11827,
                            "relation_type": 5,
                            "id": 11831,
                            "title": "Antitrust Regulation",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11827,
                            "relation_type": 5,
                            "id": 11832,
                            "title": "Benefits and Costs of Regulation",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        },
                        {
                            "relation_id": 11827,
                            "relation_type": 5,
                            "id": 11833,
                            "title": "Effects of a Specific Regulation",
                            "difficulty": 0,
                            "created_at": 1502258390,
                            "updated_at": 1502258390,
                            "score": 0,
                            "test_rate": 0,
                            "important": 0,
                            "number_of_questions": 0,
                            "project_id": 38,
                            "subject_id": 210,
                            "definition": ""
                        }
                    ],
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11827,
                    "title": "Economics of Regulation",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11799,
            "title": "Economics",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11835,
                    "title": "知识点添加1",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502331986,
                    "score": 0,
                    "test_rate": 0,
                    "important": 2,
                    "number_of_questions": 0,
                    "project_id": 1,
                    "subject_id": 3,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11836,
                    "title": "Employee Compensation: Post-Employment and ShareBased",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11837,
                    "title": "Multinational Operations",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11838,
                    "title": "Evaluating Quality of Financial Reports",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11839,
                    "title": "Integration of Financial Statement Analysis Techniques",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11834,
            "title": "Financial Reporting and Analysis",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11841,
                    "title": "Capital Budgeting",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11842,
                    "title": "Capital Structure",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11843,
                    "title": "Dividends and Share Repurchases: Analysis",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11844,
                    "title": "Corporate Performance, Governance, and Business Ethics",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11845,
                    "title": "Corporate Governance",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11846,
                    "title": "Mergers and Acquisitions",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11840,
            "title": "Corporate Finance",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11848,
                    "title": "Equity Valuation: Applications and Processes",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11849,
                    "title": "Return Concepts",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11850,
                    "title": "Industry and Company Analysis",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11851,
                    "title": "Discounted Dividend Valuation",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11852,
                    "title": "Free Cash Flow Valuation",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11853,
                    "title": "Market-Based Valuation: Price and Enterprise Value Multiples",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11854,
                    "title": "Residual Income Valuation",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11855,
                    "title": "Private Company Valuation",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11847,
            "title": "Equity Valuation",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11857,
                    "title": "The Term Structure and Interest Rate Dynamics",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11858,
                    "title": "The Arbitrage-Free Valuation Framework",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11859,
                    "title": "Valuation and Analysis: Bonds with Embedded Options",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11860,
                    "title": "Credit Analysis Models",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11861,
                    "title": "Credit Default Swaps",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11856,
            "title": "Fixed Income",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11863,
                    "title": "Pricing and Valuation of Forward Commitments",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11864,
                    "title": "Valuation of Contingent Claims",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11865,
                    "title": "Derivatives Strategies",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11862,
            "title": "Derivative Instruments",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11867,
                    "title": "Private Real Estate Investments",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11868,
                    "title": "Publicly Traded Real Estate Securities",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11869,
                    "title": "Private Equity Valuation",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11870,
                    "title": "Commodities and Commodity Derivatives: An Introduction",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11866,
            "title": "Alternative Investments",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        },
        {
            "parent": null,
            "depth": 1,
            "children": [
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11872,
                    "title": "The Portfolio Management Process and the Investment Policy Statement",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11873,
                    "title": "An Introduction to Multifactor Models",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11874,
                    "title": "Measuring and Managing Market Risk",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11875,
                    "title": "Economics and Investment Markets",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11876,
                    "title": "Analysis of Active Portfolio Management",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                },
                {
                    "parent": null,
                    "depth": 2,
                    "children": null,
                    "knowledges": null,
                    "examination_syllabus_id": 93,
                    "question_type": 0,
                    "id": 11877,
                    "title": "Algorithmic Trading and High-Frequency Trading",
                    "difficulty": 0,
                    "created_at": 1502258390,
                    "updated_at": 1502258390,
                    "score": 0,
                    "test_rate": 0,
                    "important": 0,
                    "number_of_questions": 0,
                    "project_id": 38,
                    "subject_id": 210,
                    "definition": null
                }
            ],
            "knowledges": null,
            "examination_syllabus_id": 93,
            "question_type": 0,
            "id": 11871,
            "title": "Portfolio Management",
            "difficulty": 0,
            "created_at": 1502258390,
            "updated_at": 1502258390,
            "score": 0,
            "test_rate": 0,
            "important": 0,
            "number_of_questions": 0,
            "project_id": 38,
            "subject_id": 210,
            "definition": null
        }
    ],
    "project": {
        "id": 38,
        "name": "基金从业",
        "spell": null
    },
    "subject": {
        "id": 210,
        "project": null,
        "name": "基金法律法规"
    },
    "version": 201708,
    "old_version": "201708",
    "enabled": true,
    "updated_at": "2017-08-09 13:59"
}
