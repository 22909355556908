import { formPost } from '../util/zeusFormAxios.js'
import {post, get} from '../util/zeusAxios';
import { getBaseUrl } from '../util/config'


// 后台系统登录
export const userLogin = parameters => formPost(`${getBaseUrl()}apigateway.gaodun.com/api/v3/vigo/login`, parameters)

//获取新的AccessToken
export const getAccessToken = (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/api/v1/token/refreshtoken`, params)

// 通过wxcode 获取vigocode
export const getVigoCodeByWxCode = params => get(`${getBaseUrl()}apigateway.gaodun.com/luffy/api/v1/wechat/getCode?code=${params.code}`)

// 通过code 登录
export const loginByCode = (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/api/v1/code_login`, params).then(res => res.data)

// 重置密码
export const resetPassword = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/vigo/api/v3/resetpassword`, params);

// 获取验证码
export const sendCode = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/vigo/api/v1/sendcode`, params);