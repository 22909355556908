// 繁体中文
export default {
    route: {
        home: '課程首頁',
        syllabus: '課程大綱',
        studyRecord: '學習記錄',
        handoutsDownload: '教材下載',
        questionDetail: '提問詳情',
        dominoes: '知識骨牌',
        dominoesDetail: '知識骨牌詳情',
        classPk: '班級PK',
        video: '視頻學習',
        transfer: '直播中轉',
        liveList: '直播列表',
        playback: '直播回放',
        handout: '講義學習',
        resource404: '資源404',
    },
    space: '學習空間',
    learningPlatform: '學習平台',
    toHome: '返回課程首頁',
    home: {
        auditionLoading: '正在前往課程試聽頁面...',
        aboutThisCourse: '關於本課',
        startLearning: '開始學習',
        welcome: '親愛的同學， | 歡迎你來到',
        letter: {
            welcome: '你好，歡迎來到',
            teacher: '老師',
            thank: '謝謝老師'
        }
    },
    loading: '拼命加載中',
    networkError: '服務開小差，請聯繫學管',
    syllabus: {
        empty: '內容正在製作中',
        comingSoon: '敬請期待',
        resourceEmpty: '資源還在製作中，請稍等～',
        paperError: '試卷異常',
        liveNoStart: '別急，直播還未開始哦',
        liveNoPlayback: '暫無回放，請稍後再來',
        video: '視頻',
        lectureNote: '講義',
        paper: '測試',
        legacyLive: '直播',
        soft_link: '链接',
        gradation: '階段',
        audition: '試學',
        hour: '小時',
        minute: '分鐘',
        live_new: '直播',
        liveStatus: {
            playCountdown: '直播倒計時',
            playing: '直播已開始',
            playEnd: '直播已結束',
            noPlayback: '已結束，暫無回放',
            playback: '查看回放',
            toPlay: '進入直播',
            hasPlayback: '已結束，可回放',
            noClipPlayback: '無精簡回放連結，請聯系學管老師',
            abnormalState: '直播狀態异常' 
        }
    },
    buyPopUp: {
        title: '該章節為付費課程，購買課程後即享更系統的學習和更貼心的服務！',
        consult: '諮詢一下',
        buy: '去購課',
    },
    videoPlay: {
        note: '筆記',
        ask: '提問',
        knowledge: '知識點',
        enterNote: '添加我的筆記',
        myNote: '我的筆記',
        noNote: '暫無筆記',
        submitNote: '提交筆記',
        submitting: '提交中',
        deleteNoteFail: '刪除筆記失敗！',
        sureSaveNote: '是否保存提交筆記？',
        confirmSave: '保存',
        cancelSave: '不保存',
        saving: '保存中',
        updateNoteFail: '更新筆記失敗！',
        createNoteFail: '新建筆記失敗！',
        vip: 'VIP尊享特權',
        becomeVip: '成為VIP 學習完整課程 | 購買指定課程後 | 專享筆記特權與24小時智能答疑服務',
        buyVip: '立即購課',
        videoError: '視頻資源錯誤',
        bulletScreen: {
            before: '親愛的學員（專屬ID：',
            after: '），您正在學習由高頓教育獨家出品的課程！請保護好您的賬號安全哦~'
        },
    },
    cancel: '取消',
    edit: '編輯',
    complete: '完成',
    delete: '刪除',
    cancelDelete: '不刪除',
    sureDelete: '確認刪除嗎',
    deleting: '刪除中',
    notAsk: '您暫無權限提問',
    thisCourseNote: '本課筆記',
    thisCourseAsk: '本課提問',
    view: '查看',
    myAsk: '我的提問',
    noContent: '暫時沒有內容哦~',
    total: '條',
    returnNote: '返回筆記',
    myNote: '我的筆記',
    nodeDetail: '筆記詳情',
    chapterIdError: '階段ID或者章節ID錯誤～',
    totalPages: '共 {page} 頁',
    notAnswer: '未答',
    answered: '已答',
    downloadInformation: '下載資料',
    download: '下載',
    handoutTypeError: '獲取講義分類錯誤，請聯繫管理員',
    handoutError: '講義資源異常！',
    livePlayback: '查看回放',
    liveToCourse: '去上課',
    liveNoStart: '未開始',
    liveNoPlayback: '暫無回放',
    goAsk: '去提問',
    turnText: '去轉化',
    turnSuccess: '轉換完成',
    turnFail: '轉換失敗',
    retry: '重試',
    turnTextFail: '轉文字失敗請稍後再試~',
    turnTexting: '轉換中...',
    requireEnter: '該輸入項為必填項!',
    maxEnter: '最多輸入',
    enterNotEmpty: '輸入的內容不能全為空格!',
    chineseText: '個漢字',
}
