import {ACCESS_TOKEN, SAAS_TOKEN} from './keys';
import {getLogoutUrl, getEnv} from './config'
import {deleteAllCookie} from './util';
import {cookie} from '@gaodun.com/gtools'

export const JqAjax = function (options) {
    let type = options.type || 'GET';
    let url = options.url || '';
    let data = options.data || '';
    let dataType = options.dataType || 'json';
    let successCallBack = options.successCallBack || '';
    let errorCallback = options.errorCallback || '';
    let isPreview = options.isPreview || false;

    //是否为预览课程
    let token = isPreview ? cookie.getCookie(SAAS_TOKEN) : cookie.getCookie(ACCESS_TOKEN);

    let saas_url = location.href;
    saas_url = encodeURIComponent(saas_url);

    $.ajax({
        type: type,
        url: url,
        data: data,
        async: false, //false 同步 true-异步
        dataType: dataType,
        headers: {
            'Authentication': `Basic ${token}`
        },
        success: function (ret) {
            if (ret.status > 553649000 && ret.status < 563649999) {
                if (!isPreview) {
                    // location.href = getLogoutUrl(saas_url);
                }
                return;
            }
            if (successCallBack && (successCallBack instanceof Function) && (typeof successCallBack === 'function')) {
                successCallBack(ret);
            }
        },
        error: function (e) {
            if (errorCallback && (errorCallback instanceof Function) && (typeof errorCallback === 'function')) {
                errorCallback();
            }
        }
    });
}

export const VideoAuthentication = function (options) {
    let successCallBack = options.successCallBack || '';
    let isPreview = options.isPreview || false;

    //是否为预览课程
    let token = isPreview ? cookie.getCookie(SAAS_TOKEN) : cookie.getCookie(ACCESS_TOKEN);

    let saas_url = location.href;
    saas_url = encodeURIComponent(saas_url);

    $.ajax({
        type: 'POST',
        url: '//live-hz.gaodun.com/gaodun/token/exchange',
        data: {
            accessToken: token
        },
        async: false, //false 同步 true-异步
        success: function (response) {
            if (response.status !== 0) {
                // location.href = getLogoutUrl(saas_url);
                //获取失败
                return;
            }
            if (successCallBack && (successCallBack instanceof Function) && (typeof successCallBack === 'function')) {
                successCallBack(response);
            }
        },
        error: function (e) {
            // location.href = getLogoutUrl(saas_url);
        }
    });
}

export const VideoCreateNewWithToken = function (options) {
    let element = options.element || '';
    let id = options.id || '';
    // let auto = options.auto || false;
    let isPreview = options.isPreview || false;
    let callback = options.callback || {};

    //是否为预览课程
    let token = isPreview ? cookie.getCookie(SAAS_TOKEN) : cookie.getCookie(ACCESS_TOKEN);
    //国际化
    let lang = cookie.getCookie('lang') || 'zh-CN';

    if (localStorage.getItem(ACCESS_TOKEN) && getEnv !== '') {
        token = localStorage.getItem(ACCESS_TOKEN);
    }

    const gplayer = new Gplayer({
        parent: element
    });
    gplayer.load({
        videoId: id,
        token,
        hostname: 'gaodun',
        en: lang !== 'zh-CN',
        callback
    })
    return gplayer;
};
