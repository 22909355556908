//默认头像
export const defaultSrc = 'https://simg01.gaodunwangxiao.com/v/Uploads/avatar/default.jpg';

//题型
export const question_type = [
    {id: 1, name: "单选题" },

    {id: 2, name: "多选题" },

    {id: 3, name: "判断题" },

    {id: 4, name: "填空题" },

    {id: 5, name: "综合题" },

    {id: 6, name: "简答题" },

    {id: 7, name: "不定项" }
]
//图表配置
export const gaugeOption = {
    series: [
        {
            name: '',
            type: 'gauge',
            startAngle: 225,
            endAngle: -45,
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: '100%',
            axisTick: {show: false},
            axisLabel: {show: false},
            splitLine: {show: false},
            pointer: {show: false},
            itemStyle: {
                normal: {
                    color: '#3a3e4a',
                }
            },
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0, '#fff'], [1, 'rgba(255,255,255,.2)']],
                    width: 3,
                }
            },
            detail: {
                show: true,
                color: '#ffffff',
                offsetCenter: [0, 0],
            },
            data: [{
                value: 0,
            }]
        }
    ]
}

//异常试卷
export const WrongPaper = {
    paper_data: [],
}
