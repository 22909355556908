import lang from '../language/lang';

export const record_type = [
    { index: 'exercise', name: '本课做题' },
    { index: 'note', name: lang[window.GD_LANG].thisCourseNote },
    { index: 'question', name: lang[window.GD_LANG].thisCourseAsk },
]

export const exercise_type = [
    { action: '0', name: '做错的题' },
    { action: '1', name: '做对的题' },
    {action: '2', name:'收藏的题'},
]

export const question_type = [
    { action: 'myQues', name: lang[window.GD_LANG].myAsk },
    // {action: 'collectQues', name:'我的收藏'},
]
