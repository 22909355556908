<template>
    <div>
        <img id="default_video_img" :src="picSrc" alt="" @click="openVideoDialog" v-if="picSrc">
        <img id="default_video_img" src="../assets/images/default_video.png" alt="" @click="openVideoDialog" v-if="!picSrc">
        <el-dialog
            class="video-dialog"
            title=""
            :visible.sync="dialogVideoVisible"
            size="small"
            :modal-append-to-body="modalAppendToBody"
            :close-on-click-modal="closeOnClickModal"
            @open="handleOpen"
            :before-close="handleClose"
            >
            <!-- <div id="videoPlay" class="video-play" ref="videoPlay"></div> -->
            <SubPlayer
                ref="qiankunPlayer" 
                v-if="full_video_id"
                :video_id="full_video_id" 
                :initPlayer="initPlayer"
                :businessType="300"
                :sceneType="310"
            />
        </el-dialog>
    </div>
</template>
<style scoped>
#videoPlay {
    width: 660px;
    height: 384px;
}
</style>
<style>
.video-dialog .el-dialog {
    position: relative;
    width: 660px !important;
    /* height: 384px !important; */
}

.video-dialog .el-dialog .el-dialog__headerbtn {
    position: absolute;
    right: -38px;
    top: 6px;
}
.video-dialog .el-dialog .el-dialog__headerbtn i {
    font-size: 36px;
    color: #fff;
}
.video-dialog .el-dialog .el-dialog__header {
    padding: 0;
}

.video-dialog .el-dialog .el-dialog__body {
    padding: 0;
}
</style>
<script>
import SubPlayer from "../components/videoStudy/sub-player.vue";

    export default {
        components: {
            SubPlayer
        },
        props: {
            videoUrl: {
                type: String,
                required: true
            },
            // 布尔且有默认值  //是否插入到body中
            modalAppendToBody: {
                type: Boolean,
                default: false
            },
            //是否可以通过点击 modal 关闭 Dialog
            closeOnClickModal: {
                type: Boolean,
                default: false
            },
            picSrc: {
                type: String,
            }
        },
        data() {
            return {
                dialogVideoVisible: false,
                echoLoop: null,
                echoLoopTime : 10 * 60 * 1000,
                showUserTimer: null,
                student_id: 0,
                video_user_id: 0,
                full_video_id: '',
                destroy:false,
            }
        },
        methods: {
            initPlayer(subPlayer){
                this.player = subPlayer;
                window.gdplayer = subPlayer;
            },
            openVideoDialog() {
                this.dialogVideoVisible = true;
            },
            handleOpen() {
                const qiankunPlayer = this.$refs.qiankunPlayer;
                if (!this.videoUrl) {
                    if (qiankunPlayer && typeof qiankunPlayer.destroy === 'function') {
                        qiankunPlayer && qiankunPlayer.destroy();
                    }
                    return;
                }
                this.full_video_id = this.videoUrl;

                 if(qiankunPlayer){
                qiankunPlayer.init(this.videoUrl);
            }
            },
            handleClose(done) {
                //关闭前的回调
              const qiankunPlayer = this.$refs.qiankunPlayer;
                if (this.player && typeof this.player.pause === 'function') {
                    this.player.pause();
                    
                }
               if(qiankunPlayer){
                qiankunPlayer.destroy();
               }
                done();
               
            },
            createBulletScreen(player) {
                this.checkUserLogin(player.id);
                this.showUserId(player.id);
            },
            checkUserLogin(id) {
                clearInterval(this.echoLoop);

                this.echoLoop = setInterval(() => {
                    const container = document.querySelector('#videoPlay').children[0] || document.querySelector('#videoPlay');
                    let domUserID = document.createElement('div');
                    domUserID.id = 'pos_' + Math.ceil(Math.random() * 1000);
                    domUserID.className = 'check_user_login'
                    domUserID.innerHTML =  '亲爱的学员（专属ID：' + id + '），您正在学习由高顿教育独家出品的课程！请保护好您的账号安全哦~';
                    container.appendChild(domUserID);
                    setTimeout(function () {
                        container.removeChild(domUserID);
                    }, 6000)
                }, this.echoLoopTime);
            },
            showUserId(id) {
                const container = document.querySelector('#videoPlay').children[0] || document.querySelector('#videoPlay');
                let domUserID = document.createElement('div');
                const PROGRESS_BAR = 50;
                let maxPosLeft = 100, maxPosTop = 100, domUserIDWid = 0, domUserIDHei = 0;
                let containerWid = container.offsetWidth, containerHei = container.offsetHeight;
                let posLeft = Math.floor(Math.random() * 100);
                let posTop = posLeft < 0 || posLeft > 80 ? Math.floor(Math.random() * 100) : posTop = Math.floor(Math.random() * 10);
                domUserID.id = 'pos_id_' + Math.ceil(Math.random() * 1000);
                let styleText = 'position: absolute;font-size: 13px; color: #ffffff;'
                    + 'text-shadow:0 1px 1px #000;'
                    + 'opacity: 0.1;'
                    + 'filter: alpha(opacity=10); z-index: 9147483647;'
                domUserID.setAttribute('style', styleText);
                domUserID.style.left = posLeft + '%'
                domUserID.style.top = posTop + '%';
                domUserID.innerHTML = id;
                container.appendChild(domUserID);

                clearInterval(this.showUserTimer);
                this.showUserTimer = setInterval(() => {
                    !domUserIDWid ? domUserIDWid = domUserID.offsetWidth : '';
                    !domUserIDHei ? domUserIDHei = domUserID.offsetHeight : '';
                    !containerWid ? containerWid = container.offsetWidth : '';
                    !containerHei ? containerHei = container.offsetHeight : '';
                    maxPosLeft = Math.floor(((containerWid - domUserIDWid) / containerWid) * 100);
                    maxPosTop = Math.floor(((containerHei - domUserIDHei - PROGRESS_BAR) / containerHei) * 100);
                    posLeft = Math.floor(Math.random() * maxPosLeft);
                    if (posLeft < 2 || posLeft > 80) {
                        posTop = Math.floor(Math.random() * maxPosTop);
                    } else {
                        posTop = Math.floor(Math.random() * 10);
                    }
                    domUserID.setAttribute('style', styleText);
                    domUserID.style.left = posLeft + '%'
                    domUserID.style.top = posTop + '%';
                }, 5000)
            }
        },
        created() {
            if (!isNaN(Number(localStorage.getItem('echoLoopTime'))) && Number(localStorage.getItem('echoLoopTime')) !== 0) {
                this.echoLoopTime = Number(localStorage.getItem('echoLoopTime'));
            }
        },
        beforeDestroy() {
            clearInterval(this.echoLoop);
        }
    }
</script>
