<template>
    <div class="syllabus-tree-treeNode syllabus-tree-treeNode-common">
        <div class="syllabus-tree-node-inner" :style="[innerStyle]" @click="onChangeCollapse">
            <div v-if="+data.depth === 1" class="syllabus-tree-node-icon">
                <svg v-show="isCollapse" width="11px" height="8px" viewBox="0 0 11 8" version="1.1"
                    xmlns="https://www.w3.org/2000/svg" xmlns:xlink="https://www.w3.org/1999/xlink">
                    <title>icon_catalog_less</title>
                    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="02课程大纲" transform="translate(-495.000000, -384.000000)" fill="#35C25E">
                            <g id="编组-2" transform="translate(484.000000, 368.000000)">
                                <g id="Group-11" transform="translate(10.000000, 16.000000)">
                                    <path
                                        d="M7.276114,0.955217231 L11.6751435,6.36940737 C12.0234102,6.7980433 11.958258,7.42784731 11.5296221,7.776114 C11.3513714,7.9209427 11.1287003,8 10.8990295,8 L2.10097051,8 C1.54868576,8 1.10097051,7.55228475 1.10097051,7 C1.10097051,6.77032922 1.18002781,6.54765808 1.32485651,6.36940737 L5.723886,0.955217231 C6.07215269,0.526581305 6.7019567,0.461429166 7.13059263,0.809695856 C7.18397529,0.853069271 7.23274059,0.901834567 7.276114,0.955217231 Z"
                                        id="Triangle"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <svg v-show="!isCollapse" width="11px" height="8px" viewBox="0 0 11 8" version="1.1" xmlns="https://www.w3.org/2000/svg"
                    xmlns:xlink="https://www.w3.org/1999/xlink">
                    <title>icon_catalog_less</title>
                    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="02课程大纲" transform="translate(-495.000000, -889.000000)" fill="#35C25E">
                            <g id="Rectangle" transform="translate(170.000000, 230.000000)">
                                <g id="Group-3" transform="translate(314.000000, 644.000000)">
                                    <g id="Group-11"
                                        transform="translate(16.500000, 19.000000) scale(1, -1) translate(-16.500000, -19.000000) translate(10.000000, 15.000000)">
                                        <path
                                            d="M7.276114,0.955217231 L11.6751435,6.36940737 C12.0234102,6.7980433 11.958258,7.42784731 11.5296221,7.776114 C11.3513714,7.9209427 11.1287003,8 10.8990295,8 L2.10097051,8 C1.54868576,8 1.10097051,7.55228475 1.10097051,7 C1.10097051,6.77032922 1.18002781,6.54765808 1.32485651,6.36940737 L5.723886,0.955217231 C6.07215269,0.526581305 6.7019567,0.461429166 7.13059263,0.809695856 C7.18397529,0.853069271 7.23274059,0.901834567 7.276114,0.955217231 Z"
                                            id="Triangle"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="syllabus-tree-node-content-wrapper">
                <div class="syllabus-tree-node-content">
                    <div class="syllabus-tree-node-title">
                        <span>{{ data.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-collapse-transition v-if="isChild">
            <div class="syllabus-tree-child-tree" v-show="isCollapse">
                <div class="syllabus-tree-child-tree-inner">
                    <template v-for="item in data.children">
                        <resource-node v-if="+item.is_resource === 1" :data="item" v-bind="$attrs" v-on="$listeners" />
                        <common-node v-else :data="item" v-bind="$attrs" v-on="$listeners" />
                    </template>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>

<script>
import resourceNode from './resource-node.vue';

export default {
    name: 'CommonNode',
    props: {
        data: Object,
        activeKey: Array,
    },
    components: {
        resourceNode,
    },
    computed: {
        isCollapse() {
            if (!this.activeKey || +this.data.depth >= 2) {
                return true;
            }
            return this.activeKey.indexOf(this.data.id) === -1;
        },
        isChild() {
            return this.data.children && this.data.children.constructor === Array && this.data.children.length;
        },
        innerStyle() {
            if (!this.isChild || this.data.depth >= 2) {
                return { cursor: 'default' };
            }
            return {};
        },
    },
    data() {
        return {};
    },
    methods: {
        onChangeCollapse() {
            if (!this.activeKey || +this.data.depth >= 2 || !this.isChild) {
                return;
            }
            this.$emit('on-change-collapse', this.data.id);
        },
    },
};
</script>
