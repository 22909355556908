<template>
    <div
        class="record-detail note-detail"
        v-title
        :data-title="$t('nodeDetail')"
    >
        <div class="w_1090">
            <div class="returnBtn">
                <button class="return-btn" @click="returnNote">
                    <span>{{ $t("returnNote") }}</span>
                </button>
            </div>
            <template v-if="isFlag">
                <div class="contentBox">
                    <div class="box-title">
                        <span class="box-name black_16">
                            {{ title || $t("myNote") }}
                        </span>
                        <div class="actions"></div>
                    </div>
                    <div class="box-content">
                        <template v-for="item in data" v-if="note_count > 0">
                            <div
                                class="chapter-note-box"
                                v-if="
                                    item.note_list && item.note_list.length != 0
                                "
                            >
                                <a
                                    href="javascript:void(0);"
                                    @click="openStudyPage(item)"
                                    class="noteRoute"
                                >
                                    {{ item.name }}
                                </a>
                                <ul class="noteGroup">
                                    <li
                                        class="noteItems"
                                        v-for="(obj, index) in item.note_list"
                                        :key="obj.id"
                                    >
                                        {{ index + 1 }}.{{ obj.content }}
                                    </li>
                                </ul>
                            </div>
                            <template v-for="sItem in item.children">
                                <div
                                    class="chapter-note-box"
                                    v-if="
                                        max_depth == 2 &&
                                        sItem.note_list &&
                                        sItem.note_list.length != 0
                                    "
                                >
                                    <a
                                        href="javascript:void(0);"
                                        @click="openStudyPage(sItem)"
                                        class="noteRoute"
                                        >{{ item.name }} <span>-</span>
                                        {{ sItem.name }}
                                    </a>
                                    <ul class="noteGroup">
                                        <li
                                            class="noteItems"
                                            v-for="(
                                                obj, index
                                            ) in sItem.note_list"
                                            :key="obj.id"
                                        >
                                            {{ index + 1 }}.{{ obj.content }}
                                        </li>
                                    </ul>
                                </div>
                                <template
                                    v-for="tItem in sItem.children"
                                    v-if="max_depth == 3"
                                >
                                    <div
                                        class="chapter-note-box"
                                        v-if="
                                            max_depth == 3 &&
                                            tItem.note_list &&
                                            tItem.note_list.length != 0
                                        "
                                    >
                                        <a
                                            href="javascript:void(0);"
                                            @click="openStudyPage(tItem)"
                                            class="noteRoute"
                                            >{{ item.name }} <span>-</span>
                                            {{ sItem.name }} <span>-</span>
                                            {{ tItem.name }}
                                        </a>
                                        <ul class="noteGroup">
                                            <li
                                                class="noteItems"
                                                v-for="(
                                                    obj, index
                                                ) in tItem.note_list"
                                                :key="obj.id"
                                            >
                                                {{ index + 1 }}.{{
                                                    obj.content
                                                }}
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </template>
                        </template>
                        <div
                            style="
                                height: 500px;
                                text-align: center;
                                line-height: 500px;
                                letter-spacing: 2px;
                            "
                            class="gray_16"
                            v-if="note_count === 0"
                        >
                            {{ $t("noContent") }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!isFlag">
                <div class="contentBox">
                    <div class="box-content">
                        <div
                            style="
                                height: 500px;
                                text-align: center;
                                line-height: 500px;
                                letter-spacing: 2px;
                            "
                            class="gray_16"
                        >
                            {{ FalgMsg }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { getEP3NoteList } from "../../api/record.js";
import { getBaseUrl } from "../../util/config";
import { getPaperStatusEp3Task } from "../../api/study.js";
import lang from "../../language/lang";

export default {
    data() {
        return {
            stageid: "", //阶段id
            chapterid: "", //章节id
            course_id: "", //课程id
            data: [],
            max_depth: "", // 层级
            note_count: "", //笔记数量
            syllabus_id: "", //大纲id
            isFlag: false, //查询的id是否正确
            FalgMsg: "",
            studyPath: "",
        };
    },
    computed: {
        vid() {
            return this.$route.query.vid || "";
        },
        refSite() {
            return this.$route.query.refSite || "";
        },
        title() {
            return this.$route.query.name || "";
        },
    },
    methods: {
        returnNote() {
            this.$router.push({
                path: `/studyrecord/${this.course_id}`,
                query: {
                    action: "note",
                    vid: this.vid,
                    refSite: this.refSite,
                },
            });
        },
        async getCourseNote() {
            let params = {
                course_id: this.course_id,
                cs_item_ids: this.$route.query.cs_item_ids.toString() || "",
            };
            let ret = await getEP3NoteList(params);
            this.note_count = 0;
            if (ret.status == 0) {
                this.data = ret.result;
                for (var i = 0; i < this.data.length; i++) {
                    let count =
                        (this.data[i].note_list &&
                            this.data[i].note_list.length) ||
                        0;
                    if (count > 0) {
                        this.isFlag = true;
                        this.note_count += count;
                    }
                }
                if (!this.isFlag) {
                    this.FalgMsg = lang[window.GD_LANG].chapterIdError; //阶段id或者章节id不正确
                }
            }
        },
        //打开学习页
        async openStudyPage(itemData) {
            this.course_id = this.$route.params.cid; //课程id
            this.syllabus_id = itemData.course_syllabus_id; // 大纲id
            this.gradation_id = this.stageid; // 阶段id
            this.resource_id = itemData.note_list[0].source_id || 0; //资源id
            this.syllabusitem_id = itemData.id; //资源条目id

            switch (itemData.note_list[0].source_type) {
                case "lecture_note":
                    this.studyPath = `/study/lecture_note`;
                    break;
                case "video":
                    this.studyPath = `/study/play`;
                    break;
                case "paper":
                case "question":
                    let params = {
                        course_id: this.course_id,
                        cs_item_id: itemData.id,
                        resource_id: this.resource_id,
                    };
                    const ret = await getPaperStatusEp3Task(params);
                    this.getPaperUrlSuccessCallBack(ret);
                    break;
                case "hermes_live":
                    const gotoLive = (res) => {
                        const live_id = res.result.hermes_live_id;
                        if (res.status == 0) {
                            this.$ajax({
                                type: "GET",
                                url: `${getBaseUrl()}apigateway.gaodun.com/hermes/live/${live_id}`,
                                successCallBack: this.handleReqHermesLiveDetail.bind(
                                    this,
                                    itemData
                                ),
                            });
                        } else {
                            console.log(res);
                        }
                    };
                    this.$ajax({
                        type: "GET",
                        url: `${getBaseUrl()}apigateway.gaodun.com/resourcej/resource/${
                            this.resource_id
                        }`,
                        data: { resource_id: this.resource_id },
                        successCallBack: gotoLive.bind(this),
                    });
                    return;
            }
            this.studyPath &&
                window.open(
                    `${this.studyPath}?course_id=${this.course_id}&gradation_id=${this.gradation_id}&syllabus_id=${itemData.course_syllabus_id}&syllabusitem_id=${this.syllabusitem_id}&resource_id=${this.resource_id}&vid=${this.vid}&refSite=${this.refSite}`
                );
        },
        getPaperUrlSuccessCallBack(res) {
            this.studyPath = `/study/paper/exercise`;

            // if (res.result.is_submit === 1) {
            //     this.studyPath = `/study/paper/report`;
            // } else if (res.result.is_submit === 0) {
            //     this.studyPath = `/study/paper/exercise`;
            // }
        },
        handleReqHermesLiveDetail(itemData, data) {
            if (!data || data.status !== 0) {
                return false;
            }
            const result = data.data;

            //直播状态 1 => 未开始 2 => 去上课 3 => 本地查看回放 4 => 跳转查看回放 5 => 暂无回放
            switch (result.resource_status) {
                case 1:
                    return this.$message(lang[window.GD_LANG].syllabus.liveNoStart);
                case 2:
                    return window.open(result.cc_live_url);
                case 3:
                    return window.open(
                        `/study/zhibo/playback/${result.id}?course_id=${this.course_id}&gradation_id=${this.gradation_id}&syllabus_id=${this.syllabus_id}&syllabusitem_id=${this.syllabusitem_id}&resource_id=${this.resource_id}&partId=${itemData.parent_id}&vid=${this.vid}&refSite=${this.refSite}&video_type=2`
                    );
                case 4:
                    return window.open(result.record_url);
                case 5:
                    return this.$message(lang[window.GD_LANG].syllabus.liveNoPlayback);
                default:
                    return this.$message.error(lang[window.GD_LANG].networkError);
            }
        },
    },
    created() {
        this.course_id = this.$route.params.cid;
        this.stageid = this.$route.query.stageid;
        this.chapterid = this.$route.query.chapterid;
        this.getCourseNote();
    },
};
</script>
