<template>
    <router-view></router-view>
</template>

<script>

export default {
    data() {
        return {
            value: 1
        }
    },
    computed: {},
    created() {
        this.initLink();
    },
    mounted() {
    },
    methods: {
        initLink() {
            let scriptUrl = `https://gd-file.gaodun.com/browser-modal/bundle.js?v=${Date.now()}`;
            let scriptTag = document.createElement('script');
            scriptTag.src = scriptUrl;
            document.body.appendChild(scriptTag);
            scriptTag.onload = () => {
                window.useCompatibilityModal();
            };
        }
    }
}
</script>
