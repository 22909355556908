<template>
    <div class="cloud-sub-tiku-box">
        <div id="sub-tiku-container"></div>
        <div id="micro-container"></div>
    </div>

</template>
<style>
    .cloud-sub-tiku-box {
        min-width: 860px;
        width: 100%;
        height: 100%;
        padding: 30px;
        background-color: #F7F7F7;
    }

    #sub-tiku-container {
        width: 100%;
        height: 100%;
    }

    #__qiankun_microapp_wrapper_for_sub_tiku__ {
        width: 100%;
        height: 100%;
    }

</style>
<script>
import {
    getPaperRecord,
    createItemPaper,
    reqProgressSync,
    getCsItemInfo
} from "../api/study.js";

import { loadMicroApp } from 'qiankun';
import { qs, environment } from '@gaodun.com/gtools';


export default {
    data() {
        return {
            paperId: 0,
        };
    },
    computed: {
        paper_id() {
            return this.$route.query.paper_id;
        },
        course_id() {
            return this.$route.query.course_id;
        },
        syllabus_id() {
            return this.$route.query.syllabus_id;
        },
        syllabusitem_id() {
            return this.$route.query.syllabusitem_id;
        },
        resource_id() {
            return this.$route.query.resource_id;
        },
        cs_item_id() {
            return this.$route.query.syllabusitem_id;
        },
        allow_question() {
            return +this.$store.state.home.course_info.allow_question === 1;
        },
        luca_id() {
            return this.$store.state.home.course_info.luca_qa_id
        }
    },
    methods: {
        async getPaperStatus() {
            const ret = await getPaperRecord({paperId: this.paper_id, sourceFromType: 100533962});
            if (ret.status == 0) {
                const paperDataLogId = ret.result? ret.result.paperDataLogId : 0;
                const paperId = this.paper_id;
                if (ret.result && ret.result.paperSubmitStatus === 1) {
                    reqProgressSync({
                        course_id: this.course_id,
                        cs_item_id: this.syllabusitem_id,
                        resource_id: this.resource_id,
                        status: 1
                    })
                }

                if (!paperDataLogId) {
                    const csItemRes = await getCsItemInfo(this.cs_item_id)
                    const createRes = await createItemPaper({
                        paperId,
                        sourceFromType: 100533962,
                        businessParam: {
                            title: csItemRes.result.name,
                            courseId: this.course_id,
                            csItemId: this.cs_item_id,
                            resourceId: this.resource_id,
                            paperId,
                            sourceFromType:100533962
                        }
                    })
                    if (createRes.status === 0) {
                        this.initTikuComponent(createRes.result.paperDataLogId)
                    } else {
                        this.$message(createRes.message || '试卷异常');
                    }
                } else {
                    this.initTikuComponent(paperDataLogId)
                }
            } else {
                this.$message(ret.message || '试卷异常');
            }
        },
        initTikuComponent(paperDataLogId) {
            if (this.microApp) {
                this.microApp.unmount();
            }
            const isDev = false;
            this.microApp = loadMicroApp(
                {
                    name: 'sub-tiku',
                    // entry: "http://dev-sub-tiku.gaodun.com:8066",
                    entry: isDev? "http://dev-sub-tiku.gaodun.com:8066" : `https://${environment.getApiEnv()}sub-tiku.gaodun.com/`,
                    container: document.querySelector('#sub-tiku-container'),
                    props: {
                        baseUrl: '/study/paper/exercise',
                        paperDataLogId,
                        theme: 'green',
                        businessParam: {
                            courseId: this.course_id,
                            csItemId: this.cs_item_id,
                            resourceId: this.resource_id,
                            paperId: this.paper_id,
                            sourceFromType: 100533962
                        },
                        onClickAnswerQuestion: this.handleClickAnswer.bind(this),
                    },
                },
                {
                    sandbox: { strictStyleIsolation: false }, //沙箱样式隔离
                    // 部分特殊的动态加载的微应用资源（css/js) 不被 qiankun 劫持处理
                    excludeAssetFilter: (assetUrl) => {
                        if (assetUrl.indexOf('/luckysheet') > -1) {
                            return true;
                        }
                        return false;
                    }
                },
            );
        },
        handleClickAnswer(data){
            if (this.luca_id) {
                this.answerCallback({
                    type: 'open',
                    params: {
                        lucaId: this.luca_id,
                        courseId: this.course_id,
                        courseType: 10,
                        entrySource: 100538098, // 提问入口 全局
                        resourceType: 2, 
                        clientSource: 'gaodun-pc',
                        sceneSource: 1,
                        resourceId: data.questionId,
                        projectId: data.projectId,
                        subjectId: data.subjectId,
                        twoLevelSubjectId: data.twoLevelSubjectId,
                    },
                })
                return;
            }

            if (!this.allow_question) {
                this.$message('暂无权限提问');
                return;
            }
            if (this.course_id) {
                window.open(
                    qs.stringifyUrl({
                        url: `https://${environment.getApiEnv()}gbot.gaodun.com/update-course/${this.course_id}/${data.projectId}/${data.subjectId}/${data.questionId}/2`,
                        query: {
                            source: 1,
                            source_type: 'saas',
                            resource: data.questionId,
                            payload: this.cs_item_id,
                            client_source: 0,
                            client_version: 0,
                        },
                    })
                );
            }
        },
        initLuca() {
            this.lucaChatRef = loadMicroApp(
                {
                    name: 'luca-qa',
                    entry: `https://${environment.getApiEnv()}luca.gaodun.com`,
                    container: '#micro-container',
                    props: {
                        onReceiveMessageFromMicroApp: () => {},
                        // 注册通信微应用的方法
                        registryNoticeMicroAppFn: (callback) => {
                            this.answerCallback = callback;
                        },
                    },
                },
                // 启用严格样式隔离
                { sandbox: { strictStyleIsolation: true } }
            );
        }
    },
    beforeDestroy() {
        this.microApp &&  this.microApp.unmount();
        this.lucaChatRef && this.lucaChatRef.unmount();
    },
    watch: {
        cs_item_id: {
            handler() {
                this.getPaperStatus();     
            },
            immediate: true
        },
        luca_id: {
            handler(val) {
                if (val && !this.lucaChatRef) {
                    this.initLuca();
                }
            },
            immediate: true
        }
    }
};
</script>
