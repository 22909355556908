import {ACCESS_TOKEN, REFRESH_TOKEN} from '../util/keys.js'
import {getAccessToken} from '../api/login.js'
import {getCookie} from '../util/cookie.js';
import { cookie } from '@gaodun.com/gtools';

// 检查token是否过期
export async function checkUpdateToken() {
    const refreshtoken = cookie.getCookie('Refreshtoken');
    if (!refreshtoken) return;
    let params = {
        refreshtoken: refreshtoken,
        appid: 130444, //v站appid
    }
    try {
        // 判断token的有效期
        const Accesstoken = cookie.getCookieObj(ACCESS_TOKEN) || {exp: Date.now() / 1000};
        const expirationTimestamp = Accesstoken.exp;
        // 判断时间差是否大于 1 天的毫秒数
        const oneDayInSeconds = 24 * 60 * 60;
        // 返回是否大于 1 天的判断结果 ture: 1天内过期，false: 1天后过期
        const currentDate = Date.now() / 1000;
        const tokenExpired = expirationTimestamp - currentDate;
        if (tokenExpired < oneDayInSeconds) {
            // 是否xiaoyu一天 更新token
            const tokenRet = await getAccessToken(params);

            if (tokenRet.status === 0) {
                cookie.setCookie(ACCESS_TOKEN, tokenRet.result, 7 * 24 * 60 * 60);
            }
        }
    } catch(e) {
        console.log("checkUpdateToken ~ e:", e)
    }
}
