<template>
    <div>
        <div class="answer-sheet-head">
            <span class="answer-sheet-title">答题卡</span>
            <i class="close-btn el-icon-close" @click="closeSheet"></i>
        </div>
        <div class="answer-sheet-content">
            <el-radio-group v-model="type" size="small" fill="#35c25e">
                <el-radio-button label="1">按顺序</el-radio-button>
                <el-radio-button label="2">按类型</el-radio-button>
            </el-radio-group>
            <div class="answer-sheet-list" v-show="type==1">
                <span
                    class="answer-sheet-items"
                    :class="[
                        item.user_answer && item.user_answer.istrue == 1 ? 'rightques':'wrongques',
                        question_index == cur_data_index - 1 ? 'cur' : ''
                    ]"
                    v-for="(item,question_index) in cur_data"
                    :key="question_index"
                    @click="changeSheet(item)"
                    onselectstart="return false;"
                >{{question_index+1}}
                </span>
            </div>
            <div class="answer-sheet-list" v-show="type==2||type==52">
                <ul v-for="(items,data_index) in recm_data" :key="data_index">
                    <li class="answer-sheet-items-title">{{items.title}}</li>
                    <li
                        class="answer-sheet-items"
                        :class="[
                            item.user_answer && item.user_answer.istrue == 1 ?'rightques':'wrongques',
                            item.question_id ? (item.question_id == question_id?'cur':'' ) : (item.id==question_id?'cur':''),
                        ]"
                        v-for="(item,question_index) in items.questions"
                        :key="question_index"
                        @click="changeSheet(item)"
                        onselectstart="return false;"
                    >{{question_index + 1}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="answer-sheet-foot">
            <i class="icon done"></i><span>已答</span>
            <i class="icon noDo"></i><span>未答题</span>
            <i class="icon cur"></i><span>当前答题</span>
        </div>
    </div>
</template>
<script>
    import {SaveAnswer} from '../../api/studyForm.js'
    import {showBigImg} from '../../util/showBigImg.js'
    import Vue from '../../common/Vue.js'

    export default {
        props: [
            'closeSheet',
            'getToTop',
            'changeSheet',
            'getQuesIndex',
            'cur_data_index',
            'question_id',
            'cur_data',
            'recm_data',
        ],
        components: {},
        data() {
            return {
                type: '1',

            }
        },
        computed: {},
        mounted() {
        },
        methods: {},
        created() {

        }
    }
</script>
