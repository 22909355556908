import { render, staticRenderFns } from "./RecordModuleExerciseList.vue.js?vue&type=template&id=1888477a!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./RecordModuleExerciseList.vue?vue&type=template&id=1888477a"
import script from "./RecordModuleExerciseList.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./RecordModuleExerciseList.vue?vue&type=script&lang=js"
export * from "./RecordModuleExerciseList.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./RecordModuleExerciseList.vue?vue&type=script&lang=js"
import style0 from "./RecordModuleExerciseList.vue.css?vue&type=style&index=0&id=1888477a&prod&lang=css!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./RecordModuleExerciseList.vue?vue&type=style&index=0&id=1888477a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports