<template>
    <div class="zhibo study-container">
        <div class="zhibo-header study-container-header clear">
            <div class="zhibo-title">
                <span class="zhibo-name">{{ $t("syllabus.legacyLive") }}</span>
            </div>
        </div>
        <div
            class="zhibo-content study-container-content clear"
            v-loading="loading"
            :element-loading-text="$t('loading')"
        >
            <live-list :dataList="video_list" v-on="$listeners" />
        </div>
    </div>
</template>
<script>
import liveList from "./ZhiboModuleList.vue";
import { mapState } from "vuex";
import { reqResourceDetail } from "../../api/study";

export default {
    data() {
        return {
            loading: false,
            video_list: [], //直播列表
        };
    },
    components: {
        liveList,
    },
    computed: {
        ...mapState({
            liveData: (state) => {
                return state.zhibo.liveData;
            },
        }),
        courseId() {
            return this.$route.query.course_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
        resourceId() {
            return this.$route.query.resource_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
    },
    methods: {
        async getResourceItem() {
            if (this.liveData) {
                this.video_list = this.liveData;
                return;
            }
            this.loading = true;
            const params = {
                courseId: this.courseId,
                csItemId: this.syllabusItemId,
                syllabusId: this.syllabusId,
            };

            let response = {
                status: 0,
                result: null,
            };
            try {
                response = await reqResourceDetail(this.resourceId, params);
            } catch (e) {
                response.status = 1;
                response.result = e;
            }

            this.loading = false;
            if (response.status === 0) {
                this.video_list = response.result.live.video_list;
            }
        },
    },
    async created() {
        await this.getResourceItem();
    },
};
</script>
