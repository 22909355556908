<template>
    <div class="record-detail quiz-detail" v-title data-title="提问详情">
        <div class="w_1090">
            <div class="returnBtn">
                <button class="return-btn" @click="returnQuiz">
                    <span>返回提问</span>
                </button>
            </div>
            <div class="contentBox">
                <div class="box-title">
                    <span class="box-name black_16">问题来源</span>
                    <div class="actions">
                        <button class="foldBtn" :class="show_source?'showBtn':''" @click="collapse"></button>
                    </div>
                </div>
                <el-collapse-transition>
                    <div v-show="show_source">
                        <div class="box-content">
                            <QuizSource
                                :resource_type="sourceResult.resource_type"
                                :gbot="sourceResult.gbot"
                                :question="sourceResult.question"
                                :video="sourceResult.video"
                                :payload_name="sourceResult.payload_name"
                            ></QuizSource>
                        </div>
                    </div>
                </el-collapse-transition>
            </div>
            <div class="contentBox" style="margin-top: 20px;">
                <div class="box-title">
                    <span class="box-name black_16">提问详情</span>
                </div>
                <div class="box-content">
                    <div class="quiz-question-detail">
                        <div class="user_icon">
                            <img id="user_icon" :src="quizdetail.picture?quizdetail.picture:defaultSrc"
                                 @error="getImageUrl"
                                 alt="用户头像">
                        </div>
                        <div class="quiz-question-detail-rgt">
                            <span class="user_name black_14">{{quizdetail.nick_name}}</span>
                            <div>
                                <el-tag type="success" v-if="status!=0" style="margin-right: 5px;">已答</el-tag>
                                <el-tag type="gray" class="gray_12" v-if="status==0" style="margin-right: 5px;">未答
                                </el-tag>
                                <span class="user_question black_16 long-word">{{quizdetail.content}}</span>
                            </div>
                            <div class="user_picture" v-if="quizdetail.images&&quizdetail.images.length!=0">
                                <img :src="src" alt="图片" v-for="(src,index) in quizdetail.images" :key="index"
                                 @click="handlePreview(quizdetail.images, index)">
                            </div>
                        </div>
                        <div class="quiz-time">{{(quizdetail.created_at * 1000) | number2DateTime}}</div>
                    </div>
                    <div class="dialog-box" v-if="status!=0">
                        <div class="dialog-title">
                            <span class="black_14">名师解答</span>
                        </div>
                        <div class="dialog-content">
                            <div class="dialog-answer">
                                <p class="dialog-answer-content black_16 long-word">{{answer.content}}</p>
                                <div class="supplement_answer" v-if="answer.supplement_answer">
                                    <p class="supplement_answer-title black_14">补充回答:</p>
                                    <p class="supplement_answer-content black_16 long-word">{{answer.supplement_answer}}</p>
                                </div>
                            </div>
                            <div class="dialog-images" v-if="answer.images&&answer.images.length!=0">
                                <img :src="src" alt="回答图片" v-for="(src,index) in answer.images" :key="index"
                                @click="handlePreview(answer.images, index)">
                            </div>
                            <template v-for="item in answer.trace">
                                <template v-if="item.user_type==0">
                                    <div class="dialog-ask clear">
                                        <div class="lf-line"></div>
                                        <span class="green_14">追问</span>
                                        <span class="black_16 long-word">{{item.content}}</span>
                                    </div>
                                </template>
                                <template v-if="item.user_type==1">
                                    <div class="dialog-answer black_16">
                                        <p class="dialog-answer-content black_16 long-word">{{item.content}}</p>
                                        <div class="supplement_answer" v-if="item.supplement_answer">
                                            <p class="supplement_answer-title black_14">补充回答:</p>
                                            <p class="supplement_answer-content black_16 long-word">{{item.supplement_answer}}</p>
                                        </div>
                                    </div>
                                    <div class="dialog-images" v-if="item.images&&item.images.length!=0">
                                        <img :src="src" alt="回答图片" v-for="(src,index) in item.images" :key="index"
                                        @click="handlePreview(item.images, index)">
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div class="module-quiz-box" v-if="status==6">
                        <div class="quiz-head" style="border-bottom:none;">

                            <div class="actions lf_actions">
                                <span class="gray_16">对本解答的评价：</span>
                                <el-rate v-model="appraisal.stars" disabled :colors="['#35c25e', '#35c25e', '#35c25e']"
                                         disabled-void-color="#d3d3d3"></el-rate>
                            </div>
                        </div>
                        <p class="black_16 appraisal-content " v-if="appraisal.content">{{appraisal.content}}</p>
                    </div>
                    <div class="module-quiz-box" v-if="status==1">
                        <div class="quiz-head">
                            <template v-if="isTrace">
                                <el-button type="primary" size="small" class="gr_bor_btn" @click="collapseQuiz">
                                    {{show_quizEdit?'收起提问':'展开提问'}}
                                </el-button>
                                <span class="gray_14">剩余追问次数</span>
                                <span class="green_14">{{answer.allow_trace_times}}次</span>
                            </template>

                            <div class="actions" :class="[!isTrace?'lf_actions':'']">
                                <span class="gray_16">对本解答的评价：</span>
                                <el-rate v-model="rateVal" @click.native="handleClickR"
                                         :colors="['#35c25e', '#35c25e', '#35c25e']"></el-rate>
                            </div>
                        </div>
                        <el-collapse-transition>
                            <div v-show="show_quizEdit">
                                <div class="quiz-body tools-note-box" v-if="isTrace">
                                    <el-form :model="quizFrom" ref="quizFrom" class="note-From">
                                        <el-form-item class="input-form-item" prop="content"
                                                      :rules="filter_rules({required:true,type:'isAllSpace',maxLength:800})">
                                            <el-input type="textarea"
                                                      :autosize="{ minRows: 2, maxRows: 6}"
                                                      placeholder="添加内容..."
                                                      v-model="quizFrom.content">
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item class="btn-form-item">
                                            <el-button type="primary" size="small" class="gr_bor_btn"
                                                       :loading="btnLoading"
                                                       @click="submitForm('quizFrom')"
                                            >{{btnLoading?'提交中...':'提交追问'}}
                                            </el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
            <!-- <BigImg></BigImg> -->
            <el-dialog width="700px" :visible.sync="imageListDialogVisible" class="image-list-dialog">
                <el-carousel ref="carousel" :autoplay="false" trigger="click"
                            height="480px">
                    <el-carousel-item v-for="(item,indx) in previewImageList" :key="indx" style="overflow-y: scroll;">
                        <img :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
            </el-dialog>
            <QuizDialog :dialogVisible="dialogVisible" :rateVal="rateVal" @appraisal="appraisalQuiz" :btnLoading="btnLoading"></QuizDialog>
        </div>
    </div>
</template>
<style>
/* 图片查看组件的样式 */
.image-list-dialog {
  text-align: center;
}
.image-list-dialog img {
    max-width: 700px;
  }
.long-word {
    word-break: break-all;
}
</style>
<script>
    import QuizDialog from './RecordModuleQuizDialog.vue'
    import QuizSource from './RecordModuleQuizSource.vue'
    import {getQuizDetail, appraisalQuiz, traceQuiz, getQuizSource} from '../../api/record.js'
    import {defaultSrc} from '../../common/paperConfig.js'
    import {showBigImg} from '../../util/showBigImg.js'
    import Vue from '../../common/Vue.js'
    import BigImg from '../BigImg.vue'

    export default {
        components: {
            QuizDialog,
            QuizSource,
            BigImg
        },
        data() {
            return {
                rateVal: 0,
                btnLoading: false, //按钮loading
                quizFrom: {
                    content: '',
                },
                dialogVisible: false,  //评价弹层显示
                show_source: false,
                show_quizEdit: true,
                quizdetail: {
                    nick_name: '',//昵称
                    picture: '',//用户头像图片
                    content: '',//提问内容
                    student_id: '',//学生id
                    video_time: '',//视频时间
                    images: '',   //提问图片
                    created_at: ''  //提问时间
                },
                answer: {
                    content: '',//回答内容
                    images: [],  //回答图片
                    supplement_answer: '',  //补充回答
                    trace: [],  //追问
                    allow_trace_times: '', //追问次数
                },
                appraisal: {
                    //评价
                    content: '',   //评价内容
                    stars: '',   //评价星级
                    updated_at: '',   //评价时间
                },
                status: '',   //当前提问的状态 0=>未认领/已认领/已指派/未回答 1=>已回答/未关闭  2  3=>新追问  4  5  6=>已关闭
                isTrace: false,  //是否可以追问，追问次数为五次
                defaultSrc: defaultSrc,
                sourceResult: '',//来源信息
                imageListDialogVisible: false,// 图片查看
                previewImageList: [],
            }
        },
        computed: {
            vid() {
                return this.$route.query.vid || '';
            },
            refSite() {
                return this.$route.query.refSite || '';
            }
        },
        mounted() {
            Vue.$on('handleChange', (val) => {
                this.rateVal = val;
            });
        },
        methods: {
            getImageUrl(data) {
                data.currentTarget.src = defaultSrc;
            },
            collapse() {
                this.show_source = !this.show_source;
                this.dialogVisible = false;
                this.$nextTick(() => {
                    showBigImg();
                })
            },
            collapseQuiz() {
                this.show_quizEdit = !this.show_quizEdit;
                this.dialogVisible = false;
            },
            returnQuiz() {
                this.$router.push({
                    path: `/studyrecord/${this.$route.params.cid}`,
                    query: {
                        action: 'quiz',
                        vid: this.vid,
                        refSite: this.refSite
                    }

                })
            },
            //重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            //提交按钮
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.traceQuiz();
                    } else {
                        return false;
                    }
                });
            },
            // 点亮星星
            handleClickR() {
                this.dialogVisible = true;
            },
            //打开编辑框
            openEditBox(index) {
                this.$emit('showEditBox', true);
                // this.editBoxshow = true;
                this.quizFrom.content = this.noteList[index].content;
                this.editNoteId = this.noteList[index].id;
            },
            //提问评价
            async appraisalQuiz(content) {
                let id = this.$route.query.question_id;
                let params = {
                    content: content,
                    ask_id: this.$route.query.question_id,
                    star: this.rateVal,
                }
                let ret = await appraisalQuiz(id, params);
                if (ret.status == 0) {
                    this.dialogVisible = false;
                    this.$message({
                        type: 'success',
                        message: '评价成功！'
                    })
                    this.getQuizDetail();
                } else {
                    this.$message({
                        type: 'error',
                        message: ret.info
                    })
                }
            },
            //提问追问
            async traceQuiz() {
                let id = this.$route.query.question_id;
                let params = {
                    content: this.quizFrom.content,  // 追问内容
                    ask_id: this.$route.query.question_id,   //问题id
                    file_url: '',   //图片
                };
                this.btnLoading = true;
                let ret = await traceQuiz(id, params);
                if (ret.status == 0) {
                    this.btnLoading = false;
                    this.getQuizDetail()
                }
            },
            //获取提问详情
            async getQuizDetail() {
                let question_id = this.$route.query.question_id;
                let ret = await getQuizDetail(question_id);
                if (ret.status == 0) {
                    this.status = ret.result.status;
                    this.appraisal = {
                        //评价
                        content: ret.result.appraisal.content,   //评价内容
                        stars: Number(ret.result.appraisal.stars),   //评价星级
                        updated_at: ret.result.appraisal.created_at,   //评价时间
                    };
                    this.quizdetail = {
                        //提问
                        nick_name: ret.result.nick_name,//昵称
                        content: ret.result.content,//提问内容
                        picture: ret.result.picture,//提问图片
                        student_id: ret.result.student_id,//学生id
                        video_time: ret.result.video_time,//视频时间
                        images: ret.result.images,//提问图片
                        created_at: ret.result.created_at,//提问时间
                    }
                    this.answer = {
                        //回答
                        content: ret.result.answer.content,//回答
                        images: ret.result.answer.images,//回答图片
                        supplement_answer: ret.result.answer.supplement_answer,//补充回答
                        trace: ret.result.answer.trace,//追问
                        allow_trace_times: ret.result.answer.allow_trace_times,//追问次数
                    }
                    if (ret.result.answer.allow_trace_times == 0) {
                        this.isTrace = false;
                    } else {
                        this.isTrace = true;
                    }

                } else {
                    return;
                }
                if (!ret.result.resource_uri) return;
                let url = ret.result.resource_uri;
                let sourceRet = await getQuizSource(url);
                if (sourceRet.status == 0) {
                    this.sourceResult = sourceRet.result.result;
                }
                this.$nextTick(() => {
                    showBigImg();
                })
            },
            handlePreview(list, index) {
                // 打开对应的图片数组以及更改 弹框 展示图片的 索引。切换到当前点击的图片
                this.imageListDialogVisible = true
                this.previewImageList = list
                this.$refs.carousel.setActiveItem(index)
            }
        },
        watch: {},
        created() {
            this.getQuizDetail();
        },
        beforeDestroy() {

        }
    }
</script>
