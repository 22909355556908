<template>
    <div
        class="aside-menu-box"
        ref="asideMenuBox"
    >
        <div class="aside-header">
            <div
                class="return-space"
                ref="returnBtn"
            >
                <a :href="`/course/${courseId}?vid=${vid}&refSite=${refSite}`"  @click="buryPoint">
                    <i class="return_icon"></i>
                    <span>{{$t('toHome')}}</span>
                </a>
            </div>
            <div
                :class="['aside-top-collapse', isCollapse ? 'is-active' : 'un-active']"
                @click="changeCollapse" />
        </div>
        <div
            class="scroll-content"
            ref="scrollContent"
        >
            <ul class="aside-tree">
                <aside-common-node
                    v-for="(item, index) in syllabusData"
                    :key="item.id"
                    :data="item"
                    :parent-id="[item.id]"
                    :active-key="activeKey"
                    :current-resource-id="resourceId"
                    :current-resource-parents="currentResourceParents"
                    @on-click="handleClickResourceItem"
                    @on-current-resource-parents="getCurrentResourceParents"
                    @on-change-collapse="onChangeCollapse" />
            </ul>
        </div>
    </div>
</template>

<script>
import {RESOURCE_PROGRESS_STATUS_FINISH, RESOURCE_PROGRESS_STATUS_ONGOING, SAAS_COURSE,SAAS_USER_ID} from '../../util/keys';
import asideCommonNode from './AsideMenus/common-node';
import asideResourceNode from './AsideMenus/resource-node';
import '../../assets/css/aside-menu.less';
import Vue from '../../common/Vue';
import { reqResourceRepeatRecord } from "../../api/study.js";
import { mapMutations } from 'vuex';
import { toG2Live, LiveStatus } from '../../util/g2-live';
import { bindEvents } from '../../util/sensor-data';
import { W2_STUDYVIDEO_CLICK_BACKHOME } from '@/config'
const mapTypeForUrl = {
    'video': '/study/play',
    'lecture_note': '/study/handout',
    'legacy_live': '/study/transfer',
    'live_new': '/study/g2play',
};
export default {
    data() {
        return {
            isCollapse: false,
            asideMenuBox: '',
            returnBtn: '',
            scrollContent: '',
            syllabusData: [],
            activeKey: [],
            currentResourceParents: [],
            btnLoading: false,
            currentResource: undefined,
            resourceMap: {},
            resourceList: []
        };
    },
    components: {
        asideCommonNode,
        asideResourceNode,
    },
    computed: {
        courseId() {
            return this.$route.query.course_id;
        },
        gradationId() {
            return this.$route.query.gradation_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
        resourceId() {
            return +this.$route.query.resource_id;
        },
        chapterId() {
            return this.$route.query.partId;
        },
        vid() {
            return this.$route.query.vid || '';
        },
        refSite() {
            return this.$route.query.refSite || '';
        },
        courseCheck() {
            return +this.$store.state.home.course_info.audition;
        },
    },
    watch: {
        currentResource(newVal, oldVal) {
            if (newVal !== oldVal && newVal && JSON.stringify(newVal) !== '{}') {
                const discriminator = newVal.resource.discriminator;
                 // 资源统计
                if(discriminator) {
                    ['lecture_note'].includes(discriminator) && this.resourceRepeatRecord();
                }
                switch (discriminator) {
                    case 'lecture_note':
                        this.syncPositionAndProgress(newVal, discriminator, RESOURCE_PROGRESS_STATUS_FINISH);
                        break;
                    case 'video':
                        this.syncPositionAndProgress(newVal, discriminator, RESOURCE_PROGRESS_STATUS_ONGOING);
                        break;
                    case 'paper':
                        this.syncPositionAndProgress(newVal, this.$route.name === 'paperExercise' ? 'paper/exercise' : 'paper/report', RESOURCE_PROGRESS_STATUS_ONGOING);
                        break;
                    case 'legacy_live':
                        this.syncPositionAndProgress(newVal, discriminator);
                    case 'soft_link':
                        this.syncPositionAndProgress(newVal, discriminator, RESOURCE_PROGRESS_STATUS_FINISH);
                        break;
                    default:
                        break;
                }
                this.$store.commit('SYITEM_NAME', newVal.name);
            }
        },
        $route: {
            handler() {
                this.$store.dispatch('studyCourseId', this.courseId);
                this.$store.dispatch('studySyItem_id', this.syllabusItemId);
                this.$store.dispatch('studySyllabusAttr', this.syllabusId);
            },
            immediate: true,
        },
    },
    methods: {
        ...mapMutations(['isSendXhr']),
        toSoftLink(data) {
            if (!data.resource.soft_link_url) {
                return this.$message.info('当前仅支持App端查看')
            }
            this.syncPositionAndProgress(data, data.resource.discriminator, RESOURCE_PROGRESS_STATUS_FINISH);
            window.open(data.resource.soft_link_url);
        },
        //侧边导航的展开收起
        changeCollapse() {
            if (!this.asideMenuBox) {
                this.asideMenuBox = this.$refs.asideMenuBox;
                this.scrollContent = this.$refs.scrollContent;
                this.returnBtn = this.$refs.returnBtn;
            }
            if (this.isCollapse) {
                this.asideMenuBox.style.width = '240px';
                this.scrollContent.style.opacity = 1;
                this.returnBtn.style.opacity = 1;
                this.isCollapse = false;
            } else {
                this.asideMenuBox.style.width = '0px';
                this.scrollContent.style.opacity = 0;
                this.returnBtn.style.opacity = 0;
                this.isCollapse = true;
            }
        },
        //检查学习位置
        async checkStudParams() {
            if (!this.resourceId) {
                this.$router.replace({
                    name: 'Resource404',
                    query: this.$route.query,
                });
                return;
            }
            const params = {
                course_id: this.courseId,
                gradation_id: this.gradationId,
                syllabus_id: this.syllabusId,
                cs_item_id: this.syllabusItemId,
                resource_id: this.resourceId,
            };
            const reponse = await this.$http.checkoutStudyPosition(params);
            if (reponse.status === 0 && !reponse.result.position_exist) {
                // 1.做试卷页面  将状态改为交卷
                // 2.解除页面锁定
                this.$store.dispatch('changeIsSubmit', true); //交卷了
                document.getElementsByTagName('body')[0].className = '';
                location.href = `/syllabus/${this.courseId}?vid=${this.vid}&refSite${this.refSite}`;
            }
        },
        async syncPositionAndProgress(resource, discriminator, status, isCheckStatus = true) {
            const params = {
                course_id: +this.courseId, //课程id
                cs_item_id: +resource.id, //条目id
                resource_id: +resource.resource_id, //资源id
            };
            if (status && discriminator.indexOf('paper') < 0) {
                params.status = status;      //进度状态：1-完成；2-进行中
            }
            if (discriminator) {
                params.study_position_request = {
                    parent_gradation_id: 0,                //父阶段id
                    gradation_id: +this.gradationId,         //阶段id
                    syllabus_id: +resource.course_syllabus_id, //大纲id
                    resource: discriminator,     //资源类型标识 video、audio、hermes_live、lecture_note、legacy_live、paper/exercise、paper/report、resource_group
                    resource_name: resource.name,   //资源名称
                    chapter_id: +resource.chapter_id,   //章id
                    teacher_id: +resource.teacher_id,    //老师id
                    is_resource_group: Number(Boolean(resource.resource.is_resource_group)),  //是否资源组 0：否 1：是
                };
            }
            await this.$http.reqProgressSync(params);
            this.updateSyllabus(status, isCheckStatus);
        },
        async getCourseDetails() {
            const result = await Promise.all([
                this.$http.getCourseInfoAndSetting(this.courseId),
                this.$http.checkIsHasCourse({course_id: this.courseId}),
            ]);
            const infoRet = result[0];  //课程介绍
            const checkRet = result[1];  //排课
            if (checkRet.result === -1 ) {//&& !location.href.includes('dev-')
                this.$message.warning('课程未派课哦～，请联系学管');
                // return window.location.replace(`/not-found/course/${this.course_id}`);
            }
            if(checkRet.status !== 0){
                this.$message.warning('课程异常，请联系学管');
                if(!location.href.includes('dev-')){
                    // setTimeout(()=>{
                    //     window.location.href = `https://${getEnv()}v.gaodun.com/space/#`;
                    // },1000);
                }
                return;
            }else{
                localStorage.setItem(SAAS_USER_ID, checkRet.result2.student_id);
            }
            if (infoRet.status === 0) {
                infoRet.result.audition = checkRet.result; //体验课
                localStorage.setItem(SAAS_COURSE, JSON.stringify(infoRet.result));
                this.$store.dispatch('saveCourseInfoAndSetting', infoRet.result);
            }
        },
        async handleClickResourceItem(data) {
            if (data.id === this.currentResource.id) {
                Vue.$emit('showBuyCourseVideo', false);
                return;
            }

            // const isLeave = await this.leavePaperPageMessage();
            // if (!isLeave) {
            //     return;
            // }
            if (this.btnLoading) {
                return;
            }
            this.btnLoading = true;
            const {resource, audition} = data;
            const {discriminator, simplify_record_url} = resource || {};
            //audition  1 可试听
            //只有video可以试听
            if (+this.courseCheck === 1 && (+audition !== 1 || discriminator !== 'video')) {
                this.btnLoading = false;
                return Vue.$emit('showBuyCourseVideo', true);
            }

            // 资源上报相关
            this.resourceRecordLogic(discriminator)
        
            switch (discriminator) {
                case 'video':
                case 'lecture_note':
                    this.toStudy(data, null, '');
                    break;
                case 'legacy_live':
                    this.toStudy(data, null, '&video_type=1');
                    break;
                case 'paper':
                    await this.toPaper(data);
                    break;
                case 'hermes_live':
                    await this.toHermesLive(data);
                    break;
                case 'soft_link':
                    this.resourceRepeatRecord(data.resource_id)
                    this.toSoftLink(data);
                    break;
                case 'live_new':
				     this.toGlive2Live(data);
                    break;
                default:
                    this.$message('资源还在制作中，请稍等～');
                    break;
            }
            this.btnLoading = false;
        },
        /**
         *  @description 资源上报逻辑
         *  @param {String} discriminator 资源类型
         */
        resourceRecordLogic(discriminator) {
            // 从视频切换到讲义、试卷，确保视频上报的resourceId正确
            if(['paper', 'lecture_note'].includes(discriminator)){ 
                this.isSendXhr(false)
                this.$store.commit('RESOURCE_ID', this.resourceId)
                Vue.$emit('resourceRepeatReport');
            } else {
                this.isSendXhr(true)
                this.$store.commit('RESOURCE_ID', '')
            }
            const tempLsit = ['studyPlay', 'playback'];
            if (tempLsit.some( v => v === this.$route.name) && ['legacy_live', 'video', 'live_new'].includes(discriminator)) {
                
                Vue.$emit('resourceRepeatReport');
                // TODO: 直播或者g2本地回放 => 切换普通视频 触发onpause回调会触发当前视频上报接口（暂时方案）
                ['live_new', 'video' ].includes(discriminator) && this.isSendXhr(false);
            }
        },
        toGlive2Live(data, query = '') {
            const {courseId, gradationId, vid, refSite} = this;
            const {resource: {discriminator, simplify_record_url, status_of_live}, id, course_syllabus_id, resource_id, chapter_id, teacher_id, item_id} = data;
            const prefix = mapTypeForUrl[discriminator]
            const fullPath = `${prefix}?course_id=${courseId}&gradation_id=${gradationId}&syllabus_id=${course_syllabus_id}&syllabusitem_id=${item_id}&resource_id=${resource_id}&partId=${chapter_id}&vid=${vid}&refSite=${refSite}${query}&simplifyUrl=${simplify_record_url || ''}`;
           
           toG2Live(data, () => { this.$router.push(fullPath)});
            // 2-进行中 | 3-精剪回放 | 4-即时回放 | 6-教室已开启
            if ([LiveStatus.live, LiveStatus.clipPlayback, LiveStatus.onlinePlayback, LiveStatus.preLive].includes(status_of_live)) {
			    this.syncPositionAndProgress(data, discriminator, RESOURCE_PROGRESS_STATUS_ONGOING);
            }
        },
        async toPaper(data) {
            const params = {
               paperId: data.resource.paper_id
            };
            const response = await this.$store.dispatch('getExamPaperStatus', params);

            if (!response.path) {
                return this.$message(response.message || '试卷异常');
            }
            this.toStudy(data, response.path);
        },
        async toHermesLive(data) {
            const response = await this.$store.dispatch('getHermesLiveDetail', data.resource.hermes_live_id);

            if (!response) {
                return false;
            }

            //直播状态 1 => 未开始 2 => 去上课 3 => 本地查看回放 4 => 跳转查看回放 5 => 暂无回放
            switch (response.resource_status) {
                case 1:
                case 5:
                    this.$message(response.resource_status === 1 ? '别急，直播还未开始哦' : '暂无回放，请稍后再来');
                    break;
                case 2:
                case 4:
                    this.syncPositionAndProgress(data, null, RESOURCE_PROGRESS_STATUS_FINISH, false);
                    window.open(response.resource_status === 2 ? response.cc_live_url : response.record_url);
                    break;
                case 3:
                    this.toStudy(data, `/study/zhibo/playback/${response.id}`, '&video_type=2');
                    break;
                default:
                    this.$message.error('服务开小差，请联系学管');
                    break;
            }
        },
        toStudy(data, path, query = '') {
            const {courseId, gradationId, vid, refSite} = this;
            const {resource: {discriminator, simplify_record_url}, id, course_syllabus_id, resource_id, chapter_id, teacher_id, item_id} = data;
            const prefix = path || mapTypeForUrl[discriminator];
            const fullPath = `${prefix}?paper_id=${data.resource.paper_id || 0}&course_id=${courseId}&gradation_id=${gradationId}&syllabus_id=${course_syllabus_id}&syllabusitem_id=${item_id}&resource_id=${resource_id}&partId=${chapter_id}&vid=${vid}&refSite=${refSite}${query}&simplifyUrl=${simplify_record_url || ''}`;
            this.$router.push(fullPath);
        },
        
        async reqCourseSyllabus() {
            let response = await this.$http.getCourseSyllabus(this.courseId, this.syllabusId);
            const syllabus = (response.result && response.result.items) || [];
            this.syllabusData = syllabus;
            this.getResourceList(syllabus);
        },
        onChangeCollapse(id) {
            const activeKey = [...this.activeKey];
            const index = activeKey.indexOf(id);
            if (index > -1) {
                activeKey.splice(index, 1);
            } else {
                activeKey.push(id);
            }
            this.activeKey = activeKey;
        },
        getCurrentResourceParents(data) {
            const {parentId, resource} = data;
            this.currentResourceParents = parentId;
            this.currentResource = resource;
        },
        leavePaperPageMessage() {
            return new Promise((resolve, reject) => {
                if (this.$route.name !== 'paperExercise' || this.$store.state.paper.paper_is_submit) {
                    resolve(true);
                    return;
                }
                this.$msgbox({
                    title: '',
                    customClass: 'leaveTip',
                    message: '已自动保存答题状态，请确认是否离开？',
                    showCancelButton: true,
                    showConfirmButton: true,
                    cancelButtonText: '继续做题',
                    confirmButtonText: '保存离开',
                    center: true,
                }).then(() => {
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                });
            });
        },
        updateSyllabus(status, isCheckStatus = true) {
            if (!isCheckStatus) {
                this.reqCourseSyllabus();
                return;
            }
            if (!this.currentResource) {
                return;
            }
            if (typeof status === 'number' && this.currentResource.progress === status) {
                return;
            }
            if (typeof status === 'string' && this.currentResource.progress === +(status.split('-')[1])) {
                return;
            }
            this.reqCourseSyllabus();
        },
        getResourceList(syllabusData) {
            const {resourceId, courseCheck} = this;
            const ary = [];
            const resourceMap = {};
            const forEachData = (data) => {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (element.children && element.children.length) {
                        forEachData(element.children);
                    }
                    const isResource = +element.is_resource === 1 && element.resource;
                    if (isResource) {
                        const isAuditionResource = courseCheck === 1 && element.audition === 1 && element.resource.discriminator === 'video';
                        if (courseCheck === 0 || isAuditionResource) {
                            ary.push(element);
                            resourceMap[element.resource_id] = ary.length - 1;
                        }
                    }
                }
            };
            forEachData(syllabusData);
            this.resourceList = ary;
            this.resourceMap = resourceMap;
        },
        goNextResource() {
            const index = this.resourceMap[this.resourceId];
            const resource = this.resourceList[index + 1];
            if (resource) {
                this.handleClickResourceItem(resource);
            }
        },
        async resourceRepeatRecord(resourceId) {
            try {
                await reqResourceRepeatRecord({
                    courseId: +this.courseId,
                    resourceId: resourceId || +this.resourceId,
                    learningStartTime: new Date().getTime(), 
                    learningEndTime: new Date().getTime()
                })
              } catch (e) {
                console.log(e)
            }
        },
        buryPoint() { 
            bindEvents(W2_STUDYVIDEO_CLICK_BACKHOME)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.asideMenuBox = this.$refs.asideMenuBox;
            this.scrollContent = this.$refs.scrollContent;
            this.returnBtn = this.$refs.returnBtn;
        });
        //拉取课程信息
        this.getCourseDetails();
    },
    created() {
        this.checkStudParams();//检查学习位置
        this.reqCourseSyllabus();
    },
};
</script>
<style>
    .aside-menu-box .scroll-content {
        width: 100%;
        height: -moz-calc(100% - 60px);
        height: -webkit-calc(100% - 60px);
        height: calc(100% - 60px);
        overflow: auto;
        transition: all .2s linear;
    }
</style>
<style lang="less" scoped>
    .return-space {
        position: absolute;
        display: block;
        top: 20px;
        left: 18px;
        width: 110px;
        height: 20px;
        -webkit-transition: all .5s ease-out;
        -o-transition: all .5s ease-out;
        transition: all .5s ease-out;
    }

    .return-space a {
        display: block;
        width: 100%;
        height: 100%;
        font-weight: normal;
        font-style: normal;
        color: #ffffff;
    }

    .return-space a .return_icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url("../../assets/images/return_space.png") no-repeat center;
        background-size: 18px 18px;
        vertical-align: middle;
    }

    .return-space a span {
        line-height: 20px;
        display: inline-block;
        font-size: 14px;
        vertical-align: middle;
    }
</style>
