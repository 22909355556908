<template>
    <div class="recordBox">
        <div class="w_1090 psoition-r">
            <!-- <NpsReport title="评价一下【提问】" :sceneId="sceneId" :courseId="Number($route.params.cid)"/> -->
            <div class="sub-nav-menu">
                <ul class="clear">
                    <li
                        class="sub-nav-item"
                        :class="[activeQues == item.action ? 'active' : '']"
                        v-for="(item, index) in question_type"
                        :key="index"
                    >
                        <span>{{ item.name }}</span>
                    </li>
                </ul>
            </div>
            <QuestionList
                v-if="!luca_id"
                v-show="!no__content"
                :dataList="dataList"
                :zLoading="zLoading"
                :loadingText="loadingText"
            ></QuestionList>
            <LucaQuestionList
                v-else
                :dataList="lucaList"
                :zLoading="zLoading"
                :loadingText="lucaLoadingText"
                @updateItem="updateItem"
            ></LucaQuestionList>
            <div class="pagination" v-show="!no__content">
                <el-pagination
                    small
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    @current-change="handleCurrentChange"
                    :total="total"
                >
                </el-pagination>
                <span class="pagination__page">
                    {{ $t("totalPages", { page: pages }) }}
                </span>
            </div>
            <div class="no__content" v-show="no__content && !luca_id">
                {{ $t("noContent") }}
            </div>
        </div>
    </div>
</template>
<style scoped>
.no__content {
    height: calc(100vh - 420px);
    min-height: 330px;
}
.psoition-r{
    position: relative;
}
</style>
<script>
import { question_type } from "../../common/recordConfig.js";
import QuestionList from "./RecordModuleQuizList.vue";
import LucaQuestionList from "./LucaQuestionList.vue";
import { getMyquestions, getLucaQuestionList, getQuestionItemStatus } from "../../api/record.js";
import { loadingText } from "../../common/loadingTextConfig.js";
import NpsReport from "../../components/nps-report/NpsReport.vue";
import {SCENEINFO_MAP} from "../../components/nps-report/const";
import { bindEvents } from "../../util/sensor-data.js";
import { W2_VIEWSCREEN_COURSEQUESTION } from '@/config';
export default {
    components: {
        QuestionList,
        NpsReport,
        LucaQuestionList
    },
    data() {
        return {
            activeQues: question_type[0].action,
            question_type: question_type,
            dataList: [],
            lucaList: [],
            total: 0, //总数
            pages: 0, //页数
            pageSize: 20, //每页几条
            zLoading: true,
            loadingText: "",
            no__content: false,
            sceneId:SCENEINFO_MAP.personAnswer,
            lucaLoadingText: "",
        };
    },
    computed: {
        luca_id() {
            return this.$store.state.home.course_info.luca_qa_id
        },
    },
    mounted() {
        bindEvents(W2_VIEWSCREEN_COURSEQUESTION);
    },
    methods: {
        async updateItem(id) { //更新条目信息
            const res = await getQuestionItemStatus(id);
            if (res.status === 0) {
                const currentItem = res.result;
                this.lucaList = this.lucaList.map(item => {
                    if (item.id === id) {
                        return currentItem;
                    }
                    return item;
                })
            }
        },
        async initLucaQuestionList (val) {
            this.lucaLoadingText = loadingText();
            this.zLoading = true;
            if (document.getElementById("app")) {
                document.getElementById("app").className = "";
            }

            document.getElementsByTagName("body")[0].className = "lock__scroll";
            const res = await getLucaQuestionList({
                pageSize: this.pageSize,
                pageNum: val || 1,
                courseId: this.$route.params.cid,
            });
            if (res.status === 0) {
                this.zLoading = false;
                this.lucaList = res.result.list;
                this.total = res.result.totalSize || 0;
                this.pages = res.result.totalPages;
            }
        },
        //拉取我的提问
        async getMyquestions(val) {
            let course_id = this.$route.params.cid;
            let params = {
                page: val ? val : 1, //当前页码 从1开始
                ps: this.pageSize, //每页条数默认20
                type: 1,
                course_id: course_id,
            };
            this.loadingText = loadingText();
            this.zLoading = true;
            if (document.getElementById("app")) {
                document.getElementById("app").className = "";
            }
            this.no__content = false;
            document.getElementsByTagName("body")[0].className = "lock__scroll";
            let ret = await getMyquestions(params);
            if (ret.status == 0) {
                this.zLoading = false;
                document.getElementsByTagName("body")[0].className = "";
                this.dataList = ret.result.questions;
                this.total = Number(ret.result.total);
                this.pages = Math.ceil(this.total / this.pageSize);
                if (this.total < 3 && this.total > 0) {
                    document.getElementById("app").className =
                        "bottom_background";
                }
                if (this.total == 0) {
                    this.no__content = true;
                }
            }
        },
        handleCurrentChange(val) {
            if (!this.luca_id) {
                this.getMyquestions(val);
            } else {
                this.initLucaQuestionList(val);
            }
        },
    },
    watch: {
        luca_id: {
            handler(val) {
                if (val) {
                    this.initLucaQuestionList();
                } else {
                    this.getMyquestions();
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        document.getElementById("app").className = "";
    },
};
</script>
