import {
    DESTROY_REPORT,
    GET_REPORT,
} from '../mutation-types';
import {Message} from 'element-ui';


const state = {
    knowledges:'',   //知识点
    report:'',   //报告信息
    score:'',   //个人得分
    question_total:'',   //总题数
    question_right:'',   //正确题数
    question_wrong:'',   //错误题数
    created_at:'',   //交卷日期
}

const actions = {
    //保存报告信息
    getReport({commit, state}, data) {
        commit(GET_REPORT, data)
    },
    destroyReport({commit, state}, data) {
        commit(DESTROY_REPORT, data)
    },

}

const mutations = {
    [GET_REPORT](state, data) {
        const rightNum = data.paper && data.paper.question_right_total || 0;
        const total = data.paper && data.paper.question_total || 0;
        state.report = data.paper;
        state.score = data.paper.score;
        state.question_total = total;
        state.question_right = rightNum;
        state.question_wrong = total !=null && rightNum !=null&&(total - rightNum);
        state.created_at = data.paper.created_at;
    },
    [DESTROY_REPORT](state){
        state.knowledges = '';   //知识点
        state.report = '';   //报告信息
        state.score = '';   //个人得分
        state.question_total = '';   //总题数
        state.question_right = '';   //正确题数
        state.question_wrong = '';   //错误题数
        state.created_at = '';   //交卷日期
    }
}

export default {
    state,
    actions,
    mutations,
}
