import {
    SAVE_LIVEDATA,
} from '../mutation-types';
import { Message } from 'element-ui';


const state = {
    liveData: "", //直播信息
}

const actions = {
    //保存直播信息
    saveLiveData({ commit, state }, data) {
        commit(SAVE_LIVEDATA, data)
    },

}

const mutations = {
    [SAVE_LIVEDATA](state, data) {
        state.liveData = data;
    }
}

export default {
    state,
    actions,
    mutations,
}