export const ZEUS_USER_INFO = 'ZEUS_USER_INFO'; // 用户信息

export const SAAS_COURSE = 'SAAS_COURSE'; // 课程信息
export const SAAS_MENU = 'SAAS_MENU'; // 菜单
export const ACCESS_TOKEN = 'Accesstoken'; //
export const REFRESH_TOKEN = 'Refreshtoken'; //




//后台系统token
export const SAAS_TOKEN = 'accessToken'; // 用户TOKEN
export const SAAS_REFRESH_TOKEN = 'refreshToken'; // 长token
export const SAAS_USER_NAME = 'SAAS_USER_NAME'; // 用户登录账号


export const SAAS_USER_ID = 'saas_baby';

export const RESOURCE_PROGRESS_STATUS_ONGOING = 2;
export const RESOURCE_PROGRESS_STATUS_FINISH = 1;
