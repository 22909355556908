import { render, staticRenderFns } from "./BuyCourse.vue.js?vue&type=template&id=9fce02f0&scoped=true!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./BuyCourse.vue?vue&type=template&id=9fce02f0&scoped=true"
import script from "./BuyCourse.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./BuyCourse.vue?vue&type=script&lang=js"
export * from "./BuyCourse.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./BuyCourse.vue?vue&type=script&lang=js"
import style0 from "./BuyCourse.vue.css?vue&type=style&index=0&id=9fce02f0&prod&scoped=true&lang=css!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./BuyCourse.vue?vue&type=style&index=0&id=9fce02f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fce02f0",
  null
  
)

export default component.exports