<template>
    <div class="notelist">

        <el-form :model="ruleForm" ref="ruleForm" class="addruleForm">
            <el-form-item prop="name" :rules="filter_rules({required:true,type:'isAllSpace',maxLength:1000})">
                <el-input type="textarea" :rows="6" :placeholder="$t('videoPlay.enterNote') + '...'" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="notetype">
                    <el-button type="primary" class="notebtn" :loading="btnLoading" @click="addNoteLists('ruleForm')">
                        {{btnLoading ? $t('videoPlay.submitting') : $t('videoPlay.submitNote')}}
                    </el-button>
                </div>
            </el-form-item>
        </el-form>
        <div class="notetable">
            <el-tabs>
                <el-tab-pane :label="$t('videoPlay.myNote')" name="0">
                    <div class="planebox" v-loading="Loading" :element-loading-text="$t('loading')">
                        <ul v-if="noteempty">
                            <li v-for="(ret,index) in mynotelist" :key="ret.id">
                                <div class="planp1" v-if="!ret.status">{{ret.content}}</div>
                                <el-form :model="editArea" ref="editArea" class="planp1" v-if="ret.status">
                                    <el-form-item prop="editNoteContent" :rules="filter_rules({required:true,type:'isAllSpace',maxLength:1000})">
                                        <el-input type="textarea" :rows="4" :placeholder="$t('videoPlay.enterNote') + '...'"  v-model="editArea.editNoteContent"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div class="plannote">
                                    <span class="plantime">{{ret.live_seconds | secondToDate}}</span>
                                    <span class="plansys planeait" v-if="ret.status" @click="finshupdateinfo('editArea')">{{$t('complete')}}</span>
                                    <span class="plansys planeait" v-if="!ret.status" @click="updateinfo(ret.id,index)">{{$t('edit')}}</span>
                                    <span class="plansys deletenote" v-if="!ret.status"  @click="deleteinfo(ret.id,index)">{{$t('delete')}}</span>
                                    <span class="plansys deletenote" v-if="ret.status" @click="cancelUpdate(ret.id,index)">{{$t('cancel')}}</span>
                                </div>
                            </li>
                        </ul>
                        <div class="noteempty" v-if="!noteempty">
                            {{$t('videoPlay.noNote')}}
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<style>
    .notelist {
        width: 88%;
        height: -moz-calc(100% - 16px);
        height: -webkit-calc(100% - 16px);
        height: calc(100% - 16px);
        transition: all .2s linear;
    }

    .notelist .el-form-item {
        margin-bottom: 0;
    }

    .notelist .el-form-item__content {
        margin-left: 0;
    }

    .notelist .addruleForm .el-form-item__error {
        padding-top: 14px;
    }

    .notetable {
        width: 100%;
        height: -moz-calc(100% - 202px);
        height: -webkit-calc(100% - 202px);
        height: calc(100% - 202px);
        transition: all .2s linear;
    }

    .notetable .el-tabs {
        height: 100%;
    }

    .notetable .el-tabs .el-tabs__content {
        width: 100%;
        height: -moz-calc(100% - 57px);
        height: -webkit-calc(100% - 57px);
        height: calc(100% - 57px);
        transition: all .2s linear;
    }

    .notetable .el-tabs .el-tabs__content .el-tab-pane {
        width: 100%;
        height: 100%;
        transition: all .2s linear;
    }

    .notetable .planebox {
        width: 100%;
        height: 100%;
        overflow: auto;
        transition: all .2s linear;
    }

    .notelist textarea::-webkit-scrollbar {
        width: 6px;
        background-color: #f4f7f6;
    }

    .notelist textarea::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #f4f7f6;
    }

    .notelist textarea::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #d3d3d3;
    }
</style>
<script>
    import {addNoteListEp3Task, updateNoteEp3Task} from '../../api/studyForm.js'
    import {getNoteListEP3Task, deleteNoteinfoEP3Task} from '../../api/study'
    import Vue from '../../common/Vue.js'
    import lang from '../../language/lang';
    import { bindEvents } from '../../util/sensor-data.js';
    import { W2_STUDYVIEDO_DRAWER_CLICK_NOTE_SUBMIT} from '@/config'

    export default {
        props: ['player'],
        data() {
            return {
                btnLoading: false,//提交按钮loading
                mynotelist: [],
                is_publicOpen: false,
                is_public: "0",
                resource_id: '',
                syllabusitem_id: '',
                course_id: '',
                getPosition: '',
                noteempty: false,
                msg: [
                    {"name": ""}
                ],
                ruleForm: {
                    name: ''
                },
                numfont: 0,
                numfontval: '',
                Loading: false,
                editNoteId: '',  //当前正在编辑的笔记的id
                editNoteIndex: '',  //当前正在编辑的笔记的索引
                editArea: {
                    editNoteContent: '',//当前正在编辑的笔记的内容
                    sourceType: '',
                    liveSeconds: ''
                }
            }
        },
        computed: {
            resource_type(){
                const name = this.$route.name;
                const video_type =  +this.$route.query.video_type;
                let res_type = '';
                switch(name){
                    case 'playback':
                        res_type =  video_type === 2 ? 'hermes_live' :'legacy_live';
                        break;
                    case 'studyPlay':
                        res_type = 'video';
                        break;
                }
                return res_type;
            }
        },
        mounted() {},
        methods: {
            updateinfo(eitaid, index) {
                var flag = true;
                for (var i = 0; i < this.mynotelist.length; i++) {
                    if (this.mynotelist[i].status == true) {
                        flag = false;
                        this.$confirm(lang[window.GD_LANG].videoPlay.sureSaveNote, '', {
                            customClass: 'leaveTip',
                            showCancelButton: true,
                            showConfirmButton: true,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            cancelButtonText: lang[window.GD_LANG].videoPlay.cancelSave,
                            confirmButtonText: lang[window.GD_LANG].videoPlay.confirmSave,
                            center: true,
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    instance.confirmButtonLoading = true;
                                    instance.confirmButtonText = lang[window.GD_LANG].videoPlay.saving + '...';
                                    this.finshupdateinfo('editArea'); //保存
                                    Vue.$on('confirmSuccess', () => {
                                        done();
                                        instance.confirmButtonLoading = false;
                                    });
                                } else {
                                    done();
                                }
                            }
                        }).then(() => {
                            this.mynotelist[index].status = true;
                        }).catch(() => {
                            this.mynotelist[i].status = false;
                            this.mynotelist[index].status = true;
                            this.editNoteId = eitaid;
                            this.editNoteIndex = index;
                            this.editArea.editNoteContent = this.mynotelist[index].content;
                            this.editArea.liveSeconds = this.mynotelist[index].live_seconds;
                            this.editArea.sourceType = this.mynotelist[index].source_type;
                        });
                        return;
                    }
                }
                if (flag) {
                    this.mynotelist[index].status = true;
                    this.editNoteId = eitaid;
                    this.editNoteIndex = index;
                    this.editArea.editNoteContent = this.mynotelist[index].content;
                    this.editArea.liveSeconds = this.mynotelist[index].live_seconds;
                    this.editArea.sourceType = this.mynotelist[index].source_type;
                }
            },
            //取消编辑
            cancelUpdate(eitaid, index) {
                this.mynotelist[index].status = false;
            },
            finshupdateinfo(formName) {
                this.$refs[formName][0].validate((valid) => {
                    if (valid) {
                        this.upNotetolCom();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async upNotetolCom() {
                let params = {
                    content: this.editArea.editNoteContent,               // 笔记内容
                    live_seconds: this.editArea.liveSeconds,       // 视频时间点秒数(仅限视频，直播资源)
                    is_public: this.is_public,            // 是否公开笔记,1:公开，0:不公开
                    resource_id: this.resource_id,        // 资源id
                    resource_type: this.editArea.sourceType,  // 资源类型.video：视频；lecture_note：讲义；paper：试卷；question：题目。
                    origin: 'web',                        // 来源
                    course_id: this.course_id,             // 课程id
                    course_syllabus_item_id: this.syllabusitem_id,         // 大纲条目id
                    id: this.editNoteId
                }
                let ret = await updateNoteEp3Task(params);
                Vue.$emit('confirmSuccess');
                if (ret.status == 0) {
                    this.ruleForm.name = ""
                } else {
                    this.$message.error(ret.message ? ret.message : lang[window.GD_LANG].videoPlay.updateNoteFail);
                }
                this.getNotelist();
            },
            handleCheckedPublicChange(value) {
                if (value == true) {
                    this.is_public = "1";
                } else {
                    this.is_public = "0";
                }
            },
            addNoteLists(formName) {
                bindEvents(W2_STUDYVIEDO_DRAWER_CLICK_NOTE_SUBMIT)
                this.ruleForm.name = this.ruleForm.name.trim();
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.addNoteListsCommt();
                    }
                });
            },
            async addNoteListsCommt() {
                try {
                    this.player = window.gdplayer;
                    this.getPosition = this.player.video && this.player.video.currentTime || this.player.getCurrentTime();
                } catch (error) {
                    this.getPosition = 0
                }

                let url = {
                    course_id: this.course_id, // 课程id
                    resource_id: this.resource_id, // 资源id
                    // resource_item_id: this.cur_question_id, //题目id，试卷类型资源必填
                    resource_type: "video", // 资源类型.video：视频；lecture_note：讲义；paper：试卷；question：题目。
                    is_public: 1,            // 是否公开笔记,1:公开，0:不公开
                    content: this.ruleForm.name, // 笔记内容
                    origin: "web", // 来源
                    course_syllabus_item_id: this.syllabusitem_id, // 大纲条目id
                    live_seconds: parseInt(this.getPosition),       // 视频时间点秒数(仅限视频，直播资源)
                }

                this.btnLoading = true;
                const ret = await addNoteListEp3Task(url);
                this.btnLoading = false;
                if (ret.status == 0) {
                    this.getNotelist();
                    this.ruleForm.name = "";
                } else {
                    this.$message.error(ret.message ? ret.message : lang[window.GD_LANG].videoPlay.updateNoteFail);
                }
            },
            //重置表单
            resetForm() {
                this.$refs['ruleForm'].resetFields();
            },
            async getNotelist() {
                this.resource_id = this.$route.query.resource_id;                   // 资源id
                this.syllabusitem_id = this.$route.query.syllabusitem_id;   // 大纲条目id
                this.course_id = this.$route.query.course_id;                       // 课程id

                if (this.ruleForm.name) {
                    this.$refs['ruleForm'].resetFields();
                }

                let params = {
                    course_id: this.course_id, // 课程id
                    cs_item_id:this.syllabusitem_id,
                    resource_item_id:this.$store.state.paper.paper_cur_questions.id,
                    page:1,
                    offset:50,
                    source_type:'video',
                    origin: "web", // 来源
                };
                this.mynotelist =[];
                const ret = await getNoteListEP3Task(params);
                if (ret.status == 0) {
                    let datalist = [];
                    const interactive = ret.result;
                    if (interactive && interactive.length > 0) {
                        this.noteempty = true;
                        for (let o of interactive) {
                            datalist.push({
                                content: o.content,
                                course_id: o.course_id,
                                course_syllabus_item_id: o.course_syllabus_item_id,
                                created_at: o.created_at,
                                id: o.id,
                                is_public: o.is_public,
                                live_seconds: o.live_seconds,
                                origin: o.origin,
                                source_id: o.source_id,
                                source_type: o.source_type,
                                student_id: o.student_id,
                                updated_at: o.updated_at,
                                status: false,
                                numfont: 0
                            })
                        }
                    } else {
                        this.noteempty = false;
                    }

                    this.mynotelist = datalist;
                }
            },
            deleteinfo(noteid) {
                this.$confirm(lang[window.GD_LANG].sureDelete +  '?', '', {
                    customClass: 'leaveTip',
                    showCancelButton: true,
                    showConfirmButton: true,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    cancelButtonText: lang[window.GD_LANG].cancelDelete,
                    confirmButtonText: lang[window.GD_LANG].delete,
                    center: true,
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = lang[window.GD_LANG].deleting + '...';
                            const ret = await deleteNoteinfoEP3Task(noteid);
                            instance.confirmButtonLoading = false;
                            if (ret.status == 0) {
                                this.getNotelist();
                            } else {
                                this.$message.error(ret.message ? ret.message : lang[window.GD_LANG].videoPlay.deleteNoteFail);
                            }
                            done();
                            return;
                        }
                        done();
                    }
                })
            }
        },
        created() {
            this.resource_id = this.$route.query.resource_id;                   // 资源id
            this.syllabusitem_id = this.$route.query.syllabusitem_id;   // 大纲条目id
            this.course_id = this.$route.query.course_id;                       // 课程id
            this.getNotelist();
        },
        watch: {
            "$route": ['getNotelist', 'resetForm'],
        },
    }
</script>
