import axios from 'axios';
import { getLogoutUrl } from './config'
import { ACCESS_TOKEN } from './keys';
const queryString = require('querystring');
import { getCookie } from '../util/cookie.js';
import { networkMsg } from './messageBox.js'

var instance = axios.create({
    baseURL: '//',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    // withCredentials : true,
    transformRequest(data, headers) {
        let strData = queryString.stringify(data);
        return strData;
    },
});

instance.interceptors.request.use(async function(config) {
    // 登录接口直接返回
    if (config.url.indexOf('login') !== -1) {
        return Promise.resolve(config);
    }
    // 换取最新token直接返回验证
    if (config.url.indexOf('refreshtoken') !== -1) {
        return Promise.resolve(config);
    }

    let token = getCookie(ACCESS_TOKEN);

    if (config.url.indexOf('refreshtoken') === -1 && token != undefined) {
        config.headers.common['Authentication'] = `Basic ${token}`;
        return Promise.resolve(config);
    }
    return Promise.resolve(config);
}, function(error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function(response) {
    //登陆接口直接返回response (需要headers头信息)
    if (response.config.url.indexOf('login') !== -1) {
        return Promise.resolve(response);
    }

    // 获取token不校验，直接返回
    if (response.config.url.indexOf('refreshtoken') !== -1) {
        return Promise.resolve(response.data);
    }

    let saas_url = location.href;
    saas_url = encodeURIComponent(saas_url);

    // 登录失效 553649410～553649444
    if (response.config.url.indexOf('refreshtoken') === -1 && response.data.status > 553649000 && response.data.status < 563649999) {
        // location.href = getLogoutUrl(saas_url);
        return Promise.resolve(response.data);
    }

    return Promise.resolve(response.data);
}, function(error) {
    console.log("🚀 ~ instance.interceptors.response.use ~ error:", error)
    if (error == 'Error: Network Error' && error.config.url.indexOf('/study-api/resource/') !== -1) {
        networkMsg();
    }
    return Promise.reject(error);
});

export const formPost = instance.post.bind(instance);
export const formPut = instance.put.bind(instance);
