<template>
    <div
        id="syllabus-gradation"
        class="nav-menu-wrap"
    >
        <div class="w_1090 clear">
            <div class="tab-prefix">
                <span>{{$t('syllabus.gradation')}}：</span>
            </div>
            <div class="h-menu">
                <el-tabs @tab-click="handleClick">
                    <el-tab-pane
                        :key="item.id"
                        v-for="item in list"
                        :disabled="!item.syllabusId"
                    >
                        <span slot="label">{{item.name}}</span>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="tab-suffix">
                <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                >
                    <div
                        slot="content"
                        :style="[{maxWidth: '400px'}]"
                    >
                        {{active.description}}
                    </div>
                    <i class="el-icon-info" />
                </el-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../assets/css/syllabus/syllabus-gradation.less';
import { bindEvents } from '../../../util/sensor-data';
import { W2_CLICK_OUTLINETAB } from '@/config'

export default {
    props: {
        list: Array,
    },
    data() {
        return {
            active: ''
        };
    },
    watch: {
        list: {
            handler(val, oldVal) {
                if (val && val.length && !this.active) {
                    this.active = val[0];
                }
            },
            immediate: true
        }
    },
    methods: {
        handleClick(tab, event) {
            bindEvents(W2_CLICK_OUTLINETAB, { tab_name: this.list[+tab.index].name });
            const data = this.list[+tab.index];
            this.active = data;
            this.$emit('on-click', data);
        }
    },
};
</script>
