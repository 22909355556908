<template>
    <div class="transfer-page" v-loading="true"></div>
</template>
<style>
    .transfer-page {
        width: 100%;
        height: 100%;
        background: #fff;
    }

    .transfer-page .el-loading-mask {
        height: 100%;
    }
</style>
<script>
import {reqResourceDetail} from '../api/study.js';

export default {
    data() {
        return {};
    },
    async created() {
        /*
        * 直播中转页
        * 系列直播 统一跳转列表页
        * 单直播 查看回放 跳转回放页面
        * 其余状态 统一跳转列表页
        * */
        const courseId = this.$route.query.course_id;              //课程id
        const csItemId = this.$route.query.syllabusitem_id;   //大纲条目id
        const resourceId = this.$route.query.resource_id;         //资源id
        const syllabusId = this.$route.query.syllabus_id;         //资源id

        const params = {
            courseId,
            csItemId,
            syllabusId,
        };

        let response = {
            status: 0,
            result: null,
        };

        try {
            response = await reqResourceDetail(resourceId, params);
        } catch (e) {
            response.status = 1;
            response.result = e;
        }

        if (response.status !== 0 || !response.result) {
            this.$message(response.message || '直播异常，请刷新页面或重新登录！');
            this.goResource404();
            return;
        }

        const live = response.result.live;

        this.$store.dispatch('saveLiveData', live.video_list);//保存视频信息

        if (!live.series && live.video_list && live.video_list.length) {
            //单直播 && 直播列表
            const liveInfo = live.video_list[0];

            if (liveInfo.now_status === 3) {
                //状态码为3是 查看回放
                const liveId = liveInfo.live_id;
                this.$router.replace({
                    name: 'playback',
                    params: {liveid: liveId},
                    query: this.$route.query,
                });
                return;
            }
        }

        // 其余状态码 跳转直播列表页
        this.$router.replace({
            name: 'zhibo',
            query: this.$route.query,
        });
    },
    methods: {
        //跳转到资源404
        goResource404() {
            this.$router.replace({
                name: 'Resource404',
                query: this.$route.query,
            });
        },
    },
};
</script>
