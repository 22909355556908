const state = {
    isAuth: false,
}
const mutations = {
    setIsAuth(state, boolean) {
        //按钮开关
        state.isAuth = boolean;
    }
}
const actions = {}
export default {
    state,
    actions,
    mutations
}
