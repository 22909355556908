<template>
    <div class="paper-tools-box">
        <div class="paper-tools-box-header">
            <el-menu :default-active="activeName" mode="horizontal" @select="handleSelect" class="tools-change">
                <el-menu-item index="note">记笔记</el-menu-item>
                <el-menu-item index="question">我要提问</el-menu-item>
            </el-menu>
        </div>
        <!--<el-tabs v-model="activeName" class="tools-change">-->
        <!--<el-tab-pane label="记笔记" name="note"></el-tab-pane>-->
        <!--<el-tab-pane label="我要提问" name="question"></el-tab-pane>-->
        <!--</el-tabs>-->
        <div class="paper-tools-box-content">
            <AnalysisNote v-show="activeName=='note'" :noteList="noteList" :editBoxshow="editBoxshow" @cancelUpdate="cancelUpdate" :btnLoading="btnLoading" @showEditBox="showEditBox" @addNote="addNote" @deleteinfo="deleteinfo" @updatenote="updateNote" @getNotelist="getNotelist"></AnalysisNote>
            <AnalysisAsk v-show="activeName=='question'" @goGbot="goGbot" :quizList="quizList"></AnalysisAsk>
        </div>
    </div>
</template>
<script>
import AnalysisNote from "./AnalysisModuleNote.vue";
import AnalysisAsk from "./AnalysisModuleAsk.vue";
import { updateNoteEp3Task, addNoteListEp3Task } from "../../api/studyForm.js";
import {
    getNoteListEP3Task,
    deleteNoteinfoEP3Task,
    commonQuestion,
    createGbotUrl
} from "../../api/study.js";
import Vue from "../../common/Vue.js";
import { getEnv, gbotbaseURL } from "../../util/config";
import { SAAS_COURSE, ACCESS_TOKEN } from "../../util/keys.js";
import { getCookie, setCookie } from "../../util/cookie.js";

let prefix = getEnv();
export default {
    components: {
        AnalysisNote,
        AnalysisAsk
    },
    data() {
        return {
            activeName: "note",
            noteList: [], //笔记列表
            editBoxshow: false,
            btnLoading: false, //按钮loading
            quizList: [] //提问列表
        };
    },
    computed: {
        resource_id() {
            return this.$route.query.resource_id; // 资源id
        },
        syllabusitem_id() {
            return this.$route.query.syllabusitem_id; // 大纲条目id
        },
        course_id() {
            return this.$route.query.course_id; // 课程id
        },
        project_id() {
            return this.$store.state.home.course_info.project_id;
        },
        subject_id() {
            return this.$store.state.home.course_info.subject_id;
        },
        allow_question() {
            return +this.$store.state.home.course_info.allow_question === 1;
        },
        question_id() {
            return +this.$store.state.paper.paper_cur_questions.id;
        },
    },
    mounted() {
        Vue.$on("updatenote", () => {
            this.getNotelist();
        });
        Vue.$on("UpdateCommQues", () => {
            this.commonQuestion();
        });
    },
    methods: {
        handleSelect(key) {
            this.activeName = key;
        },
        //取消编辑
        cancelUpdate(index) {
            this.noteList[index].status = false;
        },
        //编辑笔记
        showEditBox(index) {
            for (let val of this.noteList) {
                val.status = false;
            }
            this.noteList[index].status = true;
        },
        //新增笔记
        async addNote(content) {
            let url = {
                content: content, // 笔记内容
                course_id: this.course_id, // 课程id
                course_syllabus_item_id: this.syllabusitem_id, // 大纲条目id
                is_public: 1,            // 是否公开笔记,1:公开，0:不公开
                resource_id: this.resource_id, // 资源id
                resource_type: "question", // 资源类型.video：视频；lecture_note：讲义；paper：试卷；question：题目。
                origin: "web", // 来源
                resource_item_id: this.question_id  //题目id，试卷类型资源必填
            };
            this.btnLoading = true;
            // let ret = await addNoteList(url);
            const ret = await addNoteListEp3Task(url);
            this.btnLoading = false;
            if (ret.status == 0) {
                this.$message({
                    type: "success",
                    message: "新建笔记成功!"
                });
                this.getNotelist();
                Vue.$emit("clearContent");
            } else {
                this.$message.error(
                    ret.message ? ret.message : "新建笔记失败!"
                );
            }
        },
        //删除笔记
        deleteinfo(noteid) {
            this.$confirm("确认删除吗？", "", {
                customClass: "leaveTip",
                showCancelButton: true,
                showConfirmButton: true,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                cancelButtonText: "不删除",
                confirmButtonText: "删除",
                center: true,
                beforeClose: async (action, instance, done) => {
                    if (action === "confirm") {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = "删除中...";
                        // let ret = await deleteNoteinfo(noteid);
                        const ret = await deleteNoteinfoEP3Task(noteid);
                        done();
                        instance.confirmButtonLoading = false;
                        if (ret.status == 0) {
                            this.$message({
                                message: ret.message
                                    ? ret.message
                                    : "删除笔记成功！",
                                type: "success"
                            });
                            this.getNotelist();
                        } else {
                            this.$message.error(
                                ret.message ? ret.message : "删除笔记失败!"
                            );
                        }
                    } else {
                        done();
                    }
                }
            })
                .then(() => {})
                .catch(() => {});
        },
        //更新笔记
        async updateNote(content, editNoteId) {
            let params = {
                content: content, // 笔记内容
                is_public: 1,            // 是否公开笔记,1:公开，0:不公开
                resource_id: this.resource_id, // 资源id
                resource_type: "question", // 资源类型.video：视频；lecture_note：讲义；paper：试卷；question：题目。
                origin: "web", // 来源
                course_id: this.course_id, // 课程id
                course_syllabus_item_id: this.syllabusitem_id, // 大纲条目id
                id:editNoteId,
                resource_item_id:this.$store.state.paper.paper_cur_questions.id,

            };
            let ret = await updateNoteEp3Task(params);

            Vue.$emit("confirmSuccess");
            if (ret.status == 0) {
                this.$message({
                    type: "success",
                    message: "更新笔记成功!"
                });
                this.getNotelist();
            } else {
                this.$message.error(
                    ret.message ? ret.message : "更新笔记失败!"
                );
            }
        },
        //拉取笔记
        async getNotelist() {
            let params = {
                course_id: this.course_id, // 课程id
                cs_item_id:this.syllabusitem_id,
                resource_item_id:this.$store.state.paper.paper_cur_questions.id,
                page:1,
                offset:50,
                source_type:'question',
                origin: "web", // 来源
            };
            const ret = await getNoteListEP3Task(params);
            this.noteList = [];
            if (ret.status == 0) {
                let noteList = ret.result||[];
                for (let item of noteList) {
                    item.status = false;
                }
                this.noteList = noteList;
                /* 题目笔记可以多条增加 2018年3月30日14:16:17
                     */
                /* if (ret.result.length > 0) {
                        this.editBoxshow = false;
                    } else {
                        this.editBoxshow = true;
                    } */
            }
        },
        //拉去常见问题
        async commonQuestion() {
            let params = {
                course_id: this.$route.query.course_id, //课程id
                payload: this.$route.query.syllabusitem_id, //检索维度（默认大纲条目id）
                // resource :this.$route.query.resource_id ,  //检索维度（默认资源id）
                item_id: this.$store.state.paper.paper_cur_id //题目id
            };
            let ret = await commonQuestion(params);
            if (ret.status == 0) {
                this.quizList = ret.result;
            }
        },
        goGbot() {
            if (!this.allow_question) {
                return this.$message({
                    message: '您暂无权限提问'
                })
            }

            const token = getCookie(ACCESS_TOKEN);
            const course_id = this.$route.query.course_id; //课程id
            const resource = this.$route.query.resource_id; //资源条目id
            const payload = this.$route.query.syllabusitem_id; //大纲id
            const resource_id = this.$store.state.paper.paper_cur_id; //题目id

            let data = {
                course_id: course_id, // 课程id
                resource: resource, //资源条目id
                resource_id: resource_id, // 资源id（视频id 题目id）
                payload: payload, // 大纲条目id
                gbot_source: 1, //来源  1课程  2题库
                project_id: this.project_id, // 项目id
                subject_id: this.subject_id, // 科目id
                type: 2 // 类型 1视频 2题目
            };
            window.open(`https://${prefix}gbot.gaodun.com/update-course/${course_id}/${this.project_id}/${this.subject_id}/${resource_id}/2?source=1&resource=${resource}&payload=${payload}&source_type=saas&client_source=0&client_version=0`);
            // this.$ajax({
            //     type: 'POST',
            //     url: createGbotUrl,
            //     data: data,
            //     successCallBack: this.getGbotUrlSuccessCallBack
            // })
        },
        getGbotUrlSuccessCallBack(ret) {
            if (ret.status === 0) {
                window.open("//" + prefix + "gbot.gaodun.com/?cid=" + ret.data.conversation_id + "&crid=" + ret.data.conversation_record_id + "", "_blank");
            }
        }
    },
    created() {}
};
</script>
