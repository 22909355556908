<template>
    <div
        class="question-content"
        v-loading="zLoading"
        :element-loading-text="loadingText"
    >
        <div
            class="quiz-items"
            v-for="(item, index) in dataList"
            :key="item.id"
        >
            <div class="quiz-items-ask">
                <div class="quiz-items-ask-title">{{ item.content }}</div>
                <div
                    class="quiz-items-ask-pic clear"
                    v-if="item.images && item.images.length != 0"
                >
                    <img :src="item.images[0]" alt="" />
                </div>
            </div>
            <div class="quiz-items-answer">
                <el-tag type="success" v-if="item.answered">
                    {{ $t("answered") }}
                </el-tag>
                <el-tag type="gray" class="gray_12" v-if="!item.answered">
                    {{ $t("notAnswer") }}
                </el-tag>
                <div
                    class="quiz-items-answer-reply"
                    v-if="item.answered && item.answer.content"
                >
                    {{ item.answer.content | WordsLen }}
                </div>
                <AudioPlayer
                    :answer="item.answer"
                    :classname="'answer-audio'"
                    :index="index"
                    v-if="item && item.answer && item.answer.file_link"
                />
            </div>
            <div class="quiz-items-actions actions">
                <a
                    tag="a"
                    class="gr_bor_btn"
                    :href="`https://${env}gbot.gaodun.com/question-detail/${item.id}`"
                    target="_blank"
                    @click = buryPoint
                >
                    {{ $t("view") }}
                </a>
            </div>
        </div>
    </div>
</template>
<style lang='less'>
.question-content .el-loading-mask {
    height: calc(100vh - 350px);
}
.wrapper {
    .el-col.el-col-12 {
        margin-top: 10px;
        width: 100%;
    }
}
</style>
<script>
import { getEnv } from "../../util/config.js";
import { bindEvents } from "../../util/sensor-data.js";
import { W2_QUESTION_CLICK_VIEW } from "@/config";
import AudioPlayer from "../audio-play";
export default {
    components: { AudioPlayer },
    props: ["dataList", "zLoading", "loadingText"],
    data() {
        return {
            env: getEnv(),
        };
    },
    methods: {
        buryPoint() {
            bindEvents(W2_QUESTION_CLICK_VIEW)
        }
    },
    computed: {
        course_id() {
            return this.$route.params.cid;
        },
        vid() {
            return this.$route.query.vid || "";
        },
        refSite() {
            return this.$route.query.refSite || "";
        },
    },
};
</script>
