<template>
    <div class="handoutsDownload">
        <div class="handoutsDownload-content w_1090">
            <div class="handoutsDownload-title">
                <p
                    class="title-name black_16"
                    v-if="handoutTypeList && handoutTypeList.length < 2"
                >
                    {{ $store.state.home.course_name }}
                    {{ $t("downloadInformation") }}
                </p>
                <template v-if="handoutTypeList && handoutTypeList.length >= 2">
                    <div
                        class="handout-typebtn"
                        :class="{ active: item.id === activeId }"
                        v-for="item in handoutTypeList"
                        :key="item.id"
                        @click="checkType(item.id)"
                    >
                        {{ item.name }}
                    </div>
                </template>
            </div>
            <div class="handoutsDownload-list">
                <div
                    class="no__content"
                    v-if="handoutsList && !handoutsList.length"
                >
                    {{ $t("noContent") }}
                </div>
                <ul
                    class="handouts-list clear"
                    id="handoutList"
                    v-else-if="handoutsList && handoutsList.length"
                >
                    <li
                        class="handouts-items"
                        v-for="(item, index) in handoutsList"
                        :key="item.id"
                    >
                        <span class="items-dialog" />
                        <div class="items-box">
                            <div class="items-box-head clear">
                                <span class="items-size">{{ item.size }}</span>
                            </div>
                            <div class="items-box-content">
                                <div class="items-title">{{ item.name }}</div>
                            </div>
                            <button
                                class="items-btn"
                                @click="handleClickDownLoad(item)"
                            >
                                {{ $t("download") }}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import {
    reqCourseHandoutCategory,
    reqHandoutDownloadPath,
    reqHandoutList,
} from "../api/handoutsDownload";
import lang from "../language/lang";
import { bindEvents } from "../util/sensor-data";
import { W2_VIEWSCREEN_HANDOUTSDOWNLOAD,W2_CLICK_DOWNLOAD} from "@/config"

export default {
    data() {
        return {
            handoutsList: null,
            loading: false,
            handoutTypeList: null, // 讲义分类列表
            activeId: "", //选中分类
        };
    },
    computed: {
        courseId() {
            return this.$route.params.cid;
        },
        handoutCategoryOpen() {
            return this.$store.state.home.course_info.course_setting
                .handout_category_open;
        },
    },
    methods: {
        // 判断是否有讲义分类列表，如果有获取列表
        async getHandoutTypeList() {
            if (this.handoutCategoryOpen === 1) {
                let ret = await reqCourseHandoutCategory(this.courseId);
                if (ret.status === 0 && ret.result) {
                    const list = ret.result;
                    this.handoutTypeList =
                        list && list.constructor === Array ? list : [];
                    if (this.handoutTypeList.length) {
                        this.activeId = this.handoutTypeList[0].id;
                        this.getTypeHandout(this.activeId);
                    } else {
                        this.handoutsList = [];
                    }
                } else {
                    this.handoutTypeList = [];
                    this.handoutsList = [];
                    this.$message.warning(
                        lang[window.GD_LANG].handoutTypeError
                    );
                }
            }
        },
        // 切换讲义分类
        checkType(val) {
            this.activeId = val;
            this.getTypeHandout(val);
        },
        // 获取对应id下的讲义
        async getTypeHandout(val) {
            const params = {
                categoryId: val,
            };
            let ret = await reqHandoutList(this.courseId, params);
            if (ret.status === 0 && ret.result) {
                const list = ret.result;
                this.handoutsList =
                    list && list.constructor === Array ? list : [];
            } else {
                this.handoutsList = [];
            }
        },
        downloadFile(fileUrl, fileName) {
            // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
            if (window.fetch) {
                fetch(fileUrl)
                    .then((res) => res.blob())
                    .then((blob) => {
                        let a = document.createElement('a');
                        let url = window.URL.createObjectURL(blob);
                        let aId = fileName + '_' + +new Date();
                        a.id = aId;
                        a.href = url;
                        a.download = fileName;
                        document.body.append(a); //修复qq浏览器无法下载
                        a.click();
                        setTimeout(() => {
                            document.getElementById(aId) && a.remove(); //修复qq浏览器无法连续下载
                        }, 1000);
                    });
            } else {
                let a = document.createElement('a');
                a.href = fileUrl;
                a.target = '_blank';
                a.download = fileName;
                a.click();
            }
        },
        //  下载讲义
        async handleClickDownLoad(item) {
            bindEvents(W2_CLICK_DOWNLOAD, {讲义id:item.id})
            const files = await this.setDownloadUrl(item.id, item.fileName);
            const filename = item.fileName;
            if (!files || typeof files !== "string") {
                this.$message.error(lang[window.GD_LANG].handoutError);
                return;
            }

            if (item.format === 'pdf') {
                console.log(filename,files);
                this.downloadFile(files, filename);
                return;
            }

            this.IEdownloadFile(filename, files);
        },
        async setDownloadUrl(id, filename) {
            const res = await reqHandoutDownloadPath({
                handout_id: +id,
                filename: encodeURIComponent(filename),
            });
            if (res && res.status === 0 && res.data) {
                return res.data;
            }
            return false;
        },
        IEdownloadFile(fileName, contentOrPath, bool) {
            let isImg = contentOrPath.slice(0, 10) === "data:image";
            let ifr = document.createElement("iframe");

            ifr.style.display = "none";
            ifr.src = contentOrPath;

            document.body.appendChild(ifr);

            // dataURL 的情况
            isImg &&
                ifr.contentWindow.document.write(
                    "<img src='" + contentOrPath + "' />"
                );

            // 保存页面 -> 保存文件
            if (bool) {
                setTimeout(function () {
                    ifr.contentWindow.document.execCommand(
                        "SaveAs",
                        false,
                        fileName
                    );
                    document.body.removeChild(ifr);
                }, 20 * 1000);
            } else {
                setTimeout(function () {
                    try {
                        ifr.contentWindow.document.execCommand(
                            "SaveAs",
                            false,
                            fileName
                        );
                        document.body.removeChild(ifr);
                    } catch (e) {
                        document.body.removeChild(ifr);
                    }
                }, 5000);
            }
        },
        async getHandouts() {
            const ret = await reqHandoutList(this.courseId);
            if (+ret.status === 0 && ret.result) {
                const list = ret.result;
                this.handoutsList =
                    list && list.constructor === Array ? list : [];
            } else {
                this.handoutsList = [];
            }
        },
    },
    created() {
        if (+this.handoutCategoryOpen === 1) {
            this.getHandoutTypeList();
        } else if (+this.handoutCategoryOpen === 0) {
            this.getHandouts();
            this.handoutsList = [];
        }
    },
    mounted() { 
        bindEvents(W2_VIEWSCREEN_HANDOUTSDOWNLOAD);
    },
    watch: {
        handoutCategoryOpen(val) {
            if (+val === 1) {
                this.getHandoutTypeList();
            } else {
                //讲义分类关闭
                this.getHandouts(); //获取全部的讲义
                this.handoutTypeList = [];
            }
        },
    },
};
</script>

<style scoped>
.handout-typebtn {
    display: inline-block;
    border: 1px solid #00c18c;
    padding: 4px 15px;
    border-radius: 5px;
    color: #00c18c;
    margin-right: 12px;
    cursor: pointer;
}

.handout-typebtn:hover,
.active {
    background: #00c18c;
    color: white;
}
</style>

