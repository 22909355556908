<template>
    <div class="module-list-exercise exercise-list-group">
        <div
            v-for="(item, index) in syllabusData"
            v-if="item.item_total"
            :key="item.id"
            :class="['contentBox', index === 0 ? 'firstBox' : '']"
        >
            <div class="box-title">
                <span class="box-name black_16">{{item.name}}</span>
            </div>
            <div class="box-content">
                <ul class="content-list-group">
                    <li class="group-items" v-for="child in item.syllabus" :key="child.id"
                        v-if="child.item_total">
                        <div class="items-name" :title="child.name">{{child.name}}</div>
                        <div class="items-info">
                            <span>{{child.item_total}}道</span>
                        </div>
                        <div class="items-actions">
                            <router-link
                                tag="a"
                                class="gr_bor_btn"
                                target="_blank"
                                :to="`/test/again/do-question?courseId=${courseId}&syllabusId=${item.id}&chapterId=${child.id}&type=${type}&vid=${vid}&projectId=${projectId}&subjectId=${subjectId}&refSite=${refSite}&source=${source}&questionIds=${child.item_list}`"
                            >查看
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style>
    .exercise-list-group .el-loading-mask {
        height: calc(100vh - 350px);
    }
</style>
<script>

export default {
    props: [
        'syllabusData',
        'type',
        'source'
    ],
    data() {
        return {
        };
    },
    computed: {
        courseId() {
            return this.$route.params.cid;
        },
        vid() {
            return this.$route.query.vid || '';
        },
        projectId() {
            return this.$store.state.home.course_info.project_id;
        },
        subjectId() {
            return this.$store.state.home.course_info.subject_id;
        },
        refSite() {
            return this.$route.query.refSite || '';
        },
    },
};
</script>
