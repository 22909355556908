
// 资源上报
import Vue from '../common/Vue';
import { reqResourceRepeatRecord } from "../api/study.js";
import { mapState } from 'vuex'
export const ResourceReport = {
    data() {
        return {
            learningStartTime: 0,     // 视频开始时间-毫秒
            learningEndTime: 0,       // 视频结束时间-毫秒
            resourceStartTime: 0,     // 资源de开始时间-毫秒
            resourceEndTime: 0,       // 资源的结束时间-毫秒
            resourceDuration: 0,      // 视频总时长
            duration: 0,              // 学习时长
            vTimer: null,             // 定时器
            player: null,             // 播放器
            intervalTime: 180 * 1000, // 3分钟-毫秒
            isXhrError: true,
            timeDeviation: 0, // 与服务器时间的偏差 localTime + timeDeviation = serviceTime
            navigatorOnLine: true,
        };
    },
    created() {
        this.calcServiceTimeDeviation();
        window.addEventListener('online', this.onOnlineEvent.bind(this));
        window.addEventListener('offline', this.onOfflineEvent.bind(this));
    },
    computed: {
        ...mapState({
            _resourceId: state => {
                return state.study.resource_Id;
            },
        }),

    },
    methods: {
        async calcServiceTimeDeviation() {
            const { result, status } = await this.$http.getServiceTime();
            if (result && status === 0) {
                this.timeDeviation = result * 1000 - new Date().getTime();
            } else {
                this.timeDeviation = 0;
            }
        },
        onOnlineEvent() {
            if (this.navigatorOnLine === false) {
                this.navigatorOnLine = true;
                this.calcServiceTimeDeviation();
            }
        },
        onOfflineEvent() {
            this.navigatorOnLine = false;
        },
        fetchServiceTime() {
            return new Date().getTime() + this.timeDeviation;
        },
        /**
         * 资源上报
         * @param {boolean} s 时间段
         */
        _resourceRepeat(s, props = {}) {
            if (s === 'setSpeed' && !this.vTimer) {
                return;
            }
            if(!this.getTotalTime()){
                setTimeout(() => {
                    this._resourceRepeat(s, props)
                }, 500);
                return;
            }
            this.learningEndTime = this.fetchServiceTime();
            const getTotalTime = this.getTotalTime() || 0;
            const getCurrentTime = this.getCurrentTime() || 0;
            const speedNum = localStorage.getItem('GplayerRate') || '1.0';
            let params = {
                courseId: +this.courseId,
                resourceId: +this._resourceId || +this.resourceId,
                learningStartTime: this.learningStartTime,
                learningEndTime: this.learningEndTime,
                resourceStartTime: +(this.resourceStartTime||0).toFixed(0),
                resourceEndTime: +(getCurrentTime.toFixed(0)),
                resourceDuration: +(getTotalTime.toFixed(0)),
                clientSource: 'pc',
                clientReportingSource: s,
                duration: this.learningEndTime - this.learningStartTime,    // 学习时长：(实际学习时间)结束 - 开始
                playSpeed: speedNum.replace('x', ''), // 播放速度, 偶尔会出现x 加个处理
                ...props
            }
            if (s === 'onpause' || s === 'setSpeed') {
                this._eReportEvent();
            }

            reqResourceRepeatRecord(params)
                .then(res => {
                    if (res.status !== 0) {
                        this.isXhrError = false
                        this.$message.error(res.message)
                        return
                    }
                    this.timeDeviation = res.result.currentTime * 1000 - new Date().getTime();
                    this.isXhrError = true
                }).catch(e => {
                    this.isXhrError = false
                })
        },
        /**
         * 开始上报
         */
        _sReportEvent() {
            this.learningStartTime = this.fetchServiceTime();
            this.resourceStartTime = this.getCurrentTime();
            this.vTimer = setTimeout(() => {
                this._eReportEvent()
                this._resourceRepeat('timeAuto')
                if (this.isXhrError) {
                    this._sReportEvent()
                }
            }, this.intervalTime)
        },
        /**
         * 结束上报
         */
        _eReportEvent() {
            if (this.vTimer) {
                clearTimeout(this.vTimer)
                this.vTimer = null
            }
        },
        /**
         * 总时长
         */
        getTotalTime() {
            if (!this.player) {
                return;
            }
            // return this.isG1 ? this.player.getDuration() * 1000 : this.player.video.duration * 1000 ;
            return this.player.video.duration * 1000 ;
        },
        /**
         * 当前结束/暂停时间
         * @returns
         */
        getCurrentTime() {
            if (!this.player) {
                return;
            }
            // return this.isG1 ? this.player.getCurrentTime() * 1000 : this.player.video.currentTime * 1000;
            return this.player.video.currentTime * 1000;
        },
        _getVTimer() {
            return !!this.vTimer;
        }
    },
    beforeDestroy() {
        this._eReportEvent()
        Vue.$off()
        window.removeEventListener('beforeunload', this._resourceRepeat);
        window.removeEventListener('online', this.onOnlineEvent);
        window.removeEventListener('offline', this.onOfflineEvent);
    },
    mounted() {
        window.addEventListener('beforeunload', () => {
            if (this.vTimer) {
                Vue.$off()
                this._eReportEvent();
                this._resourceRepeat('beforeunload');
            }

        });
        Vue.$off().$on('resourceRepeatReport', () => {
            if (this.vTimer) {
                this._eReportEvent();
                this._resourceRepeat('changeVideo');
            }
        });
    }
}
