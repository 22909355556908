import Vue from 'vue';
import Vuex from 'vuex';
import home from './modules/home.js';
import outline from './modules/outline.js';
import study from './modules/study.js';
import paper from './modules/paper.js';
import report from './modules/report.js';
import knowledge from './modules/knowledge.js';
import retest from './modules/retest.js';
import zhibo from './modules/zhibo.js';
import base from './modules/base.js';
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        home,
        outline,
        study,
        paper,
        report,
        knowledge,
        retest,
        zhibo,
        base
    },
    strict: false
})
