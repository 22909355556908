<template>
<div class="nps-report-all-wrapper" v-if="alertVisible || hasNps !== 1 || npsShowType !== 1">
    <div class="nps-report-ui-wrapper" v-if="uiVisible && (hasNps !== 1 && npsShowType !== 1) && allowShow">
        <div class="title">
            {{title}}
        </div>
        <div class="img-wrapper">
            <img
            v-for="(url,index) in unSelectUrlListUI"
            :key="url"
            class="face-img"
            :src="url"
            @click="uiImgClick(index+1)">
        </div>
        <span v-if="inUseFlag" class="un-use-ui" @click="uiImgClick(-1)">
            未使用过
        </span>
    </div>
    <el-dialog :title="npsTitle || '告诉我们真实的评价，我们将会为您提供更好的服务！'"
     class="nps-dialog"
     :visible.sync="alertVisible"
     width="660px"
     :close-on-click-modal="false"
     @close="closeHandle"
     :append-to-body="true">
        <div class="evalute-list-content-wrapper">
            <div v-for="(ques,index) in questionList" :key="ques.questionId" class="question-item-wrapper">
                <div class="title-ques">
                    {{`${index+1}.${ques.question}`}}
                </div>
                <div class="question-able-select-line" v-if="ques.scoreStyle == 2">
                    <div class="imgs-wrapper">
                        <img
                        v-for="(option,indexImg) in ques.options"
                        v-if="option.optionImg"
                        :key="indexImg"
                        class="option-img"
                        :src="option.optionImg"
                        @click="optionImgClick(ques,option,indexImg)">
                    </div>
                    <div class="score-desc">
                        {{descMap[ques.answerScore]}}
                    </div>
                    <span v-if="inUseFlag" class="un-use-ui-absolute" @click="uiImgClick(-1)">
                        <span class="un-use-ui-inner">
                            未使用过
                        </span>
                    </span>
                </div>
                <div class="question-able-select-line-two" v-if="ques.scoreStyle == 1">
                    <div class="block-scroe-wrapper">
                        <div
                         v-for="(optionBlock,indexBlock) in ques.options"
                         :class="{
                             'block-item':true,
                             'block-item-active1': ques.answerScore !== undefined && optionBlock.optionScore < 7 && ques.answerScore >= optionBlock.optionScore,
                             'block-item-active2': ques.answerScore !== undefined && optionBlock.optionScore >= 7 && optionBlock.optionScore < 9 && ques.answerScore >= optionBlock.optionScore,
                             'block-item-active3': ques.answerScore !== undefined && optionBlock.optionScore >= 9 && ques.answerScore >= optionBlock.optionScore

                         }"
                        :key="optionBlock.optionScore"
                        @click="optionBlockClick(ques,optionBlock,indexBlock)">
                          {{optionBlock.optionScore}}
                        </div>

                    </div>
                    <div class="block-scroe-desc">
                        <div>
                            不愿推荐
                        </div>
                        <div>
                            非常推荐
                        </div>
                    </div>
                </div>
                <template
                    v-for="(optionItem,indexOptions) in ques.options">
                   <div
                    v-if="optionItem.optionScore == ques.answerScore && optionItem.part"
                    :key="indexOptions">
                        <div
                        class="question-able-options-line">
                            <template v-if="optionItem.part">
                                <div
                                 v-for="(p,indexPart) in optionItem.part"
                                 :key="indexPart"
                                 :class="{
                                    'option-item-wrapper':true,
                                    'option-item-active':ques.answerOptionPart && ques.answerOptionPart.some((partIt) => partIt.clickOptionId == p.optionId)
                                }"
                                @click="optionTextClick(ques,optionItem,p)">
                                    {{p.option}}
                                </div>
                            </template>
                        </div>
                        <div
                        v-if="ques.answerOptionPart && ques.answerOptionPart.some((partIt) => partIt.option == '其他')"
                        class="remark">
                            <el-input
                                type="textarea"
                                :rows="3"
                                placeholder="请输入内容"
                                maxlength="200"
                                :show-word-limit="true"
                                v-model="ques.remark">
                            </el-input>
                        </div>
                    </div>

                </template>

                <div class="question-able-select-line" style="margin-top: 12px;" v-if="ques.scoreStyle == 0">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="告诉我们真实的原因，我们将为您提供更好的服务"
                        maxlength="200"
                        :show-word-limit="true"
                        v-model="ques.remark">
                    </el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer-nps">
            <!-- <div v-loading="loading" class="submit-bottom-ui" @click="submitHandle">提交</div> -->
            <el-button :disabled="questionList.some(item => item.scoreStyle !== 0 && item.answerScore === undefined)" type="success" class="submit-bottom-ui" v-loading="loading" @click="submitHandle" round>提交</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import { bindEvents } from '../../util/sensor-data';
import { W2_CLICK_EVALUATE_CLOSE,W2_CLICK_EVALUATE_SUBMIT,W2_VIEWSCREEN_EVALUATE} from "@/config";
import {SELECTED_LIST, UNSELECTED_LIST,SELECTED_MAP,UNSELECTED_MAP,VERSION,DESC_MAP} from './const';

export default {
    props:{
        title:{
            type:String,
        },
        sceneId:{
            type:Number
        },
        courseId:{
            type:Number
        },
        npsShowType:{ //1不展示ui
            type:Number
        },
        resourceId:{
            type:Number
        }
    },
    data() {
        return {
            npsTitle: '',
            alertVisible:false,
            uiVisible:true,
            unSelectUrlListUI:[...UNSELECTED_LIST],
            descMap:Object.freeze(DESC_MAP),
            questionList:[],
            inUseFlag:false,
            hasNps:1,
            allowShow:0,
            evaluationStatus:1,
            productType:'',
            projectId:'',
            loading:false
        }
    },
    computed: {},
    mounted() {
        this.getQuestionList();
    },
    methods: {
        getCacheDate(){
            const date = new Date();
            const year = date.getFullYear() + ''; //获取当前日期的年份
            const month = date.getMonth() + ''; //获取当前日期的月份
            const day = date.getDate() + ''; //获取当前日期的日
            return year + month + day;
        },
        closeHandle() {

            bindEvents(W2_CLICK_EVALUATE_CLOSE, {evaluate_type:'NPS'});
            this.alertVisible = false;
            if(this.npsShowType === 1 && this.sceneId === 1){
                localStorage.setItem('nps_net_course_close',this.getCacheDate());
            }
        },
        async getQuestionList(){
            if(this.npsShowType === 1 && this.sceneId === 1 && localStorage.getItem('nps_net_course_close') === this.getCacheDate()){
                return;
            }
            let res = await this.$http.evaluationQList({
                sceneId:this.sceneId,
                courseId:this.courseId,
                productType: this.sceneId === 14? 5 : undefined,
                version:VERSION,
                businessUnitId:this.resourceId ? this.resourceId : undefined
            });
            if(!res || res.status !== 0 || !res.result){
                return;
            }
            this.npsTitle = res.result.titleDescription;
            this.hasNps = res.result.hasNps;
            this.allowShow = res.result.allowShow || 0;
            this.evaluationStatus = res.result.evaluationStatus;
            this.productType = res.result.productType;
            this.projectId = res.result.projectId;
            if((this.hasNps === 1 || this.npsShowType === 1) && this.allowShow){
                this.alertVisible = true;
                bindEvents(W2_VIEWSCREEN_EVALUATE,{evaluate_type:"NPS"})
            }
            const questions = res.result.questions
            if(questions && questions.length){
                this.questionList = questions.map((item) => {
                    if(item.scoreStyle == 2){//注入选项使用的图片
                        const options = item.options;
                        if(options && options.length){
                            item.options = options.map((option) => {
                                const optionScore = option.optionScore;
                                if(optionScore === -1){
                                    this.inUseFlag = true;
                                }
                                const imgUrl = UNSELECTED_MAP[`${optionScore}`];
                                option.optionImg = imgUrl;
                                if(Array.isArray(option.part)){
                                    option.part = option.part.map((p) => {
                                        return {
                                            ...p
                                        }
                                    });
                                }
                                return{
                                    ...option
                                }
                            });
                        }
                    }
                    return {
                        ...item,
                        answerScore:undefined,
                        answerOptionPart:undefined,
                        remark:undefined,
                        imgOptionUrlList:[...UNSELECTED_LIST]
                    }
                });
            }
        },
        //图片选项点击
        optionImgClick(ques,option){
            ques.answerScore = undefined;
            ques.answerOptionPart = undefined;
            ques.remark = undefined;
            ques.options = ques.options.map((optionA) => {
                const optionScore = optionA.optionScore;
                const imgUrl = UNSELECTED_MAP[`${optionScore}`]
                optionA.optionImg = imgUrl;
                if(optionA.optionScore === option.optionScore){
                    optionA.optionImg =  SELECTED_MAP[`${optionScore}`];
                }
                return{
                    ...optionA
                }
            });
            if(ques.answerScore === option.optionScore){
                ques.answerScore = undefined
            }else{
                ques.answerScore = option.optionScore;
            }

        },
        optionBlockClick(ques,option,index){
            ques.answerScore = option.optionScore;
            ques.answerOptionPart = undefined;
            ques.remark = undefined;
        },
        optionTextClick(ques,option,p){
            let answerOptionPart = ques.answerOptionPart || [];
            const finded = answerOptionPart.find((item) => item.clickOptionId == p.optionId);
            if(!finded){
                answerOptionPart.push({
                    clickOptionId:p.optionId,
                    isOther:p.isOther,
                    option:p.option
                });
            }else{
                answerOptionPart = answerOptionPart.filter((item) => item.clickOptionId != p.optionId)
            }
            ques.answerOptionPart = answerOptionPart;
        },
        uiImgClick(score){
            if(this.questionList && this.questionList[0]){
                const ques = this.questionList[0];
                const opSelected = ques.options && ques.options.find((o) => o.optionScore === score);
                this.optionImgClick(ques,opSelected);
                if(score === -1){
                    this.submitHandle();
                }else{
                    this.alertVisible = true;
                    bindEvents(W2_VIEWSCREEN_EVALUATE,{evaluate_type:"NPS"})
                }
            }
        },

        //问题提交
        async submitHandle() {
            bindEvents(W2_CLICK_EVALUATE_SUBMIT, {evaluate_type:"NPS"});
            if(this.loading){
                return;
            }
            if(this.questionList && this.questionList.length > 0){
                for(let i = 0; i < this.questionList.length; i++){
                    const currentScore = this.questionList[i].answerScore;
                    const scoreStyle = this.questionList[i].scoreStyle;
                    if (scoreStyle !== 0) {
                        if(currentScore === undefined){
                            return this.$message.warning('请给所有题目打分');
                        }
                        const answerPartList = this.questionList[i].answerOptionPart || [];
                        const partList = this.questionList[i].options.find(item => item.optionScore === currentScore).part;
                        if (answerPartList.length === 0 && partList) {
                            return this.$message.warning('至少选择一个选项');
                        }
                        const otherItem = answerPartList.find(item => item.isOther === 1);
                        if (otherItem && !this.questionList[i].remark) {
                            return this.$message.warning('补充内容不能为空');
                        }
                    }
                }
            }
            const evaluationContent = [];
            this.questionList.forEach((question) => {
                evaluationContent.push(question.scoreStyle === 0? {
                    questionId: question.questionId,
                    appraise: question.remark,
                } : {
                    questionId:question.questionId,
                    score:question.answerScore,
                    optionPart:question.answerOptionPart ? question.answerOptionPart.map((part) => {
                        const res = {
                            clickOptionId: part.clickOptionId
                        }
                        if(part.isOther){
                            res.remark = question.remark;
                        }
                        return res;
                    }) : undefined
                });
            });
            const params = {
                sceneId:this.sceneId,
                entrance:1,
                projectId:this.projectId,
                courseId:this.courseId,
                productType:this.productType,
                businessUnitId: this.resourceId ? this.resourceId : undefined,
                deviceType:'pc',
                version:VERSION,
                evaluationContent
            };
            this.loading = true;
            let res = await this.$http.evaluationSave(params);
             this.loading = false;
            if(res && res.status === 0){
                this.alertVisible = false;
                this.uiVisible = false;
            }else{
                this.$message({ message: res.message, type: 'error', customClass: 'message-style'});
            }
        }
    },
}
</script>

<style lang="less" scope>
.nps-report-all-wrapper{
    position: absolute;
    right: 0px;
    top: 0px;
    border: 1px solid #ebebec;
    z-index: 2002;
    .nps-report-ui-wrapper{
        height: 32px;
        background: #FFFFFF;
        display: flex;
        padding: 0px 12px;
        align-items: center;
        .title{
            color: #344563;
            font-size: 14px;
        }
        .img-wrapper{
            display: flex;
            .face-img{
                display: block;
                margin-left: 12px;
                width: 22px;
                height: 22px;
                cursor: pointer;
            }

        }
        .un-use-ui{
            color: #606266;
            font-size: 12px;
            border-bottom: 1px solid #303133;
            margin-left: 12px;
            cursor: pointer;
        }
    }

}
.nps-dialog .el-dialog{
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    .dialog-footer-nps{
        display: flex;
        justify-content: flex-end;
        .submit-bottom-ui{
            width: 120px;
            // height: 36px;
            // line-height: 36px;
            // background: #00C650;
            border-radius: 20px;
            // color: #FFFFFF;
            text-align: center;
            cursor: pointer;
        }
    }

}
.evalute-list-content-wrapper{
        .question-item-wrapper{
            margin-bottom: 12px;
            .title-ques{
                font-weight: 500;
                color: #000000;
                height: 20px;
                line-height: 20px;
            }
            .question-able-select-line{
                display: flex;
                align-items: center;
                margin-top: 2px;
                position: relative;
                .imgs-wrapper{
                    display: flex;
                    padding: 4px 0px;
                    .option-img{
                        display: block;
                        width: 24px;
                        height: 24px;
                        margin-right: 12px;
                    }
                }
                .score-desc{
                    font-weight: 500;
                    color: #000000;
                }
                .un-use-ui-absolute{
                    position: absolute;
                    display: inline;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-left: 12px;
                    cursor: pointer;
                    .un-use-ui-inner{
                        color: #606266;
                        font-size: 12px;
                        border-bottom: 1px solid #303133;
                    }
                }


            }
            .question-able-select-line-two{
                display: inline-flex;
                flex-direction: column;
                margin-top: 6px;
                .block-scroe-wrapper{
                    display: flex;
                    .block-item{
                        width: 32px;
                        height: 32px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #E6E6E6;
                        border-right: 0px;
                        cursor: pointer;
                        &:last-child{
                            border-right: 1px solid #E6E6E6;
                        }
                    }
                    .block-item-active1{
                        border-color: #00993E;
                        background: #00993E;
                        border: 0px;
                    }
                    .block-item-active2{
                        border-color: #00B348;
                        background: #00B348;
                        border: 0px;
                    }
                    .block-item-active3{
                        border-color: #00CC52;
                        background: #00CC52;
                        border: 0px;
                    }

                }
                .block-scroe-desc{
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                }
            }
            .question-able-options-line{
                display: flex;
                flex-wrap: wrap;
                padding: 6px 0px;
                .option-item-wrapper{
                    display: flex;
                    align-items: center;
                    padding: 0px 12px;
                    height: 32px;
                    border-radius: 4px;
                    margin-right: 12px;
                    background: #e4e7ed;
                    cursor: pointer;
                    margin-bottom: 6px;
                    &:last-child{

                        margin-right: 0px;
                    }

                }
                .option-item-active{
                    background-color: #cef5d9;
                    color: #00C650;
                }
                .remark{
                    width: 90%;
                }
            }

        }

    }

</style>