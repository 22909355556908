import {OUTLINE_TABS, SYLLABUS_ATTR, SYLLABUS_ITEMS, ACTIVE_SYLLABUS, ACTIVE_GRADATION,SAVE_DEVELOP} from '../mutation-types';
import {reqHermesLiveByHermesLiveId} from '../../api/outline';

const state = {
    outlineTabs: [],   //课程的阶段 所包含的大纲id
    syllabusAttr: '',   //大纲的基本属性
    syllabusItems: [],   //大纲的条目
    activeSyllabusId: '',  //当前激活的大纲
    activePhase: '',  //当前激活的阶段id#名
    activeGradation: '' ,//当前激活的阶段
    isDevelop:'',// 当前大纲是否展开
}

const actions = {
    saveOutlinetabs({commit, state}, data) {
        commit(OUTLINE_TABS, data)
    },
    saveSyllabusIndexAttr({commit, state}, data) {
        commit(SYLLABUS_ATTR, data)
    },
    saveSyllabusIndexTrees({commit, state}, data) {
        let syllabusData = [...data];
        // for (var i in syllabusData) {
        //     syllabusData[i].chapterCollapse = true;
        //     for (var j in syllabusData[i]['children']) {
        //         syllabusData[i]['children'][j].sectionCollapse = true;
        //     }
        // }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //保存当前激活的大纲
    saveActiveSyllabusId({commit, state}, data) {
        commit(ACTIVE_SYLLABUS, data)
    },
    //保存当前激活的阶段
    saveActiveGradation({commit, state}, data) {
        commit(ACTIVE_GRADATION, data)
    },
    //收起所有节
    CloseAllSections({commit, state}, index) {
        let syllabusData = [...state.syllabusItems];
        syllabusData[index].chapterCollapse = false;
        for (var i in syllabusData[index].children) {
            syllabusData[index].children[i].sectionCollapse = false;
        }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    // 展开所有节
    OpenAllSections({commit, state}, index) {
        let syllabusData = [...state.syllabusItems];
        syllabusData[index].chapterCollapse = true;
        for (var i in syllabusData[index].children) {
            syllabusData[index].children[i].sectionCollapse = true;
        }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //收起节
    CloseSection({commit, state}, data) {
        let syllabusData = [...state.syllabusItems];
        let isAllClose = true;  //是否全部收起
        syllabusData[data.fIndex]['children'][data.sIndex].sectionCollapse = false;
        for (var i in syllabusData[data.fIndex]['children']) {
            if (syllabusData[data.fIndex]['children'][i].sectionCollapse == true) {
                isAllClose = false;
            }
        }
        if (isAllClose) {//如果全部收起，则本章全部收起
            syllabusData[data.fIndex].chapterCollapse = false;
        }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //展开节
    OpenSection({commit, state}, data) {
        let syllabusData = [...state.syllabusItems];
        syllabusData[data.fIndex].chapterCollapse = true;
        syllabusData[data.fIndex]['children'][data.sIndex].sectionCollapse = true;
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //收起两层的节
    CloseTwoSection({commit, state}, index) {
        let syllabusData = [...state.syllabusItems];
        syllabusData[index].chapterCollapse = false;
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //展开两层的节
    OpenTwoSection({commit, state}, index) {
        let syllabusData = [...state.syllabusItems];
        syllabusData[index].chapterCollapse = true;
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    // 保存大纲是否展开
    saveDevelop(count,data){
        count.commit(SAVE_DEVELOP,data);
    },
    //获取hermesLive状态
    getHermesLiveDetail({commit, state}, hermesLiveId) {
        return new Promise(async (resolve) => {
            let response = {
                status: 0,
                data: null,
            };

            try {
                response = await reqHermesLiveByHermesLiveId(hermesLiveId);
            } catch (e) {
                response.status = 1;
                response.data = null;
            }

            resolve(response.data);
        });
    },
}

const mutations = {
    [OUTLINE_TABS](state, data) {
        state.outlineTabs = data;
    },
    [SYLLABUS_ATTR](state, data) {
        state.syllabusAttr = data;
    },
    [SYLLABUS_ITEMS](state, data) {
        state.syllabusItems = data;
    },
    [ACTIVE_SYLLABUS](state, data) {
        state.activeSyllabusId = data;
    },
    [ACTIVE_GRADATION](state, data) {
        state.activePhase = data
        state.activeGradation = data.split("#")[0];
    },
    [SAVE_DEVELOP](state, data) {
        state.isDevelop = data;
    }
}

export default {
    state,
    actions,
    mutations,
}
