<template>
    <div class="w_1090 about-course-box">
        <div class="contentBox course-introduce" v-if="brief_introduction">
            <div class="box-title">
                <span class="box-name black_16">{{ $t('home.aboutThisCourse') }}</span>
            </div>
            <el-row>
                <el-col :span="24">
                    <div class="about-content">
                        <div class="gray_14" v-html="brief_introduction"></div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="course-resource" v-if="resourceIntroFlag">
            <el-row :gutter="20">
                <el-col :span="8" v-for="(item,index) in resourceIntro" :key="index">
                    <div class="resourceitem">
                        <div class="restit black_20">{{item.title}}</div>
                        <div class="rescont gray_12">{{item.content}}</div>
                        <div class="item-background" :class="[item.className]">
                            <div class="semi-circle"></div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="welcome_course" v-if="!brief_introduction&&!resourceIntroFlag">
            <div class="wel_content">
                <div class="wel_info">
                    <i class="wel_icon"></i>
                    <p class="wel_txt wel_txt_t">{{ $tc('home.welcome', 1) }}</p>
                    <p class="wel_txt wel_txt_b">{{ $tc('home.welcome', 2) }} {{course_name}}</p>
                    <i class="icon_sanjiao"></i>
                </div>
            </div>
        </div>
        <div class="about-course-t">
            <button data-ripple="ripple" class="start-study-btn" @click="goOutline">{{ $t("home.startLearning") }}</button>
        </div>
        <!-- <NpsReport title="评价一下【提问】" :npsShowType="1" :sceneId="sceneId" :courseId="Number($route.params.cid)"/> -->

    </div>
</template>
<script>
import { getCourseResourceIntro } from "../../api/home.js";
import { mapState } from "vuex";
import NpsReport from "../../components/nps-report/NpsReport.vue";
import {SCENEINFO_MAP} from "../../components/nps-report/const";
import { W2_VIEWSCREEN_COURSEHOME,W2_CLICK_STARTSTUDY} from "@/config"
import { bindEvents } from "@/util/sensor-data.js";
 
export default {
    components: {
        NpsReport
    },
    data() {
        return {
            resourceIntro: [], //资源介绍
            resourceIntroFlag: true,
            sceneId:SCENEINFO_MAP.homePage
        };
    },
    computed: {
        ...mapState({
            brief_introduction: state => {
                return state.home.brief_introduction;
            },
            course_name: state => {
                return state.home.course_name;
            }
        }),
        course_id() {
            return this.$route.params.cid;
        },
        vid(){
            return this.$route.query.vid || '';
        },
        refSite() {
            //领扣标示
            return this.$route.query.refSite || '';
        }
    },
    mounted() {
        bindEvents(W2_VIEWSCREEN_COURSEHOME)
    },
    methods: {
        async getCourseResourceIntro() {
            let course_id = this.course_id;
            let ret = await getCourseResourceIntro(course_id);
            if (ret.status == 0) {
                let resourceIntro = ret.result;
                let num = 1;
                for (var i = 0; i < resourceIntro.length; i++) {
                    resourceIntro[i].className = "itembg" + num;
                    num++;
                    if (num == 4) {
                        num = 1;
                    }
                }
                this.resourceIntro = resourceIntro;
                if (!resourceIntro || resourceIntro.length == 0) {
                    this.resourceIntroFlag = false;
                } else {
                    this.resourceIntroFlag = true;
                }
            }
        },
        goOutline() {
            bindEvents(W2_CLICK_STARTSTUDY)
            this.$router.push({
                path: `/syllabus/${this.course_id}`,
                query:{
                    vid: this.vid,
                    refSite: this.refSite
                }
            });
        }
    },
    created() {
        this.getCourseResourceIntro();
    }
};
</script>
<style lang="less" scoped>
.course-introduce{
    margin-bottom: 20px;
}
.about-course-t{
    margin-top: 60px;
}
</style>

