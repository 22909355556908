<template>
    <div class="syllabus-tree-area">
        <div class="syllabus-tree-scroll-box" id="syllabus-tree-scroll-box">
            <div class="syllabus-tree-content">
                <template v-for="item in list">
                    <resource-node
                        v-if="+item.is_resource === 1"
                        :data="item"
                        v-bind="$attrs"
                        v-on="$listeners" />
                    <common-node
                        v-else
                        :data="item"
                        v-bind="$attrs"
                        v-on="$listeners" />
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../assets/css/syllabus/syllabus-tree.less';
import commonNode from './common-node.vue';
import resourceNode from './resource-node.vue';

export default {
    components: {
        commonNode,
        resourceNode,
    },
    props: {
        list: Array,
    },
    data() {
        return {};
    },
};
</script>
