import {Arab2Chn} from './Arab2Chn';
import {fillzero} from './fillzero';
import {Resource2chn} from './Resource2chn';
import {number2DateTime} from './number2DateTime';
import {capitalize} from './capitalize';
import {secondToDate} from './secondToDate';
import {WordsLen} from './WordsLen';

export default {
    Arab2Chn, fillzero, Resource2chn, number2DateTime, capitalize, secondToDate, WordsLen
}