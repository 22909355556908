
import unselected1 from '../../assets/images/nps/unselected1.png';
import unselected2 from '../../assets/images/nps/unselected2.png';
import unselected3 from '../../assets/images/nps/unselected3.png';
import unselected4 from '../../assets/images/nps/unselected4.png';
import unselected5 from '../../assets/images/nps/unselected5.png';
import selected1 from '../../assets/images/nps/selected1.png';
import selected2 from '../../assets/images/nps/selected2.png';
import selected3 from '../../assets/images/nps/selected3.png';
import selected4 from '../../assets/images/nps/selected4.png';
import selected5 from '../../assets/images/nps/selected5.png';
export const SELECTED_LIST = [selected1,selected2,selected3,selected4,selected5];
export const UNSELECTED_LIST = [unselected1,unselected2,unselected3,unselected4,unselected5];
export const SELECTED_MAP = {
    '1':selected1,
    '2':selected2,
    '3':selected3,
    '4':selected4,
    '5':selected5
};
export const UNSELECTED_MAP = {
    '1':unselected1,
    '2':unselected2,
    '3':unselected3,
    '4':unselected4,
    '5':unselected5
};
 
export const SCENEINFO_MAP = {
    homePage : 1, // 学习系统首页
    liveCourse : 2, // 直播课
    videoCourse : 3, // 视频课
    ep3StudyPlan : 4, // ep3 学习计划
    glive2StudyPlan : 5, // glive2学习计划
    ep3StudyReport : 6, // ep3学习报告
    glive2StudyReport : 7, // glive2 学习报告
    note : 8, // 笔记
    smartAnswer : 9, // 智能答疑
    personAnswer : 10, // 人工答疑
    selfLive : 11, // 私教直播
    selfFunction : 12, // 私教功能
}

export const  VERSION = '体验监测V2.0';

export const DESC_MAP = {
    '1':'非常不满意',
    '2':'不满意',
    '3':'一般',
    '4':'满意',
    '5':'非常满意'
};