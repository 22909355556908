<template>
    <div>
        <div
            class="luca-question-container"
            v-loading="zLoading"
            :element-loading-text="loadingText"
        >

            <div class="no__content" style="border: none;" v-show="!dataList || dataList.length === 0">
                {{ $t("noContent") }}
            </div>
            <div
                class="luca-question-item"
                v-for="(item, index) in dataList"
                :key="item.id"
                @click="handleClickQuestion(item)"
            >
                <div style="flex: 1;"  >
                    <div class="luca-question-title">{{ item.askChatTitle }}</div>
                    <div class="luca-question-tip">
                        <div v-if="item.transferLaborState === 1" class="famours-teacher">名师</div>
                        <div v-if="item.questionState === 4 && item.transferLaborState === 1" class="comments-status">待回答</div>
                        <div v-if="item.questionState === 2" class="satisfied">已解决</div>
                        <div v-if="item.transferLaborState === 1 && item.questionState === 5 && item.appraiseState === 0" class="comments-status">待评价</div>
                        <div v-if="item.transferLaborState === 1 && item.appraiseState === 1" class="satisfied">已评价</div>
                        <div>
                            {{ item.createTime }}
                        </div>
                    </div>
                    <div v-if="item.readState" class="message-tip">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM7.66667 9.33333H6.33333V10.6667H7.66667V9.33333ZM7.66667 3.66667H6.33333V8.66667H7.66667V3.66667Z"
                                fill="#FF7242"
                            />
                        </svg>
                        你有一条新回答
                    </div>
                </div>
            </div>
        </div>
        <div id="micro-container"></div>
    </div>
</template>
<style lang='less'>
    #app {
        overflow-y: auto;
    }

    .luca-question-container {
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 4px;
        padding: 15px;
        background-color: #fff;
    }

    .luca-question-item {
        cursor: pointer;
        font-family: PingFang SC;
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(236, 237, 243, 1);

        .message-tip {
            margin-top: 16px;
            background-color: #fff6f6;
            padding: 5px 12px;
            color: #ff3c3c;
            border-radius: 4px;
            font-size: 12px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 8px;
                font-size: 12px;
            }
        }
        &:last-child {
            border: none;
        }
        &::before {
            content: '问';
            display: block;
            width: 20px;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #37D98C;
            background-color: #CDF6E2;
            color: #37D98C;
            border-radius: 2px;
            margin-right: 20px;
            flex-shrink: 0;
        }

        .luca-question-title {
            width: 1016px;
            font-weight: 500;
            color: #333333;
            line-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .luca-question-tip {
            margin-top: 12px;
            color: #A5A5A5;
            font-size: 12px;
            display: flex;
            align-items: center;

            .famours-teacher {
                width: 38px;
                height: 18px;
                text-align: center;
                line-height: 18px;
                border-radius: 4px;
                color: #37D98C;
                background-color: rgba(55, 217, 140, 0.25);
                margin-right: 16px;
            }

            .satisfied {
                margin-right: 16px;
                width: 38px;
                height: 18px;
                border-radius: 4px;
                border: 1px solid rgba(55, 217, 140, 1);
                padding: 2px 8px;
                color: rgba(55, 217, 140, 1);
                font-weight: 400;
                line-height: 18px;
            }

            .comments-status {
                margin-right: 16px;
                width: 38px;
                height: 18px;
                border-radius: 4px;
                background-color: rgba(247, 247, 247, 1);
                padding: 2px 8px;
                color: rgba(165, 165, 165, 1);
                font-weight: 400;
                line-height: 18px;
            }
        }
    }

</style>
<script>
import { loadMicroApp } from 'qiankun';
import { environment } from '@gaodun.com/gtools';
import { updateQuestionItemStatus} from "../../api/record.js";

export default {
    components: { },
    props: ["dataList", "zLoading", "loadingText"],
    data() {
        return { 
            answerCallback: null
        };
    },
    computed: {
        course_id() {
            return this.$route.params.cid;
        },
    },
    methods: {
        handleClickQuestion (item) {
            this.answerCallback({
                type: 'open',
                params: {
                    askId: item.askId,
                    lucaAskChatId: item.lucaAskChatId,
                    showCreateNewQaBtn: false,
                    courseId: this.$route.params.cid
                },
                callbacks: {
                    onCloseDrawer: () => {
                        this.$emit('updateItem', item.id);
                    }
                },
            })
            updateQuestionItemStatus(item.id);
            this.$emit('updateItem', item.id);
        },
        initLuca() {
            this.lucaChatRef = loadMicroApp(
                {
                    name: 'luca-qa',
                    entry: `https://${environment.getApiEnv()}luca.gaodun.com`,
                    container: '#micro-container',
                    props: {
                        onReceiveMessageFromMicroApp: () => {},
                        // 注册通信微应用的方法
                        registryNoticeMicroAppFn: (callback) => {
                            this.answerCallback = callback;
                        }
                    },
                },
                // 启用严格样式隔离
                { sandbox: { strictStyleIsolation: true } }
            );
        }
    },
    mounted() {
        this.initLuca();
        console.log('luca数据', this.dataList)
    },
    beforeDestroy() {
        // this.microApp &&  this.microApp.unmount();
        this.lucaChatRef && this.lucaChatRef.unmount();
    },
};
</script>
