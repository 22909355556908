<template>
    <el-dialog class="letter" :visible.sync="letterDialog" top="10%" :close-on-click-modal="false"
               :show-close="false" :close-on-press-escape="false">
        <div>
            <div class="letter-title">
                <span class="green_20">{{nickname}}</span>
                <span class="black_20">{{$t('home.letter.welcome')}}{{course_name}}</span>
            </div>
            <div class="letter-content">
                <p class="gray_12">{{welcome_letter}}</p>
            </div>
            <div class="letter-author">
                <p class="black_16">
                    <span style="margin-right: 16px;">{{project_name}}{{$t('home.letter.teacher')}}</span>
                    <span
                        style="font-family: STXingkai,Xingkai SC;font-weight: 600;color: #000;font-size: 38px;">{{teacher_name}}</span>
                </p>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <button data-ripple="ripple" class="thanks-btn" @click="thanks">{{$t('home.letter.thank')}}</button>
        </div>
    </el-dialog>
</template>
<script>
    import {mapState} from 'vuex'

    export default {
        data() {
            return {
                dialogVisible: false,
                timer: '',
            }
        },
        mounted() {
        },
        computed: {
            ...mapState({
                letterDialog: state => {
                    return state.home.letterDialog;
                },
                course_name: state => {
                    return state.home.course_name;
                },
                welcome_letter: state => {
                    return state.home.welcome_letter;
                },
                project_name: state => {
                    return state.home.project_name;
                },
                teacher_name: state => {
                    return state.home.teacher_name;
                },
                nickname: state => {
                    return state.home.nickname;
                },

            }),
            courseId() {
                return this.$route.params.cid
            },
        },
        methods: {
            thanks() {
                this.$http.readWelcomeLetter(this.courseId);
                this.$store.dispatch('changeLetterDialog', false);
            }
        },
        created() {

        }
    }
</script>
