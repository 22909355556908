<template>
    <li :class="['aside-node', 'aside-common-node', nodeClassName]">
        <div
            :class="['aside-node-title', titleClassName]"
            :title="data.name"
            @click="onChangeCollapse"
        >
            <div
                class="aside-node-name"
                v-multiple-line-ellipsis="{ height: 40, depth: +data.depth }"
            >
                <span>{{ data.name }}</span>
            </div>
        </div>
        <el-collapse-transition v-if="isChild">
            <div v-show="isCollapse" class="aside-node-child">
                <ul class="aside-node-child-inner">
                    <template v-for="item in data.children">
                        <aside-resource-node
                            v-if="+item.is_resource === 1"
                            v-bind="$attrs"
                            v-on="$listeners"
                            :data="item"
                            :parent-id="[...parentId, item.id]"
                        />
                        <aside-common-node
                            v-else
                            v-bind="$attrs"
                            v-on="$listeners"
                            :data="item"
                            :active-key="activeKey"
                            :parent-id="[...parentId, item.id]"
                            :current-resource-parents="currentResourceParents"
                        />
                    </template>
                </ul>
            </div>
        </el-collapse-transition>
    </li>
</template>

<script>
import { bindEvents } from "../../../util/sensor-data";
import asideResourceNode from "./resource-node"; 
import { W2_STUDYVIDEO_CLICK_CATALOGUE } from "@/config"

export default {
    name: "AsideCommonNode",
    props: {
        data: Object,
        activeKey: Array,
        parentId: Array,
        currentResourceParents: Array,
    },
    components: {
        asideResourceNode,
    },
    data() {
        return {};
    },
    directives: {
        multipleLineEllipsis: {
            inserted(el, binding) {
                const {
                    value: { height, depth },
                } = binding;
                let className = el.className;
                if (el.offsetHeight <= height) {
                    return;
                }
                className += " multiple-line-ellipsis";
                el.setAttribute("class", className);
            },
        },
    },
    computed: {
        titleClassName() {
            let name = "";
            if (
                !this.isCollapse &&
                this.currentResourceParents.indexOf(this.data.id) !== -1
            ) {
                name = "active";
            }
            return name;
        },
        nodeClassName() {
            let name = "";
            if (+this.data.depth === 1) {
                name = "aside-common-node-second";
            } else if (+this.data.depth === 2) {
                name = "aside-common-node-third";
            }
            return name;
        },
        isCollapse() {
            if (!this.activeKey || +this.data.depth >= 2) {
                return true;
            }
            return this.activeKey.indexOf(this.data.id) === -1;
        },
        isChild() {
            return (
                this.data.children &&
                this.data.children.constructor === Array &&
                this.data.children.length
            );
        },
    },
    methods: {
        onChangeCollapse() {
            bindEvents(W2_STUDYVIDEO_CLICK_CATALOGUE);
            if (!this.activeKey || +this.data.depth >= 2 || !this.isChild) {
                return;
            }
            this.$emit("on-change-collapse", this.data.id);
        },
    }
};
</script>
