var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"title",rawName:"v-title"}],staticClass:"record-detail note-detail",attrs:{"data-title":_vm.$t('nodeDetail')}},[_c('div',{staticClass:"w_1090"},[_c('div',{staticClass:"returnBtn"},[_c('button',{staticClass:"return-btn",on:{"click":_vm.returnNote}},[_c('span',[_vm._v(_vm._s(_vm.$t("returnNote")))])])]),(_vm.isFlag)?[_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"box-title"},[_c('span',{staticClass:"box-name black_16"},[_vm._v(" "+_vm._s(_vm.title || _vm.$t("myNote"))+" ")]),_c('div',{staticClass:"actions"})]),_c('div',{staticClass:"box-content"},[_vm._l((_vm.data),function(item){return (_vm.note_count > 0)?[(
                                item.note_list && item.note_list.length != 0
                            )?_c('div',{staticClass:"chapter-note-box"},[_c('a',{staticClass:"noteRoute",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.openStudyPage(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('ul',{staticClass:"noteGroup"},_vm._l((item.note_list),function(obj,index){return _c('li',{key:obj.id,staticClass:"noteItems"},[_vm._v(" "+_vm._s(index + 1)+"."+_vm._s(obj.content)+" ")])}),0)]):_vm._e(),_vm._l((item.children),function(sItem){return [(
                                    _vm.max_depth == 2 &&
                                    sItem.note_list &&
                                    sItem.note_list.length != 0
                                )?_c('div',{staticClass:"chapter-note-box"},[_c('a',{staticClass:"noteRoute",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.openStudyPage(sItem)}}},[_vm._v(_vm._s(item.name)+" "),_c('span',[_vm._v("-")]),_vm._v(" "+_vm._s(sItem.name)+" ")]),_c('ul',{staticClass:"noteGroup"},_vm._l((sItem.note_list),function(obj,index){return _c('li',{key:obj.id,staticClass:"noteItems"},[_vm._v(" "+_vm._s(index + 1)+"."+_vm._s(obj.content)+" ")])}),0)]):_vm._e(),_vm._l((sItem.children),function(tItem){return (_vm.max_depth == 3)?[(
                                        _vm.max_depth == 3 &&
                                        tItem.note_list &&
                                        tItem.note_list.length != 0
                                    )?_c('div',{staticClass:"chapter-note-box"},[_c('a',{staticClass:"noteRoute",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.openStudyPage(tItem)}}},[_vm._v(_vm._s(item.name)+" "),_c('span',[_vm._v("-")]),_vm._v(" "+_vm._s(sItem.name)+" "),_c('span',[_vm._v("-")]),_vm._v(" "+_vm._s(tItem.name)+" ")]),_c('ul',{staticClass:"noteGroup"},_vm._l((tItem.note_list),function(obj,index){return _c('li',{key:obj.id,staticClass:"noteItems"},[_vm._v(" "+_vm._s(index + 1)+"."+_vm._s(obj.content)+" ")])}),0)]):_vm._e()]:_vm._e()})]})]:_vm._e()}),(_vm.note_count === 0)?_c('div',{staticClass:"gray_16",staticStyle:{"height":"500px","text-align":"center","line-height":"500px","letter-spacing":"2px"}},[_vm._v(" "+_vm._s(_vm.$t("noContent"))+" ")]):_vm._e()],2)])]:_vm._e(),(!_vm.isFlag)?[_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"gray_16",staticStyle:{"height":"500px","text-align":"center","line-height":"500px","letter-spacing":"2px"}},[_vm._v(" "+_vm._s(_vm.FalgMsg)+" ")])])])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }