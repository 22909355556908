<template>
    <div class="paper-view Integrate-paper" id="Integrate-paper">
        <div class="paper-view-head clear">
            <div class="paper-type">
                <span>综合题</span>
            </div>
            <div class="progerss-text">
                <span> (</span
                ><span class="currentnum">{{ cur_data_index }}</span
                >/<span>{{ total_data }}</span
                ><span>)</span>
            </div>
        </div>
        <div class="paper-view-body clear">
            <div class="Integrate-title">
                <Audio v-if="titleAudio" :audio-src="titleAudio"></Audio>
                <div
                    class="Integrate-title-content"
                    v-html="title"
                    @click="previewImages($event)"
                ></div>
            </div>
            <div class="middle-line"></div>
            <div class="Integrate-question">
                <div
                    class="Integrate-question-head"
                    v-if="data_child && data_child.length != 0"
                >
                    <span class="timu">题目</span>
                    <el-tabs
                        v-model="currentIndex"
                        type="card"
                        @tab-click="changeButton"
                        ref="smallQuestionTab"
                        :class="tabBtnClass"
                    >
                        <el-tab-pane
                            :label="'Q' + String(index + 1)"
                            :name="String(index + 1)"
                            v-for="(items, index) in data_child"
                            :key="index"
                        >
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="Integrate-options-content">
                    <!--  [1=> '单选题', 2=> '多选题', 3=> '判断题', 4=> '填空题', 5=> '综合题', 6=> '简答题', 7=> '不定项'] -->
                    <Single
                        v-if="cur_Sm_type == 1"
                        :titleAudio="cur_data.title_audio"
                        :type="cur_Sm_type"
                        :title="cur_data.title"
                        :checkAns="checkAns"
                        :option="cur_data.option"
                        :optionNum="cur_data.partnum"
                        :answer="answer"
                        @previewImages="previewImages"
                    ></Single>
                    <Multiple
                        v-if="cur_Sm_type == 2 || cur_Sm_type == 7"
                        :titleAudio="cur_data.title_audio"
                        :type="cur_Sm_type"
                        :title="cur_data.title"
                        :checkAns="checkAns"
                        :option="cur_data.option"
                        :optionNum="cur_data.partnum"
                        :answer="answer"
                        @previewImages="previewImages"
                    ></Multiple>
                    <TFNG
                        v-if="cur_Sm_type == 3"
                        :titleAudio="cur_data.title_audio"
                        :type="cur_Sm_type"
                        @checkAns="checkAns"
                        :title="cur_data.title"
                        :answer="answer"
                        @previewImages="previewImages"
                    ></TFNG>
                    <ShortAnswer
                        v-if="cur_Sm_type == 4 || cur_Sm_type == 6"
                        :titleAudio="cur_data.title_audio"
                        :type="cur_Sm_type"
                        @checkAns="checkAns"
                        :title="cur_data.title"
                        :answer="answer"
                        :isAnalysis="isAnalysis"
                        @previewImages="previewImages"
                    ></ShortAnswer>
                </div>
                <div class="Integrate-options-foot">
                    <span
                        class="
                            footer-btn
                            btn-next
                            comprehensive-exercise-footer-btn
                        "
                        v-show="
                            data_child &&
                            data_child.length > 0 &&
                            active != data_child.length
                        "
                        @click="nextButton"
                    ></span>
                    <span
                        class="
                            footer-btn
                            btn-prev
                            comprehensive-exercise-footer-btn
                        "
                        @click="prevButton"
                        v-show="
                            data_child && data_child.length > 0 && active != 1
                        "
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Single from "./PaperModuleSingle.vue";
import Multiple from "./PaperModuleMultiple.vue";
import TFNG from "./PaperModuleTFNG.vue";
import ShortAnswer from "./PaperModuleShortAnswer.vue";
import Audio from "./Audio.vue";

export default {
    components: {
        Single,
        Multiple,
        TFNG,
        ShortAnswer,
        Audio,
    },
    props: {
        //题型
        type: [String, Number],
        //当前小题的题型
        cur_Sm_type: [String, Number],
        //当前激活的小题索引 用于tabs切换
        active: String,
        //题干
        title: {
            type: String,
            required: true,
        },
        //答案
        answer: {
            type: [String, Array],
            required: true,
        },
        //当前第几题
        cur_data_index: [String, Number],
        //总题数
        total_data: [String, Number],
        //选择答案的回调
        checkAns: {
            type: Function,
            default: function () {},
        },
        //综合题下小题的详情
        cur_data: {
            type: Object,
            default: {},
        },
        //综合题下所有小题
        data_child: {
            type: Array,
        },
        isAnalysis: [Boolean],
        titleAudio: String,
    },
    data() {
        return {
            tabBtnClass: "right-active",
        };
    },
    computed: {
        currentIndex: {
            get: function () {
                return String(this.active);
            },
            set: function (a) {},
        },
    },
    mounted() {
        this.$nextTick(() => {
            const comprehensive = document.getElementById("Integrate-paper");
            comprehensive &&
                comprehensive.removeEventListener("click", this.setTabsBtn);
            comprehensive &&
                comprehensive.addEventListener("click", this.setTabsBtn);
        });
    },
    methods: {
        //下一题
        nextButton() {
            this.$emit("nextSmQuestion");
        },
        //上一题
        prevButton() {
            this.$emit("prevSmQuestion");
        },
        //tab切换小题
        changeButton(tab, event) {
            this.$emit("changeSmQuestion", tab.index);
        },
        previewImages(e) {
            this.$emit("previewImages", e);
        },
        setTabsBtn(e) {
            let flag = false;
            const myEvent = e;
            const myTarget = myEvent.target
                ? myEvent.target
                : myEvent.srcElement;
            const classNameArr = [
                "el-icon-prev",
                "el-icon-next",
                "el-icon-arrow-right",
                "el-icon-arrow-left",
                "el-tabs__item",
                "comprehensive-exercise-footer-btn",
            ];
            // console.log(myTarget.className);
            const myTargetClassName = myTarget.className.split(" ");
            // console.log(myTargetClassName);
            myTargetClassName.forEach((i) => {
                if (classNameArr.indexOf(i) > -1) {
                    flag = true;
                }
            });
            if (!flag) return;

            try {
                const currentOffset =
                    this.$refs.smallQuestionTab.$refs.nav.navOffset;
                const navSize =
                    this.$refs.smallQuestionTab.$refs.nav.$refs.nav[
                        `offsetWidth`
                    ];
                const containerSize =
                    this.$refs.smallQuestionTab.$refs.nav.$refs.navScroll[
                        `offsetWidth`
                    ];
                // console.log(currentOffset, navSize, containerSize);
                if (currentOffset === 0) {
                    this.tabBtnClass = "right-active";
                } else if (
                    currentOffset !== 0 &&
                    currentOffset + containerSize < navSize
                ) {
                    this.tabBtnClass = "all-active";
                } else if (
                    currentOffset !== 0 &&
                    currentOffset + containerSize >= navSize
                ) {
                    this.tabBtnClass = "left-active";
                }
            } catch (e) {
                console.log(e);
            }
        },
    },
    beforeDestroy() {
        const comprehensive = document.getElementById("Integrate-paper");
        comprehensive &&
            comprehensive.removeEventListener("click", this.setTabsBtn);
    },
};
</script>
<style lang="less">
.Integrate-paper {
    /* 综合体小题切换tab样式优化 */
    .el-tabs.el-tabs--card {
        &.right-active {
            .el-tabs__nav-next {
                color: #35c25e;
                cursor: pointer;
                i {
                    font-weight: 700;
                }
            }
            .el-tabs__nav-prev {
                color: #909399;
                cursor: default;
                i {
                    font-weight: 400;
                }
            }
        }
        &.left-active {
            .el-tabs__nav-next {
                color: #909399;
                cursor: pointer;
                i {
                    font-weight: 400;
                }
            }
            .el-tabs__nav-prev {
                color: #35c25e;
                cursor: pointer;
                i {
                    font-weight: 700;
                }
            }
        }
        &.all-active {
            .el-tabs__nav-next {
                color: #35c25e;
                cursor: pointer;
                i {
                    font-weight: 700;
                }
            }
            .el-tabs__nav-prev {
                color: #35c25e;
                cursor: pointer;
                i {
                    font-weight: 700;
                }
            }
        }
    }
}
</style>
