<template>
    <div id="app" class="test-align paper-study analysis-paper study-container">
        <div class="answer-sheet" ref="answersheet">
            <AnalysisAnswerSheet
                :close-sheet="closeSheet"
                :get-to-top="getToTop"
                :changeSheet="changeSheet"
                :cur_data_index="cur_question_index"
                :question_id="question_id"
                :cur_data="question_data"
                :recm_data="recm_question_data"
            >
            </AnalysisAnswerSheet>
        </div>
        <div class="paper-header study-container-header clear">
            <div class="paper-header-tools clear">
                <div class="header-tools-l timer">
                </div>
                <div class="header-tools-r clear">
                    <!-- <span class="calc-btn tools-btn"><i class="btn-icon calc-icon"></i><span style="vertical-align: middle">计算器</span></span> -->
                    <span class="collect-btn tools-btn active" v-if="paper_cur_favorite"
                          @click="cancelFavorite">
                        <i class="btn-icon collect-icon"></i><span style="vertical-align: middle">收藏本题</span>
                    </span>
                    <span class="collect-btn tools-btn" v-if="!paper_cur_favorite"
                          @click="favoriteQuestion">
                    <i class="btn-icon collect-icon"></i><span style="vertical-align: middle">收藏本题</span>
                </span>
                    <span class="card-btn tools-btn" @click="openSheet">答题卡</span>
                </div>
            </div>
            <el-progress class="progress-bar" :show-text="false" :percentage="0" status="success"></el-progress>
        </div>
        <div class="paper-content study-container-content clear" id="exerciseContent" v-loading="zLoading"
             :element-loading-text="loadingText">
            <div style="width: 1000px;margin: 0 auto;" class="clear"
                 v-if="question_data&&question_data.length!=0">
                <!--  [1=> '单选题', 2=> '多选题', 3=> '判断题', 4=> '填空题', 5=> '综合题', 6=> '简答题', 7=> '不定项'] -->
                <Single
                    v-if="cur_type==1"
                    :titleAudio="cur_question.title_audio"
                    :type="cur_type"
                    :title="cur_question.title"
                    :option="cur_question.options"
                    :optionNum="cur_question.total_options"
                    :answer="optionsAns"
                    :cur_data_index="cur_question_index"
                    :total_data="total_question"
                ></Single>
                <Multiple
                    v-if="cur_type==2||cur_type==7"
                    :titleAudio="cur_question.title_audio"
                    :type="cur_type"
                    :title="cur_question.title"
                    :option="cur_question.options"
                    :optionNum="cur_question.total_options"
                    :answer="optionsAns"
                    :cur_data_index="cur_question_index"
                    :total_data="total_question"
                ></Multiple>
                <TFNG
                    v-if="cur_type==3"
                    :titleAudio="cur_question.title_audio"
                    :type="cur_type"
                    :title="cur_question.title"
                    :answer="optionsAns"
                    :cur_data_index="cur_question_index"
                    :total_data="total_question"
                ></TFNG>
                <ShortAnswer
                    v-if="cur_type==4||cur_type==6"
                    :titleAudio="cur_question.title_audio"
                    :type="cur_type"
                    :title="cur_question.title"
                    :answer="optionsAns"
                    :cur_data_index="cur_question_index"
                    :total_data="total_question"
                    :isAnalysis="isAnalysis"
                ></ShortAnswer>
                <Integrate
                    v-if="cur_type==5"
                    :cur_data="cur_Sm_question"
                    :data_child="cur_question.sonitem"
                    :titleAudio="cur_question.title_audio"
                    :type="cur_type"
                    :answer="optionsAns"
                    :cur_Sm_type="cur_Sm_type"
                    :title="cur_question.title"
                    :cur_data_index="cur_question_index"
                    :total_data="total_question"
                    :active="activeQuestion"
                    @nextSmQuestion="nextSmQuestion"
                    @prevSmQuestion="prevSmQuestion"
                    @changeSmQuestion="changeSmQuestion"
                    :isAnalysis="isAnalysis"
                ></Integrate>
                <AnalysisContent
                    :user_answer="analysis_userAnswer"
                    :type="cur_type"
                    :cur_Sm_type="cur_Sm_type"
                    :yanswer="cur_question_yanswer"
                    :is_video="question_is_video"
                    :question_analysis="cur_question_analysis"
                    :video_analysis="video_analysis"
                ></AnalysisContent>
                <AnalysisTools></AnalysisTools>
            </div>
            <div v-if="question_data&&question_data.length==0"
                 style="width: 1000px;margin: 0 auto;height: 100%;font-size: 16px;line-height: 400px;text-align:center;color: #b0b2b7;"
            >暂时木有内容哦～
            </div>
        </div>
        <AnalysisFoot
            v-if="question_data&&question_data.length!=0"
            :getToTop="getToTop"
            :next-button="nextButton"
            :prev-button="prevButton"
            :cur_data_index="cur_question_index"
            :total_data="total_question"
        ></AnalysisFoot>
    </div>
</template>
<style>
    .test-align.analysis-paper .el-loading-mask {
        height: 100%;
        background: #f4f7f6;
    }
    .test-align.analysis-paper .response{
        display: none;
    }
</style>
<script>
    import {getExericeRecord, getReAnalysis, getRePaperAnalysis, getFavoriteRecord} from '../../api/record.js'
    import {showBigImg} from '../../util/showBigImg.js'
    import AnalysisFoot from '../paperAnalysis/AnalysisModuleFoot.vue'
    import AnalysisAnswerSheet from '../paperAnalysis/AnalysisModuleAnswerSheet.vue'
    import AnalysisContent from '../paperAnalysis/AnalysisModuleContent.vue'
    import AnalysisTools from './RecordModuleAnalysisTools.vue'
    import Single from '../paperExercise/PaperModuleSingle.vue'
    import Multiple from '../paperExercise/PaperModuleMultiple.vue'
    import TFNG from '../paperExercise/PaperModuleTFNG.vue'
    import ShortAnswer from '../paperExercise/PaperModuleShortAnswer.vue'
    import Integrate from '../paperExercise/PaperModuleIntegrate.vue'
    import {mapState} from 'vuex';
    import Vue from '../../common/Vue.js'
    import {loadingText} from '../../common/loadingTextConfig.js'
    import { cancelFavorite } from "../../api/study.js";
    import { favoriteOrDelQuestionEP3Task } from "../../api/studyForm.js";

    export default {
        components: {
            Single,
            Multiple,
            Integrate,
            TFNG,
            AnalysisFoot,
            AnalysisAnswerSheet,
            AnalysisContent,
            AnalysisTools,
            ShortAnswer
        },
        data() {
            return {
                isResource: null,
                syllabus_id: '',   //大纲id
                course_id: '',   //课程id
                answersheet: '',  //答题卡
                // checkList:[],
                // optionsAns : '',
                stageid: '',  //阶段id
                chapterid: '',  //章节id
                cs_item_id: '',
                zLoading: false,
                loadingText: '',
                isAnalysis:true
            }
        },
        computed: {
            ...mapState({
                //当前题目类型
                cur_type: state => {
                    return state.retest.cur_type
                },
                //是否最后一题
                last_question: state => {
                    return state.retest.last_question
                },
                //当前的题目
                cur_question: state => {
                    return state.retest.cur_question;
                },
                //当前的综合题下小题
                cur_Sm_question: state => {
                    return state.retest.cur_Sm_question;
                },
                //用户的答案
                optionsAns: state => {
                    if (state.retest.cur_type == 2 ||state.retest.cur_type == 52 || state.retest.cur_type == 7 || (state.retest.cur_type == 5 && state.retest.cur_Sm_type == 2) || (state.retest.cur_type == 5 && state.retest.cur_Sm_type == 7)) {
                        return !!state.retest.cur_userAnswer.content ? state.retest.cur_userAnswer.content.split(',') : [];
                    } else {
                        return state.retest.cur_userAnswer.content;
                    }
                },
                //当前题目的排序
                cur_question_index: state => {
                    return state.retest.cur_question_index
                },
                //试卷的总题数
                total_question: state => {
                    return state.retest.total_question
                },
                //当前综合题下的题型
                cur_Sm_type: state => {
                    return state.retest.cur_Sm_type;
                },
                //当前小题的索引
                activeQuestion: state => {
                    return String(state.retest.cur_Sm_question_index);
                },
                //题目列表
                question_data: state => {
                    return state.retest.question_data;
                },
                //按照类型重组的试卷
                recm_question_data: state => {
                    return state.retest.recm_question_data;
                },
                //当前题目的解析
                cur_question_analysis: state => {
                    return state.retest.cur_question_analysis;
                },
                //当前用户的答案 解析用
                analysis_userAnswer: state => {
                    if (state.retest.cur_type == 3 || state.retest.cur_Sm_type == 3) {
                        if (state.retest.cur_userAnswer.content == '1') {
                            return '对'
                        } else if (state.retest.cur_userAnswer.content == '0') {
                            return '错'
                        } else {
                            return state.retest.cur_userAnswer.content;
                        }
                    } else {
                        return state.retest.cur_userAnswer.content;
                    }
                },
                //当前正确的答案
                cur_question_yanswer: state => {
                    if (state.retest.cur_type == 3 || state.retest.cur_Sm_type == 3) {
                        if (state.retest.cur_question_yanswer == '1') {
                            return '对'
                        } else if (state.retest.cur_question_yanswer == '0') {
                            return '错'
                        } else {
                            return state.retest.cur_question_yanswer;
                        }
                    } else {
                        return state.retest.cur_question_yanswer;
                    }
                },
                //是否可以视频解析
                question_is_video: state => {
                    return state.retest.question_is_video
                },
                //视频解析内容
                video_analysis: state => {
                    return state.retest.video_analysis
                },
                paper_cur_favorite: state => {
                    return state.retest.paper_cur_favorite;
                },
                recordType() {
                    const typeMap = {
                        0: 2,
                        1: 1,
                        2: 9,
                    };
                    return typeMap[this.$route.query.type];
                }

            }),
            question_id: {//题目的id （综合题同级id）
                get: function() {
                    if(this.$store.state.retest.question_data && this.$store.state.retest.question_data.length != 0){
                        return this.$store.state.retest.question_data[this.$store.state.retest.cur_question_index - 1].id
                    }else{
                        return '';
                    }
                },
                set: function() {}
            }

        },
        mounted() {
            this.$nextTick(() => {
                this.answersheet = this.$refs.answersheet;
                document.body.onmousedown = (e) => {
                    let ele = e.path;
                    let falg = true;
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].className == 'answer-sheet') {
                            falg = false;
                        }
                    }
                    if (falg) {
                        this.closeSheet();
                    }
                }
            })
        },
        methods: {
            //下一题
            nextButton() {
                this.$store.dispatch('nextExercise')
                this.getToTop();
                Vue.$emit('updatenote');
                //事件通知拉取常见问题
                Vue.$emit('UpdateCommQues');
                this.$nextTick(() => {
                    showBigImg();
                })
            },
            //上一题
            prevButton() {
                this.$store.dispatch('prevExercise')
                this.getToTop();
                Vue.$emit('updatenote');
                //事件通知拉取常见问题
                Vue.$emit('UpdateCommQues');
                this.$nextTick(() => {
                    showBigImg();
                })
            },
            //切换答题卡
            changeSheet(item) {
                this.question_id = item.id;
                let index = this.getQuesIndex(item.id);
                this.$store.dispatch('changeTestSheet', index);
                this.getToTop();
                Vue.$emit('updatenote');
                //事件通知拉取常见问题
                Vue.$emit('UpdateCommQues');
                this.$nextTick(() => {
                    showBigImg();
                })
            },
            //遍历题目列表拿到题目的索引
            getQuesIndex(id) {
                let paper_data = this.question_data;
                for (var i = 0; i < paper_data.length; i++) {
                    if (paper_data[i].id == id) {
                        return {question_index: i}
                    }
                }
            },
            //切换综合体下小题
            changeSmQuestion(index) {
                this.$store.dispatch('changeSmExercise', index)
                Vue.$emit('updatenote');
                //事件通知拉取常见问题
                Vue.$emit('UpdateCommQues');
            },
            //下一个综合体小题
            nextSmQuestion() {
                this.$store.dispatch('nextSmExercise');
            },
            // 上一个综合体小题
            prevSmQuestion() {
                this.$store.dispatch('prevSmExercise')
            },
            // 回到顶部
            getToTop() {
                var container = this.$el.querySelector("#exerciseContent");
                container.scrollTop = 0;
            },
            //折叠答疑卡
            closeSheet() {
                this.answersheet.style.right = '-330px'
            },
            openSheet() {
                this.answersheet.style.right = '0'
            },
            //获取课程下的做题记录
            async getExericeRecord() {
                let params = {
                    course_id: this.$route.params.cid,
                    type: this.recordType  //1表示正确，2表示错误
                }
                let ret = await getExericeRecord(params);
                if (ret.status == 0) {
                    let dataList = ret.result;
                    for (var i = 0; i < dataList.length; i++) {
                        if (dataList[i].id == this.stageid) {
                            let syllist = dataList[i].syllabus;
                            for (var k = 0; k < syllist.length; k++) {
                                if (syllist[k].id == this.chapterid) {
                                    this.cs_item_ids = syllist[k].cs_item_ids.join(',');
                                    this.getReAnalysis();
                                    return;
                                }
                            }
                        }
                    }
                }
            },
            //获取做题记录用于重新做题
            async getReAnalysis() {
                let params = {
                    course_id: this.$route.params.cid,
                    type: this.recordType  //1表示正确，2表示错误
                }
                this.loadingText = loadingText();
                this.zLoading = true;
                // let ret = await getExericeRecord(params);
                let ret = ''
                if (params.type === 1 || params.type === 2) {
                    ret = await getExericeRecord(params);
                } else {
                    ret = await getFavoriteRecord({course_id: this.$route.params.cid});
                }
                if (ret.status == 0) {
                    let dataList = ret.result;
                    for (var i = 0; i < dataList.length; i++) {
                        if (dataList[i].id == this.stageid) {
                            let syllist = dataList[i].syllabus;
                            for (var k = 0; k < syllist.length; k++) {
                                if (syllist[k].id == this.chapterid) {
                                    this.cs_item_ids = syllist[k].cs_item_ids.join(',');
                                }
                            }
                        }
                    }
                } else {
                    this.zLoading = false;
                    return;
                }

                let data = {
                    course_id: this.$route.params.cid,
                    cs_item_id: this.cs_item_ids,
                    type: this.recordType,
                }
                let res = await getReAnalysis(data);
                this.zLoading = false;
                if (res.status == 0) {
                    this.$store.dispatch('saveTestData', res.result);
                    setTimeout(() => {
                        Vue.$emit('updatenote');
                    }, 0)
                    this.$nextTick(() => {
                        showBigImg();
                    })
                }

            },
            //收藏题目
            async favoriteQuestion() {
                let course_id = this.course_id;
                let syllabus_id = this.syllabus_id;
                let paper_id = this.$store.state.retest.cur_question.paper_id;
                let params = {
                    question_id: this.$store.state.retest.cur_question_id,
                    course_id: course_id,
                    cs_item_id: this.$store.state.retest.cs_item_id, //大纲条目id
                    project_id: this.$route.query.projectId,
                    subject_id: this.$route.query.subjectId,
                    part_id: this.chapterid,
                    paper_id,
                    handle_type: 1,//1收藏，2取消收藏
                }
                if (this.$store.state.retest.cur_type == 5) {
                    params.question_id = this.$store.state.retest.cur_question.id
                }
                //{"course_id":12295,"paper_id":20640,"question_id":653113,"project_id":108,"subject_id":552,"handle_type":2,"cs_item_id":"256627"}
                let ret = await favoriteOrDelQuestionEP3Task(params);
                if (ret.status == 0) {
                    this.$message({
                        type: 'success',
                        message: '收藏成功'
                    })
                    this.$store.dispatch('favoriteExercise', true)
                } else {
                    this.$message({
                        type: 'error',
                        message: '收藏失败'
                    })
                }
            },
            //取消收藏
            async cancelFavorite() {
                let course_id = this.course_id;
                let syllabus_id = this.syllabus_id;
                let paper_id = this.$store.state.retest.cur_question.paper_id;
                let params = {
                    question_id: this.$store.state.retest.cur_question_id,
                    course_id: course_id,
                    cs_item_id: this.$store.state.retest.cs_item_id, //大纲条目id
                    project_id: this.$route.query.projectId,
                    subject_id: this.$route.query.subjectId,
                    part_id: this.chapterid,
                    paper_id,
                    handle_type: 2,//1收藏，2取消收藏
                }
                if (this.$store.state.retest.cur_type == 5) {
                    params.question_id = this.$store.state.retest.cur_question.id
                }
                let ret = await favoriteOrDelQuestionEP3Task(params);
                if (ret.status == 0) {
                    this.$message({
                        type: 'success',
                        message: '取消收藏成功'
                    })
                    this.$store.dispatch('favoriteExercise', false)
                } else {
                    this.$message({
                        type: 'error',
                        message: '取消收藏失败'
                    })
                }

            },
            //【学习记录】-交卷后的试题解析
            async getRePaperAnalysis() {
                let params = {
                    course_id: this.course_id,
                    part_id: this.chapterid,
                }
                this.loadingText = loadingText();
                this.zLoading = true;
                let ret = await getRePaperAnalysis(params);
                this.zLoading = false;

                if (ret.status == 0) {
                    this.$store.dispatch('saveTestData', ret.result);
                    Vue.$emit('updatenote');

                }
            }
        },

        created() {

            this.course_id = this.$route.params.cid;
            this.stageid = this.$route.query.stageid;
            this.chapterid = this.$route.query.chapterid;
            this.action = this.$route.query.action;
            if (this.action == 'paper_analysis') {
                //重做题目交卷后解析
                this.getRePaperAnalysis();
            } else if (this.action == 'question_analysis') {
                //题集看解析
                this.getReAnalysis();
            } else {
                this.$router.push({
                    name: 404
                })
            }

            //拉取课程信息
            this.$http.getCourseInfoAndSetting(this.course_id).then(res =>{
                if (res.status == 0) {
                    this.$store.dispatch('saveCourseInfoAndSetting', res.result);
                }
            })
        },
    }
</script>
