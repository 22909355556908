import lang from '../language/lang';

const Entry = resolve => require.ensure([], () => resolve(require('../pages/Entry.vue')), 'Entry');

const Home = resolve => require.ensure([], () => resolve(require('../pages/Home.vue')), 'Home');

const Syllabus = resolve => require.ensure([], () => resolve(require('../pages/Syllabus.vue')), 'Syllabus');

const HandoutsDownload = resolve => require.ensure([], () => resolve(require('../pages/HandoutsDownload.vue')), 'HandoutsDownload');

const KnowledgeDominoes = resolve => require.ensure([], () => resolve(require('../pages/KnowledgeDominoes.vue')), 'KnowledgeDominoes');

const VideoModulePlay = resolve => require.ensure([], () => resolve(require('../components/videoStudy/VideoModulePlay.vue')), 'VideoModulePlay');

const G2VideoPlay = resolve => require.ensure([], () => resolve(require('../components/videoStudy/G2VideoPlay.vue')), 'G2VideoPlay');

const ZhiboModuleLive = resolve => require.ensure([], () => resolve(require('../components/zhibo/ZhiboModuleLive.vue')), 'ZhiboModuleLive');

const ZhiboModulePlayback = resolve => require.ensure([], () => resolve(require('../components/zhibo/ZhiboModulePlayback.vue')), 'ZhiboModulePlayback');

const Transfer = resolve => require.ensure([], () => resolve(require('../pages/Transfer.vue')), 'Transfer');

const HandoutModuleView = resolve => require.ensure([], () => resolve(require('../components/handoutStudy/HandoutModuleView.vue')), 'HandoutModuleView');

const PaperExercise = resolve => require.ensure([], () => resolve(require('../pages/NewPaperExercise.vue')), 'PaperExercise');

const PaperAnalysis = resolve => require.ensure([], () => resolve(require('../pages/PaperAnalysis.vue')), 'PaperAnalysis');

const PaperReport = resolve => require.ensure([], () => resolve(require('../pages/PaperReport.vue')), 'PaperReport');

const Resource404 = resolve => require.ensure([], () => resolve(require('../components/Resource404.vue')), 'Resource404');

const Page404 = resolve => require.ensure([], () => resolve(require('../components/Page404.vue')), 'Page404');

const StudyRecord = resolve => require.ensure([], () => resolve(require('../pages/StudyRecord.vue')), 'StudyRecord');

const RecordExercise = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/RecordModuleExercise.vue')), 'RecordExercise');

const RecordNote = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/RecordModuleNote.vue')), 'RecordNote');

const RecordQuiz = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/RecordModuleQuiz.vue')), 'RecordQuiz');

const NoteModuleDetail = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/RecordModuleNoteDetail.vue')), 'NoteModuleDetail');

const QuizModuleDetail = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/RecordModuleQuizDetail.vue')), 'QuizModuleDetail');

const RecordModuleTestAlign = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/NewRecordModuleTestAlign.vue')), 'RecordModuleTestAlign');

const RecordModuleAnalysis = resolve => require.ensure([], () => resolve(require('../components/StudyRecord/RecordModuleAnalysis.vue')), 'RecordModuleAnalysis');

const Login = resolve => require.ensure([], () => resolve(require('../pages/Login.vue')), 'Login');

const getStudyRecordRedirect = () => {
    const lang = window.GD_LANG;
    return lang === 'zh-CN' ? '/studyrecord/:cid/exercise' : '/studyrecord/:cid/note';
}
const currentLang = lang[window.GD_LANG];

export const routes = [
    {
        path: '/',
        name: '550',
        component: Entry,
        // redirect: '/login',
        children: [
            { path: '/course/:cid', meta: { title: currentLang.route.home }, name: 'home', component: Home }, //课程首页
            { path: '/syllabus/:cid', meta: { title: currentLang.route.syllabus }, name: 'syllabus', component: Syllabus }, //课程大纲
            { path: '/glive/:cid', meta: { title: 'Glive' }, name: 'glive', component: Home }, //课程首页
            {
                path: '/studyrecord/:cid',
                meta: { title: currentLang.route.studyRecord },
                name: 'studyRecord',
                component: StudyRecord,
                redirect: getStudyRecordRedirect(),
                children: [
                    { path: 'exercise', name: 'exercise', meta: { title: '本课做题' }, component: RecordExercise },
                    { path: 'note', name: 'note', meta: { title: currentLang.thisCourseNote }, component: RecordNote },
                    { path: 'question', name: 'question', meta: { title: currentLang.thisCourseAsk }, component: RecordQuiz },
                ]
            }, //学习记录
            { path: '/note/detail/:cid', meta: { title: currentLang.nodeDetail }, name: 'noteDetail', component: NoteModuleDetail }, //学习记录 笔记详情
            { path: '/quiz/detail/:cid', meta: { title: currentLang.route.questionDetail }, name: 'quizDetail', component: QuizModuleDetail }, //学习记录 提问详情
            {
                path: '/knowledge/dominoes/:cid',
                meta: { title: currentLang.route.dominoes },
                name: 'knowledgeDominoes',
                component: KnowledgeDominoes
            }, //知识骨牌
            {
                path: '/knowledge/dominoes/details/:cid',
                meta: { title: currentLang.route.dominoesDetail },
                name: 'knowledgeDominoesDetails',
                component: KnowledgeDominoes
            }, //知识骨牌详情
            { path: '/handouts/download/:cid', meta: { title: currentLang.route.handoutsDownload }, name: 'handoutsDownload', component: HandoutsDownload }, //讲义下载
            { path: '/class/pk/:cid', meta: { title: currentLang.route.classPk }, name: 'classPk', component: Home }, //班级pk
            { path: '/study/play', meta: { title: currentLang.route.video }, name: 'studyPlay', component: VideoModulePlay }, //看视频
            { path: '/study/video', meta: { title: currentLang.route.video }, name: 'studyPlay', component: VideoModulePlay }, //看视频   //兼容处理
            { path: '/study/g2play', meta: { title: currentLang.route.video }, name: 'studyPlay', component: G2VideoPlay }, // G2视频
            { path: '/study/live_new', meta: { title: currentLang.route.video }, name: 'studyPlay', component: G2VideoPlay }, // G2视频
            { path: '/study/transfer', meta: { title: currentLang.route.transfer }, name: 'transfer', component: Transfer }, // 中转页面
            { path: '/study/legacy_live', meta: { title: currentLang.route.transfer }, name: 'transfer', component: Transfer }, // 中转页面 //兼容处理
            { path: '/study/zhibo/list', meta: { title: currentLang.route.liveList }, name: 'zhibo', component: ZhiboModuleLive }, //直播列表
            {
                path: '/study/zhibo/playback/:liveid',
                meta: { title: currentLang.route.playback },
                name: 'playback',
                component: ZhiboModulePlayback
            }, // 回放直播
            { path: '/study/handout', meta: { title: currentLang.route.handout }, name: 'studyHandout', component: HandoutModuleView }, //看讲义
            { path: '/study/lecture_note', meta: { title: currentLang.route.handout }, name: 'studyHandout', component: HandoutModuleView }, //看讲义 //兼容处理
            { path: '/study/paper/exercise', meta: { title: '学习测试' }, name: 'paperExercise', component: PaperExercise }, //试卷
            { path: '/study/paper/report', meta: { title: '试卷报告' }, name: 'paperReport', component: PaperReport }, //做题报告页面
            { path: '/study/paper/analysis', meta: { title: '试卷解析' }, name: 'paperAnalysis', component: PaperAnalysis }, //做题解析页面
            { path: '/study/resource404', meta: { title: currentLang.route.resource404 }, name: 'Resource404', component: Resource404 }, //资源404页面
        ]
    },

    { path: '/test/again/do-question', meta: { title: '学习测试' }, name: 'testAgain', component: RecordModuleTestAlign }, //学习记录 重做试卷
    { path: '/review/analysis/:cid', meta: { title: '查看解析' }, name: 'reviewAnalysis', component: RecordModuleAnalysis }, //学习记录 查看解析
    { path: '/login', meta: { title: '高顿教育 - 登录' }, name: 'login', component: Login }, //登录
    // { path: '/not-found/course/:cid', meta: { title: '404' }, name: 'notFoundCourse', component: Page404 }, //404
    { path: '*', meta: { title: '404' }, meta: { title: '404' }, name: '404', component: Page404 }, // 404
]
