var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:([{
        position: 'relative',
        width: '100%',
        minHeight: '300px',
        height: 'calc(100vh - 210px)',
    }])},[_c('div',{style:([{
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
        }])},[_c('img',{attrs:{"src":require("../../assets/images/empty.png"),"alt":""}}),(_vm.text)?_c('div',{style:([{
                textAlign: 'center',
                color: '#687583',
                marginTop: '16px',
                fontSize: '16px',
            }])},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }