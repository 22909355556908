export const WordsLen = function (v) {
    var re1 = new RegExp("<.+?>", "g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
    var msg = v.replace(re1, '');//执行替换成空字符
    if (msg.length > 100) {
        return msg.substr(0, 100) + '...';
    }
    return msg;
};


