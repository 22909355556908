<template>
    <div style="background:#f7f7f8;width: 100%;height: 100%;margin-top: -120px;">
        <div class="http404">
            <div class="pic-404">
                <img class="pic-404__parent" src="../assets/images/page404.png" alt="404">
            </div>
        </div>
        <!-- <UpgradeCourse></UpgradeCourse> -->
    </div>
</template>
<script>
// import UpgradeCourse from "../components/home/HomeMoudleUpgradeCourse.vue";
export default {
  data() {
    return {};
  },
  components:{
    //   UpgradeCourse
  },
  computed: {},
  methods: {},
  created() {}
};
</script>
