<template>
    <div class="courseHome">
        <About></About>
        <Letter></Letter>
    </div>
</template>
<script>
    import About from '../components/home/HomeModuleAbout.vue'
    import Letter from '../components/home/HomeModuleLetter.vue'
    import '../plugins/ripple/ripple.min'

    export default {
        components: {
            About, Letter
        },
        data() {
            return {}
        },
        computed: {},
        created() {
        },
        mounted() {
            this.$nextTick(function () {
                new Ripple({
                    opacity: 0.6,  //水波纹透明度
                    speed: 1,      //水波纹扩散速度
                    bgColor: "#ffffff", //水波纹颜色
                    cursor: false  //是否显示手型指针
                })
            })

        },
        methods: {
        }
    }
</script>
