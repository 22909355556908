import moment from 'moment';
import {Message} from 'element-ui';
import lang from '@/language/lang';
export const LiveStatus = {
    notStart: 1,         // 未开始
	live: 2,             // 进行中
	clipPlayback: 3,     // 精剪回放
	onlinePlayback: 4,   // 即时回放
	notPlayback: 5,      // 暂无回放
	preLive: 6           // 教室已开启 
}

const allowLiving = (liveUrl) => {
	//直播不兼容 的版本
	//增加浏览器判断
	const ua = navigator.userAgent.toLocaleLowerCase();
	if (
		(ua.indexOf('applewebkit') === -1 || ua.indexOf('edg') > -1) &&
		liveUrl.indexOf('realclass.gaodun.com') > -1
	) {
		window.open('/browser');
		return false;
	}
	return true;
};
/**
 * G2资源类型状态
 * @param {*} data 
 * 
 */
export const getLiveStatus = (data) => {
    const { resource } = data;
    switch (resource.status_of_live) {
        case LiveStatus.notStart:
            return moment(resource.start_time * 1000).format('MM-DD HH:mm');
        case LiveStatus.preLive:
        case LiveStatus.live:
            return `${moment(resource.start_time * 1000).format(
                'MM-DD HH:mm'
            )} 直播中`;
        case LiveStatus.clipPlayback:
        case LiveStatus.onlinePlayback:
            return lang[window.GD_LANG].syllabus.liveStatus.hasPlayback;
        case LiveStatus.notPlayback:
            return lang[window.GD_LANG].syllabus.liveStatus.noPlayback;
    }
}

/**
* @param {*} path : 精简回放链接
*/
export const toG2Live = (data, callback) => {
   const { resource } = data;
   switch (resource && resource.status_of_live) {
       case LiveStatus.notStart:
        Message({message: '别急，直播还未开始哦', type: 'info' })
           break;
       case LiveStatus.live:
       case LiveStatus.preLive:
           if (!allowLiving(resource.url_live_is_playing_pc)) {
               return;
           }
           window.open(resource.url_live_is_playing_pc);
           break;
       case LiveStatus.onlinePlayback:
           window.open(resource.url_live_play_back_pc);
           break;
       case LiveStatus.clipPlayback:
           if (callback) {
              callback()
           } else {
              Message({message: lang[window.GD_LANG].syllabus.liveStatus.noClipPlayback, type: 'info' })
           }
           break;
       case LiveStatus.notPlayback:
            Message({message: lang[window.GD_LANG].syllabus.liveStatus.noPlayback, type: 'info' })
           break;
       default:
            Message({message: lang[window.GD_LANG].syllabus.liveStatus.abnormalState, type: 'warning' })
   }
}