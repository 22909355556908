<template>
    <div
        id="syllabus-layout"
        class="syllabus-layout"
        v-loading.lock="fullLoading"
        :element-loading-text="$t('loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#f4f7f6"
    >
        <syllabus-gradation
            v-if="gradations && gradations.length"
            :list="gradations"
            @on-click="handleClickGradation"
        />
        <div class="syllabus-layout-main" v-if="syllabusData && syllabusData.length">
            <div class="syllabus-layout-menu">
                <syllabus-chapter
                    :list="syllabusData"
                    :active="currentChapter"
                    @on-click="handleClickChapter" />
            </div>
            <div class="syllabus-layout-content">
                <syllabus-tree
                    v-if="currentChapter.children && currentChapter.children.length"
                    :list="currentChapter.children"
                    :service-time="serviceTime"
                    :active-key="activeKey"
                    @on-click="handleClickResourceItem"
                    @on-change-collapse="onChangeCollapse"
                />
                <empty-view v-else :text="$t('syllabus.empty')" />
            </div>
        </div>
        <empty-view v-else :text="$t('syllabus.empty')" />
        <go-buy-dialog
            :is-dialog="isShowBuyDialog"
            @close="isShowBuyDialog = false" />
    </div>
</template>
<script>
import '../assets/css/syllabus.less';
import syllabusChapter from '../components/syllabus/syllabus-chapter';
import syllabusTree from '../components/syllabus/syllabus-tree';
import syllabusGradation from '../components/syllabus/syllabus-gradation';
import emptyView from '../components/syllabus/empty-view';
import goBuyDialog from '../components/syllabus/go-buy-dialog';
import lang from '../language/lang';
import { getSyllabusTree, reqResourceRepeatRecord, getSyllabusItemCorrectStatus} from "../api/study.js";
import { toG2Live, LiveStatus } from '../util/g2-live';
import {RESOURCE_PROGRESS_STATUS_ONGOING} from '@/util/keys';
import { bindEvents } from '../util/sensor-data';
import { W2_VIEWSCREEN_SYLLABUS } from '@/config'

const mapTypeForUrl = {
    'video': '/study/play',
    'lecture_note': '/study/handout',
    'legacy_live': '/study/transfer',
    'live_new': '/study/g2play'
};

export default {
    components: {
        syllabusChapter,
        syllabusTree,
        syllabusGradation,
        emptyView,
        goBuyDialog,
    },
    data() {
        return {
            loading: false,
            serviceTime: 0,
            gradations: [],
            syllabusData: [],
            currentChapter: {},
            currentGradation: undefined,
            btnLoading: false,
            activeKey: [],
            isShowBuyDialog: false,
        };
    },
    computed: {
        courseId() {
            return this.$route.params.cid;
        },
        route() {
            return this.$route;
        },
        courseCheck() {
            return this.$store.state.home.course_info.audition;
        },
        fullLoading() {
            return this.gradations === undefined || this.syllabusData === undefined || this.loading;
        },
    },
    methods: {
        async getServiceTime() {
            let response = {};
            try {
                response = await this.$http.getServiceTime();
            } catch (e) {
                console.log(e);
            }

            if (response && response.status === 0) {
                this.serviceTime = response.result * 1000;
            }
        },
        async reqCourseGradations() {
            let response = await this.$http.getCourseGradations(this.courseId);
            if (response.status !== 0) {
                this.gradations = [];
                this.syllabusData = null;
                return;
            }
            const gradations = response.result || [];
            if (!(gradations && gradations.constructor === Array && gradations.length)) {
                this.gradations = [];
                this.syllabusData = null;
                return;
            }
            this.gradations = gradations;
            let currentGradation = this.gradations.find((item) => {
                return item.syllabusId;
            });
            currentGradation = currentGradation ? currentGradation : this.gradations[0];
            const currentSyllabusId = currentGradation.syllabusId;
            if (currentSyllabusId) {
                this.reqCourseSyllabus(currentSyllabusId);
            }
            this.currentGradation = currentGradation;
        },
        loopPaperId(data, list) {
            data.forEach(item => {
                if (item.is_resource === 1) {
                    if (item.resource && item.resource.discriminator === 'paper') {
                        list.push(item.resource.paper_id);
                    }
                } else {
                    this.loopPaperId(item.children, list)
                }
            })
            return list;
        },
        async getSyllabusPaper() {
            const paperIdList = this.loopPaperId(this.currentChapter.children || [], []);
            const res = await getSyllabusItemCorrectStatus({
                course_id: this.courseId,
                paper_ids: paperIdList.join(',')
            })
            if (res.status === 0) {
                this.$store.dispatch('savePaperCorrectStatus', res.result || {});
            }
        },
        async reqCourseSyllabus(syllabysId) {
            let response = await this.$http.getCourseSyllabus(this.courseId, syllabysId);
            if (response.status !== 0) {
                this.syllabusData = null;
                this.currentChapter = null;
                return;
            }
            const syllabus = (response.result && response.result.items) || [];
            this.currentChapter = syllabus && syllabus.constructor === Array && syllabus.length ? syllabus[0] : null;
            this.getSyllabusPaper();
            this.syllabusData = syllabus;
        },
        async handleClickGradation(data) {
            if (data.id === this.currentGradation.id) {
                return false;
            }
            this.currentGradation = data;
            this.loading = true;
            await this.reqCourseSyllabus(data.syllabusId);
            this.loading = false;
        },
        handleClickChapter(data) {
            this.currentChapter = data;
            this.getSyllabusPaper();
        },
        async handleClickResourceItem(data) {
            if (this.btnLoading) {
                return;
            }
            this.btnLoading = true;
            const {resource, audition} = data;
            const {discriminator} = resource || {};
            //audition  1 可试听
            //只有video可以试听
            if (+this.courseCheck === 1 && (+audition !== 1 || discriminator !== 'video')) {
                this.btnLoading = false;
                this.isShowBuyDialog = true;
                return;
            }
            if(['lecture_note', 'soft_link'].includes(discriminator)) {
                this.resourceRepeatRecord(data)
            }
            switch (discriminator) {
                case 'video':
                case 'lecture_note':
                    this.toStudy(data, null, '');
                    break;
                case 'legacy_live':
                    this.toStudy(data, null, '&video_type=1');
                    break;
                case 'paper':
                    await this.toPaper(data);
                    break;
                case 'hermes_live':
                    await this.toHermesLive(data);
                    break;
                case 'soft_link':
                    this.toSoftLink(data);  
                    break;
                case 'live_new':
				    this.toGlive2Live(data);
                    break;
                default:
                    this.$message.info(lang[window.GD_LANG].syllabus.resourceEmpty);
                    break;
            }
            this.isShowBuyDialog = false;
            this.btnLoading = false;
        },
        toGlive2Live(data, query = '') {
            const {courseId, currentGradation, currentChapter} = this;
            const {query: {vid, refSite}} = this.route;
            const {resource: {discriminator, simplify_record_url, status_of_live}, id, course_syllabus_id, resource_id, chapter_id, teacher_id, item_id} = data;
            const prefix = mapTypeForUrl[discriminator]
            const fullPath = `${prefix}?course_id=${courseId}&gradation_id=${currentGradation.id}&syllabus_id=${course_syllabus_id}&syllabusitem_id=${item_id}&resource_id=${resource_id}&partId=${currentChapter.id}&vid=${vid}&refSite=${refSite}${query}&simplifyUrl=${simplify_record_url || ''}`;
           
            toG2Live(data, () => { this.$router.push(fullPath)});
            
            // 2-进行中 | 3-精剪回放 | 4-即时回放 | 6-教室已开启
            if ([LiveStatus.live, LiveStatus.clipPlayback, LiveStatus.onlinePlayback, LiveStatus.preLive].includes(status_of_live)) {
			    this.syncPositionAndProgress(data, discriminator, RESOURCE_PROGRESS_STATUS_ONGOING);
            }
        },
        toSoftLink(data) {
            if (!data.resource.soft_link_url) {
                return this.$message.info('当前仅支持App端查看');
            }
            this.syncPositionAndProgress(data, data.resource.discriminator, 1);
            window.open(data.resource.soft_link_url);
        },
        async syncPositionAndProgress(resource, discriminator, status, isCheckStatus = true) {
            const params = {
                course_id: +this.courseId, //课程id
                cs_item_id: +resource.id, //条目id
                resource_id: +resource.resource_id, //资源id
            };
            if (status && discriminator.indexOf('paper') < 0) {
                params.status = status;      //进度状态：1-完成；2-进行中
            }
            if (discriminator) {
                params.study_position_request = {
                    parent_gradation_id: 0,                //父阶段id
                    gradation_id: +this.gradationId,         //阶段id
                    syllabus_id: +resource.course_syllabus_id, //大纲id
                    resource: discriminator,     //资源类型标识 video、audio、hermes_live、lecture_note、legacy_live、paper/exercise、paper/report、resource_group
                    resource_name: resource.name,   //资源名称
                    chapter_id: +resource.chapter_id,   //章id
                    teacher_id: +resource.teacher_id,    //老师id
                    is_resource_group: Number(Boolean(resource.resource.is_resource_group)),  //是否资源组 0：否 1：是
                };
            }
            await this.$http.reqProgressSync(params);
            // this.reqCourseGradations();
        },
        async toPaper(data) {
            const params = {
                paperId: data.resource.paper_id
            };
            const response = await this.$store.dispatch('getExamPaperStatus', params);

            if (!response.path) {
                return this.$message(response.message || lang[window.GD_LANG].syllabus.paperError);
            }
            this.toStudy(data, response.path);
        },
        async toHermesLive(data) {
            const response = await this.$store.dispatch('getHermesLiveDetail', data.resource.hermes_live_id);
            if (!response) {
                return false;
            }

            //直播状态 1 => 未开始 2 => 去上课 3 => 本地查看回放 4 => 跳转查看回放 5 => 暂无回放
            switch (response.resource_status) {
                case 1:
                case 5:
                    this.$message(response.resource_status === 1 ? lang[window.GD_LANG].syllabus.liveNoStart : lang[window.GD_LANG].syllabus.liveNoPlayback);
                    break;
                case 2:
                case 4:
                    this.$http.reqProgressSync({
                        course_id: this.courseId,                //类型：Number  必有字段  备注：课程id
                        cs_item_id: data.item_id,                //类型：Number  必有字段  备注：条目id
                        resource_id: data.resource_id,           //类型：Number  可有字段  备注：资源id
                        status: 1,                               //类型：Number  必有字段  备注：进度状态：1-完成；2-进行中
                    });
                    window.open(response.resource_status === 2 ? response.cc_live_url : response.record_url);
                    break;
                case 3:
                    this.toStudy(data, `/study/zhibo/playback/${response.id}`, '&video_type=2');
                    break;
                default:
                    this.$message.error(lang[window.GD_LANG].networkError);
                    break;
            }
        },
        toStudy(data, path, query = '') {
            const {courseId, currentGradation, currentChapter} = this;
            const {query: {vid, refSite}} = this.route;
            const {resource: {discriminator}, id, course_syllabus_id, resource_id, chapter_id, teacher_id, item_id} = data;

            const prefix = path || mapTypeForUrl[discriminator];
            const fullPath = `${prefix}?paper_id=${data.resource.paper_id || 0}&course_id=${courseId}&gradation_id=${currentGradation.id}&syllabus_id=${course_syllabus_id}&syllabusitem_id=${item_id}&resource_id=${resource_id}&partId=${currentChapter.id}&vid=${vid}&refSite=${refSite}${query}`;
            window.open(fullPath);
        },
        onChangeCollapse(id) {
            const activeKey = [...this.activeKey];
            // this.activeKey
            const index = activeKey.indexOf(id);
            if (index > -1) {
                activeKey.splice(index, 1);
            } else {
                activeKey.push(id);
            }
            this.activeKey = activeKey;
        },
        async resourceRepeatRecord(v) {
            try {
                await reqResourceRepeatRecord({
                    courseId: this.courseId,
                    resourceId: v.resource_id,
                    learningStartTime: new Date().getTime(), 
                    learningEndTime: new Date().getTime()
                })
              } catch (e) {
                console.log(e)
            }
        }
    },
    created() {
        this.reqCourseGradations();
        this.getServiceTime();
    },
    mounted() { 
        bindEvents(W2_VIEWSCREEN_SYLLABUS);
    }
};
</script>
