import {post, get} from '../util/zeusAxios';
import {getBaseUrl} from '../util/config'

//拉取课程下所有阶段和大纲接口
export const getStageAndOutline= (course_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/course-api/course/${course_id}/gradation`, params)

//查看大纲
export const checkSyllabus = (id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/toc-service/course-syllabus/${id}`, params);

//课程首页大纲树
export const getSyllabusTree= (course_id,syllabus_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/syllabus/${syllabus_id}/tree`, params)

// 查询大纲展示状态
export const getIsDevelop = (course_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/syllabus/status`,params);

// 设置大纲展示状态
export const postIsDevelop = (course_id,params) => post(`${getBaseUrl()}apigateway.gaodun.com/study-api/course/${course_id}/syllabus/status`,params);

export const getServiceTime = () => get(`${getBaseUrl()}apigateway.gaodun.com/ep-study/service/time`);

export const reqHermesLiveDetail = (resourceId) => get(`${getBaseUrl()}apigateway.gaodun.com/hermes/live/resourceId/${resourceId}`);

//阿里云视频鉴权
export const reqAliPlayerPlayAuth = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/hermes/front/vod/check-auth`, params);

//【ep-course】获取阶段平铺数据适用于非ep3课程类型
export const getCourseGradations = (courseId, params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-course/api/v1/course/${courseId}/gradation`, params)

//【前台】根据大纲ID获取大纲条目
export const getCourseSyllabus = (courseId, syllabusId) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-study/front/course/${courseId}/syllabus/${syllabusId}`)

//根据hermes_live_id获取详情
export const reqHermesLiveByHermesLiveId = (hermesLiveId) => get(`${getBaseUrl()}apigateway.gaodun.com/hermes/live/${hermesLiveId}`);
