import { render, staticRenderFns } from "./PaperModuleSingle.vue.js?vue&type=template&id=ca10c7f0!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./PaperModuleSingle.vue?vue&type=template&id=ca10c7f0"
import script from "./PaperModuleSingle.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./PaperModuleSingle.vue?vue&type=script&lang=js"
export * from "./PaperModuleSingle.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./PaperModuleSingle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports