<template>
    <div class="paper-view short-answer">
        <div class="paper-view-head clear">
            <div class="paper-type">
                <span v-if="type == 4">填空题</span>
                <span v-if="type == 6">简答题</span>
            </div>
            <div class="progerss-text">
                <span> (</span
                ><span class="currentnum">{{ cur_data_index }}</span
                >/<span>{{ total_data }}</span
                ><span>)</span>
            </div>
        </div>
        <Audio v-if="titleAudio" :audio-src="titleAudio"></Audio>
        <div
            v-html="title"
            class="paper-title"
            @click="previewImages($event)"
        ></div>

        <div class="response">
            <el-input
                type="textarea"
                :rows="textAreaRows"
                :placeholder="placeholder"
                @input="handleTextAreaInput"
                :disabled="isAnalysis"
                v-model="computedAnswer"
            >
            </el-input>
            <div class="input-numer" id="input-numer">0/1000</div>
            <div class="input-tip" id="input-tip"></div>
        </div>
    </div>
</template>
<script>
import Audio from "./Audio.vue";

export default {
    components: {Audio},
    props: {
        //题型
        type: {
            type: [String, Number],
            required: true,
        },
        //题干
        title: {
            type: String,
            required: true,
        },
        //答案
        answer: {
            type: [String, Array],
        },
        //当前第几题
        cur_data_index: [String, Number],
        //总题数
        total_data: [String, Number],
        //选择答案的回调
        checkAns: [Function],
        isAnalysis: [Boolean],
        titleAudio: String,
    },
    data() {
        return {};
    },
    computed: {
        placeholder() {
            if (this.type == 4) {
                return "填空题不计入分数，仅做答题后解析估分之用...";
            } else if (this.type == 6) {
                return "简答题不计入分数，仅做答题后解析估分之用...";
            }
        },
        textAreaRows() {
            if (this.type == 4) {
                return 4;
            } else if (this.type == 6) {
                return 8;
            }
        },
        computedAnswer: {
            get: function () {
                return this.answer;
            },
            set: function (a) {
                this.$emit("checkAns", a);
            },
        },
    },
    watch: {
        title: function (newValue, oldValue) {
            this.handleTextAreaInput(this.answer);
        },
    },
    mounted() {
        this.$nextTick(() => {
            const el = document.querySelector("#input-numer");
            el ? (el.innerHTML = `${this.answer.length}/1000`) : "";
        });
    },
    methods: {
        previewImages(e) {
            this.$emit("previewImages", e);
        },
        handleTextAreaInput(val) {
            let self = this;
            this.$nextTick(() => {
                const element = document.querySelector("#input-tip");
                if (val.length > 1000) {
                    val = val.slice(0, 1000);
                    self.computedAnswer = val;
                    if (element) {
                        document.querySelector("#input-tip").style.display =
                            "block";
                        document.querySelector("#input-tip").innerHTML =
                            "最多可以输入1000个字!";
                    }
                } else {
                    if (element) {
                        document.querySelector("#input-tip").style.display =
                            "none";
                        document.querySelector("#input-tip").innerHTML = "";
                    }
                }
                const element2 = document.querySelector("#input-numer");
                if (element2) element2.innerHTML = `${val.length}/1000`;
            });
        },
    },
    created() {},
};
</script>
<style lang="less">
.short-answer {
    .response {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 14px;
        color: #787993;
        line-height: 20px;
        textarea {
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            border-color: transparent;
        }
        span {
            font-size: 14px;
            color: #25265e;
            font-weight: 600;
        }
        .input-numer {
            font-size: 14px;
            text-align: right;
        }
        .input-tip {
            font-size: 14px;
            color: #ff7052;
        }
        > p {
            background: #fafafa;
            font-size: 14px;
            color: #787993;
            border: 1px solid #377dff;
            padding: 20px;
            margin-top: 15px;
        }
    }
}
</style>
