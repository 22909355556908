//*************埋点事件类型定义*************/ 

// ------------------------------------------------ 课程评价 ------------------------------------------------
export const W2_VIEWSCREEN_EVALUATE   = 'W2_VIEWSCREEN_EVALUATE';                  // 评价弹窗
export const W2_CLICK_EVALUATE_SUBMIT = 'W2_CLICK_EVALUATE_SUBMIT';                // 评价点击提交
export const W2_CLICK_EVALUATE_CLOSE  = 'W2_CLICK_EVALUATE_CLOSE';                 // 评价点击关闭

// ------------------------------------------------ 课程首页 ------------------------------------------------
export const  W2_VIEWSCREEN_COURSEHOME  = 'W2_VIEWSCREEN_COURSEHOME';              // 课程首页
export const  W2_CLICK_STARTSTUDY       = 'W2_CLICK_STARTSTUDY';                   // 点击开始学习
export const  W2_CLICK_COURSEHOMETAB    = 'W2_CLICK_COURSEHOMETAB';                // 点击课程首页tab
export const  W2_CLICK_SYLLABUSTAB      = 'W2_CLICK_SYLLABUSTAB';                  // 点击课程大纲tab
export const  W2_CLICK_STUDYRECORDTAB   = 'W2_CLICK_STUDYRECORDTAB';               // 点击学习记录tab
export const  W2_CLICK_HANDOUTSDOWNLOAD = 'W2_CLICK_HANDOUTSDOWNLOAD';             // 点击讲义下载tab

// ------------------------------------------------ 课程大纲 ------------------------------------------------
export const  W2_VIEWSCREEN_SYLLABUS  =  'W2_VIEWSCREEN_SYLLABUS';         // 课程大纲页面
export const  W2_CLICK_OUTLINETAB     =  'W2_CLICK_OUTLINETAB';            // 点击切换大纲tab
export const  W2_CLICK_EPSYLLABUS     =  'W2_CLICK_EPSYLLABUS';            // 点击章节
export const  W2_CLICK_EPLIVE         =  'W2_CLICK_EPLIVE';                // 大纲-点击直播
export const  W2_CLICK_EPHANDOUT      =  'W2_CLICK_EPHANDOUT';             // 大纲-点击讲义
export const  W2_CLICK_EPPAPER        =  'W2_CLICK_EPPAPER';               // 大纲-点击试卷
export const  W2_CLICK_EPVIDEO        =  'W2_CLICK_EPVIDEO';               // 大纲-点击视频

// ------------------------------------------------ 学习记录 ------------------------------------------------
export const  W2_VIEWSCREEN_STUDYRECORDEXERCISE   = 'W2_VIEWSCREEN_STUDYRECORDEXERCISE';         // 学习记录本课做题页面
export const  W2_CLICK_COURSEEXERCISE             = 'W2_CLICK_COURSEEXERCISE';                   // 点击本课做题
export const  W2_CLICK_COURSENOTE                 = 'W2_CLICK_COURSENOTE';                       // 点击本课笔记
export const  W2_CLICK_COURSEQUESTION             = 'W2_CLICK_COURSEQUESTION';                   // 点击本课提问
export const  W2_VIEWSCREEN_COURSENOTE            = 'W2_VIEWSCREEN_COURSENOTE';                  // 本课笔记页面
export const  W2_CLICK_COURSEEXERCISE_ERRORS      = 'W2_CLICK_COURSEEXERCISE_ERRORS';            // 点击做错的题
export const  W2_CLICK_COURSEEXERCISE_RIGHT       = 'W2_CLICK_COURSEEXERCISE_RIGHT';             // 点击做对的题
export const  W2_CLICK_COURSEEXERCISE_STOREUP     = 'W2_CLICK_COURSEEXERCISE_STOREUP';           // 点击收藏的题
export const  W2_NOTE_CLICK_VIEW                  = 'W2_NOTE_CLICK_VIEW';                        // 点击查看
export const  W2_VIEWSCREEN_COURSEQUESTION        = 'W2_VIEWSCREEN_COURSEQUESTION';              // 本课提问页面
export const  W2_QUESTION_CLICK_VIEW              = 'W2_QUESTION_CLICK_VIEW';                    // 点击查看

// ------------------------------------------------ 讲义下载 ------------------------------------------------
export const  W2_VIEWSCREEN_HANDOUTSDOWNLOAD  = 'W2_VIEWSCREEN_HANDOUTSDOWNLOAD';      // 讲义下载页面
export const  W2_CLICK_DOWNLOAD               = 'W2_CLICK_DOWNLOAD';                   // 点击下载

// ------------------------------------------------   视频   ------------------------------------------------
export const  W2_VIEWSCREEN_STDUYVIDEO                     = 'W2_VIEWSCREEN_STDUYVIDEO';                       // 视频页面
export const  W2_STUDYVIDEO_CLICK_NOTE                     = 'W2_STUDYVIDEO_CLICK_NOTE';                       // 点击笔记
export const  W2_STUDYVIDEO_CLICK_QUESTION                 = 'W2_STUDYVIDEO_CLICK_QUESTION';                   // 点击提问
export const  W2_STUDYVIEDO_VIEWSCREEN_DRAWER              = 'W2_STUDYVIEDO_VIEWSCREEN_DRAWER';                // 笔记抽屉弹窗
export const W2_STUDYVIEDO_DRAWER_CLICK_NOTE               = 'W2_STUDYVIEDO_DRAWER_CLICK_NOTE';                // 点击笔记
export const W2_STUDYVIEDO_DRAWER_CLICK_QUESTION           = 'W2_STUDYVIEDO_DRAWER_CLICK_QUESTION';            // 点击提问
export const  W2_STUDYVIEDO_DRAWER_CLICK_NOTE_SUBMIT       = 'W2_STUDYVIEDO_DRAWER_CLICK_NOTE_SUBMIT';         // 点击提交笔记
export const  W2_STUDYVIEDO_DRAWER_CLICK_NOTE_ASKQUESTIONS = 'W2_STUDYVIEDO_DRAWER_CLICK_NOTE_ASKQUESTIONS';   // 点击去提问
export const  W2_STUDYVIDEO_CLICK_CATALOGUE                = 'W2_STUDYVIDEO_CLICK_CATALOGUE';                  // 点击目录
export const  W2_STUDYVIDEO_CLICK_SYLLABUSTREE             = 'W2_STUDYVIDEO_CLICK_SYLLABUSTREE';               // 点击目录树
export const  W2_STUDYVIDEO_CLICK_BACKHOME                 = 'W2_STUDYVIDEO_CLICK_BACKHOME';                   // 点击反馈课程首页
export const  W2_VIEWSCREEN_STUDYHANDOUT                   = 'W2_VIEWSCREEN_STUDYHANDOUT';                     // 讲义页面
export const  W2_VIEWSCREEN_STUDYPAPER                     = 'W2_VIEWSCREEN_STUDYPAPER';                       // 试卷页面
export const  W2_STUDYPAPER_CLICK_SUBMITPAPER              = 'W2_STUDYPAPER_CLICK_SUBMITPAPER';                // 点击交卷
export const  W2_VIEWSCREEN_PAPERREPORT                    = 'W2_VIEWSCREEN_PAPERREPORT';                      // 测试报告页面