import * as handoutsDownload from './handoutsDownload'
import * as home from './home'
import * as login from './login'
import * as outline from './outline'
import * as record from './record'
import * as study from './study'
import * as studyForm from './studyForm'
import * as download from './download'

function Service() {
    Object.keys(handoutsDownload).map(item => {
        this[item] = handoutsDownload[item]
    })
    Object.keys(home).map(item => {
        this[item] = home[item]
    })
    Object.keys(login).map(item => {
        this[item] = login[item]
    })
    Object.keys(outline).map(item => {
        this[item] = outline[item]
    })
    Object.keys(record).map(item => {
        this[item] = record[item]
    })
    Object.keys(study).map(item => {
        this[item] = study[item]
    })
    Object.keys(studyForm).map(item => {
        this[item] = studyForm[item]
    })
    Object.keys(download).map(item => {
        this[item] = download[item]
    })
}
export default new Service()
