<template>
    <div class="tools-ask-box tools-note-box">
        <div class="questiontype">
            <a href="javascript:void (0)" class="notebtn" @click="goGbot">去提问</a>
        </div>
    </div>
</template>
<script>

    import {defaultSrc} from '../../common/paperConfig.js'

    export default {
        components: {},
        props: [
            'quizList'
        ],
        data() {
            return {
                defaultSrc: defaultSrc,
            }
        },
        computed: {
            allow_question() {
                return +this.$store.state.home.course_info.allow_question === 1;
            }
        },
        mounted() {
        },
        methods: {
            getImageUrl(data) {
                data.currentTarget.src = defaultSrc;
            },
            goGbot() {
                if (!this.allow_question) {
                    return this.$message({
                        message: '您暂无权限提问'
                    })
                }
                this.$emit('goGbot');
            }
        },
        created() {

        }
    }
</script>
