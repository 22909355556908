import Vue from "../common/Vue";
import { MessageBox } from 'element-ui';

//断网提示
window.ErrorTimer = '';
export const networkMsg = () => {
    clearTimeout(window.ErrorTimer)
    window.ErrorTimer = setTimeout(() => {
        const h = Vue.$createElement;
        MessageBox({
            title: '',
            customClass: 'networkError',
            message: h('p', null, [
                h('i'),
                h('span', {style: 'font-size:20px;color:#3a3e4a;display:block;font-weight: 600;margin-bottom: 6px;'}, '网络异常'),
                h('span', {style: 'font-size:14px;color:#b0b2b7;display:block;line-height: 1.14;text-align:center;'}, '网络出现问题啦，请检查网络状态')
            ]),
            showCancelButton: false,
            showConfirmButton: false,
        })
    }, 2000)
}