<template>
    <div class="go-buy-dialog" v-show="isDialog">
        <span
            class="close-icon"
            @click="handleClose()"
        >×</span>
        <p class="go-buy-title">
            {{$t('buyPopUp.title')}}
        </p>
        <el-button
            class="go-buy-btn t-btn1"
            @click="handleClick(1)"
        >{{$t('buyPopUp.consult')}}
        </el-button>
        <el-button
            class="go-buy-btn t-btn2"
            v-show="this.vid"
            @click="handleClick(2)"
        >{{$t('buyPopUp.buy')}}
        </el-button>
    </div>
</template>

<script>
import {getEnv} from '../../util/config.js';

export default {
    name: 'GoBuyDialog',
    props: {
        isDialog: Boolean,
    },
    data() {
        return {};
    },
    computed: {
        vid() {
            return this.$route.query.vid || '';
        },
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleClick(type) {//1.咨询 2。购买
            if (type === 1) {
                window.open('//kf.gaodun.com/LR/Chatpre.aspx?id=KEV39534957&skid=14&p=http%3A//www.gaodun.com/cpa/%3Fwxkcxq');
                return;
            }
            const prefix = getEnv();
            window.open(`//${prefix}v.gaodun.com/shopping/check/cid/${this.vid}.html`);
        },
    },
};
</script>

<style lang="less">
    .go-buy-dialog {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        width: 275px;
        height: 240px;
        background: url("../../assets/images/shiting/tixing.png") no-repeat;
        background-size: cover;

        .go-buy-title {
            margin: 130px 20px 10px;
            line-height: 24px
        }

        .go-buy-btn {
            height: 30px;
            width: 112px;
            font-size: 12px;
            padding: 0;
        }


        .t-btn1 {
            margin-left: 20px;
            background: #35C25E;
            color: #fff;
        }

        .t-btn2 {
            margin-left: 8px;
            color: #35C25E;
            border-color: #35C25E;
        }

        .close-icon {
            position: absolute;
            right: 16px;
            top: 33px;
            font-size: 24px;
            color: #fff;
            cursor: pointer;
        }
    }
</style>
