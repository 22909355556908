<template>
    <div class="tools-note-box">
        <el-collapse-transition>
            <!-- <div v-show="editBoxshow">2018年3月30日14:17:06 -->
            <div>
                <el-form :model="noteFrom" ref="noteFrom" class="note-From">
                    <el-form-item class="input-form-item" prop="content" :rules="filter_rules({required:true,type:'isAllSpace',min:1,max:2000})">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" placeholder="添加笔记..." v-model="noteFrom.content">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item class="btn-form-item">
                        <el-button type="primary" size="small" class=" submit-note" v-show="noteList.length>0"
                                   @click="cancelEdit('noteFrom')">取消编辑
                        </el-button>
                        <el-button type="primary" size="small" class=" submit-note" :loading="btnLoading"
                                   v-if="noteList.length==0"
                                   @click="submitForm('noteFrom')">{{btnLoading?'提交中...':'提交笔记'}}
                        </el-button>
                        <el-button type="primary" size="small" class=" submit-note" :loading="btnLoading"
                                   v-if="noteList.length>0"
                                   @click="saveForm('noteFrom')">{{btnLoading?'保存中...':'保存笔记'}}
                        </el-button>
                    </el-form-item> -->
                    <el-form-item class="btn-form-item">
                        <el-button type="primary" size="small" class=" submit-note" @click="cancelEdit('noteFrom')">清空
                        </el-button>
                        <el-button type="primary" size="small" class=" submit-note" :loading="btnLoading" @click="submitForm('noteFrom')">{{btnLoading?'提交中...':'提交笔记'}}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-collapse-transition>
        <ul class="note-list">
            <li class="note-items" v-for="(item,index) in noteList" :key="item.id">

                <div class="user_icon">
                    <img :src="item.avatar?item.avatar:defaultSrc" @error="getImageUrl" alt="">
                </div>
                <div class="user_name">
                    <span>{{item.nickname}}</span>
                </div>
                <div class="user_note" v-show="!item.status">
                    <p>{{item.content}}</p>
                </div>

                <el-form :model="editFrom" v-if="item.status" ref="editFrom" class="note-From">
                    <el-form-item class="input-form-item" prop="content" :rules="filter_rules({required:true,type:'isAllSpace',min:1,max:2000})">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" placeholder="添加笔记..." v-model="editFrom.content">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="user_actions">

                    <template v-if="!item.status">
                        <button class="node_btn delete-btn" @click="deleteinfo(item.id)">删除</button>
                        <button class="node_btn edit-btn" @click="openEditBox(index,item.id)">编辑</button>
                    </template>
                    <template v-if="item.status">
                        <button class="node_btn finsh-btn" @click="saveForm('editFrom')">完成</button>
                        <button class="node_btn cancale-btn" @click="cancelUpdate(index)">取消</button>
                    </template>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { defaultSrc } from "../../common/paperConfig.js";
import Vue from "../../common/Vue.js";
import { callExpression } from "babel-types";

export default {
    components: {},
    props: ["noteList", "editBoxshow", "btnLoading"],
    data() {
        return {
            // btnLoading: false, //按钮loading
            noteFrom: {
                content: ""
            },
            editFrom: {
                content: ""
            },
            // noteList: [],//笔记列表
            // editBoxshow: false, //编辑框展示
            editNoteId: "", //编辑笔记的id
            defaultSrc: defaultSrc
        };
    },
    computed: {},
    mounted() {
        Vue.$on("clearContent", () => {
            this.resetForm("noteFrom");
        });
    },
    methods: {
        getImageUrl(data) {
            data.currentTarget.src = defaultSrc;
        },
        //重置表单
        resetForm(formName) {
            this.$refs[formName] && this.$refs[formName].resetFields();
        },
        //提交笔记
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$emit("addNote", this.noteFrom.content);
                } else {
                    return false;
                }
            });
        },
        //保存笔记
        saveForm(formName) {
            this.$refs[formName][0].validate(valid => {
                if (valid) {
                    this.$emit(
                        "updatenote",
                        this.editFrom.content,
                        this.editNoteId
                    );
                    return true;
                } else {
                    return false;
                }
            });
        },
        //取消编辑
        cancelEdit() {
            // this.$emit('showEditBox',false);
            this.resetForm("noteFrom");
            // this.editBoxshow = false;
        },
        //打开编辑框
        openEditBox(index, id) {
            var flag = true;
            for (var i = 0; i < this.noteList.length; i++) {
                if (this.noteList[i].status == true) {
                    flag = false;
                    this.$confirm("是否保存提交笔记？", "", {
                        customClass: "leaveTip",
                        showCancelButton: true,
                        showConfirmButton: true,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        cancelButtonText: "不保存",
                        confirmButtonText: "保存",
                        center: true,
                        callback: (action, instance) => {
                            if (action === "confirm") {
                            } else if (action === "cancel") {
                                this.$emit("showEditBox", index);
                                this.editNoteId = id;
                                this.editFrom.content = this.noteList[
                                    index
                                ].content;
                            }
                        },
                        beforeClose: (action, instance, done) => {
                            if (action === "confirm") {
                                instance.confirmButtonLoading = true;
                                instance.confirmButtonText = "保存中...";
                                this.$refs["editFrom"][0].validate(valid => {
                                    if (valid) {
                                        this.$emit(
                                            "updatenote",
                                            this.editFrom.content,
                                            this.editNoteId
                                        );
                                        Vue.$on("confirmSuccess", () => {
                                            done();
                                            instance.confirmButtonLoading = false;
                                        });
                                    } else {
                                        this.$message({
                                            type: "warning",
                                            message: "笔记输入有误，请检查！"
                                        });
                                        done();
                                        instance.confirmButtonLoading = false;
                                    }
                                });
                            } else {
                                done();
                            }
                        }
                    });
                    return;
                }
            }
            if (flag) {
                this.$emit("showEditBox", index);
                this.editNoteId = id;
                this.editFrom.content = this.noteList[index].content;
            }
        },
        //取消编辑
        cancelUpdate(index) {
            this.$emit("cancelUpdate", index);
        },
        //删除笔记按钮
        deleteinfo(id) {
            this.$emit("deleteinfo", id);
        }
    },
    created() {}
};
</script>
