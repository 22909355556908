import { getPaperStatusEp3Task } from '../api/study.js'
export function padding(v) {
    let value = v + '';
    if (value.length < 2) {
        value = '0' + value;
    }
    return value;
}
export function format(d) { //时间转换 => YYYY-MM-dd
    return !!d && typeof d !== 'string' ? (d.getFullYear() + '-' + padding(d.getMonth() + 1) + '-' + padding(d.getDate())) : d;
}

export const fillZero = n => n < 10 ? '0' + n : '' + n;

export function number2DateTime(value, fmt = 'yyyy-MM-dd HH:mm:ss') { // yyyy-MM-dd HH:mm:ss
    if (isNaN(value)) return '';
    const date = new Date(Number(value));
    const year = date.getFullYear();
    const month = fillZero(date.getMonth() + 1);
    const day = fillZero(date.getDate());
    const hour = fillZero(date.getHours());
    const minute = fillZero(date.getMinutes());
    const second = fillZero(date.getSeconds());
    return fmt.replace('yyyy', year.toString())
        .replace('yy', (year % 100).toString())
        .replace('MM', month)
        .replace('dd', day)
        .replace('HH', hour)
        .replace('mm', minute)
        .replace('ss', second);
}
//判断跳转的学习页
export async function studyPath(itemData, course_id) {
    var path;
    if (!itemData.resource) {
        return path = '/study/resource404'
    }
    switch (itemData.resource.discriminator) {
        case 'lecture_note':
            path = `/study/lecture_note`
            break;
        case 'video':
            path = `/study/video`
            break;
        case 'legacy_live':
            path = `/study/legacy_live`
            break;
        case 'paper':
            let params = {
                course_id: course_id,
                cs_item_id: itemData.id,
                resource_id:itemData.resource_id
            }
            let ret = await getPaperStatusEp3Task(params);

            path = `/study/paper/exercise`
            // if (ret.result.is_submit == 1) {
            //     path = `/study/paper/report`
            // } else if (ret.result.is_submit == 0) {
            //     path = `/study/paper/exercise`
            // }
            break;
        case "hermes_live":
            return '';
        default:
            path = ''
    }
    return path;
}
//检测用户是否全部做完
export function confirmQuestions(paper_data) {
    var paper_data = paper_data;
    var questionIndex = '';
    for (var index in paper_data) {
        if (paper_data[index].type == 5) {
            for (var sIndex in paper_data[index].children) {
                const content = paper_data[index].children[sIndex].answer && paper_data[index].children[sIndex].answer.content;
                if (content == "") {
                    questionIndex = {
                        question_index: index,
                        sm_question_index: sIndex
                    }
                    return questionIndex;
                }
            }
        } else {
            if (paper_data[index].answer && paper_data[index].answer.content == "") {
                questionIndex = {
                    question_index: index
                }
                return questionIndex;
            }
        }
    }
    return questionIndex;
}
export function trim(str) { // 去空格
    return str.replace(/\s/g, '');
}
export function isPhone(str) { // 手机号验证
    var reg = /^(0|86|17951)?(13[0-9]|15[012356789]|18[0-9]|14[57]|17[678])[0-9]{8}$/;
    if (reg.test(str)) {
        return true;
    } else {
        return false;
    }
}
export function isEmail(str) { // 邮箱验证
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    // var reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (reg.test(str)) {
        return true;
    } else {
        return false;
    }
}
export function isNumber(value) { // 数字验证
    var patrn = /^[0-9]*$/;
    if (patrn.exec(value) == null || value == "") {
        return false
    } else {
        return true
    }
}
export function isMoney(value) { // 验证输入金额
    var patrn = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
    if (patrn.exec(value) == null || value == "") {
        return false
    } else {
        return true
    }
}
export function isCardNo(card) {
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(card)) {
        return false;
    } else {
        return true;
    }
}
export function isAllSpace(value) {
    // 全为空格时的验证
    var reg = /^\s+$/g;
    if (reg.test(value)) {
        return false;
    } else {
        return true;
    }
}
export function maxLength(value, maxlength) {
    //长度验证
    if (value.replace(/[^\x00-\xff]/g, "aa").length > maxlength) {
        return false;
    } else {
        return true;
    }
}
//删除所有cookie
export function deleteAllCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (var i = keys.length; i--;)
            document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() + "; domain=" + 'gaodun.com' + ";path=/";
    }
}
//压缩图片
export function ResizePicture(value) {
    if (!value) {
        return '';
    }
    if (value.indexOf('x-oss-process') > -1) {
        return value;
    }
    return value = value.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
        return `<img src="${capture}?x-oss-process=image/resize,h_100" alt="" title="点击查看原图" style="max-height: 100px;"/>`;
    });
}

/**
 * @description 判断是否为IE浏览器，如果是，是IE几
/**
 * 判断是否为ie浏览器
 * @return {number | string}
 */
 export const judgeIEVersion = () => {
    const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; //判断是否IE<11浏览器
    const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; //判断是否IE的Edge浏览器
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    if (isIE) {
        const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
        reIE.test(userAgent);
        const fIEVersion = parseFloat(RegExp['$1']);
        if (fIEVersion === 7) {
            return 7;
        } else if (fIEVersion === 8) {
            return 8;
        } else if (fIEVersion === 9) {
            return 9;
        } else if (fIEVersion === 10) {
            return 10;
        } else {
            return 6;//IE版本<=7
        }
    } else if (isEdge) {
        return 'edge';//edge
    } else if (isIE11) {
        return 11; //IE11
    } else {
        return -1;//不是ie浏览器
    }
};

export const loadJS = (url, success) => {
    let domScript = document.createElement('script');
    domScript.src = url;
    success = success || function () {
    };
    domScript.onload = domScript.onreadystatechange = function () {
        if (!this.readyState || 'loaded' === this.readyState || 'complete' === this.readyState) {
            success();
            this.onload = this.onreadystatechange = null;
            this.parentNode.removeChild(this);
        }
    };
    document.getElementsByTagName('head')[0].appendChild(domScript);
};
