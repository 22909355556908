<template>
    <div id="app">
        <HeadBar v-if="showHeader()" />
        <NavMenu v-if="showNavMenu()"></NavMenu>
        <AsideMenu v-if="showAsideMenu()" ref="asideMenu"></AsideMenu>
        <div class="content-box" :class="[showAsideMenu()?'selfwidth':'']" :style="[showBackGround()]">
            <transition name="fade" mode="out-in" appear>
                <router-view
                    @on-update-syllabus="handleUpdateSyllabus"
                    @on-next-resource="handleGoNextResource" />
            </transition>
        </div>
    </div>
</template>
<style>
.selfwidth {
    height: 100%;
    background-color: #282828;
    overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-active {
    transition: all 0.3s;
    opacity: 0;
}

.fold-enter-active {
    animation-name: fold-in;
    animation-duration: 0.1s;
}

.fold-leave-active {
    animation-name: fold-out;
    animation-duration: 0.1s;
}

@keyframes fold-in {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fold-out {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, 100%, 0);
    }
}
</style>
<script>
import HeadBar from "../components/public/HeadBar.vue";
import NavMenu from "../components/public/NavMenu.vue";
import AsideMenu from "../components/public/AsideMenu.vue";
// import { getCookie, setCookie } from "../util/cookie.js";
// import { ZEUS_TOKEN, ACCESS_TOKEN } from "../util/keys";
// import { login } from "../api/login.js";
// import { getEnv } from '../util/config'
export default {
    data() {
        return {
            activePhase111: 1
        };
    },
    components: {
        HeadBar,
        NavMenu,
        AsideMenu
    },
    computed: {},
    created() {
        this.loadRequestAnimation();
        // this.login();
    },
    mounted() {
        // this.loadMessage()
        // mySocket.init();
    },
    methods: {
        handleUpdateSyllabus(status) {
            if (this.$refs.asideMenu) {
                this.$refs.asideMenu.updateSyllabus(status);
            }
        },
        handleGoNextResource() {
            if (this.$refs.asideMenu) {
                this.$refs.asideMenu.goNextResource();
            }
        },
        showHeader() {
            let path = this.$route.path;
            if (path.indexOf("/study/") > -1) {
                return false;
            } else {
                return true;
            }
        },
        showNavMenu() {
            let path = this.$route.path;
            if (
                path.indexOf("/study/") > -1 ||
                path.indexOf("/note/detail/") > -1 ||
                path.indexOf("/quiz/detail/") > -1
            ) {
                return false;
            } else {
                return true;
            }
        },
        showAsideMenu() {
            let path = this.$route.path;
            if (path.indexOf("/study/") > -1) {
                return true;
            } else {
                return false;
            }
        },
        showBackGround() {
            let path = this.$route.path;
            let background = "";
            switch (path) {
                case "/study/zhibo/list":
                case "/study/transfer":
                case "/study/legacy_live":
                case "/study/paper/exercise":
                case "/study/paper/report":
                case "/study/paper/analysis":
                    background = { background: "#fff" };
                    break;
            }
            return background;
        },
        loadRequestAnimation() {
            const USE_HTML5 = ((navigator.userAgent.indexOf("Android") > -1) ||
                (navigator.userAgent.indexOf("iPhone") > -1) ||
                (navigator.userAgent.indexOf("iPad") > -1) ||
                (window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"'))
            );

            const vendors = ['webkit', 'moz'];
            for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
                window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
                window.cancelAnimationFrame =
                    window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
            }
            if (!USE_HTML5) {
                console.log('USE_HTML5');
                window.requestAnimationFrame = function (callback, element) {
                    const currTime = new Date().getTime();
                    // var timeToCall = Math.max(0, 16 - (currTime - lastTime));
                    const timeToCall = 300; //100ms
                    let id = window.setTimeout(function () {
                        callback(currTime + timeToCall);
                    }, timeToCall);
                    // lastTime = currTime + timeToCall;
                    return id;
                };

                window.cancelAnimationFrame = function (id) {
                    clearTimeout(id);
                };
            }

        },

    },
};
</script>
