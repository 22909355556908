<template>
    <div class="knowledgedominoes">
        <div class="knowledge-head w_1090 clear">
            <el-radio-group v-model="knowledgeRadio" class="knowledgeRadio" @change="changeKnowledge">
                <el-radio :label="item.label" v-for="(item,index) in knowledge_type" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
        </div>
        <div class="knowledge-content w_1090">
            <KnowledgeList></KnowledgeList>
            <KnowledgeStairs></KnowledgeStairs>
        </div>
    </div>
</template>
<script>
    import {knowledge_type,knowledgeResult} from '../common/knowledgeConfig.js'
    import KnowledgeList from '../components/knowledgeDominoes/KnowledgeModuleList.vue'
    import KnowledgeStairs from '../components/knowledgeDominoes/KnowledgeModuleStairs.vue'
    export default {
        components: {
            KnowledgeList,KnowledgeStairs
        },
        data(){
            return {
                knowledge_type:knowledge_type,//知识点切换类型
                knowledgeRadio:'0',
            }
        },
        computed: {
            course_id(){
                return this.$route.params.cid;
            },
        },
        methods: {
            changeKnowledge(value){
            }
        },
        created(){
            this.$store.dispatch('getKnowledge',knowledgeResult)
        },
        mounted(){
        },
    }
</script>
