<template>
    <div :class="`syllabus-tree-treeNode syllabus-tree-treeNode-resource syllabus-tree-treeNode-${data.depth}`"
        @click.stop="handleClick">
        <div class="syllabus-tree-node-inner">
            <div class="syllabus-tree-node-icon" :style="[iconStyle]">
                <span>{{ data.resource && data.resource.discriminator | Resource2chn }}</span>
            </div>
            <div class="syllabus-tree-node-content-wrapper">
                <div class="syllabus-tree-node-content">
                    <div :class="`syllabus-tree-node-title`">
                        <span>{{ data.name }}</span>
                        <div v-if="correctTip" class="syllabus-item-correct-tip">{{ correctTip }}</div>
                    </div>
                    <div class="syllabus-tree-node-mark">
                        <span class="tree-mark tree-mark-text" v-if="resourceMark">{{ $t('syllabus.audition') }}</span>
                    </div>
                    <div class="syllabus-tree-node-info">
                        <span v-if="data.resource.discriminator === 'paper' && data.progress === 1"
                            class="syllabus-tree-redo" @click.stop="redoPaper(data)">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.30269 10.0145C2.09134 8.91417 1.62321 7.19366 2.10582 5.63001C2.74528 3.5572 4.84463 2.32895 6.92951 2.72228L6.44207 2.97806C6.23455 3.08665 6.15733 3.34243 6.26592 3.54996C6.3745 3.75748 6.63029 3.8347 6.83781 3.72611L8.26392 2.97324C8.47144 2.86465 8.54866 2.60887 8.44007 2.40134L7.6872 0.975232C7.57862 0.76771 7.32283 0.690492 7.11531 0.799079C6.90779 0.907667 6.83057 1.16345 6.93916 1.37097L7.22872 1.92115C4.68054 1.36373 2.07686 2.84293 1.29262 5.38146C0.713488 7.2564 1.27814 9.31956 2.7308 10.6419C2.86593 10.765 3.05897 10.7843 3.21341 10.7022C3.25684 10.6805 3.29545 10.6491 3.32923 10.613C3.48608 10.4392 3.47402 10.1738 3.30269 10.0145ZM5.7085 10.2872L5.69885 8.71147C5.68678 8.59564 5.67955 8.29643 5.87259 8.05271L8.89614 4.27146C9.02403 4.1122 9.20501 4.01326 9.40529 3.99155C9.6104 3.96741 9.80586 4.02533 9.96512 4.15322L10.7566 4.78544C10.9159 4.91333 11.0148 5.09431 11.0365 5.29459C11.0582 5.49729 11.0027 5.69516 10.8748 5.85442L7.85129 9.63808C7.65825 9.87939 7.36385 9.93971 7.24803 9.95178L5.7085 10.2872ZM9.50423 4.87231L6.53377 8.58358L6.54583 8.68251L6.54824 9.23752L7.15151 9.10962C7.16357 9.10721 7.19494 9.09997 7.20459 9.09273L10.1606 5.39353L9.50423 4.87231ZM10.3029 11.25H5.66989C5.43583 11.25 5.24761 11.0594 5.24761 10.8277C5.24761 10.5961 5.43824 10.4054 5.66989 10.4054H10.3029C10.537 10.4054 10.7252 10.5961 10.7252 10.8277C10.7252 11.0594 10.537 11.25 10.3029 11.25Z"
                                    fill="#35C25E" />
                            </svg>
                            重做
                        </span>
                        <span v-html="getInfo(data)"></span>
                        <i class="anticon">
                            <svg v-show="getIcon(data) === iconStatusMap[0]" width="12px" height="12px"
                                viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 55.1 (78136) - https://sketchapp.com -->
                                <title>Oval Copy 17</title>
                                <desc>Created with Sketch.</desc>
                                <g id="组件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="任务-未开始" stroke="#CFD4DB">
                                        <circle id="Oval-Copy-17" cx="6" cy="6" r="5.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <svg v-show="getIcon(data) === iconStatusMap[1]" width="12px" height="12px"
                                viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 55.1 (78136) - https://sketchapp.com -->
                                <title>任务-已完成</title>
                                <desc>Created with Sketch.</desc>
                                <g id="任务-已完成" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <circle id="Oval-Copy-15" fill="#E6FFF1" cx="6" cy="6" r="6"></circle>
                                    <polyline id="Path-2-Copy" stroke="#2ED47A" stroke-linecap="round"
                                        stroke-linejoin="round" points="3 6 5.30769231 8 9 4"></polyline>
                                </g>
                            </svg>

                            <svg v-show="getIcon(data) === iconStatusMap[2]" width="12px" height="12px"
                                viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">

                                <!-- Generator: Sketch 55.1 (78136) - https://sketchapp.com -->
                                <title>任务-进行中</title>
                                <desc>Created with Sketch.</desc>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Group-66">
                                        <circle id="Oval-Copy-15" stroke="#CFD4DB" stroke-width="1.33333333"
                                            fill="#FFFFFF" cx="8" cy="8" r="7.33333333" />
                                        <path
                                            d="M8,14.6666667 C11.6818983,14.6666667 14.6666667,11.6818983 14.6666667,8 C14.6666667,4.31810167 11.6818983,1.33333333 8,1.33333333"
                                            id="Path" fill="#CFD4DB" />
                                        <path
                                            d="M7,18.3333333 C10.6818983,18.3333333 13.6666667,15.348565 13.6666667,11.6666667 C13.6666667,7.98476833 10.6818983,5 7,5"
                                            id="Path-Copy-2" fill="#CFD4DB"
                                            transform="translate(10.333333, 11.666667) rotate(-315.000000) translate(-10.333333, -11.666667) " />
                                    </g>
                                </g>
                            </svg>
                            <svg v-show="getIcon(data) === iconStatusMap[3]" width="12px" height="12px"
                                viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 58 (84663) - https://sketch.com -->
                                <title>勾选/勾选不可勾选备份</title>
                                <desc>Created with Sketch.</desc>
                                <g id="大纲" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                    opacity="0.400000006">
                                    <g id="勾选/勾选不可勾选">
                                        <g id="元素/选择/不可勾选">
                                            <path
                                                d="M6,12 C2.69177729,12 0,9.30837524 0,5.99972506 C0,2.69114362 2.69177729,0 6,0 C9.30822271,0 12,2.69162476 12,5.99972506 C12,9.3078941 9.30877342,12 6,12"
                                                id="Fill-" fill="#CCCCCC"></path>
                                            <path
                                                d="M0.545454545,5.99977572 C0.545454545,9.00754555 2.99257141,11.4540969 5.99996262,11.4540969 C9.00787711,11.4540969 11.4545455,9.00754555 11.4545455,5.99977572 C11.4544707,2.99260301 9.00787711,0.545454545 5.99996262,0.545454545 C2.99257141,0.545454545 0.545454545,2.99252837 0.545454545,5.99977572 Z"
                                                id="Fill-" fill="#E1E1E1"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <svg v-show="getIcon(data) === iconStatusMap[4]" width="10px" height="12px"
                                viewBox="0 0 10 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->
                                <title>icon_lock</title>
                                <desc>Created with Sketch.</desc>
                                <g id="大纲" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-655.000000, -143.000000)" fill="#CFD4DB"
                                        fill-rule="nonzero" id="lock-2-copy-8备份">
                                        <g transform="translate(654.000000, 143.000000)">
                                            <g id="Group-10" transform="translate(0.250000, 0.000000)">
                                                <path
                                                    d="M9.5,4.75 L9.125,4.75 L9.125,3.375 C9.125,1.51103897 7.61396103,1.14134695e-16 5.75,0 C3.88603897,-1.14134695e-16 2.375,1.51103897 2.375,3.375 L2.375,4.75 L2,4.75 C1.44771525,4.75 1,5.19771525 1,5.75 L1,11 C1,11.5522847 1.44771525,12 2,12 L9.5,12 C10.0522847,12 10.5,11.5522847 10.5,11 L10.5,5.75 C10.5,5.19771525 10.0522847,4.75 9.5,4.75 Z M5.75,9.25 C5.19771525,9.25 4.75,8.80228475 4.75,8.25 C4.75,7.69771525 5.19771525,7.25 5.75,7.25 C6.30228475,7.25 6.75,7.69771525 6.75,8.25 C6.75,8.80228475 6.30228475,9.25 5.75,9.25 Z M7.875,4.5 C7.875,4.63807119 7.76307119,4.75 7.625,4.75000003 L3.875,4.75 C3.73692881,4.75 3.625,4.63807119 3.625,4.5 L3.625,3.375 C3.62500002,2.20139492 4.57639492,1.25000003 5.75,1.25000003 C6.92360508,1.25000003 7.87499998,2.20139492 7.875,3.375 L7.875,4.5 Z"
                                                    id="Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>

                        </i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lang from '../../../language/lang';
import { number2DateTime } from '../../../util/util';
import { getLiveStatus } from '../../../util/g2-live';
import { bindEvents } from '../../../util/sensor-data';
import { W2_CLICK_EPLIVE, W2_CLICK_EPHANDOUT, W2_CLICK_EPPAPER, W2_CLICK_EPVIDEO } from '@/config'
import {
    getPaperRecord,
    redoItemPaper,
    createItemPaper
} from "../../../api/study.js";
export default {
    name: 'ResourceNode',
    props: {
        data: Object,
        serviceTime: Number,
    },
    data() {
        return {
            iconStatusMap: [
                'icon_circle',
                'icon_circle_check',
                'icon_half_circle',
                'icon_circle_solid',
                'icon_lock',
            ],
        };
    },
    computed: {
        correctTip() {
            const paper = this.$store.state.paper;
            const resource = this.data.resource;
            if (resource && resource.discriminator === 'paper') {
                const currentItem = paper.paperCorrectStatus[resource.paper_id];
                if (currentItem) {
                    return currentItem.correct_status_show;
                }
            }
            return null;
        },
        courseCheck() {
            return +this.$store.state.home.course_info.audition;
        },
        iconStyle() {
            if (!this.data.resource) {
                return {
                    borderColor: 'transparent',
                };
            }
            return {};
        },
        resourceMark() {
            const isAuditionResource = this.data.audition === 1 && this.data.resource && this.data.resource.discriminator === 'video';
            return this.courseCheck === 1 && isAuditionResource;
        }
    },
    methods: {
        handleClick() {
            switch (this.data.resource.discriminator) {
                case "lecture_note":
                    bindEvents(W2_CLICK_EPHANDOUT, { handout_study_type: '去学习', handout_id: this.data.resource.id });
                    break;
                case "video":
                    bindEvents(W2_CLICK_EPVIDEO, { video_state: this.data.progress === 1 ? '已完成' : '未完成', video_id: this.data.resource.video_id });
                    break;
                case "legacy_live":
                    bindEvents(W2_CLICK_EPLIVE, { live_task_type: '', live_type: 'G1', resource_id: this.data.resource.id, live_id: '' });
                    break;
                case "live_new":
                    bindEvents(W2_CLICK_EPLIVE, { live_task_type: getLiveStatus(this.data), live_type: 'G2', resource_id: this.data.resource.id, live_id: this.data.resource.live_id });
                    break;
                case "paper":
                    bindEvents(W2_CLICK_EPPAPER, { paper_type: this.data.progress === 1 ? '已完成' : '未完成', paper_id: this.data.resource.paper_id });
                    break;
                default:
            }

            this.$emit('on-click', this.data);
        },
        getIcon(data) {
            if (this.courseCheck === 1 && !this.resourceMark) {
                return this.iconStatusMap[4];
            }
            const { progress, resource } = data;
            const { discriminator } = resource || {};
            let index = discriminator ? progress : 3;
            index = index ? index : 0;
            return this.iconStatusMap[index];
        },
        async redoPaper(data) {
            const paperId = data.resource.paper_id;
            const ret = await getPaperRecord({ paperId: paperId, sourceFromType: 100533962 });
            if (ret.status == 0) {
                const businessParam = {
                    courseId: this.$route.params.cid,
                    csItemId: data.id,
                    resourceId: data.resource.id,
                    paperId,
                    sourceFromType: 100533962
                }

                if (ret.result && ret.result.paperSubmitStatus === 1) {
                    const redoRes = await redoItemPaper({
                        // title: data.name,
                        paperDataLogId: ret.result.paperDataLogId,
                        sourceFromType: 100533962,
                        businessParam
                    })
                    if (redoRes.status === 0) {
                        this.$emit('on-click', this.data);
                    }
                } else {
                    if (!ret.result || !ret.result.paperDataLogId) {
                        const createRes = await createItemPaper({
                            // title: data.name,
                            paperId,
                            sourceFromType: 100533962,
                            businessParam
                        })
                        if (createRes.status === 0) {
                            this.$emit('on-click', this.data);
                        } else {
                            this.$message(createRes.message || '试卷异常');
                        }
                    } else {
                        this.$emit('on-click', this.data);
                    }
                }
            } else {
                this.$message(ret.message || '试卷异常');
            }

        },
        getInfo(data) {
            const { resource } = data;
            const { discriminator } = resource || {};
            switch (discriminator) {
                case 'hermes_live':
                    return this.getHermesLiveInfo(data);
                case 'live_new':
                    return getLiveStatus(data);
                default:
                    return '';
            }
        },
        getHermesLiveInfo(data) {
            const { resource, start_time } = data;
            const { status } = resource || {};
            const diffTime = Math.abs(this.serviceTime - start_time) / 1000;
            const h = Math.floor(diffTime / 3600);
            const m = Math.floor((diffTime / 60 % 60));
            const time = `${h ? h + lang[window.GD_LANG].syllabus.hour : ''}${m ? m + lang[window.GD_LANG].syllabus.minute : ''}`;

            if (status === 5) {
                return `<span>${lang[window.GD_LANG].syllabus.liveStatus.playEnd}</span> <span>${lang[window.GD_LANG].syllabus.liveStatus.noPlayback}</span>`;
            }
            if (status === 3 || status === 4) {
                return `<span>${lang[window.GD_LANG].syllabus.liveStatus.playEnd}</span> <button style="color: #F7BA2A;">${lang[window.GD_LANG].syllabus.liveStatus.playback}</button>`;
            }
            if (status === 2) {
                return `
                        <span>${this.serviceTime < start_time ? lang[window.GD_LANG].syllabus.liveStatus.playCountdown : lang[window.GD_LANG].syllabus.liveStatus.playing}${time}</span>
                        <button style="color: #35C25E;">${lang[window.GD_LANG].syllabus.liveStatus.toPlay}</button>
                    `;
            }
            if (status === 1) {
                if (this.serviceTime + 2 * 60 * 60 * 1000 < start_time) {
                    return number2DateTime(start_time, 'yyyy-MM-dd HH:mm');
                } else {
                    return `<span>${lang[window.GD_LANG].syllabus.liveStatus.playCountdown} ${time}</span>`;
                }
            }
        },
    },
};
</script>
