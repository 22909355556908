<template>
    <div id="player-video-study" style="height: 100%;background-color: black;min-height: 300px;">
    </div>
</template>
<style>
</style>
<script>
import { loadMicroApp } from 'qiankun';
import {SAAS_USER_ID} from '../../util/keys';
import { environment  } from '@gaodun.com/gtools';

export default {
    props: ['video_id', 'onPlay', 'initPlayer' , 'callbackSpeed', 'isVideoPage', "businessType" ,"sceneType", "businessNote", "oncustomfinish"],
    mounted() {
        try {
            this.init();
        } catch (error) {
            alert('抱歉，不支持该视频的播放～');
        }
    },
    beforeDestroy() {
        this.microApp &&  this.microApp.unmount();
    },
    created() {
    },
    methods: {
        // 子组件的方法
        init(vid, businessNote) {
            if(this.microApp){
                this.microApp.unmount();
            }
            const studentId = localStorage.getItem(SAAS_USER_ID);    // 学生id
            const noteData = businessNote ? businessNote : this.businessNote;
            const data =  noteData ? {...noteData, courseId: this.$route.query.course_id, resourceId: this.$route.query.resource_id, csItemId: this.$route.query.syllabusitem_id } : undefined;
            this.microApp = loadMicroApp({
                name: 'player-video-study',
                // entry: "http://dev-sub-study-player.gaodun.com:3038/",
                entry: `https://${environment.getApiEnv()}sub-study-player.gaodun.com/`,
                container: document.querySelector('#player-video-study'),
                props: {
                    videoId: vid || this.video_id,
                    onPlay: this.onPlay,
                    playerType: 'study',
                    initPlayer: this.initPlayer,
                    userId: studentId,
                    businessType: this.businessType, // 课程的意思
                    sceneType: this.sceneType, // 网课2
                    courseId: this.$route.query.course_id,
                    isTrack: location.href.includes('/study/play') || location.href.includes('/study/g2play') || location.href.includes('/study/video'),//学习页面才上报
                    businessNote: data,
                    onSetSpeedSuccess: this.callbackSpeed,
                    oncustomfinish: this.oncustomfinish, //普通视频自定义完成
                },
            },
            { sandbox: { strictStyleIsolation: false } },
            {
                afterMount: async () => {//__qiankun_microapp_wrapper_for_player_video_study_1__
                    // 获取目标元素
                    const targetElement = document.getElementById('__qiankun_microapp_wrapper_for_player_video_study__')||document.getElementById('__qiankun_microapp_wrapper_for_player_video_study_1__');

                    // 设置高度为 100%
                    if (targetElement) {
                        targetElement.style.height = '100%';
                    }
                    // const dom = document.getElementById('sub-player-load');
                    // const shadowRoot = dom && dom.children && dom.children[0] && dom.children[0].shadowRoot;
                    // if (shadowRoot) cancelMicroEventsListener.current = retargetEvents(shadowRoot);
                },
                beforeUnmount: async () => {
                    // cancelMicroEventsListener.current?.();
                },
            });
        },
        pause(){
            console.log('pause');
        },
        destroy(){
            console.log('destroy');
        },
        onPlayer(){
            console.log('onPlayer');
        },

    }
};
</script>