export const capitalize = function (value,data_index,paper_data){
    var data_index = data_index;
    var num = 0;
    while (data_index>0)
    {
        num += paper_data[data_index-1].question_number;
        data_index--;
    }
    value = value + 1 + num;
    return value
};