<template>
    <div class="source">
        <div class="video-source clear" v-if="resource_type==1">
            <div class="source-pic">
                <VideoPlayBox
                    :videoUrl="video.video_id"
                    :picSrc="gbot&&gbot.images"
                ></VideoPlayBox>
            </div>
            <div class="source-info">
                <span class="resource-item-icon green_12">视频</span>
                <span class="resource-name black_16">{{payload_name}}</span>
                <span class="video-time" v-if="gbot">{{gbot&&gbot.capture_time}}</span>
            </div>

        </div>
        <div class="question-source" v-if="resource_type==2">
            <Single
                v-if="question.type==1"
                :titleAudio="question.title_audio"
                :type="question.type"
                :title="question.title"
                :option="question.options"
                :optionNum="question.total_options"
            ></Single>
            <Multiple
                v-if="question.type==2||question.type==7||question.type==52"
                :titleAudio="question.title_audio"
                :type="question.type"
                :title="question.title"
                :option="question.options"
                :optionNum="question.total_options"
            ></Multiple>
            <TFNG
                v-if="question.type==3"
                :titleAudio="question.title_audio"
                :type="question.type"
                :title="question.title"
            ></TFNG>
            <ShortAnswer
                v-if="question.type==4||question.type==6"
                :titleAudio="question.title_audio"
                :type="question.type"
                :title="question.title"
                :isAnalysis="isAnalysis"
            ></ShortAnswer>
        </div>
    </div>
</template>
<script>
    import TFNG from '../paperExercise/PaperModuleTFNG.vue'
    import Multiple from '../paperExercise/PaperModuleMultiple.vue'
    import Single from '../paperExercise/PaperModuleSingle.vue'
    import Integrate from '../paperExercise/PaperModuleIntegrate.vue'
    import ShortAnswer from '../paperExercise/PaperModuleShortAnswer.vue'
    import VideoPlayBox from '../VideoPlayBox.vue'

    export default {
        components: {
            TFNG,
            Multiple,
            Single,
            Integrate,
            VideoPlayBox,
            ShortAnswer
        },
        props: [
            'resource_type',
            'question',
            'video',
            'gbot',
            'payload_name'
        ],
        data() {
            return {
                isAnalysis:true
            }
        },
        computed: {},
        mounted() {
        },
        methods: {},
        watch: {},
        created() {
        },
        beforeDestroy() {
        }
    }
</script>
