var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paper-view Single-paper"},[_c('div',{staticClass:"paper-view-head clear"},[_vm._m(0),_c('div',{staticClass:"progerss-text"},[_c('span',[_vm._v(" (")]),_c('span',{staticClass:"currentnum"},[_vm._v(_vm._s(_vm.cur_data_index))]),_vm._v("/"),_c('span',[_vm._v(_vm._s(_vm.total_data))]),_c('span',[_vm._v(")")])])]),(_vm.titleAudio)?_c('Audio',{attrs:{"audio-src":_vm.titleAudio}}):_vm._e(),_c('div',{staticClass:"paper-title",domProps:{"innerHTML":_vm._s(_vm.title)},on:{"click":function($event){return _vm.previewImages($event)}}}),_c('div',{staticClass:"paper-options"},[_c('div',[_c('div',{staticClass:"paper-options-content",attrs:{"v-html":_vm.option},on:{"click":function($event){return _vm.previewImages($event)}}}),_c('div',{staticClass:"paper-options-group"},_vm._l((Number(_vm.optionNum)),function(val,index){return _c('span',{key:index,staticClass:"paper-options-items",class:[
                        String.fromCharCode(index + 65) == _vm.answer
                            ? 'cur'
                            : '',
                    ],on:{"click":function($event){_vm.checkAns &&
                            _vm.checkAns(String.fromCharCode(index + 65))}}},[_vm._v(" "+_vm._s(String.fromCharCode(index + 65))+" ")])}),0)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paper-type"},[_c('span',[_vm._v("单选题")])])
}]

export { render, staticRenderFns }