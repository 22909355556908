<template>
  <div class="knowledge-stairs">
      <ul>
          <li
              class="chapter-name"
              :class="[active==index?'active':'']"
              v-for="(item,index) in knowledge_info.contents"
              @click="changeActKnowledge(index)"
              :key="index"
          >第{{(index+1) | Arab2Chn}}章</li>
      </ul>
  </div>
</template>
<script>

  export default {
    components: {

    },
    data(){
      return {
      }
    },
    computed: {
        knowledge_info(){
            return this.$store.state.knowledge.knowledge_info;
        },
        active(){
            return this.$store.state.knowledge.active;
        }
    },
    created(){

    },
    mounted(){
    },
    methods: {
        changeActKnowledge(index){
            this.$store.dispatch("changeActKnowledge",index);
        }
    }
  }
</script>
