var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"study-record"},[_c('div',{staticClass:"nav-menu-wrap"},[_c('div',{staticClass:"w_1090 clear"},[_c('div',{staticClass:"h-menu"},_vm._l((_vm.record_type),function(item,index){return (
                        !(_vm.gdLang !== 'zh-CN' && item.index === 'exercise')
                    )?_c('router-link',{key:index,staticClass:"h-menu-link",attrs:{"active-class":"h-menu-link--active","tag":"div","to":{
                        name: item.index,
                        params: { cid: _vm.course_id },
                        query: { vid: _vm.vid, refSite: _vm.refSite },
                    }},nativeOn:{"click":function($event){return _vm.buryPoint(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()}),1)])]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }