import promise from 'es6-promise';
import Vue from 'vue';
import i18n from './language'// 多语言
import store from './store/index';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import {routes} from './routes/index';
import './assets/css/public.css';
import './assets/css/ripple.css';
import './assets/css/zeus.less';
import './assets/css/paper.css';
import './assets/css/knowledge.css';
import './assets/css/handouts.css';
import './assets/css/record.css';
import './assets/css/zhibo.css';
import { checkUpdateToken } from './util/updateToken.js';
import { sensorsInit } from "./util/sensor-data.js";
import Validate from './util/filter_rules';
import {JqAjax, VideoAuthentication, VideoCreateNewWithToken} from './util/jquery_ajax';
import Service from './api/index';
import './assets/icons';
//引入全局过滤器
import filters from './filters/index.js';
import lang from './language/lang';
import * as Sentry from "@sentry/vue";

promise.polyfill();
Vue.prototype.$http = Service;
Vue.prototype.$ajax = JqAjax;
Vue.prototype.$auth = VideoAuthentication;
Vue.prototype.$create = VideoCreateNewWithToken;
Vue.use(VueAwesomeSwiper);
Vue.use(VueRouter);
Vue.use(Validate);
Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title;
    },
});

require('element-ui/lib/theme-chalk/index.css');
const ELEMENT = require('element-ui');
Vue.use(ELEMENT);
Vue.use(VueRouter);

sensorsInit();

Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

const router = new VueRouter({
    mode: 'history',
    routes,
});
router.beforeEach(async (to, from, next) => {
    if (to.name === 'login') {
        document.title = `${to.meta.title}`;
    } else {
        // 非登陆页 检查token
        if (!store.state.base.isAuth) {
            try {
                await checkUpdateToken();
                store.commit('setIsAuth', true);
            } catch (e) {
                console.log(e);
            }
        }
        document.title = `${lang[window.GD_LANG].learningPlatform} ${to.meta.title}` || lang[window.GD_LANG].learningPlatform;
    }
    next();
});

export default router;

console.log("🚀 ~ process.env.NODE_ENV:", process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === '') {
    Sentry.init({
        Vue,
        dsn: "https://596fc5e93d8918c78c270bbeabfd1ce8@sentry.gaodun.com/124",
        integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop',
            'h is not defined',
            "Can't find variable: h",
        ],
    });
}
new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');
