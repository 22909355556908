import {get} from '../util/zeusAxios';

//获取课程下讲义
export const reqHandoutList = (courseId, params) => get(`/ep-course/api/v1/course/${courseId}/handout`, params);

// 获取课程讲义分类列表
export const reqCourseHandoutCategory = (courseId, params) => get(`/ep-course/api/v1/course/${courseId}/handout/category`, params);

//获取讲义下载的真实地址
export const reqHandoutDownloadPath = (params) => get(`/hermes/front/v1/download/handout`, params);


