<template>
    <div class="paper-footer clear">
        <BigImg></BigImg>
        <div style="max-width: 1000px;width:100%;min-width:400px;margin: 0 auto;height: 100%;" class="clear">
            <span class="footer-btn btn-next" @click="nextButton"
                  v-show="cur_data_index == total_data?false:true">下一题</span>
            <template v-if="goReport">
                 <span
                     class="footer-btn btn-next"
                     v-show="cur_data_index == total_data?true:false"
                     @click="goReport"
                 >返回报告页</span>
            </template>
            <span class="footer-btn btn-prev" @click="prevButton" v-show="cur_data_index == 1?false:true">上一题</span>
        </div>
    </div>
</template>
<script>
    import BigImg from '../BigImg.vue'
    import Vue from '../../common/Vue.js'

    export default {
        components: {
            BigImg
        },
        props: {
            //滚动到顶部
            getToTop: {
                type: [Function],
                required: true
            },
            //下一题
            nextButton: {
                type: [Function],
                required: true
            },
            //上一题
            prevButton: {
                type: [Function],
                required: true
            },
            //去报告
            goReport: {
                type: [Function],
            },
            //当前第几题
            cur_data_index: [String, Number],
            //总题数
            total_data: [String, Number],
        },
        data() {
            return {}
        },
        computed: {},
        mounted() {
        },
        methods: {},
        created() {
        }
    }
</script>
