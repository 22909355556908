var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"answer-sheet-head"},[_c('span',{staticClass:"answer-sheet-title"},[_vm._v("答题卡")]),_c('i',{staticClass:"close-btn el-icon-close",on:{"click":_vm.closeSheet}})]),_c('div',{staticClass:"answer-sheet-content"},[_c('el-radio-group',{attrs:{"size":"small","fill":"#35c25e"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('el-radio-button',{attrs:{"label":"1"}},[_vm._v("按顺序")]),_c('el-radio-button',{attrs:{"label":"2"}},[_vm._v("按类型")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type==1),expression:"type==1"}],staticClass:"answer-sheet-list"},_vm._l((_vm.cur_data),function(item,question_index){return _c('span',{key:question_index,staticClass:"answer-sheet-items",class:[
                    item.user_answer && item.user_answer.istrue == 1 ? 'rightques':'wrongques',
                    question_index == _vm.cur_data_index - 1 ? 'cur' : ''
                ],attrs:{"onselectstart":"return false;"},on:{"click":function($event){return _vm.changeSheet(item)}}},[_vm._v(_vm._s(question_index+1)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type==2||_vm.type==52),expression:"type==2||type==52"}],staticClass:"answer-sheet-list"},_vm._l((_vm.recm_data),function(items,data_index){return _c('ul',{key:data_index},[_c('li',{staticClass:"answer-sheet-items-title"},[_vm._v(_vm._s(items.title))]),_vm._l((items.questions),function(item,question_index){return _c('li',{key:question_index,staticClass:"answer-sheet-items",class:[
                        item.user_answer && item.user_answer.istrue == 1 ?'rightques':'wrongques',
                        item.question_id ? (item.question_id == _vm.question_id?'cur':'' ) : (item.id==_vm.question_id?'cur':''),
                    ],attrs:{"onselectstart":"return false;"},on:{"click":function($event){return _vm.changeSheet(item)}}},[_vm._v(_vm._s(question_index + 1)+" ")])})],2)}),0)],1),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-sheet-foot"},[_c('i',{staticClass:"icon done"}),_c('span',[_vm._v("已答")]),_c('i',{staticClass:"icon noDo"}),_c('span',[_vm._v("未答题")]),_c('i',{staticClass:"icon cur"}),_c('span',[_vm._v("当前答题")])])
}]

export { render, staticRenderFns }