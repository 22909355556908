<template>
    <div class="password-box">
        <div class="go-back" @click="handleBack">
            <i class="el-icon-arrow-left"> 返回</i>
        </div>
        <div class="password-form">
            <div class="title">找回密码</div>
            <el-form :model="passwordForm" :rules="passwordRules" ref="passwordRef">
                <el-form-item prop="account">
                    <el-input
                        v-model="passwordForm.account"
                        @keyup.enter.native="handleChangePassword"
                        autofocus="true"
                        placeholder="请输入登录账号"
                        clearable
                        prefix-icon="el-icon-user"
                        auto-complete="new-password"
                    />
                </el-form-item>
                <el-form-item prop="code">
                    <el-input
                        v-model="passwordForm.code"
                        @keyup.enter.native="handleChangePassword"
                        placeholder="请输入验证码"
                        prefix-icon="el-icon-message"
                        auto-complete="new-password"
                        clearable
                    >
                        <span
                            slot="suffix"
                            @click="sendCode"
                            class="send-code"
                            v-if="!isCountDown"
                        >发送验证码</span>
                        <span
                            slot="suffix"
                            class="countdown"
                            v-else
                        >{{count}}s后重新发送</span>
                    </el-input>
                </el-form-item>
                <el-form-item prop="new_password">
                    <el-input
                        :type="inputType ? 'password' : 'text'"
                        v-model="passwordForm.new_password"
                        @keyup.enter.native="handleChangePassword"
                        placeholder="设置新密码"
                        prefix-icon="el-icon-lock"
                        clearable
                        auto-complete="new-password"
                    >
                        <i
                            slot="suffix"
                            @click="changeInputType"
                            class="el-icon-view"
                        />
                    </el-input>
                </el-form-item>
                <el-button
                    type="primary"
                    class="edit-btn"
                    :loading="loading"
                    @click.native.prevent="handleChangePassword"
                >确 认 修 改</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>

import { token, gdsid, environment } from '@gaodun.com/gtools';
export default {
    data(){
        return {
            passwordForm: {
                account: "", //登录账号,
                code: "", //验证码
                new_password: "", //新密码
            },
            loading: false,
            isCountDown: false,
            count: 60,
            inputType: true,
            passwordRules: {
                account: { required: true, message: '请输入登录账号', trigger: ['blur','change'] },
                code: [
                    { required: true, message: '请输入验证码', trigger: ['blur','change'] },
                    { pattern: /^\d{6}$/, message: '验证码格式错误', trigger: ['blur','change'] },
                ],
                new_password: { required: true, message: '请设置新密码', trigger: ['blur','change'] },
            }
        }
    },
    methods: {
        changeInputType(){
            this.inputType = !this.inputType
        },
        sendCode(){
            this.$refs.passwordRef.validateField('account',async(valid)=> {
                if(!valid) {
                    let { account } = this.passwordForm
                    try {
                        const GDSID = await gdsid.get();
                        const res = await this.$http.sendCode({
                            account,
                            app_id: 200409, //不变
                            gdsid: GDSID
                        })
                        if (res.message === 'OK') {
                            this.isCountDown = true
                            let timer = setInterval(()=> {
                                this.count--;
                                if (this.count === 0) {
                                    this.isCountDown = false
                                    this.count = 60
                                    clearInterval(timer)
                                }
                            }, 1000)
                           this.$message({ message: '发送成功', type: 'success', customClass: 'message-style'});
                        } else {
                           this.$message({ message: res.message, type: 'error', customClass: 'message-style'});
                        }
                    } catch (e) {
                        this.$message({
                            message: e,
                            type: 'error',
                            customClass: 'message-style',
                        });
                    }
                }
            })
        },
        handleBack(){
            this.$emit("isShowForgetPassword",false)
        },
        // 重置密码
        handleChangePassword() {
            this.$refs.passwordRef.validate(async(valid) => {
                if (valid) {
                    this.loading = true;
                    let {account,code,new_password} = this.passwordForm
                    try {
                        const res = await this.$http.resetPassword({
                            account,
                            app_id: 200409, //不变
                            code: code,
                            new_password
                        })
                        if (res.code === "success") {
                            this.handleBack()
                            this.$message({ message: '密码修改成功', type: 'success', customClass: 'message-style'});
                        } else {
                            this.$message({ message: res.message, type: 'error', customClass: 'message-style'});
                        }
                        this.loading = false;
                    } catch (e) {
                        this.$message({
                            message: e,
                            type: 'error',
                            customClass: 'message-style',
                        });
                        this.loading = false;
                    }
                }
            });
        }
    }
}
</script>

<style lang="less">
    .password-box {
        padding: 30px;
        .go-back {
            float: left;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;
        }
        .password-form {
            margin: 0 auto;
            width: 350px;
            text-align: center;
            .title {
                font-size: 23px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 23px;
            }
            .el-form-item {
                margin-bottom: 23px!important;
                .el-input {
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    color: rgba(0, 0, 0, 0.85);
                    .el-input__inner {
                        padding-left: 35px;
                    }
                    .send-code {
                        font-size: 15px;
                        color: #1890FF;
                        margin-right: 12px;
                        cursor: pointer;
                        float: right;
                    }
                    .countdown {
                        font-size: 15px;
                        margin-right: 12px;
                        float: right;
                    }
                    .el-icon-view {
                        float: right;
                        margin-top: 13px;
                        margin-right: 12px;
                    }
                }
            }
            .edit-btn {
                width: 100%;
                font-size: 17px;
                color: #FFFFFF;
                // margin-top: 2px;
            }
        }
    }

    .message-style {
        background: #fff!important;
        border: none!important;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        p {
            color: #000!important;
        }
    }
</style>
