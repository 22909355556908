// 中文-简体
export default {
    //header
    route: {
        home: '课程首页',
        syllabus: '课程大纲',
        studyRecord: '学习记录',
        handoutsDownload: '讲义下载',
        questionDetail: '提问详情',
        dominoes: '知识骨牌',
        dominoesDetail: '知识骨牌详情',
        classPk: '班级PK',
        video: '视频学习',
        transfer: '直播中转',
        liveList: '直播列表',
        playback: '直播回放',
        handout: '讲义学习',
        resource404: '资源404',
    },
    space: '学习空间',
    learningPlatform: '学习平台',
    toHome: '返回课程首页',
    //home
    home: {
        auditionLoading: '正在前往课程试听页面...',
        aboutThisCourse: '关于本课',
        startLearning: '开始学习',
        welcome: '亲爱的同学， | 欢迎你来到',
        letter: {
            welcome: '你好，欢迎来到',
            teacher: '老师',
            thank: '谢谢老师'
        }
    },
    loading: '拼命加载中',
    networkError: '服务开小差，请联系学管',
    syllabus: {
        empty: '内容正在制作中',
        comingSoon: '敬请期待',
        resourceEmpty: '资源还在制作中，请稍等～',
        paperError: '试卷异常',
        liveNoStart: '别急，直播还未开始哦',
        liveNoPlayback: '暂无回放，请稍后再来',
        video: '视频',
        lectureNote: '讲义',
        paper: '测试',
        legacyLive: '直播',
        soft_link: '链接',
        gradation: '阶段',
        audition: '试学',
        hour: '小时',
        minute: '分钟',
        live_new: '直播',
        liveStatus: {
            playCountdown: '直播倒计时',
            playing: '直播已开始',
            playEnd: '直播已结束',
            noPlayback: '已结束，暂无回放',
            playback: '查看回放',
            toPlay: '进入直播',
            hasPlayback: '已结束，可回放',
            noClipPlayback: '无精简回放链接，请联系学管老师',
            abnormalState: '直播状态异常' 
        }
    },
    buyPopUp: {
        title: '该章节为付费课程，购买课程后即享更系统的学习和更贴心的服务！',
        consult: '咨询一下',
        buy: '去购课'
    },
    videoPlay: {
        note: '笔记',
        ask: '提问',
        knowledge: '知识点',
        enterNote: '添加我的笔记',
        myNote: '我的笔记',
        noNote: '暂无笔记',
        submitNote: '提交笔记',
        submitting: '提交中',
        deleteNoteFail: '删除笔记失败！',
        sureSaveNote: '是否保存提交笔记？',
        confirmSave: '保存',
        cancelSave: '不保存',
        saving: '保存中',
        updateNoteFail: '更新笔记失败！',
        createNoteFail: '新建笔记失败！',
        vip: 'VIP尊享特权',
        becomeVip: '成为VIP 学习完整课程 | 购买指定课程后 | 专享笔记特权与24小时智能答疑服务',
        buyVip: '立即购课',
        videoError: '视频资源错误',
        bulletScreen: {
            before: '亲爱的学员（专属ID：',
            after: '），您正在学习由高顿教育独家出品的课程！请保护好您的账号安全哦~'
        },
    },
    cancel: '取消',
    edit: '编辑',
    complete: '完成',
    delete: '删除',
    notDelete: '不删除',
    sureDelete: '确认删除吗',
    deleting: '删除中',
    notAsk: '您暂无权限提问',
    thisCourseNote: '本课笔记',
    thisCourseAsk: '本课提问',
    view: '查看',
    myAsk: '我的提问',
    noContent: '暂时没有内容哦~',
    total: '条',
    returnNote: '返回笔记',
    myNote: '我的笔记',
    nodeDetail: '笔记详情',
    chapterIdError: '阶段ID或者章节ID错误～',
    totalPages: '共 {page} 页',
    notAnswer: '未答',
    answered: '已答',
    downloadInformation: '下载资料',
    download: '下载',
    handoutTypeError: '获取讲义分类错误，请联系管理员',
    handoutError: '讲义资源异常！',
    livePlayback: '查看回放',
    liveToCourse: '去上课',
    liveNoStart: '未开始',
    liveNoPlayback: '暂无回放',
    goAsk: '去提问',
    turnText: '去转化',
    turnSuccess: '转换完成',
    turnFail: '转换失败',
    retry: '重试',
    turnTextFail: '转文字失败请稍后再试~',
    turnTexting: '转换中...',
    requireEnter: '该输入项为必填项!',
    maxEnter: '最多输入',
    enterNotEmpty: '输入的内容不能全为空格!',
    chineseText: '个汉字',
}
