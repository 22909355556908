<template>
    <div class="comm">
        <div class="commhead clear">
            <div class="return-space">
                <a :href="goBackConf.url" target="_blank">
                    <i class="return_icon"></i>
                    <span>{{goBackConf.name}}</span>
                </a>
            </div>
            <div class="course-name break-word">
                <h3 class="course-title">{{$store.state.home.course_name}}</h3>
            </div>

        </div>
        <canvas id="canvas" height="88"></canvas>
    </div>
</template>
<script>
    import headerCanvas from '../../plugins/headerCanavs'
    import {getEnv} from '../../util/config';
    import {SAAS_COURSE,SAAS_USER_ID} from '../../util/keys.js'
    import lang from '../../language/lang';

    export default {
        components: {
        },
        data() {
            return {
                prefix: getEnv(),
                canvas: ''
            }
        },
        mounted() {
            headerCanvas();
        },
        computed: {
            courseId() {
                return this.$route.params.cid
            },
            showLetter() {
                let path = this.$route;
                if (path.name == 'home') {
                    return true;
                } else {
                    return false;
                }
            },
            refSite() {
                return this.$route.query.refSite || '';
            },
            goBackConf() {
                if (this.refSite === 'finance') {
                    return {
                        url: `//${this.prefix}finance.gaodun.com`,
                        name: '领扣学堂'
                    }
                }
                return {
                    url: `//${this.prefix}v.gaodun.com/space/#/?project-tab=${this.$store.state.home.course_info.project_id}`,
                    name: lang[window.GD_LANG].space
                }
            }
        },
        methods: {
            async checkIsHasCourse() {
                return await this.$http.checkIsHasCourse({
                    course_id: this.courseId
                });
            },
            async getCourseInfoAndSetting() {
                return this.$http.getCourseInfoAndSetting(this.courseId)
            },
            async checkCourse() {
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0)'
                });
                const result = await Promise.all([
                    this.getCourseInfoAndSetting(),
                    this.checkIsHasCourse()
                ]);
                loading.close();
                const infoRet = result[0];  //课程介绍
                const checkRet = result[1];  //排课
                if (infoRet.status === 0) {
                    if (infoRet.result) {
                        infoRet.result.audition = checkRet.result; //体验课
                        localStorage.setItem(SAAS_COURSE, JSON.stringify(infoRet.result));
                        this.$store.dispatch('saveCourseInfoAndSetting', infoRet.result);
                    }

                    if (this.$store.state.home.bWelcomeLetter && this.showLetter && infoRet.result.welcomeLetterIsRead === 0) {
                        this.$store.dispatch('changeLetterDialog', true);
                    }
                }
                localStorage.setItem(SAAS_USER_ID, checkRet.result2.student_id);
            }
        },
        created() {
            this.checkCourse();
        }
    }
</script>
