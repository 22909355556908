<template>
    <div class="knowledge-list">
        <div class="knowledge-list-title">
            <p class="title-name black_16">{{activeKnowledge.title}}</p>
            <div class="title-tips">
                <i class="dot" style="background: #35c25e;"></i>
                <span>已掌握</span>
                <i class="dot" style="background: #fdc02f;"></i>
                <span>需加强</span>
                <i class="dot" style="background: #FFFFFF;border: 1px solid #35c25e;"></i>
                <span>未掌握</span>
            </div>
        </div>
        <div class="knowledge-list-content">
            <ul>
                <li class="knowledge-list-item" v-for="(item,index) in activeKnowledge.children" :key="index">
                    <!--<i class="dot" :class="[item.dot==0?'white_dot':'',item.dot==1?'orange_dot':'',item.dot==2?'green_dot':'']"></i>-->
                    <span class="knowledge-item-title">{{item.title}}</span>
                    <!--<span class="knowledge-item-tag">{{item.tag}}</span>-->
                    <div class="knowledge-item-action">
                        <button class="actions-btn" @click="checkDetails(item)">看讲解</button>
                        <button class="actions-btn">去做题</button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

    export default {
        components: {},
        data() {
            return {

            }
        },
        computed: {
            activeKnowledge(){
                return this.$store.state.knowledge.activeKnowledge;
            }
        },
        created() {

        },
        mounted() {
        },
        methods: {
            checkDetails(item){
                this.$router.push({
                    path:'/knowledge/dominoes/details',
                    query:this.$route.query
                })
            }
        }
    }
</script>
