import {post, get} from '../util/zeusAxios.js';
import {formPut,formPost} from '../util/zeusFormAxios.js'
import {getBaseUrl} from '../util/config'

//获取课程大纲下的笔记列表接口
export const getCourseNote= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/note/list`, params)

//获取课程下的做题记录
export const getExericeRecord= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/answers/count`, params)

//获取做题记录用于重新做题
export const getRedoing= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/answers/redoing`, params)

//学习记录-试题解析
export const getReAnalysis= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/answers/analysis`, params)

//学习记录 重做题目 交题
export const submitAnswers= (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/answers/submit`, params)

//学习记录 重做题目 交卷
export const submitPaper= (params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/answers/paper/submit`, params)

//【学习记录】-交卷后的试题解析
export const getRePaperAnalysis= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/answers/paper/analysis`, params)

//提问 我的提问
export const getMyquestions= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/carefree/api/v1/front/ask/course/questions`, params)

//ItemId获取ResourceId
// export const getResourceId= (item_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/toc-service/syllabus/item/${item_id}/resource/id`, params)
//ItemId获取ResourceId https://t-ep-course.gaodun.com/api/v1/syllabus/item/533508/resource/id
export const getResourceId= (item_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-course/api/v1/syllabus/item/${item_id}/resource/id`, params)

//提问详情
export const getQuizDetail= (question_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/question/${question_id}`, params)

//问题评价
export const appraisalQuiz= (id,params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/question/${id}/appraisal`, params)

//问题追问
export const traceQuiz= (id,params) => formPost(`${getBaseUrl()}apigateway.gaodun.com/study-api/question/${id}/trace`, params)

//获取提问详情的资源来源
export const getQuizSource= (url) => get(url)

//获取课程下的收藏的题目
export const getFavoriteRecord= (params) => get(`${getBaseUrl()}apigateway.gaodun.com/study-api/question/favorite/count`, params)

//网课2对题/错题/收藏题/笔记大纲结构列表
export const getStudyRecordStructure = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/record/syllabus/structure`, params)

//EP3 笔记列表// https://t-apigateway.gaodun.com/ep-paper/front/note/syllabus/cs-item-id?course_id=73015&cs_item_ids=215555,216414
export const getEP3NoteList = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-paper/front/note/syllabus/cs-item-id`, params)

// 获取luca 列表 
export const getLucaQuestionList = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/athena/api/v1/front/luca-ask/list`, params)

// 获取单个问题条目状态
export const getQuestionItemStatus = (id) => get(`${getBaseUrl()}apigateway.gaodun.com/athena/api/v1/front/luca-ask/chat/${id}`)

// 更新单个条目信息
export const updateQuestionItemStatus = (id) => post(`${getBaseUrl()}apigateway.gaodun.com/athena/api/v1/front/luca-ask/${id}/question/state`)

// 获取大纲条目上的项目科目id
export const getCsItemInfo = (params) => get('/ep-study/front/cs-item-info', params);