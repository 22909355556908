<template>
    <div class="paper-analysis">
        <div class="answer-box" v-if="!isShortAnswer">
            <div class="status_icon">
                <span class="right-icon" v-if="isTrue"></span>
                <span class="wrong-icon" v-if="!isTrue"></span>
            </div>
            <div class="answer-props">
                <span>正确答案</span>
                <i>{{yanswer}}</i>
                <span>您的答案</span>
                <i>{{user_answer && user_answer.toString()}}</i>
            </div>
        </div>
        <div class="answer-tip" v-if="isShortAnswer">
            {{type == 4 || (type == 5 && cur_Sm_type == 4) ? '填空题不计入分数': '简答题不计入分数'}}
        </div>
        <div class="analysis-box">
            <div class="analysisContent" v-if="isShortAnswer">
                <div class="ana-title">
                    正确答案：
                </div>
                <div v-html="yanswer"></div>
                <br />
                <div class="ana-title">你的答案</div>
                <div class="user_answer">
                    <div v-html="user_answer ? user_answer && user_answer.toString():'暂未回答'"></div>
                </div>
            </div>
            <div class="analysisContent">
                <div class="ana-title">解析</div>
                <div class="clear" style="position: relative;min-height: 150px;">
                    <div class="analysisTxt" v-html="question_analysis" v-if="question_analysis"
                         @click="previewImages($event)"></div>
                    <div class="analysisTxt"
                         v-if="!question_analysis"
                         style="font-size: 32px;color: #d3d3d3;text-align: center;line-height: 240px;"
                    >暂无解析
                    </div>
                    <div class="analysisVideo">
                        <template v-if="+is_video === 1 && video_analysis && video_analysis.uri">
                            <VideoPlayBox
                                :video-url="video_analysis && video_analysis.uri"
                                :modal-append-to-body="false"
                                :close-on-click-modal="false"
                            ></VideoPlayBox>
                        </template>
                        <template v-else>
                            <img id="default_video_img" src="../../assets/images/videoAnalysis.png" alt="">
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import VideoPlayBox from '../VideoPlayBox.vue'
    import {mapState} from 'vuex'

    export default {
        components: {
            VideoPlayBox
        },
        data() {
            return {}
        },
        props: [
            'user_answer',
            'yanswer',
            'is_video',
            'question_analysis',
            'video_analysis',
            'type',
            'cur_Sm_type'
        ],
        computed: {
            isTrue() {
                if (this.user_answer == this.yanswer) {
                    return true;
                } else {
                    return false;
                }
            },
            isShortAnswer() {
                if (this.type == 4 || this.type == 6 || (this.type == 5 && this.cur_Sm_type == 4) || (this.type == 5 && this.cur_Sm_type == 6)){
                    return true;
                } else {
                    return false;
                }
            }
        },
        mounted() {
        },
        methods: {
            previewImages(e) {
                this.$emit('previewImages', e);
            }
        },
        created() {
        }
    }
</script>
<style lang="less">
    .paper-analysis {
        .answer-tip {
            height: 30px;
            background: rgba(53, 194, 94, 0.15);
            text-indent: 40px;
            line-height: 30px;
            color: #35C25E;
            font-size: 12px;
            margin-top: 40px;
            margin-bottom: -10px;
        }
        .analysisContent {
            .user_answer {
                border: 1px solid #EBEBEC;
                background: #FBFBFB;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                padding: 20px;
                font-size: 16px;
                line-height: 22px;
                word-break: break-all;

            }
            & + .analysisContent {
                margin-top: 20px;
            }
        }
    }
</style>
