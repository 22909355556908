<template>
    <div class="toolrgt">
        <div class="tooltab">
            <el-tabs v-model="activeName2" class="video-tool-tabs" @tab-click="buryPoint">
                <el-tab-pane
                    :label="$t('videoPlay.note')"
                    name="1"
                ></el-tab-pane>
                <el-tab-pane
                    v-if="!luca_id"
                    :label="$t('videoPlay.ask')"
                    name="2"
                ></el-tab-pane>
            </el-tabs>
        </div>
        <div v-show="activeName2 == 1" class="handletab" v-if="player">
            <BuyCourse v-if="isTiyanke"></BuyCourse>
            <NoteList v-else :player="player"></NoteList>
        </div>
        <div v-show="activeName2 == 2" class="handletab" v-if="player && !luca_id">
            <BuyCourse v-if="isTiyanke"></BuyCourse>
            <QuestionList
                :video_id="video_id"
                v-else
                :player="player"
            ></QuestionList>
        </div>
    </div>
</template>
<style>
.handletab {
    width: 100%;
    height: -moz-calc(100% - 60px);
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px);
    transition: all 0.2s linear;
    margin-top: 20px;
}
</style>
<script>
import VM from "../../common/Vue.js";
import NoteList from "../videoStudy/NoteList.vue";
import QuestionList from "../videoStudy/QuestionList.vue";
import BuyCourse from "../videoStudy/BuyCourse.vue";
import { bindEvents } from "../../util/sensor-data.js";
import { W2_STUDYVIEDO_DRAWER_CLICK_NOTE, W2_STUDYVIEDO_DRAWER_CLICK_QUESTION } from '@/config';

export default {
    components: {
        NoteList,
        QuestionList,
        BuyCourse,
    },
    props: ['activeName', 'video_id', 'player'],
    data() {
        return {
            activeName2: "1",
        };
    },
    computed: {
        luca_id() {
            return this.$store.state.home.course_info.luca_qa_id
        },
        course_id() {
            return this.$route.query.course_id;
        },
        resource_id() {
            return this.$route.query.resource_id; // 资源id
        },
        syllabusitem_id() {
            return this.$route.query.syllabusitem_id; // 大纲条目id
        },
        isTiyanke() {
            return this.$store.state.home.course_info.audition;
        },
    },
    mounted() {
        VM.$on("videostatus", (activecur) => {
            this.activeName2 = String(activecur);
        });
    },
    methods: {
        buryPoint() { 
            this.activeName2 == 1 ? bindEvents(W2_STUDYVIEDO_DRAWER_CLICK_NOTE) : this.activeName2 == 2 ? bindEvents(W2_STUDYVIEDO_DRAWER_CLICK_QUESTION) : null;
        },
    }
};
</script>
