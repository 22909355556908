import {put, get,post} from '../util/zeusAxios';
import {getBaseUrl} from '../util/config'

//拉取课程下的资源介绍接口
export const getCourseResourceIntro = (course_id,params) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-course/api/v1/course/${course_id}/source`, params)

//课程基本设置拉取课程信息接口
export const getCourseInfo = (url,params) => get(`${getBaseUrl()}apigateway.gaodun.com/course-api/course/${url}`, params)

//获取token
export const getToken = (AppID,GDSSID,params) =>get(`${getBaseUrl()}apigateway.gaodun.com/api/v1/token/getbygdssid?AppID=${AppID}&GDSSID=${GDSSID}`, params)

//拉取课程内容管理设置
export const getMenu = (course_id,params) =>get(`${getBaseUrl()}apigateway.gaodun.com/course-api/course/${course_id}/setting`, params)

//获取课程信息和课程基本设置
export const getCourseInfoAndSetting = (courseId) => get(`${getBaseUrl()}apigateway.gaodun.com/ep-course/api/v1/front/course/${courseId}`);

//检查学生是否排课
export const checkIsHasCourse = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/caen/v1/course/assign`, params);

//【前台】更新网课2学员进课程欢迎信为已读
export const readWelcomeLetter = (courseId, params) => put(`${getBaseUrl()}apigateway.gaodun.com/ep-course/api/v1/front/course/${courseId}/welcome-letter/is-read`, params);

//NPS问题列表查询
export const evaluationQList = (params) => get(`${getBaseUrl()}apigateway.gaodun.com/evaluation/api/v1/evaluation/${params.sceneId}`,params);
//NPS问题提交
export const evaluationSave = (params) => post(`${getBaseUrl()}apigateway.gaodun.com/evaluation/api/v1/evaluation/save`, params);


