<template>
    <div class="paper-study study-container analysis-paper">
        <div class="answer-sheet" ref="answersheet">
            <AnalysisAnswerSheet
                :closeSheet="closeSheet"
                :getToTop="getToTop"
                :changeSheet="changeSheet"
                :cur_data_index="paper_cur_question_index"
                :getQuesIndex="getQuesIndex"
                :question_id="question_id"
                :cur_data="paper_data"
                :recm_data="paper_recm_data"
            >
            </AnalysisAnswerSheet>
        </div>
        <div class="paper-header study-container-header clear">
            <div class="paper-header-tools clear">
                <div class="header-tools-l timer"></div>
                <div class="header-tools-r clear">
                    <!-- <span class="calc-btn tools-btn"><i class="btn-icon calc-icon"></i><span style="vertical-align: middle">计算器</span></span> -->
                    <!--1是没收藏  2是收藏了-->
                    <span
                        class="collect-btn tools-btn active"
                        v-if="paper_cur_favorite"
                        @click="cancelFavorite"
                    >
                        <i class="btn-icon collect-icon"></i>
                        <span style="vertical-align: middle">收藏本题</span>
                    </span>
                    <span
                        class="collect-btn tools-btn"
                        v-if="!paper_cur_favorite"
                        @click="favoriteQuestion"
                    >
                        <i class="btn-icon collect-icon"></i>
                        <span style="vertical-align: middle">收藏本题</span>
                    </span>
                    <span class="card-btn tools-btn" @click="openSheet"
                        >答题卡</span
                    >
                </div>
            </div>
            <el-progress
                class="progress-bar"
                :show-text="false"
                :percentage="20"
                status="success"
            ></el-progress>
        </div>
        <div
            class="paper-content study-container-content clear"
            id="paperContent"
            v-loading="zLoading"
            :element-loading-text="loadingText"
        >
            <div style="width: 1000px; margin: 0 auto">
                <!--  [1=> '单选题', 2=> '多选题', 3=> '判断题', 4=> '填空题', 5=> '综合题', 6=> '简答题', 7=> '不定项'] -->
                <Single
                    v-if="paper_cur_type == 1"
                    :titleAudio="paper_cur_questions.title_audio"
                    :type="paper_cur_type"
                    :title="paper_cur_questions.title"
                    :option="paper_cur_questions.option"
                    :optionNum="paper_cur_questions.partnum"
                    :answer="optionsAns"
                    :cur_data_index="paper_cur_question_index"
                    @previewImages="previewImages"
                    :total_data="paper_total_questions"
                >
                </Single>
                <Multiple
                    v-if="
                        paper_cur_type == 2 ||
                        paper_cur_type == 7 ||
                        paper_cur_type == 52
                    "
                    :titleAudio="paper_cur_questions.title_audio"
                    :type="paper_cur_type"
                    :title="paper_cur_questions.title"
                    :option="paper_cur_questions.option"
                    :optionNum="paper_cur_questions.partnum"
                    :answer="optionsAns"
                    :cur_data_index="paper_cur_question_index"
                    @previewImages="previewImages"
                    :total_data="paper_total_questions"
                >
                </Multiple>
                <TFNG
                    v-if="paper_cur_type == 3"
                    :titleAudio="paper_cur_questions.title_audio"
                    :type="paper_cur_type"
                    :title="paper_cur_questions.title"
                    :answer="optionsAns"
                    :cur_data_index="paper_cur_question_index"
                    @previewImages="previewImages"
                    :total_data="paper_total_questions"
                >
                </TFNG>
                <ShortAnswer
                    v-if="paper_cur_type == 4 || paper_cur_type == 6"
                    :titleAudio="paper_cur_questions.title_audio"
                    :type="paper_cur_type"
                    :title="paper_cur_questions.title"
                    :answer="optionsAns"
                    :isAnalysis="isAnalysis"
                    :cur_data_index="paper_cur_question_index"
                    @previewImages="previewImages"
                    :total_data="paper_total_questions"
                >
                </ShortAnswer>
                <Integrate
                    v-if="paper_cur_type == 5"
                    :cur_data="paper_cur_Sm_question"
                    :data_child="paper_cur_questions.sonitem"
                    :titleAudio="paper_cur_questions.title_audio"
                    :type="paper_cur_type"
                    :answer="optionsAns"
                    :cur_Sm_type="paper_cur_Sm_type"
                    :title="paper_cur_questions.title"
                    :cur_data_index="paper_cur_question_index"
                    :total_data="paper_total_questions"
                    :active="activeQuestion"
                    @nextSmQuestion="nextSmQuestion"
                    @prevSmQuestion="prevSmQuestion"
                    @previewImages="previewImages"
                    :isAnalysis="isAnalysis"
                    @changeSmQuestion="changeSmQuestion"
                >
                </Integrate>
                <AnalysisContent
                    :user_answer="analysis_userAnswer"
                    :yanswer="paper_yanswer"
                    :type="paper_cur_type"
                    :cur_Sm_type="paper_cur_Sm_type"
                    :is_video="paper_is_video"
                    :question_analysis="paper_cur_analysis"
                    @previewImages="previewImages"
                    :video_analysis="paper_video_analysis"
                >
                </AnalysisContent>
                <AnalysisTools></AnalysisTools>
            </div>
        </div>
        <AnalysisFoot
            :getToTop="getToTop"
            :nextButton="nextButton"
            :prevButton="prevButton"
            :cur_data_index="paper_cur_question_index"
            :total_data="paper_total_questions"
            :goReport="goReport"
        ></AnalysisFoot>
    </div>
</template>
<style>
.paper-study.analysis-paper .el-loading-mask {
    height: 100%;
    background: #f4f7f6;
}
</style>
<script>
import {
    getPaperReportEp3Task,
    getPaperStatusEp3Task,
} from "../api/study.js";
import { favoriteOrDelQuestionEP3Task } from "../api/studyForm.js";
import { showBigImg } from "../util/showBigImg.js";
import AnalysisFoot from "../components/paperAnalysis/AnalysisModuleFoot.vue";
import AnalysisAnswerSheet from "../components/paperAnalysis/AnalysisModuleAnswerSheet.vue";
import AnalysisContent from "../components/paperAnalysis/AnalysisModuleContent.vue";
import AnalysisTools from "../components/paperAnalysis/AnalysisModuleTools.vue";
import Single from "../components/paperExercise/PaperModuleSingle.vue";
import Multiple from "../components/paperExercise/PaperModuleMultiple.vue";
import TFNG from "../components/paperExercise/PaperModuleTFNG.vue";
import Integrate from "../components/paperExercise/PaperModuleIntegrate.vue";
import ShortAnswer from "../components/paperExercise/PaperModuleShortAnswer.vue";
import { mapState } from "vuex";
import Vue from "../common/Vue.js";
import { loadingText } from "../common/loadingTextConfig.js";

export default {
    components: {
        AnalysisFoot,
        AnalysisAnswerSheet,
        AnalysisContent,
        AnalysisTools,
        Single,
        Multiple,
        Integrate,
        TFNG,
        ShortAnswer,
    },
    data() {
        return {
            syllabus_id: "", //大纲id
            course_id: "", //课程id
            resource_id: "", //资源id
            cs_item_id: "", //大纲条目id
            paper_id: "",
            paper_data_id: "",
            answersheet: "", //答题卡
            zLoading: false,
            loadingText: "",
            isAnalysis: true,
        };
    },
    computed: {
        ...mapState({
            paper_cur_type: (state) => {
                return state.paper.paper_cur_type;
            },
            paper_cur_favorite: (state) => {
                return state.paper.paper_cur_favorite;
            },
            lastOneFlag: (state) => {
                return state.paper.lastOneFlag;
            },
            paper_cur_questions: (state) => {
                // if(state.paper.paper_cur_type == 5){
                // //     为综合题的小题是,则读取小题的题目
                //     return state.paper.paper_cur_Sm_question;
                // }else{
                return state.paper.paper_cur_questions;
                // }
            },
            paper_cur_Sm_question: (state) => {
                return state.paper.paper_cur_Sm_question;
            },
            optionsAns: (state) => {
                if (
                    state.paper.paper_cur_type == 2 ||
                    state.paper.paper_cur_type == 52 ||
                    state.paper.paper_cur_type == 7 ||
                    (state.paper.paper_cur_type == 5 &&
                        state.paper.paper_cur_Sm_type == 2) ||
                    (state.paper.paper_cur_type == 5 &&
                        state.paper.paper_cur_Sm_type == 7)
                ) {
                    return !!state.paper.paper_cur_userAnswer
                        ? state.paper.paper_cur_userAnswer.split(",")
                        : [];
                } else {
                    // return state.paper.paper_cur_userAnswer.content;
                    if (state.paper.paper_cur_type == 5) {
                        const { user_answer } =
                            state.paper.paper_cur_Sm_question;
                        return (user_answer && user_answer.user_answer) || "";
                    } else {
                        const { user_answer } = state.paper.paper_cur_questions;
                        return (user_answer && user_answer.user_answer) || "";
                    }
                }
            },
            paper_cur_question_index: (state) => {
                return state.paper.paper_cur_question_index;
            },
            paper_total_questions: (state) => {
                //试卷的总题数
                return state.paper.paper_data.length;
            },
            paper_cur_Sm_type: (state) => {
                return state.paper.paper_cur_Sm_type;
            },
            activeQuestion: (state) => {
                return String(state.paper.paper_cur_Sm_question_index);
            },
            paper_data: (state) => {
                return state.paper.paper_data;
            },
            //按照类型重组的试卷
            paper_recm_data: (state) => {
                return state.paper.paper_recm_data;
            },
            paper_cur_analysis: (state) => {
                const { paper_cur_questions, paper_cur_Sm_question_index } =
                    state.paper;
                if (
                    paper_cur_questions.sonitem &&
                    paper_cur_questions.sonitem.length > 0
                ) {
                    return paper_cur_questions.sonitem[
                        paper_cur_Sm_question_index - 1
                    ].analysis;
                } else {
                    return paper_cur_questions && paper_cur_questions.analysis;
                }
            },
            //用户答案
            analysis_userAnswer: (state) => {
                const { user_answer } = state.paper.paper_cur_questions;
                if (
                    state.paper.paper_cur_type == 3 ||
                    (state.paper.paper_cur_type == 5 &&
                        state.paper.paper_cur_Sm_type == 3)
                ) {
                    const u_answer =
                        (user_answer && user_answer.user_answer) || "";
                    if (u_answer == "1") {
                        return "对";
                    } else if (u_answer == "0") {
                        return "错";
                    } else {
                        return u_answer;
                    }
                } else {
                    return state.paper.paper_cur_userAnswer;
                }
            },
            //正确答案
            paper_yanswer: (state) => {
                const { paper_cur_questions } = state.paper;
                if (paper_cur_questions.type == 5) {
                    const { paper_cur_Sm_question } = state.paper;
                    const { answer } = paper_cur_Sm_question;
                    if (paper_cur_Sm_question.type == 3) {
                        if (answer == "1") {
                            return "对";
                        } else if (answer == "0") {
                            return "错";
                        } else {
                            return answer;
                        }
                    } else {
                        return answer;
                    }
                } else {
                    const { answer } = paper_cur_questions;
                    if (paper_cur_questions.type == 3) {
                        if (answer == "1") {
                            return "对";
                        } else if (answer == "0") {
                            return "错";
                        } else {
                            return answer;
                        }
                    } else {
                        return answer;
                    }
                }
                // if (state.paper.paper_cur_type == 3 || (state.paper.paper_cur_type ==5 && state.paper.paper_cur_Sm_type == 3)) {
                //     if (answer == "1") {
                //         return "对";
                //     } else if (answer == "0") {
                //         return "错";
                //     } else {
                //         return answer;
                //     }
                // }else if (state.paper.paper_cur_type == 4 || (state.paper.paper_cur_type ==5 && state.paper.paper_cur_Sm_type == 4)){
                //     return "填空题不计入分数";
                // }else if (state.paper.paper_cur_type == 6 || (state.paper.paper_cur_type ==5 && state.paper.paper_cur_Sm_type == 6)){
                //     return "简答题不计入分数";
                // } else {//paper_yanswer
                //     return state.paper.paper_cur_type ==5 ? state.paper.paper_cur_Sm_question.answer : answer;
                // }
            },
            paper_is_video: (state) => {
                return state.paper.paper_is_video;
            },
            paper_video_analysis: (state) => {
                return state.paper.paper_video_analysis;
            },
        }),
        question_id: {
            get: function () {
                return this.$store.state.paper.paper_data &&
                    this.$store.state.paper.paper_data.length !== 0
                    ? this.$store.state.paper.paper_data[
                          this.$store.state.paper.paper_cur_question_index - 1
                      ].id
                    : 0;
            },
            set: function () {},
        },
        project_id() {
            return this.$store.state.home.course_info.project_id;
        },
        subject_id() {
            return this.$store.state.home.course_info.subject_id;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.answersheet = this.$refs.answersheet;
            // showBigImg();
            document.body.onmousedown = (e) => {
                let ele = e.target;
                if (!ele) {
                    ele = e.srcElement;
                }
                let falg = true;
                function checkParent(ele) {
                    if (
                        ele.offsetParent &&
                        ele.offsetParent.className === "answer-sheet"
                    ) {
                        falg = false;
                    } else if (
                        ele.offsetParent &&
                        ele.offsetParent.className !== "answer-sheet"
                    ) {
                        checkParent(ele.offsetParent);
                    } else if (ele.offsetParent === null) {
                        falg = true;
                    }
                }
                checkParent(ele);
                if (falg) {
                    this.closeSheet();
                }
            };
        });
    },
    methods: {
        //下一题
        nextButton() {
            this.$store.dispatch("nextQuestion");
            this.getToTop();
            Vue.$emit("clearContent");
            Vue.$emit("updatenote");
            //事件通知拉取常见问题
            Vue.$emit("UpdateCommQues");
            // this.$nextTick(() => {
            //     showBigImg();
            // });
        },
        //上一题
        prevButton() {
            this.$store.dispatch("prevQuestion");
            this.getToTop();
            Vue.$emit("clearContent");
            Vue.$emit("updatenote");
            //事件通知拉取常见问题
            Vue.$emit("UpdateCommQues");
            // this.$nextTick(() => {
            //     showBigImg();
            // });
        },
        //返回报告页
        goReport() {
            this.$router.push({
                path: "/study/paper/report",
                query: this.$route.query,
            });
        },
        //切换答题卡
        changeSheet(item) {
            this.question_id = item.id;
            let index = this.getQuesIndex(item.id);
            this.$store.dispatch("changeSheet", index);
            this.getToTop();
            Vue.$emit("updatenote");
            //事件通知拉取常见问题
            Vue.$emit("UpdateCommQues");
            // this.$nextTick(() => {
            //     showBigImg();
            // });
        },
        //遍历题目列表拿到题目的索引
        getQuesIndex(id) {
            let paper_data = this.$store.state.paper.paper_data;
            for (var i = 0; i < paper_data.length; i++) {
                if (paper_data[i].id == id) {
                    return { question_index: i };
                }
            }
        },
        //切换综合题小题
        changeSmQuestion(index) {
            this.$store.dispatch("changeSmQuestion", index);
            Vue.$emit("updatenote");
            //事件通知拉取常见问题
            Vue.$emit("UpdateCommQues");
        },
        nextSmQuestion() {
            this.$store.dispatch("nextSmQuestion");
        },
        prevSmQuestion() {
            this.$store.dispatch("prevSmQuestion");
        },
        getToTop() {
            var container = this.$el.querySelector("#paperContent");
            container.scrollTop = 0;
        },
        //折叠答疑卡
        closeSheet() {
            if (!this.answersheet) {
                this.answersheet = this.$refs.answersheet;
            }
            this.answersheet.style.right = "-330px";
        },
        openSheet() {
            if (!this.answersheet) {
                this.answersheet = this.$refs.answersheet;
            }
            this.answersheet.style.right = "0";
        },
        //获取试卷状态
        async getPaperStatus() {
            this.course_id = this.$route.query.course_id;
            this.syllabus_id = this.$route.query.syllabus_id;
            this.resource_id = this.$route.query.resource_id;
            this.cs_item_id = this.$route.query.syllabusitem_id;
            let resource_id = this.resource_id;
            let params = {
                course_id: this.course_id,
                cs_item_id: this.cs_item_id,
                resource_id: this.resource_id,
            };
            this.loadingText = loadingText();
            // this.zLoading = true;
            // let ret = await getPaperStatus(resource_id, params);
            const ret = await getPaperStatusEp3Task(params);
            if (ret.status == 0) {
                if (ret.result.is_submit == 1) {
                    //做完获取报告
                    this.$store.dispatch("changeIsSubmit", true); //交卷了
                    this.paper_id = ret.result.paper_id;
                    this.paper_data_id = ret.result.paper_data_id;
                    await this.getPaperReport();
                    // this.zLoading = false;
                } else if (ret.result.is_submit == 0) {
                    //没做跳转练习页
                    this.$store.dispatch("changeIsSubmit", false); //mei交卷了
                    this.$router.push({
                        path: "/study/paper/exercise",
                        query: this.$route.query,
                    });
                }
            } else {
                this.zLoading = false;
                console.error("获取试卷状态", ret.status);
            }
        },
        //获取报告
        async getPaperReport() {
            let course_id = this.course_id;
            let syllabus_id = this.syllabus_id;
            const paper_data_log_id = this.$route.query.paper_data_log_id;
            let paper_id = this.paper_id;
            let params = {
                course_id: this.course_id,
                // cs_item_id: this.cs_item_id,
                // paper_data_id: this.$store.state.paper.paper_data_id,
                // paper_data_id: this.paper_data_id,
                paper_data_log_id,
                syllabus_id,
                paper_id,
            };
            let ret = await getPaperReportEp3Task(params);
            if (ret.status == 0) {
                this.$store.dispatch("getReport", ret.result);
                this.$store.dispatch("savePaper", ret.result.paper);
                //事件通知拉取笔记
                Vue.$emit("updatenote");
                //事件通知拉取常见问题
                Vue.$emit("UpdateCommQues");
            } else {
                // 无其余异常状态码
                // this.$router.replace({
                //     name: 'Resource404',
                //     query: this.$route.query,
                // })
                // return;
            }
        },
        //收藏题目
        async favoriteQuestion() {
            let course_id = this.course_id;
            let syllabus_id = this.syllabus_id;
            let paper_id = this.$store.state.paper.paper_id;
            let params = {
                question_id: this.$store.state.paper.paper_cur_questions.id,
                course_id: course_id,
                cs_item_id: this.$route.query.syllabusitem_id, //大纲条目id
                project_id: this.project_id,
                subject_id: this.subject_id,
                part_id: this.$route.query.partId,
                paper_id,
                handle_type: 1, //1收藏，2取消
            };
            let ret = await favoriteOrDelQuestionEP3Task(params);
            if (ret.status == 0) {
                this.$message({
                    type: "success",
                    message: "收藏成功",
                });
                this.$store.dispatch("changeFavorite", true);
            } else {
                this.$message({
                    type: "error",
                    message: "收藏失败",
                });
            }
        },
        //取消收藏
        async cancelFavorite() {
            let course_id = this.course_id;
            let syllabus_id = this.syllabus_id;
            let paper_id = this.$store.state.paper.paper_id;
            let params = {
                question_id: this.$store.state.paper.paper_cur_questions.id,
                course_id: course_id,
                cs_item_id: this.$route.query.syllabusitem_id, //大纲条目id
                project_id: this.project_id,
                subject_id: this.subject_id,
                part_id: this.$route.query.partId,
                paper_id,
                handle_type: 2, //1收藏，2取消收藏
            };
            let ret = await favoriteOrDelQuestionEP3Task(params);
            if (ret.status == 0) {
                this.$message({
                    type: "success",
                    message: "取消收藏成功",
                });
                this.$store.dispatch("changeFavorite", false);
            } else {
                this.$message({
                    type: "error",
                    message: "取消收藏失败",
                });
            }
        },
        //初始化试卷
        destroyPaper() {
            this.$store.dispatch("destroyPaper");
        },
        previewImages(e) {
            let myEvent = e;
            let myTarget = e.target ? e.target : e.srcElement;
            let self = this;
            let winW = document.body.offsetWidth;
            let winH = document.body.offsetHeight;
            let oBigImg = document.querySelector("#bigimg");
            let oIndiv = document.querySelector(".indiv");
            let oOutdiv = document.querySelector("#outdiv");
            if (myTarget.nodeName.toLocaleUpperCase() === "IMG") {
                let imgEle = new Image();
                imgEle.src = myTarget.currentSrc.split("?")[0];
                imgEle.onload = function () {
                    let imgW = this.width;
                    let imgH = this.height;
                    let scale = imgW / imgH;
                    document.querySelector("#bigimg").src = this.src;
                    if (imgW > winW) {
                        oBigImg.style.width = "100%";
                        oBigImg.style.height = "auto";
                        imgH = winW / scale;
                        let h = (winH - imgH) / 2;
                        oIndiv.style.left = "0px";
                        oIndiv.style.top = h + "px";
                    } else {
                        if (imgH > winH) {
                            oIndiv.style.height = "100%";
                            oBigImg.style.width = "auto";
                            oBigImg.style.height = "100%";
                            imgW = winH * scale;
                            let w = (winW - imgW) / 2;
                            oIndiv.style.left = w + "px";
                            oIndiv.style.top = "0px";
                        } else {
                            oBigImg.style.width = imgW + "px";
                            oBigImg.style.height = imgH + "px";
                            let w = (winW - imgW) / 2;
                            let h = (winH - imgH) / 2;
                            oIndiv.style.left = w + "px";
                            oIndiv.style.top = h + "px";
                        }
                    }
                    oOutdiv.style.display = "block";
                    oOutdiv.onclick = function () {
                        oOutdiv.style.display = "none";
                    };
                };
            }
        },
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: ["destroyPaper", "getPaperStatus"],
        // "$route":function () {
        //     location.reload();
        // },
    },
    created() {
        this.course_id = this.$route.query.course_id;
        this.syllabus_id = this.$route.query.syllabus_id;
        this.resource_id = this.$route.query.resource_id;
        this.cs_item_id = this.$route.query.syllabusitem_id;
        this.getPaperStatus();
    },
    beforeDestroy() {
        this.$store.dispatch("destroyPaper");
    },
};
</script>
<style lang="less">
.analysis-paper {
    .short-answer {
        .response {
            display: none;
        }
    }
}
</style>
