import {
    COURSE_ID,
    RESOURCE_ID,
    SYLTEM_ID,
    SYLLABUS_ATTR,
    SYLLABUS_ITEMS,
    CURRENT_RESOURCE,
    NEXT_RESOURCE,
    STUDY_PATH,
    SYITEM_NAME,
    RESOURCE_LIST
} from '../mutation-types';


const state = {
    isSendXhr: true, // 是否发送请求标识
    course_Id: '', //课程所有信息
    resource_Id: '', // 资源Id
    sylllabusId: '', //大纲id
    studySyllabusTree: [], //大纲的条目
    sylllabusItemId: '', //当前资源大纲条目id
    sylllabusItemName: '', //当前资源大纲条目名称
    parentId: [], //当前激活的资源的父级条目
    nextResource: '', //下一个资源
    StudyPath: '', //跳转到学习需要的路径
    ResourceList: [] // 资源列表
}

const actions = {
    getStudyPathConfig({ commit, state }, data) {
        commit(STUDY_PATH, path)
    },
    studyCourseId({ commit, state }, id) {
        commit(COURSE_ID, id)
    },
    studySyItem_id({ commit, state }, id) {
        commit(SYLTEM_ID, id)
    },
    studySyllabusAttr({ commit, state }, data) {
        commit(SYLLABUS_ATTR, data)
    },
    //保存大纲条目 并处理折叠和点亮的初始化
    saveStudySyllabusItems({ commit, state }, data) {
        let syllabusData = [...data];
        for (var item of syllabusData) {
            item.chapterCollapse = false;
            item.active = false;
            for (var value of item['children']) {
                value.sectionCollapse = false;
                value.active = false;
            }
        }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //折叠章
    changeStudyChapterCollapse({ commit, state }, data) {
        let syllabusData = [...state.studySyllabusTree];
        syllabusData[data.indexfir].chapterCollapse = !syllabusData[data.indexfir].chapterCollapse;
        if (state.parentId.indexOf(syllabusData[data.indexfir]['id']) > -1) {
            syllabusData[data.indexfir].active = !syllabusData[data.indexfir].active;
        }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //折叠节
    changeStudySectionCollapse({ commit, state }, data) {
        let syllabusData = [...state.studySyllabusTree];
        syllabusData[data.indexfir]['children'][data.indexsec].sectionCollapse = !syllabusData[data.indexfir]['children'][data.indexsec].sectionCollapse;
        if (state.parentId.indexOf(syllabusData[data.indexfir]['children'][data.indexsec]['id']) > -1) {
            syllabusData[data.indexfir]['children'][data.indexsec].active = !syllabusData[data.indexfir]['children'][data.indexsec].active;
        }
        commit(SYLLABUS_ITEMS, syllabusData)
    },
    //点亮当前的资源条目 适应多层模板  记下当前资源的父级模板
    changeActiveResource({ commit, state }, id) {
        let syData = state.studySyllabusTree;
        let ResourceList = []; //资源列表
        function ForEachData(data, itemId) {
            data.forEach((val, index) => {
                val.parentId = [val.id]; //父级的id
                if (itemId) {
                    val.parentId = [...itemId, ...val.parentId]
                }
                if (val.id == id) {
                    val.active = true;
                    commit(CURRENT_RESOURCE, val); //当前资源
                } else {
                    val.active = false;
                }
                if (val.children && val.children.length != 0) {
                    ForEachData(val.children, val.parentId);
                } else if (val.resource_id) {
                    ResourceList.push(val); //将所有资源编成一个数组
                }
            })
        }
        ForEachData(syData);
        let parentId = state.parentId;
        //折叠
        for (var item of syData) {
            if (item.id == parentId[0]) {
                item.chapterCollapse = true;
            } else {
                item.chapterCollapse = false;
            }
            for (var value of item['children']) {
                if (value.id == parentId[1]) {
                    value.sectionCollapse = true;
                } else {
                    value.sectionCollapse = false;
                }
            }
        }
        commit(SYLLABUS_ITEMS, syData);
        commit(RESOURCE_LIST, ResourceList);
    },
    save_next_resource({ commit, state }, syllabusitem_id) {
        let ResourceList = state.ResourceList;
        let len = ResourceList.length;
        let Resource = '';
        ResourceList.forEach((item, index) => {
            if (item.id == syllabusitem_id && (index + 1 < len)) {
                Resource = ResourceList[index + 1].resource_id ? ResourceList[index + 1] : '';
            }
        })
        commit(NEXT_RESOURCE, Resource);
    }
}

const mutations = {
    [STUDY_PATH](state, path) {
        state.StudyPath = path;
    },
    [COURSE_ID](state, id) {

        state.course_Id = id;
    },
    [RESOURCE_ID](state, id) {
        state.resource_Id = id;
    },
    [SYLTEM_ID](state, id) {
        state.sylllabusItemId = id;
    },
    [SYITEM_NAME](state, name) {
        state.sylllabusItemName = name;
    },
    [SYLLABUS_ATTR](state, data) {
        state.sylllabusId = data;
    },
    [SYLLABUS_ITEMS](state, data) {
        state.studySyllabusTree = data;
    },
    [CURRENT_RESOURCE](state, data) {
        state.parentId = data.parentId;
        state.sylllabusItemName = data.name;
    },
    [RESOURCE_LIST](state, data) {
        state.ResourceList = data;
    },
    [NEXT_RESOURCE](state, data) {
        state.nextResource = data;
    },
    isSendXhr(state, data) {
        state.isSendXhr = data;
    }

}

export default {
    state,
    actions,
    mutations,
}
