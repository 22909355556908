import { render, staticRenderFns } from "./BigImg.vue.js?vue&type=template&id=14654414!=!../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./BigImg.vue?vue&type=template&id=14654414"
var script = {}
import style0 from "./BigImg.vue.css?vue&type=style&index=0&id=14654414&prod&lang=css!=!../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/index.js??vue-loader-options!./BigImg.vue?vue&type=style&index=0&id=14654414&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/npm-verdaccio.gaodunwangxiao.com+vue-loader@15.11.1_h5mvtpw3u6rkkijarfanlod62a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports