<template>
    <div class="notelist">
        <div class="questiontype">
            <a href="javascript:void (0)" class="notebtn" @click="goGbot">
                {{ $t("goAsk") }}
            </a>
        </div>
        <canvas style="display: none"></canvas>
        <div
            class="questiontable"
            v-if="isShowQuestion"
            v-loading="loading1"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="#f4f7f6"
        >
            <div class="questiontit">常见问题 <i class="bottom__line"></i></div>
            <div class="planebox">
                <ul v-if="quizlist && quizlist.length != 0">
                    <li v-for="item in quizlist" :key="item.id">
                        <div class="planp1">
                            <a
                                :href="
                                    '//' +
                                    pre +
                                    'gbot.gaodun.com/question-detail/' +
                                    item.id
                                "
                                target="_blank"
                                >{{ item.content }}</a
                            >
                        </div>
                        <div class="plannote">
                            <span class="plantime">
                                {{ item.video_time | secondToDate }}
                            </span>
                            <span class="plansys questionmin">
                                {{ (item.created_at * 1000) | number2DateTime }}
                            </span>
                        </div>
                    </li>
                </ul>
                <div class="noteempty" v-if="!quizlist || quizlist.length == 0">
                    暂无问题
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.notelist {
    width: 88%;
    height: -moz-calc(100% - 16px);
    height: -webkit-calc(100% - 16px);
    height: calc(100% - 16px);
    transition: all 0.2s linear;
}

.questiontable {
    margin-top: 18px;
    width: 100%;
    height: -moz-calc(100% - 110px);
    height: -webkit-calc(100% - 110px);
    height: calc(100% - 110px);
    transition: all 0.2s linear;
}

.questiontable .planebox {
    width: -moz-calc(100% + 10px);
    width: -webkit-calc(100% + 10px);
    width: calc(100% + 10px);
    height: -moz-calc(100% - 45px);
    height: -webkit-calc(100% - 45px);
    height: calc(100% - 45px);
    overflow: auto;
    transition: all 0.2s linear;
}

.planp1 {
    > a {
        color: #898b92;

        &:hover {
            opacity: 0.7;
        }
    }
}
</style>

<script>
import { getEnv } from "../../util/config.js";
import { commonQuestion } from "../../api/study.js";
import { ACCESS_TOKEN } from "../../util/keys.js";
import { getCookie } from "../../util/cookie.js";
import lang from "../../language/lang";
import { bindEvents } from "../../util/sensor-data.js";
import { W2_STUDYVIEDO_DRAWER_CLICK_NOTE_ASKQUESTIONS } from "@/config";

let prefix = getEnv();
export default {
    props: ["video_id", "player"],
    data() {
        return {
            textarea: "",
            checkList: [],
            quizlist: [],
            loading1: false,
            pre: getEnv(),
        };
    },
    computed: {
        allow_question() {
            return +this.$store.state.home.course_info.allow_question === 1;
        },
        project_id() {
            return this.$store.state.home.course_info.project_id;
        },
        subject_id() {
            return this.$store.state.home.course_info.subject_id;
        },
        isShowQuestion() {
            return window.GD_LANG === "zh-CN";
        },
    },
    methods: {
        async commonQuestion() {
            const { course_id, resource_id } = this.$route.query;
            let params = {
                resource_id,
                course_id,
                resource_type: 1,
                ps: 30,
            };
            this.loading1 = true;
            let ret = await commonQuestion(params);
            this.loading1 = false;
            if (ret.status == 0) {
                this.quizlist = ret.result;
            }
        },
        goGbot() {
            bindEvents(W2_STUDYVIEDO_DRAWER_CLICK_NOTE_ASKQUESTIONS);
            if (!this.allow_question) {
                return this.$message({
                    message: lang[window.GD_LANG].notAsk,
                });
            }
            const course_id = this.$route.query.course_id; //课程id
            const resource = this.$route.query.resource_id; //资源条目id
            const payload = this.$route.query.syllabusitem_id; //大纲id
            const resource_id = this.video_id; //视频id
            try {
                let picurl = "";
                this.gPlayer = window.gdplayer;
                let duration = this.gPlayer && this.gPlayer.core ? this.gPlayer.core.duration : this.gPlayer.video.duration;
                let currentTime = this.gPlayer.video && this.gPlayer.video.currentTime || this.gPlayer.getCurrentTime();

                let data = {
                    course_id: course_id, // 课程id
                    resource: resource, //资源条目id
                    resource_id: resource_id, // 资源id（视频id 题目id）
                    payload: payload, // 大纲条目id
                    gbot_source: 1, //来源  1课程  2题库
                    total_time: duration, // 视频总时长
                    capture_time: currentTime, // 截图观看时长
                    image: picurl, // 截图图片
                    project_id: this.project_id, // 项目id
                    subject_id: this.subject_id, // 科目id
                    type: 1, // 类型 1视频 2题目
                };
                const { total_time, capture_time } = data;
                window.open(
                    `//${prefix}gbot.gaodun.com/update-course/${course_id}/${this.project_id}/${this.subject_id}/${resource}/1?total_time=${total_time}&capture_time=${capture_time}&resource=${resource}&payload=${payload}&source=1&source_type=saas&client_source=0&client_type=0&client_version=0`
                );
            } catch (error) {
                this.$message({
                    message: "播放器还在加载中，请稍后再试哦~"
                });
            }
        },
        getGbotUrlSuccessCallBack(ret) {
            if (ret.status === 0) {
                window.open(
                    "//" +
                        prefix +
                        "gbot.gaodun.com/?cid=" +
                        ret.data.conversation_id +
                        "&crid=" +
                        ret.data.conversation_record_id +
                        "",
                    "_blank"
                );
            }
        },
    },
    watch: {
        $route: ["commonQuestion"],
    },
    created() {
        if (this.isShowQuestion) {
            this.commonQuestion();
        }
    },
};
</script>
