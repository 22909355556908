<template>
    <div class="nav-menu-wrap">
        <div class="w_1090 clear">
            <div class="h-menu" >
                <router-link
                    active-class="h-menu-link--active"
                    class="h-menu-link"
                    tag="div"
                    :to="{name: item.index, params: {cid: course_id}, query:{vid: vid, refSite: refSite}}"
                    v-for="(item, index) in menu"
                    :key="index"
                    @click.native = buryPoint(item.index)>
                    {{ $t(`route.${item.index}` ) }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

import { bindEvents } from '../../util/sensor-data';
import { W2_CLICK_COURSEHOMETAB,W2_CLICK_SYLLABUSTAB,W2_CLICK_STUDYRECORDTAB,W2_CLICK_HANDOUTSDOWNLOAD} from '@/config';

export default {
    data() {
        return {};
    },
    computed: {
        course_id() {
            return this.$route.params.cid;
        },
        vid(){
            return this.$route.query.vid || '';
        },
        refSite(){
            //领扣标示
            return this.$route.query.refSite || '';
        },
        isAudition() {
            //是否为体验课
            return +this.$store.state.home.course_info.audition === 1;
        },
        menu() {
            const courseTitles = this.$store.state.home.course_info.course_titles || [];
            if (!this.isAudition) {
                return courseTitles;
            }
            let i = null;
            courseTitles.forEach((item, ind) => {
                if (item.index === 'handoutsDownload') {
                    i = ind;
                    courseTitles.splice(i, 1);
                }
            });            
            return courseTitles;
        }
    },
    methods: {
        //检查路由
        checkRouter() {
            let resultMenu = this.menu; //用户菜单
            let name = this.$route.name; //当前跳转的路由
            let isPush = true; //标志是否跳转
            switch (name) {
                case 'home':
                case 'syllabus':
                case 'glive':
                case 'knowledgeDominoes':
                case 'handoutsDownload':
                case 'classPk':
                    for (var k in resultMenu) {
                        if (resultMenu[k].index == name) {
                            isPush = false;
                            break;
                        }
                    }
                    break;
                case 'exercise':
                case 'note':
                case 'question':
                    isPush = false;
                    break;
                default:
                    isPush = true;
                    break;
            }
            if (isPush) {
                this.$router.push({
                    name: resultMenu[0].index,
                    params: this.$route.params,
                    query: {
                        vid: this.vid,
                        refSite: this.refSite
                    }
                });
                return;
            }
            if (this.$route.query.vid === undefined || this.$route.query.refSite === undefined) {
                this.$router.replace({
                    path: this.$route.path,
                    params: this.$route.params,
                    query: {
                        vid: this.vid,
                        refSite: this.refSite
                    }
                })
            }
        },
        buryPoint(keyName) { 
            switch (keyName) { 
                case 'home':
                    bindEvents(W2_CLICK_COURSEHOMETAB);
                    break;
                case 'syllabus':
                    bindEvents(W2_CLICK_SYLLABUSTAB);
                    break;
                case 'studyRecord':
                    bindEvents(W2_CLICK_STUDYRECORDTAB);
                    break; 
                case 'handoutsDownload':
                     bindEvents(W2_CLICK_HANDOUTSDOWNLOAD);
                    break; 
                default:       
            }
        }
    },
    watch: {
        menu: {
            handler(newVal, oldVal) {
                newVal && newVal.length && this.checkRouter();
            },
            immediate: true
        },
        $route: "checkRouter"
    }
};
</script>
