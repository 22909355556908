<template>
    <div class="module-list-exercise note-list-group" v-loading="zLoading"
         :element-loading-text="loadingText">
        <div class="contentBox" v-for="item in dataList" :key="item.id" v-if="item.item_total&&item.item_total!=0">
            <div class="box-title">
                <span class="box-name black_16">{{item.name}}</span>
            </div>
            <div class="box-content">
                <ul class="content-list-group">
                    <li class="group-items" v-for="sItem in item.syllabus" :key="sItem.id" v-if="sItem.item_total&&sItem.item_total!=0">
                        <div class="items-name">{{sItem.name}}</div>
                        <div class="items-info">
                            <span>{{sItem.item_total?sItem.item_total:0}}{{$t('total')}}</span>
                        </div>
                        <div class="items-actions">
                            <router-link
                                tag="a"
                                class="gr_bor_btn"
                                :to="`/note/detail/${course_id}?stageid=${item.id}&chapterid=${sItem.id}&vid=${vid}&refSite=${refSite}&name=${sItem.name}&cs_item_ids=${sItem.cs_item_ids}`"
                                @click.native = buryPoint
                            >{{$t('view')}}
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style>
    .note-list-group .el-loading-mask{
        height: calc(100vh - 305px);
    }
</style>
<script>
import { bindEvents } from '../../util/sensor-data';
import { W2_NOTE_CLICK_VIEW } from '@/config';

    export default {
        components: {},
        props:[
            'dataList',
            'zLoading',
            'loadingText'
        ],
        data() {
            return {

            }
        },
        computed: {
            course_id(){
                return this.$route.params.cid;
            },
            vid() {
                return this.$route.query.vid || '';
            },
            refSite() {
                return this.$route.query.refSite || '';
            }
        },
        mounted() {},
        updated(){},
        methods: {
            buryPoint() { 
                bindEvents(W2_NOTE_CLICK_VIEW)
            }
        },
        watch: {},
        created() {},
        beforeDestroy() {}
    }
</script>
