<template>
    <div class="video-study">
        <div class="video-header clear">
            <!-- 头部 -->
            <div class="video-header-tools">
                <span class="videoiconitem" @click="changeCollapse(1)">
                    <span class="notetab">{{ $t("videoPlay.note") }}</span>
                </span>
                <span class="videoiconitem" @click="changeCollapse(2)">
                    <span class="questiontab">{{ $t("videoPlay.ask") }}</span>
                </span>
            </div>
        </div>
        <div class="video-content clear">
            <!-- <div id="videoPlay" class="video-play" ref="videoPlay"></div> -->
            <div  class="video-play"  ref="videoPlayConten">
            <SubPlayer 
                :video_id="video_id" 
                v-if="video_id" 
                ref="qiankunPlayer" 
                :onPlay="onPlay"
                :initPlayer="initPlayer"
                :businessType="100"
                :sceneType="110"
                :businessNote="businessNote"
                :callbackSpeed="callbackSpeed"
                :oncustomfinish="oncustomfinish"
            />
            </div>
            <div class="buy-course-video" v-if="showBuyCor">
                <p class="vipt" :class="{ 'padding-r': isCollapse }">
                    <img
                        class="small-icon"
                        src="../../assets/images/shiting/left-icn.png"
                    />
                    <span>{{ $t("videoPlay.vip") }}</span>
                    <img
                        class="small-icon"
                        src="../../assets/images/shiting/right-icn.png"
                    />
                </p>
                <p class="text" :class="{ 'padding-r': isCollapse }">
                    {{ $tc("videoPlay.becomeVip", 1) }}<br />
                    {{ $tc("videoPlay.becomeVip", 3) }}
                </p>
                <el-button
                    type="success"
                    class="buy-btn"
                    :class="{ 'margin-r': isCollapse }"
                    @click="goBuy"
                    >{{ $t("videoPlay.buyVip") }}
                </el-button>
            </div>
        </div>
        <!-- 工具栏 -->
        <div class="tools-box" ref="toolsBox">
            <i
                class="toolsBoxclose"
                v-show="toolsBoxclose"
                @click="closeCollapse"
            ></i>
            <PlayTool
                :activeName="parentMsg"
                :video_id="video_id"
                :player="player"
            ></PlayTool>
        </div>
        <div id="micro-container"></div>
    </div>
</template>
<style>
.video-content {
    width: 100%;
    height: -moz-calc(100% - 60px);
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px);
    position: relative;
}
.buy-course-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/shiting/video-bg.png");
    background-size: cover;
    /*background: rgba(0, 0, 0, 0.6);*/
    color: #fff;
    text-align: center;
}
.padding-r {
    padding-right: 320px;
}
.margin-r {
    margin-right: 320px;
}
.vipt {
    font-size: 30px;
    margin: 150px 0 35px;
}
.text {
    font-size: 16px;
    line-height: 30px;
}
.buy-btn {
    width: 180px;
    color: #fff;
    background: #35c25e;
    margin-top: 40px;
}
.small-icon {
    width: 105px;
    height: 23px;
}
</style>
<script>
import PlayTool from "../videoStudy/PlayTool.vue";
import Vue from "../../common/Vue.js";
import SubPlayer from "./sub-player.vue";
import { reqResourceDetail } from "../../api/study.js";
import { getCsItemInfo } from "../../api/record.js";
import { studyPath } from "../../util/util.js";
import { getEnv } from "../../util/config.js";
import { RESOURCE_PROGRESS_STATUS_FINISH } from "../../util/keys";
import lang from "../../language/lang";
import { ResourceReport } from '../../mixins'
import { bindEvents } from "../../util/sensor-data";
import { W2_VIEWSCREEN_STDUYVIDEO,W2_STUDYVIDEO_CLICK_NOTE,W2_STUDYVIDEO_CLICK_QUESTION,W2_STUDYVIEDO_VIEWSCREEN_DRAWER} from "@/config";

import { loadMicroApp } from 'qiankun';
import { environment } from '@gaodun.com/gtools';

let prefix = getEnv();

export default {
    components: {
        PlayTool,
        SubPlayer
    },
    mixins: [ResourceReport],
    data() {
        return {
            video_id: "", //视频id
            full_video_id: "",
            videoPlay: "", //动画盒子
            toolsBox: "", //动画盒子
            timer: "", //查询视频播放时间
            toolsBoxclose: false, //右侧工具弹框的叉叉显示隐藏
            parentMsg: 1,
            player: {}, //播放器
            showBuyCor: false, // 不可试听显示提醒购课页
            isCollapse: false,
            isFirstPlay: true, //视频是否第一次播放
            echoLoop: null,
            echoLoopTime: 10 * 60 * 1000,
            showUserTimer: null,
            isProgressOver: false, //视频播放进度是否结束
            isEnded: false, //视频播放是否结束
            student_id: 0,
            video_user_id: 0,
            mutationRef: null,
            findRateTagTimer: null,
            realyPlayTime: 0,//G2 视频真实播放时长
            playTimeout: null,
            isG1: true,
            businessNote:{},
            liveId:'',
            discriminator:'',
            reallyPlayTime: 0//真实播放时间
        };
    },
    computed: {
        courseId() {
            return this.$route.query.course_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
        gradationId() {
            return this.$route.query.gradation_id;
        },
        resourceId() {
            return this.$route.query.resource_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
        vid() {
            return this.$route.query.vid || "";
        },
        refSite() {
            return this.$route.query.refSite || "";
        },
        luca_id() {
            return this.$store.state.home.course_info.luca_qa_id
        },
        project_id() {
            return this.$store.state.home.course_info.project_id
        },
        subject_id() {
            return this.$store.state.home.course_info.subject_id
        }
    },
    mounted() {
        bindEvents(W2_VIEWSCREEN_STDUYVIDEO);
        this.$nextTick(() => {
            this.videoPlay = this.$refs.qiankunPlayer;
            this.toolsBox = this.$refs.toolsBox;
        });
        Vue.$on("showBuyCourseVideo", (val) => {
            this.showBuyCor = val;
            if (this.player) {
                this.player.pause();
            }
        });
    },
    methods: {
        initLuca() {
            this.lucaChatRef = loadMicroApp(
                {
                    name: 'luca-qa',
                    entry: `https://${environment.getApiEnv()}luca.gaodun.com`,
                    container: '#micro-container',
                    props: {
                        onReceiveMessageFromMicroApp: () => {},
                        // 注册通信微应用的方法
                        registryNoticeMicroAppFn: (callback) => {
                            this.answerCallback = callback;
                        },
                    },
                },
                // 启用严格样式隔离
                { sandbox: { strictStyleIsolation: true } }
            );
        },
        //播放器返回的自定义完成
        oncustomfinish(){
            //完成了不用调了
            if(!this.isProgressOver){
                this.isProgressOver = true; 
                this.updateResourceProgress(
                    RESOURCE_PROGRESS_STATUS_FINISH
                ); 
            }

        },
        initPlayer(subPlayer){
            this.player = subPlayer;
            window.gdplayer = this.player;
            let localDuration = 0;
            this.player.on('play', () => {
                this._sReportEvent();
                const currentTime = this.player && this.player.video && this.player.video.currentTime;
                this.resourceStartTime =  +(currentTime * 1000).toFixed(0);
                if (!this.playTimeout) {
                    this.playTimeout = setInterval(() => {
                        this.realyPlayTime++;
                    }, 1000);
                }
            });
            this.player.on('pause', () => {
                this._resourceRepeat('onpause');
                this.playTimeout && clearInterval(this.playTimeout);
                this.playTimeout = null;
                setTimeout(() => {
                    this.realyPlayTime = 0;
                }, 1000);
                try {
                    const currentTime = this.player && this.player.video && this.player.video.currentTime || 0;
                    if (
                        Math.abs(localDuration - currentTime) < 1.5 &&
                        localDuration &&
                        !this.isEnded
                    ) {
                        this.isEnded = true;
                        // this.$emit("on-next-resource"); //切换到下一个资源
                    }
                } catch (error) {
                    console.log("🚀 ~ file: VideoModulePlay.vue:421 ~ this.player.on ~ error:", error)
                }
            })
            this.player.on('canplay', () => {
                setTimeout(() => {
                    const currentTime =  this.player &&  this.player.video && this.player.video.currentTime;
                    this.resourceStartTime =  currentTime * 1000;
                }, 0);
            })
            this.player.on('timeupdate', () => {
                if (this.isFirstPlay) {
                    this.isFirstPlay = false;
                }
            })
            // 上报回调事件
            this.player.on('report_update', (reportInfo) => {
            }); 

            this.player.on('durationchange', () => {
                const currentTime = this.player &&  this.player.video && this.player.video.currentTime;
                localDuration = currentTime;
            })
        },
        onPlay(){
            console.log("🚀 ~ file: VideoModulePlay.vue:188 ~ onPlayonPlayonPlayonPlay ----");
        },
        async changeCollapse(num) {
            if (num === 2 && this.luca_id) {
                const res =  await getCsItemInfo({csItemId: this.syllabusItemId})
                const result = res.result || {}
                this.answerCallback({
                    type: 'open',
                    params: {
                        lucaId: this.luca_id,
                        courseId: this.courseId,
                        courseType: 10,
                        entrySource: 100538099, // 提问入口 全局
                        resourceType: 1, 
                        clientSource: 'gaodun-pc',
                        sceneSource: 1,
                        resourceId: this.resourceId,
                        projectId: result.projectId || this.project_id,
                        subjectId: result.subjectId || this.subject_id,
                        twoLevelSubjectId: result.twoLevelSubject || 0,
                    },
                    callbacks: {
                            getVideoTime: () => {
                            // 视频学习下，传入获取当前视频播放时间进度的回调，方便内部答疑的时候实时获取当前播放进度
                            return Math.round(this.player.video ? this.player.video.currentTime : 0);
                        },
                    },
                })
                return;
            }
           
            switch (num) {
                case 1:
                    bindEvents(W2_STUDYVIDEO_CLICK_NOTE);
                    break;
                case 2:
                    bindEvents(W2_STUDYVIDEO_CLICK_QUESTION);
                    break;
                default:
            }
            Vue.$emit("videostatus", num);
            this.parentMsg = num;
            if (!this.isCollapse) {
                this.toolsBox.style.width = "320px";
                this.toolsBox.style.opacity = 1;
                this.toolsBoxclose = true;
                this.isCollapse = true;
                this.$refs.videoPlayConten.style.marginRight = "320px";
             
               
                bindEvents(W2_STUDYVIEDO_VIEWSCREEN_DRAWER);
                
            } else {
                this.toolsBox.style.width = "0px";
                this.toolsBox.style.opacity = 0;
                 this.toolsBoxclose = false;
                this.isCollapse = false;
                this.$refs.videoPlayConten.style.marginRight = "0px";
               
            }
        },
        closeCollapse() {
            if (this.isCollapse) {
                this.toolsBox.style.width = "0px";
                this.toolsBox.style.opacity = 0;
                 this.toolsBoxclose = false;
                this.isCollapse = false;
                this.$refs.videoPlayConten.style.marginRight = "0px";
               
            }
        },
        async getResourceItem() {
            this.isFirstPlay = true;
            this.isProgressOver = false;
            this.isEnded = false;

            const ret = await reqResourceDetail(this.resourceId, {
                courseId: this.courseId,
                syllabusId: this.syllabusId,
                csItemId: this.syllabusItemId,
            });

            let video_id =
                ret.result &&
                ret.result.resource &&
                ret.result.resource.video_id; //视频id

            if (ret.status !== 0 || !video_id) {
                this.$message({
                    message: lang[window.GD_LANG].videoPlay.videoError,
                });
                this.goResource404();
                return;
            }
            this.discriminator = ret.result.resource && ret.result.resource.discriminator;
            this.liveId = ret.result.resource && ret.result.resource.live_id
            this.video_id = video_id;
            this.full_video_id = video_id;
            //更新一下播放器
            const qiankunPlayer = this.$refs.qiankunPlayer;
            this.businessNote = {
                csItemId: this.syllabusItemId,
                resourceId: this.resourceId,
                // "progressStatus": 0,// 资源完成状态 0：未完成，1：已完成，2：进行中
                liveId: this.liveId,
                // videoPlayerRecovery: 0,//  0：正常模式，1：灾备模式
                resourceType: this.discriminator
            };
            if(qiankunPlayer){
                qiankunPlayer.init(this.video_id, this.businessNote);
            }
        },
        //跳转到资源404
        goResource404() {
            this.$router.replace({
                name: "Resource404",
                query: this.$route.query,
            });
        },
        //切换学习的资源 视频 讲义 试卷...
        async openStudyPage(itemData) {
            if (!itemData) return;
            const { id, resource_id } = itemData;

            let path = "";
            path = await studyPath(itemData, this.courseId);
            if (!path) {
                return false;
            }
            this.$router.push({
                path: path,
                query: {
                    course_id: this.courseId,
                    gradation_id: this.gradationId, //阶段id
                    syllabus_id: this.syllabusId, //大纲id
                    syllabusitem_id: id, //大纲条目id
                    resource_id: resource_id, //资源的id
                    vid: this.vid,
                    refSite: this.refSite,
                },
            });
        },
        async updateResourceProgress(status) {
            const params = {
                course_id: +this.courseId, //类型：Number  必有字段  备注：课程id
                cs_item_id: +this.syllabusItemId, //类型：Number  必有字段  备注：条目id
                resource_id: +this.resourceId, //类型：Number  可有字段  备注：资源id
                status: status, //类型：Number  必有字段  备注：进度状态：1-完成；2-进行中
            };

            await this.$http.reqProgressSync(params);
            this.$emit("on-update-syllabus", `progress-${status}`);
        },
        goBuy() {
            window.open(
                `//${prefix}v.gaodun.com/shopping/check/cid/${this.vid}.html`
            );
        },
        _findGPlayerRateTagElement() {
            return new Promise((resolve) => {
                let observerDOM;
                let findSecond = 0;
                if (this.findRateTagTimer) {
                    clearInterval(this.findRateTagTimer);
                    this.findRateTagTimer = null;
                }
                this.findRateTagTimer = setInterval(() => {
                    if (findSecond > 5) {
                        clearInterval(this.findRateTagTimer);
                        this.findRateTagTimer = null;
                        resolve(null)
                    }
                    if (this.player
                        && this.player.render
                        && this.player.render.rightPanel
                        && this.player.render.rightPanel.rateTag.element
                    ) {
                        observerDOM = this.player.render.rightPanel.rateTag.element;
                        clearInterval(this.findRateTagTimer);
                        this.findRateTagTimer = null;
                        resolve(observerDOM)
                    }
                    findSecond++;
                }, 1000);
            })
        },
        async initGPlayerRateChange() {
            const observerDOM = await this._findGPlayerRateTagElement();
            if (this.mutationRef) {
                this.mutationRef.disconnect();
                this.mutationRef = null;
            }
            if (observerDOM) {
                const callback = (mutations) => {
                    const GplayerRate = mutations[0].removedNodes[0].nodeValue
                        ? mutations[0].removedNodes[0].nodeValue.slice(0,3)
                        : '';
                    if (this._getVTimer()) {
                        // 立即上报
                        this._resourceRepeat('setSpeed', {playSpeed: GplayerRate});
                        // 结束计时
                        this._eReportEvent();
                        // 重新计时
                        this._sReportEvent()
                    }
                };

                const config = {
                    attributes: false,
                    attributeOldValue: false,
                    characterData: true,
                    characterDataOldValue: true,
                    childList: true,
                    subtree: true,
                };
                this.mutationRef = new MutationObserver(callback);
                // cofig设置项必须的有
                this.mutationRef.observe(observerDOM, config);
            }
        },
        callbackSpeed(speedNum){
            const localStorageSpeed = localStorage.getItem('GplayerRate') || -1;
            if(+localStorageSpeed === speedNum){
                //切换清晰度
                // 立即上报
                this._resourceRepeat('changeVideoQuality');
                // 结束计时
                this._eReportEvent();
            }else{
                if (this._getVTimer()) {
                    // 立即上报
                    this._resourceRepeat('setSpeed');
                    // 结束计时
                    this._eReportEvent();
                    // 重新计时
                    this._sReportEvent();
                }
            }
        },
        createBulletScreen(player) {
            this.checkUserLogin(player.id);
            this.showUserId(player.id);
        },
        checkUserLogin(id) {
            clearInterval(this.echoLoop);

            this.echoLoop = setInterval(() => {
                const container =
                    (document.querySelector("#videoPlay") &&
                        document.querySelector("#videoPlay").children[0]) ||
                    document.querySelector("#videoPlay");
                let domUserID = document.createElement("div");
                domUserID.id = "pos_" + Math.ceil(Math.random() * 1000);
                domUserID.className = "check_user_login";
                domUserID.innerHTML =
                    lang[window.GD_LANG].videoPlay.bulletScreen.before +
                    id +
                    lang[window.GD_LANG].videoPlay.bulletScreen.after;
                container.appendChild(domUserID);
                setTimeout(function () {
                    container.removeChild(domUserID);
                }, 6000);
            }, this.echoLoopTime);
        },
        bindPlayerEvent() {
            this.player.getCurrentTime = () => {
                const currentTime =
                    this.player && this.player.core
                        ? this.player.core.currentTime
                        : 0;
                return parseInt(currentTime || 0);
            };
            this.player.getDuration = () => {
                const duration =
                    this.player && this.player.core
                        ? this.player.core.duration
                        : 0;
                return parseInt(duration || 0);
            };
        },
        showUserId(id) {
            const container =
                (document.querySelector("#videoPlay") &&
                    document.querySelector("#videoPlay").children[0]) ||
                document.querySelector("#videoPlay");
            let domUserID = document.createElement("div");
            const PROGRESS_BAR = 50;
            let maxPosLeft = 100,
                maxPosTop = 100,
                domUserIDWid = 0,
                domUserIDHei = 0;
            let containerWid = container.offsetWidth,
                containerHei = container.offsetHeight;
            let posLeft = Math.floor(Math.random() * 100);
            let posTop =
                posLeft < 0 || posLeft > 80
                    ? Math.floor(Math.random() * 100)
                    : (posTop = Math.floor(Math.random() * 10));
            domUserID.id = "pos_id_" + Math.ceil(Math.random() * 1000);
            let styleText =
                "position: absolute;font-size: 13px; color: #ffffff;" +
                "text-shadow:0 1px 1px #000;" +
                "opacity: 0.1;" +
                "filter: alpha(opacity=10); z-index: 9147483647;";
            domUserID.setAttribute("style", styleText);
            domUserID.style.left = posLeft + "%";
            domUserID.style.top = posTop + "%";
            domUserID.innerHTML = id;
            container.appendChild(domUserID);

            clearInterval(this.showUserTimer);
            this.showUserTimer = setInterval(() => {
                !domUserIDWid ? (domUserIDWid = domUserID.offsetWidth) : "";
                !domUserIDHei ? (domUserIDHei = domUserID.offsetHeight) : "";
                !containerWid ? (containerWid = container.offsetWidth) : "";
                !containerHei ? (containerHei = container.offsetHeight) : "";
                maxPosLeft = Math.floor(
                    ((containerWid - domUserIDWid) / containerWid) * 100
                );
                maxPosTop = Math.floor(
                    ((containerHei - domUserIDHei - PROGRESS_BAR) /
                        containerHei) *
                        100
                );
                posLeft = Math.floor(Math.random() * maxPosLeft);
                if (posLeft < 2 || posLeft > 80) {
                    posTop = Math.floor(Math.random() * maxPosTop);
                } else {
                    posTop = Math.floor(Math.random() * 10);
                }
                domUserID.setAttribute("style", styleText);
                domUserID.style.left = posLeft + "%";
                domUserID.style.top = posTop + "%";
            }, 5000);
        },
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: {
            handler() {
                this.getResourceItem();
            },
            deep: true,
        },
        luca_id: {
            handler(val) {
                if (val && !this.lucaChatRef) {
                    this.initLuca();
                }
            },
            immediate: true
        }
    },
    created() {
        setTimeout(() => {
            //测试方法
            // const qiankunPlayer = this.$refs.qiankunPlayer;
        }, 200);
        this.businessNote = {
                csItemId: this.syllabusItemId,
                resourceId: this.resourceId,
                // "progressStatus": 0,// 资源完成状态 0：未完成，1：已完成，2：进行中
                liveId: this.liveId,
                // videoPlayerRecovery: 0,//  0：正常模式，1：灾备模式
                resourceType: this.discriminator
            };
        if (
            !isNaN(Number(localStorage.getItem("echoLoopTime"))) &&
                Number(localStorage.getItem("echoLoopTime")) !== 0
            ) {
                this.echoLoopTime = Number(localStorage.getItem("echoLoopTime"));
            }
        this.getResourceItem();
    },
    beforeDestroy() {
        this.player = null;
        clearInterval(this.echoLoop);
        if (this.mutationRef) {
            this.mutationRef.disconnect();
            this.mutationRef = null;
        }
        if (this.findRateTagTimer) {
            clearInterval(this.findRateTagTimer);
            this.findRateTagTimer = null;
        }
    },
};
</script>
