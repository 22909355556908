<template>
    <div class="login-page">
        <div class="login-wrap">
            <div v-if="!isPassword">
                <div class="login-box-left">
                    <div class="login-box-left-title">财经云</div>
                    <p>让数据创造价值</p>
                    <p>让工作更有效率</p>
                    <div class="login-bg"></div>
                </div>
                <div class="login-box-right">
                    <div v-show='loginshow == "user"' class="login-input-box">
                        <div class="login-box-right-title"><span>登录</span>请使用高顿账号登录</div>
                        <el-form :model="ruleForm" ref="ruleForm" class="box-conten">
                            <el-form-item prop="user" :rules="filter_rules({required: true, type: 'isAllSpace'})">
                                <span class="container_login">
                                    <img src="http://simg01.gaodunwangxiao.com/uploadimgs/tmp/upload/202109/02/b2251_20210902102315.png">
                                </span>
                                <el-input
                                    type="text"
                                    v-model="ruleForm.user"
                                    @keyup.enter.native="submitForm('ruleForm')"
                                    :autofocus="true"
                                    placeholder="手机／邮箱"
                                    clearable
                                    auto-complete="new-password"
                                />
                            </el-form-item>
                            <el-form-item prop="password" :rules="filter_rules({required: true, type: 'isAllSpace'})">
                                <span class="container_login">
                                    <img style="width: 13px" src="http://simg01.gaodunwangxiao.com/uploadimgs/tmp/upload/202109/02/7f193_20210902102355.png">
                                </span>
                                <el-input
                                    type="password"
                                    v-model="ruleForm.password"
                                    @keyup.enter.native="submitForm('ruleForm')"
                                    placeholder="密码"
                                    clearable
                                    auto-complete="new-password"
                                />
                            </el-form-item>
                            <div style="margin-top: 32px">
                                <el-button
                                    type="primary"
                                    class="login-btn"
                                    :loading="loading"
                                    :disabled="!ruleForm.user || !ruleForm.password"
                                    @click.native.prevent="submitForm('ruleForm')">
                                    {{loading ? '登录中...' : '登录'}}
                                </el-button>
                            </div>
                        </el-form>
                         <div class="login-input-box-button">
                            <el-button type="text" class="forget-password" @click="isShowForgetPassword(true)">忘记密码</el-button>
                            <el-button type="text" @click="handleWXlogin">企业微信扫码登录</el-button>
                        </div>
                    </div>
                    <div v-show='loginshow == "wx"' class="login-qrcode" v-loading="qrcodeLoading">
                        <div id="qrcode"></div>
                        <el-button class="login-qrcode-button" type="text" @click="toggleLoginShow('user')">账号登录</el-button>
                    </div>
                </div>
            </div>
            <ForgetPassword
                @isShowForgetPassword="isShowForgetPassword"
                v-else
            />
        </div>
    </div>
</template>

<script>
import ForgetPassword from "../components/Login/ForgetPassword";
import { consoleAppid } from "../common/tokenConfig.js";
import { getEnv, appid } from "../util/config";
import { getCookie } from "../util/cookie.js";
import { setToken } from "../util/setToken.js";
// import { loadJS } from "../util/util.js";
import { SAAS_TOKEN, SAAS_REFRESH_TOKEN ,SAAS_USER_NAME, ACCESS_TOKEN, REFRESH_TOKEN} from "../util/keys.js";
import { token } from '@gaodun.com/gtools';

let prefix = getEnv();
export default {
    name: "login", //h后台登录系统
    data() {
        return {
            isRest: false,
            ruleForm: {
                user: "",
                password: "",
                appid: consoleAppid
            },
            loading: false,
            qrcodeLoading: false,
            isPassword: false,
            loginshow: 'user',
            msgOpts: {
                type: 'error',
                message: '微信登录失败，请尝试其他方式',
                customClass: 'message-style'
            }
        };
    },
    components: {
        ForgetPassword
    },
    methods: {
        isShowForgetPassword (value) {
            this.isPassword = value;
            const ruleForm = this.$refs['ruleForm'];
            ruleForm && ruleForm.resetFields();
        },
        submitForm(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    let GDSID = getCookie(`${prefix}GDSID`);
                    if (!GDSID) {
                        GDSID = getCookie(`${prefix}GDSID`);
                    }
                    // GDSID = 'dGVpSWtMUXV1UWdRSHpobGJLaGtHVXFReXR1YVpPZmQ=';
                    if (!GDSID) {
                        this.$message({
                            type: "warning",
                            message: "请求失败，请联系技术客服!"
                        });
                        return;
                    }
                    //登录
                    let response = await this.$http.userLogin({
                        ...this.ruleForm,
                        GDSID: GDSID
                    });
                    if (response.data.status == 0) {
                        this.loading = false;
                        setToken(ACCESS_TOKEN, response.headers.accesstoken, 2);
                        setToken(REFRESH_TOKEN,response.headers.refreshtoken, 24 * 7);
                        setToken(SAAS_TOKEN, response.headers.accesstoken, 2);
                        setToken(SAAS_USER_NAME, this.ruleForm.user, 24 * 7);
                        token.setAdminRefreshToken(response.headers.refreshtoken);
                        token.setAdminAccessToken(response.headers.accesstoken);
                        setToken(
                            SAAS_REFRESH_TOKEN,
                            response.headers.refreshtoken,
                            24 * 7
                        );

                        if (document.referrer.includes('gtrs')) {
                            location.href = document.referrer;
                        } else {
                            location.href = `//${getEnv()}cloud-console.gaodun.com/#/home`;
                        }
                    }else if (response.data.status == 99033149) {
                        location.href = `https://${getEnv()}arthas.gaodun.com/editPassword`;
                    } else {
                        this.$message({
                            message: response.data.info,
                            type: "warning"
                        });
                        return;
                    }
                }
            });
        },
        toggleLoginShow (type) {
            this.loginshow = type;
        },
        async handleWXlogin () {
            this.toggleLoginShow('wx');
            // 获取企业配置信息
            const wechatConfig = {
                agentId: '1000147',
                appid: 'ww3e4fc9b495ff0fd8'
            };

            const qrcodeState = Date.now();

            window.sessionStorage.setItem('WX_QRCODE_STATE', qrcodeState);
            // 扫码后回调页面，通过redirect.gaodun.com 重定向到当前页
            // 企业微信将在 url 中添加code 及 state 参数，扫码拒绝授权将无 code 参数
            const redirectUrl = encodeURIComponent(`https://redirect.gaodun.com/?redirect=${encodeURIComponent(`${window.location.origin}/login`)}`);

            // 初始化企业微信二维码
            window.WwLogin({
                id: 'qrcode',
                appid: wechatConfig.appid,
                agentid: wechatConfig.agentId,
                redirect_uri: redirectUrl,
                state: qrcodeState,
                href: ''
            });
        },
        async checkUrlLogin () {
            const qrcodeState = window.sessionStorage.getItem('WX_QRCODE_STATE');
            const queryParams = this.$route.query;

            // 如果参数中有 'code', 且state和登录页存储相同， 即为企业微信扫码登录成功重定向页面
            if ('code' in queryParams && qrcodeState == queryParams.state) {
                this.qrcodeLoading = true;
                this.toggleLoginShow('wx');
                // TODO 换取vigo code 用于登录
                   try {
                        const res = await this.$http.getVigoCodeByWxCode({ code: queryParams.code })
                        if (res.result) {
                            let params = {
                                code: res.result,
                                appid: appid,
                                GDSID: getCookie(`${prefix}GDSID`)
                            }
                            const { status, accessToken, refreshToken, result }= await this.$http.loginByCode(params)

                            // 登录成功
                            if (!status) {
                                this.qrcodeLoading = false;
                                setToken(ACCESS_TOKEN, accessToken, 2);
                                setToken(REFRESH_TOKEN, refreshToken, 24 * 7);
                                setToken(SAAS_TOKEN, accessToken, 2);
                                setToken(SAAS_REFRESH_TOKEN, refreshToken, 24 * 7);

                                token.setAdminAccessToken(accessToken);
                                token.setAdminRefreshToken(refreshToken);
                                location.href = `//${getEnv()}cloud-console.gaodun.com/#/home`;
                            } else {
                              this.$message({...this.msgOpts});
                            }
                        } else {
                           this.$message({...this.msgOpts});
                        }
                   } catch(e) {
                         this.$message({...this.msgOpts});
                   }
                this.qrcodeLoading = false;
            }
        },
        loadScript(url) {
            let script = document.createElement('script');
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    },
    async created() {
        this.loadScript(`//${prefix}s.gaodun.com/web/sso/gdssid_v2.js`)
        this.loadScript('https://rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js');
        this.checkUrlLogin();
    }
};
</script>

<style lang="less" scoped>
.login-page {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;
        min-height: 625px;
        background-color: #f0f2f5;
        .login-wrap {
            position: relative;
            box-sizing: border-box;
            width: 718px;
            height: 342px;
            background: #FFFFFF;
            .login-box-left {
                float: left;
                box-sizing: border-box;
                width: 278px;
                height: 342px;
                padding: 32px 0 24px 32px;
                background: url(http://simg01.gaodunwangxiao.com/uploadimgs/tmp/upload/202109/02/0bce9_20210902102441.png);
                background-size: cover;
                .login-box-left-title {
                    margin-bottom: 8px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #fff;
                }
                p {
                    line-height: 24px;
                    font-size: 14px;
                    color: #E0E2EB;
                }
                .login-bg {
                    position: absolute;
                    width: 120px;
                    height: 37px;
                    bottom: 23px;
                    left: 23px;
                    background: url(http://simg01.gaodunwangxiao.com/uploadimgs/tmp/upload/202109/02/7050a_20210902102522.png);
                    background-size: cover;
                }
            }
            .login-box-right {
                box-sizing: border-box;
                float: left;
                padding: 30px 46px;
                height: 342px;
                width: 440px;
                .login-input-box {
                    .login-box-right-title {
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.65);
                        span {
                            margin-right: 15px;
                            font-size: 30px;
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.85);
                        }
                    }
                    .box-conten {
                        margin-top: 32px;
                        .el-form-item {
                            margin-bottom: 16px;
                            .container_login {
                                position: absolute;
                                top: -3px;
                                left: 12px;
                                height: 38px;
                                line-height: 42px;
                                width: 36px;
                                img {
                                    width: 15px;
                                }
                            }
                            /deep/ .el-input__inner {
                                height: 38px;
                                line-height: 38px;
                                padding: 0 10px 0 35px;
                                background: transparent;
                                -webkit-appearance: none;
                                font-size: 16px;
                                color: #000;
                            }
                        }
                        .login-btn {
                            margin-bottom: 18px;
                            width: 100%;
                            height: 44px;
                            line-height: 38px;
                            padding: 0;
                            text-align: center;
                            font-size: 18px;
                            color: #fff;
                        }
                    }
                    .login-input-box-button {
                        text-align: right;
                        font-size: 16px;
                        .forget-password {
                            float: left;
                            color: rgba(0, 0, 0, 0.65);
                        }
                    }
                }
                .login-qrcode {
                    margin-top: -5px;
                    width: 100%;
                    text-align: center;
                    #qrcode {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 275px;
                        transform: scale(.75);
                    }
                    .login-qrcode-button {
                        text-align: center;
                        font-size: 16px;
                    }
                     /deep/ iframe {
                      background: #fff;
                    }

                }
            }
        }
    }

    .login-wrap .el-input__inner:focus {
        outline: 0;
        border-radius: 2px;
        border: 1px solid #888787;
    }

    .login-wrap .el-input__inner {
        height: 36px !important;
        line-height: 36px !important;
        color: #333 !important;
    }

    .login-wrap .Copyright {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        text-align: center;
    }
    .message-style {
        background: #fff;
        border: none;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        p {
            color: #000!important;
        }
    }
</style>
