import lang from '../language/lang';

const resourceTypeList = [
    {
        'discriminator': 'video',
        'label': lang[window.GD_LANG].syllabus.video,
    },
    {
        'discriminator': 'lecture_note',
        'label': lang[window.GD_LANG].syllabus.lectureNote,
    },
    {
        'discriminator': 'paper',
        'label': lang[window.GD_LANG].syllabus.paper,
    },
    {
        'discriminator': 'legacy_live',
        'label': lang[window.GD_LANG].syllabus.legacyLive,
    },
    {
        'discriminator': 'hermes_live',
        'label': lang[window.GD_LANG].syllabus.legacyLive,
    },
    {
        'discriminator': 'live_playback_link',
        'label': lang[window.GD_LANG].syllabus.legacyLive,
    },
    {
        'discriminator': 'soft_link',
        'label': lang[window.GD_LANG].syllabus.soft_link,
    },
    {
        'discriminator': 'live_new',
        'label': lang[window.GD_LANG].syllabus.live_new,
    },
];

export const Resource2chn = (v) => {
    let chnResource = '';
    if (!v) {
        return chnResource;
    }
    for (let i = 0; i < resourceTypeList.length; i++) {
        if (resourceTypeList[i].discriminator === v) {
            chnResource = resourceTypeList[i].label;
        }
    }
    return chnResource;
};
