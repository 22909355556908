<template>
    <div class="contentBox liveitem">
        <div class="box-title">
            <i class="icon_zhibo"></i>
            <span class="box-name black_16">{{ sylllabusItemName }}</span>
            <span class="goclw">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </span>
        </div>
        <div class="box-content">
            <ul class="content-list-group">
                <li
                    class="group-items"
                    v-for="(item, index) in dataList"
                    :key="index"
                >
                    <div class="items-name">
                        <span>{{ item.duration }}</span>
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="items-actions">
                        <span class="goclw" v-if="item.now_status === 2">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        <button
                            class="gr_bor_btn"
                            v-if="item.now_status === 3"
                            @click="playback(item)"
                        >
                            {{ $t("livePlayback") }}
                        </button>
                        <button
                            class="gr_bor_btn"
                            v-if="item.now_status === 2"
                            @click="golive(item, 1)"
                        >
                            {{ $t("liveToCourse") }}
                        </button>
                        <button
                            class="gr_bor_btn"
                            v-if="item.now_status === 4"
                            @click="golive(item, 2)"
                        >
                            {{ $t("livePlayback") }}
                        </button>
                        <button
                            class="gray_border_0_btn"
                            v-if="item.now_status === 5"
                        >
                            {{ $t("liveNoPlayback") }}
                        </button>
                        <button
                            class="green_border_0_btn"
                            v-if="item.now_status === 1"
                        >
                            {{ $t("liveNoStart") }}
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        //直播列表
        dataList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            sylllabusItemName: (state) => {
                return state.study.sylllabusItemName;
            },
        }),
        courseId() {
            return this.$route.query.course_id;
        },
        syllabusItemId() {
            return this.$route.query.syllabusitem_id;
        },
        syllabusId() {
            return this.$route.query.syllabus_id;
        },
        resourceId() {
            return this.$route.query.resource_id;
        },
    },
    methods: {
        playback(item) {
            //查看回放
            let live_id = item.live_id && item.live_id;
            this.$router.push({
                name: "playback",
                params: { liveid: live_id },
                query: this.$route.query,
            });
        },
        golive(item, statistics_type) {
            // 去看直播
            window.open(item.url);
            this.syncResourceProgress(1);
        },
        async syncResourceProgress(status) {
            await this.$http.reqProgressSync({
                course_id: +this.courseId,
                cs_item_id: +this.syllabusItemId,
                resource_id: +this.resourceId,
                status: status,
            });
            this.$emit("on-update-syllabus", `progress-${status}`);
        },
    },
};
</script>
