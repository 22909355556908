import { environment } from '@gaodun.com/gtools';

export const getEnv = () => {
    return environment.getApiEnv();
}

export const getBaseUrl = () => {
    return `https://${environment.getApiEnv()}`;
}

export const baseURL = 'apigateway.gaodun.com'; //网关域名

export const gbotbaseURL = 'apigateway.gaodun.com/gbotapi'; //gbot网关域名


export const getBrowser = () => {
    let browserName = navigator.userAgent.toLowerCase();
    if (/msie/i.test(browserName) && !/opera/.test(browserName)) {
        return "IE";
    } else if (/firefox/i.test(browserName)) {
        return "Firefox";
    } else if (/chrome/i.test(browserName) && /webkit/i.test(browserName) && /mozilla/i.test(browserName)) {
        return "Chrome";
    } else if (/opera/i.test(browserName)) {
        return "Opera";
    } else if (/webkit/i.test(browserName) && !(/chrome/i.test(browserName) && /webkit/i.test(browserName) && /mozilla/i.test(browserName))) {
        return "Safari";
    } else {
        return "";
    }
}

// 获取视频播放的源
export const getUseHTML5 = () => {
    var USE_HTML5 = ((navigator.userAgent.indexOf("Android") > -1) ||
        (navigator.userAgent.indexOf("iPhone") > -1) ||
        (navigator.userAgent.indexOf("iPad") > -1) ||
        (window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"'))
    );
    if (navigator.userAgent.indexOf('Chrome/45') > -1) {
        USE_HTML5 = false;
    }
    return USE_HTML5;
}

export const downloadHandout = `${getBaseUrl()}study.gaodun.com/calais/front/v1/download`;


//推出登录的地址
export const getLogoutUrl = (saas_url) => {
    let search = location.search;
    let prefix = getBaseUrl();

    let logoutUrl = `${prefix}v.gaodun.com/Member/logout?saas_url=${saas_url}`;

    if (search.indexOf('refSite=finance') > -1){
        logoutUrl = `${prefix}finance.gaodun.com/?showLogin=1`;
    }
    return logoutUrl;
}
export const appid = 190705;
