export const formatDate = () => {
    var d = new Date();
    var YY = d.getFullYear();
    var MM = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
    var dd = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
    var hh = d.getHours() > 9 ? d.getHours() : '0' + d.getHours();
    var mm = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes();
    return `${YY}-${MM}-${dd} ${hh}:${mm}`;
};

export const formatDateTime = (time) => {
    let d = new Date(parseInt(time) * 1000);
    let YY = d.getFullYear();
    var MM = (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1));
    var dd = (d.getDate() > 9 ? d.getDate() : '0' + d.getDate());
    var hh = d.getHours() > 9 ? d.getHours() : '0' + d.getHours();
    var mm = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes();

    return `${YY}-${MM}-${dd} ${hh}:${mm}`;
};

export const DateDiff = (sDate1, sDate2) => { //sDate1和sDate2是2006-12-18格式
    var aDate, oDate1, oDate2, iDays;
    aDate = sDate1.split("-")
    oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]) //转换为12-18-2006格式
    aDate = sDate2.split("-")
    oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
    iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) //把相差的毫秒数转换为天数
    return iDays + '天'
}